import { RequestWithRelations } from '@/models/request';
import { ArchivedMessage } from '@/routes/Requests/Request/components/RequestStepMessage/components/ArchivedMessage/ArchivedMessage';
import PaymentMessage from '@/routes/Requests/Request/components/RequestStepMessage/components/PaymentMessage';
import TransitMessage from '@/routes/Requests/Request/components/RequestStepMessage/components/TransitMessage';
import ValidationMessage from '@/routes/Requests/Request/components/RequestStepMessage/components/ValidationMessage';

export const RequestStepMessage = ({ request }: { request: RequestWithRelations }) => {
  if (request.isArchived) {
    return <ArchivedMessage request={request} />;
  }

  if (request.isInValidationStep) {
    return <ValidationMessage request={request} />;
  }

  if (request.isInPaymentStep) {
    return <PaymentMessage request={request} />;
  }

  if (request.isInPendingClientDeliveryStep) {
    return <TransitMessage request={request} />;
  }

  return null;
};

import { ReactNode } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Tooltip from '@/design_system/Tooltip';
import { createBEMClasses } from '@/utils/classname';

import './Stepper.css';

const { block, element } = createBEMClasses('stepper');

export interface StepProps {
  name: string;
  label?: ReactNode;
  status: 'done' | 'in-progress' | 'pending' | 'cancelled' | 'issue';
}

export interface StepperProps {
  variant?: 'linear' | 'round';
  size?: 'small' | 'medium' | 'large';
  steps: StepProps[];
  showStepCount?: boolean;
  labelVariant?: 'awaiting' | 'pending' | 'done';
  children?: ReactNode;
}

const Statuses = {
  done: msg({ id: 'stepper.status.done', message: 'Done' }),
  'in-progress': msg({ id: 'stepper.status.in-progress', message: 'In progress' }),
  pending: msg({ id: 'stepper.status.pending', message: 'Pending' }),
  cancelled: msg({ id: 'stepper.status.cancelled', message: 'Cancelled' }),
  issue: msg({ id: 'stepper.status.issue', message: 'Issue' }),
};

export const Stepper = ({
  variant = 'linear',
  size = 'small',
  steps,
  showStepCount = false,
  labelVariant,
  children,
}: StepperProps) => {
  const { _ } = useLingui();

  /**
   * The current step is the first step 'in-progress', or the first step 'pending', or the last step.
   */
  let currentStepIndex = steps.findIndex((step) => step.status === 'in-progress');
  if (currentStepIndex < 0) {
    currentStepIndex = steps.findIndex((step) => step.status === 'pending');
  }
  if (currentStepIndex < 0) {
    currentStepIndex = steps.length - 1;
  }
  const totalStepsCount = steps.length;

  const stepDegrees = 360 / totalStepsCount;
  const gapDegrees = 15;

  return (
    <div
      className={block({
        variant,
        size,
        'show-step-count': showStepCount,
      })}
    >
      <div className={element('header')}>
        <span
          className={element('header__label', {
            'has-variant': !!labelVariant,
            variant: labelVariant,
          })}
        >
          <span className={element('header__label__step-count')}>
            <Trans id="stepper.step">
              Step {currentStepIndex + 1}/{totalStepsCount}:
            </Trans>
          </span>
          <span className={element('header__label__text')}>
            {steps[currentStepIndex].label ?? steps[currentStepIndex].name}
          </span>
        </span>
        <span className={element('header_children')}>{children}</span>
      </div>
      {variant === 'linear' && (
        <ol className={element('steps')}>
          {steps.map((step, index) => (
            <li
              key={index}
              className={element('step', {
                status: step.status,
                current: index === currentStepIndex,
              })}
              aria-label={_(
                msg({
                  id: 'stepper.step.label',
                  message: `Step ${step.name}: ${_(Statuses[step.status])}`,
                })
              )}
            >
              <div className={element('step__indicator')}>
                <div className={element('step__indicator__first')} />
                <div className={element('step__indicator__second')} />
              </div>
              <span className={element('step__label')}>{step.label ?? step.name}</span>
            </li>
          ))}
        </ol>
      )}
      {variant === 'round' && (
        <svg
          className={element('steps')}
          viewBox="0 0 100 100"
          style={{
            strokeDasharray: `${stepDegrees - gapDegrees} ${360 - stepDegrees + gapDegrees}`,
          }}
        >
          {steps.map((step, index) => (
            <circle
              key={index}
              className={element('step', { status: step.status })}
              cx="50"
              cy="50"
              r="42.5"
              transform={`rotate(${stepDegrees * index} 50 50)`}
              pathLength={360}
              aria-label={_(
                msg({
                  id: 'stepper.step.label',
                  message: `Step ${step.name}: ${_(Statuses[step.status])}`,
                })
              )}
            />
          ))}
        </svg>
      )}
    </div>
  );
};

export const StepperTooltip = ({
  steps,
  children,
}: {
  steps: StepProps[];
  children?: ReactNode;
}) => {
  let currentStepIndex = steps.findIndex((step) => step.status === 'pending') - 1;
  if (currentStepIndex < 0) {
    currentStepIndex = 0;
  }
  const totalStepsCount = steps.length;

  return (
    <Tooltip
      content={
        <p>
          <span className="paragraph-400-medium text-disabled">
            <Trans id="stepper.step">
              Step {currentStepIndex + 1}/{totalStepsCount}:
            </Trans>
          </span>
          <br />
          <span className="paragraph-200-regular">{steps[currentStepIndex].name}</span>
        </p>
      }
    >
      <Button variant="style-less">{children}</Button>
    </Tooltip>
  );
};

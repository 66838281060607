import { CSSProperties, ReactNode, useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Loader from '@/components/Loader';
import Button from '@/design_system/Button';
import Menu from '@/design_system/Menu';
import { DangerMenuItem, MenuItem } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import IconDownload from '@/icons/Download.svg';
import IconMore from '@/icons/More.svg';
import IconTrash from '@/icons/Trash.svg';
import IconZoomIn from '@/icons/ZoomIn.svg';
import { createBEMClasses } from '@/utils/classname';

import PhotoDialog from './PhotoDialog';

import './PhotoCard.css';

export type PhotoCardProps = {
  name: string;
  alt?: string;
  url?: string;
  loading?: boolean;
  onDelete?: () => void;
  onZoom?: () => void;
  variant?: 'default' | 'brand';
  customIcon?: ReactNode;
  imgStyle?: CSSProperties;
};

const { block, element } = createBEMClasses('photo-card');

const PhotoCard = ({
  name,
  url,
  alt,
  loading,
  onDelete,
  onZoom,
  variant = 'default',
  customIcon,
  imgStyle,
}: PhotoCardProps) => {
  const { _ } = useLingui();
  const [showDefaultDialog, setShowDefaultDialog] = useState(false);

  if (loading || !url || !alt) {
    return (
      <div className={block()} aria-label={name}>
        <Stack justifyContent="center" alignItems="center" className={element('loader')}>
          <Loader />
        </Stack>
      </div>
    );
  }

  const zoomLabel = _(msg({ id: 'design-system.photo-card.full-screen', message: 'Full screen' }));
  const zoom = () => {
    onZoom ? onZoom() : setShowDefaultDialog(true);
  };

  return (
    <>
      <div className={block()} aria-label={name}>
        <img src={url} alt={alt} style={imgStyle} />
        <div className={element('zoom-overlay')}>
          <button className={element('zoom')} onClick={zoom} tabIndex={-1} aria-label={zoomLabel} />
          <Menu
            placement="bottom"
            trigger={
              <Button
                iconOnly
                variant={variant === 'brand' ? 'secondary-brand' : 'secondary'}
                size="medium"
                ariaLabel={_(msg({ id: 'design-system.photo-card.menu', message: 'Options' }))}
              >
                <IconMore />
              </Button>
            }
            onAction={(action) => {
              switch (action as 'zoom' | 'download' | 'delete') {
                case 'zoom':
                  zoom();
                  break;
                case 'download':
                  window.open(url, '_blank');
                  break;
                case 'delete':
                  onDelete?.();
                  break;
                default:
                  break;
              }
            }}
          >
            <MenuItem id="zoom" size="large">
              <Stack row gap="0.5rem" alignItems="center">
                <IconZoomIn style={{ fontSize: '1.25rem' }} />
                {zoomLabel}
              </Stack>
            </MenuItem>
            <MenuItem id="download" size="large">
              <Stack row gap="0.5rem" alignItems="center">
                <IconDownload style={{ fontSize: '1.25rem' }} />
                <Trans id="design-system.photo-card.download">Download</Trans>
              </Stack>
            </MenuItem>
            {onDelete && (
              <DangerMenuItem id="delete" size="large">
                <Stack row gap="0.5rem" alignItems="center">
                  <IconTrash style={{ fontSize: '1.25rem' }} />
                  <Trans id="design-system.photo-card.delete">Delete</Trans>
                </Stack>
              </DangerMenuItem>
            )}
          </Menu>
          <div className={element('custom-icon')}>{customIcon}</div>
        </div>
      </div>
      {!onZoom && (
        <PhotoDialog
          url={url}
          alt={alt}
          isOpen={showDefaultDialog}
          onOpenChange={setShowDefaultDialog}
          customIcon={customIcon}
        />
      )}
    </>
  );
};

export default PhotoCard;

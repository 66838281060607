import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRequests } from '@/models/request';
import { useShipments } from '@/models/shipment';
import useViewPort from '@/utils/useViewport';

import Desktop from './Desktop';
import Mobile from './Mobile';

import './Scan.css';

const Scan = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();
  const { isMobile } = useViewPort();
  const [code, setCode] = useState<string>();

  let [orgSlug, parsedCode] = parseCode(code); // eslint-disable-line prefer-const

  // Legacy (May 2024): Handle request qr codes generated before we added the `R24-` prefix
  if (parsedCode?.startsWith('A') && parsedCode.length === 4) {
    parsedCode = `R24-${parsedCode}`;
  }

  const { data: { requests } = { requests: [] }, status: statusRequests } = useRequests(
    {
      reference: parsedCode,
    },
    {
      enabled: !!parsedCode,
    }
  );

  const { data: { shipments: shipmentsByRef } = { shipments: [] }, status: statusShipmentsByRef } =
    useShipments(
      {
        reference: parsedCode,
      },
      {
        enabled: !!parsedCode,
      }
    );

  // This is probably a Mondial Relay tracking barcode.
  // We need to remove the first 2 characters and the last 16 characters to get the tracking ID
  if (parsedCode?.match(/^\d{2}\d{8}0101\d{12}/)) {
    parsedCode = parsedCode.slice(2, 10);
  }

  const {
    data: { shipments: shipmentsByTrackingId } = { shipments: [] },
    status: statusShipmentsByTrackingId,
  } = useShipments(
    {
      trackingId: parsedCode,
    },
    {
      enabled: !!parsedCode,
    }
  );

  const results = useMemo(
    () => [...requests, ...shipmentsByRef, ...shipmentsByTrackingId],
    [requests, shipmentsByRef, shipmentsByTrackingId]
  );

  const resultStatus =
    code === undefined
      ? 'idle'
      : statusRequests === 'pending' ||
          statusShipmentsByRef === 'pending' ||
          statusShipmentsByTrackingId === 'pending'
        ? 'loading'
        : results.length > 0
          ? 'success'
          : 'error';

  useEffect(() => {
    // If a user tries to scan a QR Code with `/requests/claim` in the URL, we should redirect it
    // to the dedicated page
    const isClaimRequestUrl = parsedCode?.match(/\/requests\/claim(.*)/);
    if (!!isClaimRequestUrl && isClaimRequestUrl.length > 0) {
      onClose();
      navigate(isClaimRequestUrl[0]);
    } else if (resultStatus === 'success') {
      // Show the success status for 2 seconds before redirecting
      setTimeout(() => {
        onClose();

        // Only 1 result, redirect to it
        if (results.length === 1) {
          if (results[0].reference.startsWith('R')) {
            navigate(`/requests/${results[0].id}`);
          } else {
            navigate(`/shipments/${results[0].id}`);
          }
          return;
        }

        // Multiple results, let's try to find the correct one based on the organization slug
        if (orgSlug) {
          const resultsFilteredByOrg = results.filter(
            (result) => result.organization.slug === orgSlug
          );

          if (resultsFilteredByOrg.length === 1) {
            if (resultsFilteredByOrg[0].reference.startsWith('R')) {
              navigate(`/requests/${resultsFilteredByOrg[0].id}`);
            } else {
              navigate(`/shipments/${resultsFilteredByOrg[0].id}`);
            }
            return;
          }
        }

        // Cannot find the correct result, redirect to the search page
        if (results[0].reference.startsWith('R')) {
          navigate(`/requests?search=${parsedCode}`);
        } else {
          navigate(`/shipments?search=${parsedCode}`);
        }
        return;
      }, 2000);
    }
  }, [resultStatus, results, onClose, navigate, orgSlug, parsedCode]);

  if (isMobile) {
    return <Mobile onClose={onClose} onResult={setCode} resultStatus={resultStatus} />;
  }

  return <Desktop onClose={onClose} onResult={setCode} resultStatus={resultStatus} />;
};

export default Scan;

function parseCode(code?: string) {
  if (!code) return [undefined, undefined];

  // In some weird cases on Windows, the scanner device will use a keyboard layout that inverts all non-letter characters
  // This is a workaround to fix that
  if (code.startsWith('https/::')) {
    code = code.replace(
      /[/;:&é"'(\-è_çà\0]/g,
      (char: string) =>
        ({
          '/': ':',
          ';': '.',
          ':': '/',
          '&': '1',
          é: '2',
          '"': '3',
          "'": '4',
          '(': '5',
          '-': '6',
          è: '7',
          _: '8',
          ç: '9',
          à: '0',
          '\0': '',
        })[char] ?? char
    );
  }

  const codeWithoutOrigin = code.replace(window.location.origin, '');

  // Legacy (May 2024): Handle request QR codes generated before we moved to the `ref/:brand/:ref` format
  if (codeWithoutOrigin.startsWith('/r/')) {
    return ['fusalp', codeWithoutOrigin.slice(3)];
  } else if (codeWithoutOrigin.startsWith('/ref/')) {
    return codeWithoutOrigin.slice(5).split('/');
  }

  return [undefined, codeWithoutOrigin];
}

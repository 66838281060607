import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import type { DefaultError, QueryKey } from '@tanstack/query-core';
import {
  InfiniteData,
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  ArticleArchivalDetail,
  ArticleDisplayedStep,
  ArticleSteps,
  ArticleTask,
  BaseStepConfig,
  Endpoints,
  Permission,
  ProductL1,
  ProductL2,
  ProductL3,
} from '@/api';
import { useFetch } from '@/utils/fetch';
import { Currency } from '@/utils/number';

import { Medium } from './medium';
import { Model } from './model';
import { DEFAULT_COLORS, PRODUCT_CATEGORIES_L2, PRODUCT_MATERIALS } from './product';
import {
  ArticleAction,
  ArticleCustomAction,
  ArticlePackAction,
  ArticleWithRelations,
  instanciateRequestWithRelations,
  RequestWithRelations,
} from './request';
import { instanciateArticleWithRelations } from './shipment';

export class Article extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);

    this.requestId = (data.requestId ?? data.archivedRequestId)!;
  }

  id!: string;

  status!: ArticleLegacyStatus;
  statusStartedAt!: string | null;
  statusDueAt!: string | null;
  task!: ArticleTask | null;

  hasIssue!: boolean;
  archivedAt!: string | null;
  archivedById!: string | null;
  archivalDetail!: ArticleArchivalDetail | null;

  estimatedAt!: string | null;
  dispatchedAt!: string | null;
  workshopAcceptedAt!: string | null;
  quoteAcceptedAt!: string | null;
  quoteRefusedAt!: string | null;
  analyzedAt!: string | null;
  requalifiedAt!: string | null;
  requalificationValidatedAt!: string | null;
  requalificationRefusedAt!: string | null;
  repairedAt!: string | null;

  cancelledAt!: Date | null;
  cancellationDetail!: ArticleCancellationDetail | null;

  paidAt!: string | null;

  step!: BaseStepConfig | null;
  steps?: ArticleSteps;
  data!: ArticleData;

  productL1!: ProductL1 | null;
  productL2!: ProductL2 | null;
  productL3!: ProductL3 | null;

  currentCostAmount!: number | null;
  currentCostCurrency!: Currency | null;
  currentCostManualDiscount!: number | null;
  currentCostAmountWithoutDiscount!: number | null;
  currentPriceAmount!: number | null;
  currentPriceCurrency!: Currency | null;
  currentPriceManualDiscount!: number | null;
  currentPriceAmountWithoutDiscount!: number | null;

  initialCostAmount!: number | null;
  initialCostCurrency!: Currency | null;
  initialCostManualDiscount!: number | null;
  initialCostAmountWithoutDiscount!: number | null;
  initialPriceAmount!: number | null;
  initialPriceCurrency!: Currency | null;
  initialPriceManualDiscount!: number | null;
  initialPriceAmountWithoutDiscount!: number | null;

  estimatedCostAmount!: number | null;
  estimatedCostCurrency!: Currency | null;
  estimatedCostManualDiscount!: number | null;
  estimatedCostAmountWithoutDiscount!: number | null;
  estimatedPriceAmount!: number | null;
  estimatedPriceCurrency!: Currency | null;
  estimatedPriceManualDiscount!: number | null;
  estimatedPriceAmountWithoutDiscount!: number | null;

  finalCostAmount!: number | null;
  finalCostCurrency!: Currency | null;
  finalCostManualDiscount!: number | null;
  finalCostAmountWithoutDiscount!: number | null;
  finalPriceAmount!: number | null;
  finalPriceCurrency!: Currency | null;
  finalPriceManualDiscount!: number | null;
  finalPriceAmountWithoutDiscount!: number | null;

  purchaseDate!: string | null;
  warranty!: boolean;
  clientComment!: string | null;

  requalificationComment!: string | null;
  requalificationAnalysisComment!: string | null;

  productId!: string | null;
  requestId!: string;
  archivedRequestId!: string | null;
  workshopId!: string | null;
  organizationId!: string;

  atClient!: boolean;
  atStoreId!: string | null;
  atWorkshopId!: string | null;
  toClient!: boolean;
  toStoreId!: string | null;
  toWorkshopId!: string | null;
  inTransit!: boolean;
  inTransitVerification!: boolean;

  createdAt!: string;

  get articlePhoto() {
    if ('media' in this) {
      return (this.media as Medium[]).find((medium) => medium.type === 'article-photo');
    } else {
      return undefined;
    }
  }

  get archived() {
    return !!this.archivedAt;
  }

  get cancelled() {
    return !!this.cancelledAt;
  }

  get completed() {
    return this.archived && this.archivalDetail?.type === 'completed';
  }

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  get archivedAtDate() {
    return this.archivedAt ? new Date(this.archivedAt) : null;
  }

  get statusDueAtDate() {
    return this.statusDueAt ? new Date(this.statusDueAt) : null;
  }

  /**
   * @deprecated
   */
  get legacyStep() {
    return ARTICLE_LEGACY_STATUSES.find((status) => status.id === this.status)!.stepId;
  }

  get displayedStep(): ArticleDisplayedStep | null {
    if (!this.step) {
      return null;
    }

    return getDisplayedStep(this.step);
  }

  get estimatedDueAtDate() {
    if (this.archivedAt) {
      return null;
    }

    if (this.steps) {
      const stepsWithDueDate = this.steps.filter((step) => !!step.dueDate);

      if (stepsWithDueDate.length > 0) {
        return new Date(stepsWithDueDate.at(-1)!.dueDate!);
      }
    }

    return null;
  }

  get numberOfActions() {
    if (
      'currentCustomActions' in this &&
      'currentActions' in this &&
      'currentPackActions' in this
    ) {
      return (
        (this.currentActions as ArticleAction[]).reduce((acc, action) => acc + action.quantity, 0) +
        (this.currentCustomActions as ArticleCustomAction[]).reduce(
          (acc, action) => acc + action.quantity,
          0
        ) +
        (this.currentPackActions as ArticlePackAction[]).reduce(
          (acc, action) => acc + action.quantity,
          0
        )
      );
    }
    return 0;
  }

  get hasActions() {
    if (
      'currentCustomActions' in this &&
      'currentActions' in this &&
      'currentPackActions' in this
    ) {
      return (
        (this.currentActions as ArticleAction[]).length > 0 ||
        (this.currentCustomActions as ArticleCustomAction[]).length > 0 ||
        (this.currentPackActions as ArticlePackAction[]).length > 0
      );
    } else {
      return false;
    }
  }

  get clientPriceAmount() {
    return this.finalPriceAmount ?? this.estimatedPriceAmount ?? this.initialPriceAmount;
  }

  get clientPriceCurrency() {
    return this.finalPriceCurrency ?? this.estimatedPriceCurrency ?? this.initialPriceCurrency;
  }

  get clientPriceAmountWithoutDiscount() {
    return (
      this.finalPriceAmountWithoutDiscount ??
      this.estimatedPriceAmountWithoutDiscount ??
      this.initialPriceAmountWithoutDiscount
    );
  }

  get clientActions() {
    const finalActions = 'finalActions' in this ? (this.finalActions as ArticleAction[]) : [];
    const estimatedActions =
      'estimatedActions' in this ? (this.estimatedActions as ArticleAction[]) : [];
    const initialActions = 'initialActions' in this ? (this.initialActions as ArticleAction[]) : [];

    if (this.finalPriceAmount !== null) {
      return finalActions;
    } else if (this.estimatedPriceAmount !== null) {
      return estimatedActions;
    } else {
      return initialActions;
    }
  }

  get clientCustomActions() {
    const finalActions =
      'finalCustomActions' in this ? (this.finalCustomActions as ArticleCustomAction[]) : [];
    const estimatedActions =
      'estimatedCustomActions' in this
        ? (this.estimatedCustomActions as ArticleCustomAction[])
        : [];
    const initialActions =
      'initialCustomActions' in this ? (this.initialCustomActions as ArticleCustomAction[]) : [];

    if (this.finalPriceAmount !== null) {
      return finalActions;
    } else if (this.estimatedPriceAmount !== null) {
      return estimatedActions;
    } else {
      return initialActions;
    }
  }

  get clientPackActions() {
    const finalActions =
      'finalPackActions' in this ? (this.finalPackActions as ArticlePackAction[]) : [];
    const estimatedActions =
      'estimatedPackActions' in this ? (this.estimatedPackActions as ArticlePackAction[]) : [];
    const initialActions =
      'initialPackActions' in this ? (this.initialPackActions as ArticlePackAction[]) : [];

    if (this.finalPriceAmount !== null) {
      return finalActions;
    } else if (this.estimatedPriceAmount !== null) {
      return estimatedActions;
    } else {
      return initialActions;
    }
  }
}

type GetArticleNameProps = {
  article?: Pick<Article, 'data' | 'productL2' | 'productL3'> & {
    product: { name: string } | null;
  };
  product?: { name: string };
  color?: string;
  productL3?: ProductL3;
  productL2?: ProductL2;
  type?: 'short' | 'full';
};

export const useGetArticleName = () => {
  const { _ } = useLingui();

  return ({
    article,
    product: productProp,
    color: colorProp,
    productL3: productL3Prop,
    productL2: productL2Prop,
    type = 'full',
  }: GetArticleNameProps) => {
    const product = article?.product ?? productProp;
    const color = article?.data.color ?? colorProp;
    const productL3 = article?.productL3 ?? productL3Prop;
    const productL2 = article?.productL2 ?? productL2Prop;

    if (product) {
      return product.name;
    }

    const productL2Label = PRODUCT_CATEGORIES_L2.find((l2) => l2.id === productL2)?.labelOne;
    const productL2String = productL2Label ? _(productL2Label) : undefined;

    if (!productL2String) {
      return _(msg({ id: 'article.name.unnamed', message: 'Unnamed item' }));
    }

    if (type === 'short') {
      return productL2String;
    }

    const colorLabel = DEFAULT_COLORS.find(({ id }) => id === color)?.label;
    const colorString = colorLabel ? _(colorLabel) : color;

    const productL3Label = PRODUCT_MATERIALS.find((l3) => l3.id === productL3)?.label;
    const productL3String = productL3Label ? _(productL3Label) : undefined;

    if (productL3String && colorString) {
      return `${productL2String} (${productL3String}, ${colorString})`;
    } else if (productL3String || colorString) {
      return `${productL2String} (${productL3String ?? colorString})`;
    } else {
      return productL2String;
    }
  };
};

export const useArticleName = (props: GetArticleNameProps) => {
  const getArticleName = useGetArticleName();

  return getArticleName(props);
};

export type ArticleBrand = { isOther: boolean; name: string };

export type ArticleData = {
  size?: string;
  color?: string;
  brand?: ArticleBrand;
};

export const ARTICLE_LEGACY_STATUSES = [
  {
    id: 'draft',
    stepId: 'draft',
    stepGroupId: undefined,
  },
  {
    id: 'added-to-request',
    stepId: 'estimation',
    stepGroupId: 'estimation',
  },
  {
    id: 'estimated',
    stepId: 'estimation',
    stepGroupId: 'estimation',
  },
  {
    id: 'dispatch-pending-acceptation',
    stepId: 'dispatch-pending-acceptation',
    stepGroupId: 'dispatch',
  },
  {
    id: 'dispatch-accepted',
    stepId: 'dispatch-pending-acceptation',
    stepGroupId: 'dispatch',
  },
  {
    id: 'dispatch-pending',
    stepId: 'dispatch-pending',
    stepGroupId: 'dispatch',
  },
  {
    id: 'pending-quote-acceptation',
    stepId: 'validation',
    stepGroupId: 'validation',
  },
  {
    id: 'pending-requestor-to-workshop-transit',
    stepId: 'pending-transit',
    stepGroupId: 'transit',
  },
  {
    id: 'in-requestor-to-workshop-transit',
    stepId: 'in-transit',
    stepGroupId: 'transit',
  },
  {
    id: 'received-requestor-to-workshop-transit',
    stepId: 'received-transit',
    stepGroupId: 'transit',
  },
  {
    id: 'pending-workshop-to-workshop-transit',
    stepId: 'pending-transit-requalification',
    stepGroupId: 'transit',
  },
  {
    id: 'in-workshop-to-workshop-transit',
    stepId: 'in-transit-requalification',
    stepGroupId: 'transit',
  },
  {
    id: 'received-workshop-to-workshop-transit',
    stepId: 'received-transit-requalification',
    stepGroupId: 'transit',
  },
  {
    id: 'pending-analysis',
    stepId: 'analysis',
    stepGroupId: 'analysis',
  },
  {
    id: 'analysed',
    stepId: 'analysis',
    stepGroupId: 'analysis',
  },
  {
    id: 'requalified',
    stepId: 'analysis',
    stepGroupId: 'analysis',
  },
  {
    id: 'pending-requalification-acceptation',
    stepId: 'analysis-requalification',
    stepGroupId: 'analysis',
  },
  {
    id: 'requalification-refused',
    stepId: 'analysis-requalification',
    stepGroupId: 'analysis',
  },
  {
    id: 'requalification-accepted',
    stepId: 'analysis-requalification',
    stepGroupId: 'analysis',
  },
  {
    id: 'pending-bill-payment',
    stepId: 'payment',
    stepGroupId: 'payment',
  },
  {
    id: 'pending-repair',
    stepId: 'repair',
    stepGroupId: 'repair',
  },
  {
    id: 'repaired',
    stepId: 'repair',
    stepGroupId: 'repair',
  },
  {
    id: 'pending-workshop-to-requestor-transit',
    stepId: 'pending-delivery',
    stepGroupId: 'delivery',
  },
  {
    id: 'in-workshop-to-requestor-transit',
    stepId: 'in-delivery',
    stepGroupId: 'delivery',
  },
  {
    id: 'received-workshop-to-requestor-transit',
    stepId: 'received-delivery',
    stepGroupId: 'delivery',
  },
  {
    id: 'pending-client-in-store-pickup',
    stepId: 'pending-client-delivery',
    stepGroupId: 'delivery',
  },
  {
    id: 'completed',
    stepId: 'completed',
    stepGroupId: 'completed',
  },
] as const;

export const ARTICLE_DISPLAYED_STEPS = [
  {
    id: 'estimation',
    name: msg({ id: 'article.step.estimation', message: 'Estimate' }),
  },
  {
    id: 'dispatch',
    name: msg({ id: 'article.step.dispatch', message: 'Dispatch' }),
  },
  {
    id: 'validation',
    name: msg({ id: 'article.step.validation', message: 'Validation' }),
  },
  {
    id: 'transit',
    name: msg({ id: 'article.step.transit', message: 'Transit' }),
  },
  {
    id: 'analysis',
    name: msg({ id: 'article.step.analysis', message: 'Analysis' }),
  },
  {
    id: 'payment',
    name: msg({ id: 'article.step.payment', message: 'Payment' }),
  },
  {
    id: 'repair',
    name: msg({ id: 'article.step.repair', message: 'Repair' }),
  },
  {
    id: 'delivery',
    name: msg({ id: 'article.step.delivery', message: 'Delivery' }),
  },
] satisfies { id: ArticleDisplayedStep; name: MessageDescriptor }[];

export const getDisplayedStep = (step: BaseStepConfig): ArticleDisplayedStep | null => {
  if (step.step === 'creation' || step.step === 'archival') {
    return null;
  }

  if (
    step.step === 'transit' &&
    (step.config.destinationType === 'client' ||
      (step.config.originType === 'workshop' && step.config.destinationType === 'store'))
  ) {
    return 'delivery';
  }

  return step.step;
};

export const ARTICLE_CANCELLATION_REASONS = [
  {
    id: 'too-expensive',
    label: msg({
      id: 'article.archival.reason.too-expensive',
      message: 'The estimate is too expensive',
    }),
  },
  {
    id: 'no-longer-want-repair',
    label: msg({
      id: 'article.archival.reason.no-longer-want-repair',
      message: 'I no longer wish to have it repaired',
    }),
  },
  {
    id: 'found-another-solution',
    label: msg({
      id: 'article.archival.reason.found-another-solution',
      message: 'I found another solution',
    }),
  },
  {
    id: 'do-not-wish-to-answer',
    label: msg({
      id: 'article.archival.reason.do-not-wish-to-answer',
      message: 'I do not wish to answer',
    }),
  },
  {
    id: 'other',
    label: msg({ id: 'article.archival.reason.other', message: 'Other reason, please specify' }),
  },
] as const;
export type ArticleCancellationReason = (typeof ARTICLE_CANCELLATION_REASONS)[number]['id'];
export const ARTICLE_CANCELLATION_TYPES = [
  {
    id: 'requestor_refusal',
    name: msg({
      id: 'article.cancellation-type.requestor_refusal',
      message: 'Client refusal',
    }),
    color: 'black',
  },
] as const;
export type ArticleCancellationType = (typeof ARTICLE_CANCELLATION_TYPES)[number]['id'];

export const ARTICLE_ARCHIVAL_TYPES = [
  {
    id: 'manual',
    name: msg({ id: 'article.archival-type.manual', message: 'Cancelled' }),
    color: 'red',
  },
  {
    id: 'automatic',
    name: msg({ id: 'article.archival-type.automatic', message: 'Cancelled' }),
    color: 'red',
  },
  {
    id: 'completed',
    name: msg({ id: 'article.archival-type.completed', message: 'Completed' }),
    color: 'green',
  },
] as const;

export type ArticleLegacyStatus = (typeof ARTICLE_LEGACY_STATUSES)[number]['id'];

export type ArticleArchivalType = (typeof ARTICLE_ARCHIVAL_TYPES)[number]['id'];

export type ArticleCancellationDetail = {
  type: ArticleCancellationType;
  statusWhenCancelled: ArticleLegacyStatus;
  reason?: ArticleCancellationReason;
  otherReason?: string;
};

export const useShipmentArticles = (
  params: {
    limit?: number;
    offset?: number;
    search?: string;
    originClient?: string;
    originStore?: string;
    originWorkshop?: string;
    destinationClient?: string;
    destinationStore?: string;
    destinationWorkshop?: string;
  },
  options?: {
    keepPreviousData?: boolean;
    enabled?: boolean;
  }
) => {
  const fetch = useFetch<Endpoints['GET /shipments/articles']>();

  return useQuery({
    queryKey: ['shipments/articles', params],
    queryFn: () =>
      fetch('/shipments/articles', params).then(({ articles, meta }) => ({
        articles: articles.map(instanciateArticleWithRelations),
        meta: meta,
      })),
    placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
    enabled: options?.enabled,
  });
};

export const useUpdateArticle = (
  {
    articleId,
    requestId,
    shipmentId,
  }: {
    articleId: string;
    requestId: string;
    shipmentId?: string;
  },
  {
    optimistic = true,
  }: {
    optimistic?: boolean;
  } = {}
) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();

  const requestQueryKey = ['requests', requestId];
  const shipmentQueryKey = ['shipments', shipmentId];

  return useMutation({
    mutationKey: ['articles', articleId],
    mutationFn: ({
      data,
      optimisticData,
    }: {
      data: {
        status?: ArticleLegacyStatus;
        productId?: string | null;
        workshopId?: string | null;
        purchaseDate?: string;
        warranty?: boolean;
        costManualDiscount?: number | null;
        priceManualDiscount?: number | null;
        data?: {
          size?: string;
          color?: string;
          brand?: ArticleBrand;
        };
        clientComment?: string | null;
        requalificationWorkshopComment?: string | null;
        requalificationAfterSalesComment?: string | null;
        actions?: {
          id?: string;
          actionTypeOrganizationId: string;
          quantity: number;
          brandResponsibility?: boolean;
        }[];
        customActions?: {
          id?: string;
          description: string;
          quantity: number;
          brandResponsibility?: boolean;
          costAmountWithoutDiscount?: number;
          priceAmountWithoutDiscount?: number;
        }[];
        packActions?: {
          id?: string;
          packActionTypeOrganizationId: string;
          quantity: number;
          brandResponsibility?: boolean;
        }[];
        productL1?: ProductL1 | null;
        productL2?: ProductL2 | null;
        productL3?: ProductL3 | null;
        proofOfPurchaseIds?: string[];
        defectPhotoIds?: string[];
      };
      optimisticData?: Partial<ArticleWithRelations>;
    }) => {
      const body: any = { ...data };

      /* Optimistic update */
      const currentRequest = queryClient.getQueryData<RequestWithRelations>(requestQueryKey);
      if (currentRequest && optimistic) {
        const newRequest = instanciateRequestWithRelations({
          ...currentRequest,
          articles: currentRequest?.articles.map((article) =>
            article.id === articleId ? { ...article, ...data, ...optimisticData } : article
          ),
        });

        queryClient.setQueryData(requestQueryKey, newRequest);
      }

      return fetch(`/articles/${articleId}`, undefined, {
        method: 'PATCH',
        body,
      });
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({ queryKey: requestQueryKey });
      queryClient.invalidateQueries({ queryKey: ['activities'] });

      const actionsUpdated =
        !!variables.data?.actions ||
        !!variables.data?.customActions ||
        !!variables.data?.packActions;
      const mediaUpdated = !!variables.data?.proofOfPurchaseIds || !!variables.data?.defectPhotoIds;

      if (actionsUpdated) {
        queryClient.invalidateQueries({ queryKey: ['workshops'] });
      }

      if (actionsUpdated || mediaUpdated) {
        queryClient.invalidateQueries({ queryKey: ['media'] });
      }

      if (shipmentId) {
        queryClient.invalidateQueries({ queryKey: shipmentQueryKey });
      }
    },
  });
};

export const useDeleteArticle = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch();

  return useMutation({
    mutationFn: (id: string) => fetch(`/articles/${id}`, undefined, { method: 'DELETE' }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['requests'] }),
  });
};

export type ArticleActivity =
  Endpoints['GET /articles/:id/activities']['response']['activities'][number];
export type ArticleActivityOfType<T extends ArticleActivity['type']> = Extract<
  ArticleActivity,
  { type: T }
>;
export const useActivities = <T extends ArticleActivity['type']>({
  articleId,
  ...params
}: {
  articleId: string;
  limit?: number;
  types?: T[];
}) => {
  const fetch = useFetch<Endpoints['GET /articles/:id/activities']>();

  return useQuery({
    queryKey: ['activities', articleId, params],
    queryFn: () =>
      fetch<{
        activities: ArticleActivityOfType<T>[];
        meta: Endpoints['GET /articles/:id/activities']['response']['meta'];
      }>(`/articles/${articleId}/activities`, params),
  });
};

export const useInfiniteActivities = ({ articleId }: { articleId: string }) => {
  const fetch = useFetch<Endpoints['GET /articles/:id/activities']>();

  return useInfiniteQuery<
    Endpoints['GET /articles/:id/activities']['response'],
    DefaultError,
    InfiniteData<Endpoints['GET /articles/:id/activities']['response']>,
    QueryKey,
    string | null
  >({
    queryKey: ['activities', articleId],
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage.meta.next,

    queryFn: ({ pageParam }) => {
      const requestParams = pageParam ? { before: pageParam } : undefined;

      return fetch(`/articles/${articleId}/activities`, requestParams);
    },
  });
};

export const useEstimate = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/estimate']>();

  return useMutation({
    mutationFn: () => fetch(`/articles/${articleId}/estimate`, undefined, { method: 'POST' }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['requests'] }),
  });
};

export const useDispatch = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/dispatch']>();

  return useMutation({
    mutationFn: () => fetch(`/articles/${articleId}/dispatch`, undefined, { method: 'POST' }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['requests'] }),
  });
};

export const useAcceptDispatch = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/accept-dispatch']>();

  return useMutation({
    mutationFn: () =>
      fetch(`/articles/${articleId}/accept-dispatch`, undefined, { method: 'POST' }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['requests'] }),
  });
};

export const useRefuseDispatch = ({ article }: { article: Article }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/refuse-dispatch']>();

  return useMutation({
    mutationFn: (body: Endpoints['POST /articles/:id/refuse-dispatch']['body']) =>
      fetch(`/articles/${article.id}/refuse-dispatch`, undefined, {
        method: 'POST',
        body,
      }),
    onSettled: () => {
      // After refusing the job, there is no need to refresh the request
      // because the user is redirected to the /requests route
      queryClient.removeQueries({ queryKey: ['requests', article.requestId] });
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

export const useAnalyze = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/analyze']>();

  return useMutation({
    mutationFn: () => fetch(`/articles/${articleId}/analyze`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useRepair = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/repair']>();

  return useMutation({
    mutationFn: () => fetch(`/articles/${articleId}/repair`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useRequalify = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/requalify']>();

  return useMutation({
    mutationFn: (data: Endpoints['POST /articles/:id/requalify']['body']) =>
      fetch(`/articles/${articleId}/requalify`, undefined, { method: 'POST', body: data }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useRefuseRequalification = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/refuse-requalification']>();

  return useMutation({
    mutationFn: (data: Endpoints['POST /articles/:id/refuse-requalification']['body']) =>
      fetch(`/articles/${articleId}/refuse-requalification`, undefined, {
        method: 'POST',
        body: data,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useValidateRequalification = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/validate-requalification']>();

  return useMutation({
    mutationFn: () =>
      fetch(`/articles/${articleId}/validate-requalification`, undefined, {
        method: 'POST',
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const ARTICLE_TASK_TYPES = [
  {
    id: 'validate_external_payment',
    label: msg({
      id: 'article.task.type.validate_external_payment',
      message: 'Payment',
    }),
    permissions: ['validate_external_payment'],
  },
  {
    id: 'estimate_article',
    label: msg({ id: 'article.task.type.estimate_article', message: 'Estimate' }),
    permissions: ['estimate_article'],
  },
  {
    id: 'dispatch_article',
    label: msg({ id: 'article.task.type.dispatch_article', message: 'Dispatch' }),
    permissions: ['dispatch_article'],
  },
  {
    id: 'accept_dispatch',
    label: msg({
      id: 'article.task.type.accept_dispatch',
      message: 'Dispatch (Job review)',
    }),
    permissions: ['accept_dispatch'],
  },
  {
    id: 'create_shipment',
    label: msg({ id: 'article.task.type.create_shipment', message: 'Shipment creation' }),
    permissions: ['create_shipment_from_origin'],
  },
  {
    id: 'prepare_shipment',
    label: msg({
      id: 'article.task.type.prepare_shipment',
      message: 'Shipment preparation',
    }),
    permissions: ['create_shipment_from_origin'],
  },
  {
    id: 'finalise_shipment',
    label: msg({
      id: 'article.task.type.finalise_shipment',
      message: 'Shipment finalisation',
    }),
    permissions: ['create_shipment_from_origin'],
  },
  {
    id: 'dropoff_shipment',
    label: msg({ id: 'article.task.type.dropoff_shipment', message: 'Shipment dropoff' }),
    permissions: ['create_shipment_from_origin'],
  },
  {
    id: 'verify_shipment_reception',
    label: msg({
      id: 'article.task.type.verify_shipment_reception',
      message: 'Shipment verification',
    }),
    permissions: ['verify_shipment_reception'],
  },
  {
    id: 'handle_shipment_issue',
    label: msg({
      id: 'article.task.type.handle_shipment_issue',
      message: 'Shipment issue',
    }),
    permissions: [
      'verify_shipment_reception',
      'create_shipment_from_origin',
      'view_all_shipments_issues',
    ],
  },
  {
    id: 'analyze_article',
    label: msg({ id: 'article.task.type.analyze_article', message: 'Analysis' }),
    permissions: ['analyze_article'],
  },
  {
    id: 'accept_requalification',
    label: msg({
      id: 'article.task.type.accept_requalification',
      message: 'Re-qualification analysis',
    }),
    permissions: ['accept_requalification'],
  },
  {
    id: 'repair_article',
    label: msg({ id: 'article.task.type.repair_article', message: 'Repair' }),
    permissions: ['repair_article'],
  },
] satisfies { id: ArticleTask['type']; label: MessageDescriptor; permissions: Permission[] }[];

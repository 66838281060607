import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { ShipmentWithRelations, useCompleteFinalisation } from '@/models/shipment';
import { useCurrentSession } from '@/services/auth';

const FinalisationAction = ({
  shipment,
  onFinalisationDone,
}: {
  shipment: ShipmentWithRelations;
  onFinalisationDone: () => void;
}) => {
  const { mutateAsync: completeFinalisation, isPending, isSuccess } = useCompleteFinalisation();

  const { currentSession } = useCurrentSession();

  const canCreateShipment = shipment.canBeCreatedBy(currentSession);

  if (!canCreateShipment) {
    return null;
  }

  return (
    <Stack row gap="16px" justifyContent="flex-end">
      <Button
        variant="primary"
        onPress={async () => {
          await completeFinalisation(shipment.id);
          onFinalisationDone();
        }}
        isLoading={isPending || isSuccess}
      >
        <Trans id="shipment.ready-for-handover">Mark package finalisation as done</Trans>
      </Button>
    </Stack>
  );
};

export default FinalisationAction;

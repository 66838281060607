import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import OrganizationSwitch from '@/components/OrganizationSwitch';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import Tabs, { Tab, TabList, TabPanel } from '@/design_system/Tabs/Tabs';
import IconChevron from '@/icons/Chevron.svg';
import { InternalWorkshops } from '@/routes/Settings/InternalWorkshops';
import { useCurrentOrganization, useCurrentSession } from '@/services/auth';
import { useSearchParamState, useSyncSearchParams } from '@/utils/useSearchParams';
import useViewport from '@/utils/useViewport';

import ActionTypes from './ActionTypes';
import ActionTypesWorkshop from './ActionTypesWorkshop';
import Countries from './Countries';
import { ExternalStores } from './ExternalStores';
import Stores from './Stores';
import Users from './Users';
import Workshop from './Workshop';

const Settings = () => {
  const { _ } = useLingui();
  const { isMobile } = useViewport();
  const { currentSession } = useCurrentSession();
  const [currentOrganization, setCurrentOrganization] = useCurrentOrganization();

  const [tab, setTab, syncTab] = useSearchParamState<string>('settings', 'tab', '');

  useSyncSearchParams([syncTab]);

  return (
    <div className="container container--list">
      <Stack gap="40px">
        <Stack row alignItems="center" justifyContent="space-between" gap="8px">
          <Stack row alignItems="center" gap="1rem">
            {isMobile && (
              <Button iconOnly variant="secondary" to="/account">
                <IconChevron left />
              </Button>
            )}
            <h1 className={isMobile ? 'headline-200-bold-mobile' : 'headline-100-bold'}>
              <Trans id="settings.title">Settings</Trans>
            </h1>
          </Stack>
          <Stack row gap="8px">
            <OrganizationSwitch
              organizations={currentSession?.organizations ?? []}
              onSelectOrganization={setCurrentOrganization}
              placement="top left"
            >
              <Button variant="secondary" size="medium" className="is-hidden-from-tablet">
                <IconChevron down />
                {currentOrganization?.name}
              </Button>
            </OrganizationSwitch>
          </Stack>
        </Stack>
        <Tabs selectedKey={tab} onSelectionChange={setTab}>
          <TabList aria-label={_(msg({ id: 'settings.tabs.label', message: 'Settings panels' }))}>
            {currentSession?.hasPermission('manage_user', {
              organizationId: currentOrganization?.id,
              storeId: null,
              workshopId: null,
            }) && (
              <Tab id="users">
                <Trans id="settings.tabs.users">Users</Trans>
              </Tab>
            )}
            {currentSession?.hasPermission('manage_store', {
              organizationId: currentOrganization?.id,
              storeId: null,
              workshopId: null,
            }) && (
              <>
                <Tab id="stores">
                  <Trans id="settings.tabs.stores">Stores</Trans>
                </Tab>
                <Tab id="external-stores">
                  <Trans id="settings.tabs.external-stores">External Stores</Trans>
                </Tab>
              </>
            )}
            {currentSession?.hasPermission('manage_workshop', {
              organizationId: currentOrganization?.id,
              storeId: null,
              workshopId: null,
            }) && (
              <Tab id="internal-workshops">
                <Trans id="settings.tabs.internal-workshops">Internal Workshops</Trans>
              </Tab>
            )}
            {currentSession?.hasPermission('manage_organization', {
              organizationId: currentOrganization?.id,
              storeId: null,
              workshopId: null,
            }) && (
              <Tab id="action-types">
                <Trans id="settings.tabs.action-types">Prices</Trans>
              </Tab>
            )}
            {currentSession?.hasPermission('manage_billing', {
              organizationId: currentOrganization?.id,
              storeId: null,
              workshopId: null,
            }) && (
              <Tab id="countries">
                <Trans id="settings.tabs.countries">Countries</Trans>
              </Tab>
            )}
            {currentSession?.hasPermission('manage_workshop', {
              workshopId: currentSession?.workshop?.id,
            }) && (
              <>
                <Tab id="workshop">
                  <Trans id="settings.tabs.workshop">Workshop</Trans>
                </Tab>
                <Tab id="action-types-workshop">
                  <Trans id="settings.tabs.action-types-workshop">Prices</Trans>
                </Tab>
              </>
            )}
          </TabList>
          <TabPanel id="countries">
            <Countries />
          </TabPanel>
          <TabPanel id="users">
            <Users />
          </TabPanel>
          <TabPanel id="stores">
            <Stores />
          </TabPanel>
          <TabPanel id="external-stores">
            <ExternalStores />
          </TabPanel>
          <TabPanel id="internal-workshops">
            <InternalWorkshops />
          </TabPanel>
          <TabPanel id="workshop">
            <Workshop />
          </TabPanel>
          <TabPanel id="action-types">
            <ActionTypes />
          </TabPanel>
          <TabPanel id="action-types-workshop">
            <ActionTypesWorkshop />
          </TabPanel>
        </Tabs>
      </Stack>
    </div>
  );
};

export default Settings;

import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import DiscardChangesDialog from '@/components/DiscardChangesDialog';
import {
  FullPageLayout,
  FullPageLayoutContent,
  FullPageLayoutFooter,
  FullPageLayoutSimpleHeader,
} from '@/components/FullPageLayout';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import IconClock from '@/icons/Clock.svg';
import { RequestWithRelations, useRequest, useSendDraftRequest } from '@/models/request';
import { useWorkflow } from '@/models/workflow';
import { ArchiveRequestButton } from '@/routes/Requests/components/ArchiveRequestButton';
import Articles, { useArticlesError } from '@/routes/Requests/New/components/Articles';
import ClientStoreForm, {
  getClientStoreError,
} from '@/routes/Requests/New/components/ClientStoreForm';
import NewRequestGlobalError from '@/routes/Requests/New/components/NewRequestGlobalError';
import {
  PrintDropOffButton,
  PrintDropOffModal,
} from '@/routes/Requests/New/components/PrintDropOff';
import SettingsForm from '@/routes/Requests/New/components/SettingsForm';
import LegacyRequestComments from '@/routes/Requests/Request/components/RequestComments';
import { useShowWarningBeforeLeavingApp, useSimpleBlocker } from '@/utils/navigation';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import './New.css';

const New = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: request, isError } = useRequest(id);

  useEffect(() => {
    if (isError) {
      navigate('/requests', { state: { skipRouterBlocker: true } });
    }
  }, [navigate, isError]);

  // Block going away
  const blocker = useSimpleBlocker();
  useShowWarningBeforeLeavingApp();

  if (!request) {
    return null;
  }

  return (
    <>
      <RequestForm request={request} />
      <Outlet context={request} />
      {blocker.state === 'blocked' && (
        <DiscardChangesDialog
          onConfirm={() => blocker.proceed()}
          onCancel={() => blocker.reset()}
        />
      )}
    </>
  );
};

export default New;

const RequestForm = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const { isMobile } = useViewPort();

  const [showPrintDropOffModal, setShowPrintDropOffModal] = useState(false);

  const goToRequests = ({ skipRouterBlocker }: { skipRouterBlocker: boolean }) =>
    navigate('/requests', { state: { skipRouterBlocker } });
  const goToRequest = ({ skipRouterBlocker }: { skipRouterBlocker: boolean }) =>
    navigate(`/requests/${request.id}`, { state: { skipRouterBlocker } });

  const { data: workflow } = useWorkflow(request.workflowId);

  // The workflow does not have an internal payment step (where we generate an invoice).
  // Therefore, we do not have to ask for a billing address.
  const hideBillingAddress = !workflow?.nodes.find(
    (node) => 'step' in node && node.step === 'payment' && node.config.externalPayment === false
  );

  // Errors
  const clientStoreError = getClientStoreError(request, hideBillingAddress);
  const {
    noArticlesError,
    incompleteDraftArticlesError,
    incompleteSavedArticlesError,
    multipleWorkshopsError,
  } = useArticlesError(request);

  const hasSaveForLaterError =
    clientStoreError ||
    !!noArticlesError ||
    !!incompleteDraftArticlesError ||
    !!multipleWorkshopsError;
  const hasSaveError =
    clientStoreError ||
    !!noArticlesError ||
    !!incompleteSavedArticlesError ||
    !!multipleWorkshopsError;

  const [showSaveForLaterErrors, setShowSaveForLaterErrors] = useState(false);
  const [showSaveErrors, setShowSaveErrors] = useState(false);

  const [alertRef, scrollAlertIntoView] = useScrollIntoView<HTMLDivElement>();
  const [apiError, setApiError] = useState('');

  // Actions

  const handleApiError = (err: any) => {
    console.error(err);
    setApiError(
      (err.message as string) ?? _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
    );
  };

  const { mutateAsync: sendDraftRequest, isPending: isPendingSendDraftRequest } =
    useSendDraftRequest(request.id);

  const handleSaveForLater = () => {
    setShowSaveForLaterErrors(true);
    setShowSaveErrors(false);

    if (hasSaveForLaterError) {
      scrollAlertIntoView();
      return;
    }

    const isDraft = request.draft;

    sendDraftRequest({ saveForLater: true })
      .then(() => {
        if (request.client && request.store && isDraft) {
          setShowPrintDropOffModal(true);
        } else {
          goToRequests({ skipRouterBlocker: true });
        }
      })
      .catch(handleApiError);
  };

  const handleSaveRequest = () => {
    setShowSaveForLaterErrors(false);
    setShowSaveErrors(true);

    if (hasSaveError) {
      scrollAlertIntoView();
      return;
    }

    sendDraftRequest({ saveForLater: false })
      .then(() => {
        goToRequest({ skipRouterBlocker: true });
      })
      .catch(handleApiError);
  };

  const saveForLaterLabel = _(
    msg({ id: 'requests.edit.actions.save-for-later', message: 'Save for later' })
  );

  // Only draft requests here
  if (!request.draft && !request.isInDraftStep) {
    goToRequest({ skipRouterBlocker: true });
    return null;
  }

  return (
    <FullPageLayout>
      <FullPageLayoutSimpleHeader
        title={
          request.draft
            ? _(msg({ id: 'requests.new.title', message: 'New request' }))
            : _(msg({ id: 'requests.edit.title', message: 'Edit request' }))
        }
        onClose={() => goToRequests({ skipRouterBlocker: !request.draft })}
      />
      <FullPageLayoutContent>
        <NewRequestGlobalError
          ref={alertRef}
          showGlobalError={
            (showSaveForLaterErrors && hasSaveForLaterError) || (showSaveErrors && hasSaveError)
          }
          request={request}
        />
        <Stack gap={isMobile ? '1rem' : '1.5rem'} padding={isMobile ? '16px' : '24px 32px'}>
          <SettingsForm request={request} workflow={workflow} />
          <ClientStoreForm
            request={request}
            hideBillingAddress={hideBillingAddress}
            showErrors={showSaveForLaterErrors || showSaveErrors}
          />
          <Articles
            request={request}
            error={
              showSaveErrors
                ? noArticlesError || incompleteSavedArticlesError || multipleWorkshopsError
                : showSaveForLaterErrors
                  ? noArticlesError || incompleteDraftArticlesError || multipleWorkshopsError
                  : undefined
            }
          />
          {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
          <Box style={{ padding: '16px 0 8px' }} removeStyleOnMobile>
            <LegacyRequestComments requestId={request.id} />
          </Box>
        </Stack>
      </FullPageLayoutContent>
      <FullPageLayoutFooter>
        {apiError && (
          <Stack alignItems="center">
            <Message type="error">{apiError}</Message>
          </Stack>
        )}
        {request.draft ? (
          <>
            <Button
              variant="secondary"
              size="medium"
              onPress={handleSaveForLater}
              isLoading={showSaveForLaterErrors && isPendingSendDraftRequest}
              dataTrackingId="requests.new.actions.save-for-later"
            >
              <IconClock />
              {saveForLaterLabel}
            </Button>
          </>
        ) : (
          <>
            <Stack row gap={isMobile ? '0.5rem' : '1rem'}>
              <ArchiveRequestButton
                request={request}
                onArchive={() => goToRequests({ skipRouterBlocker: true })}
                onArchiveError={(reason) => {
                  setApiError(
                    ((reason as any).body?.message as string) ??
                      _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
                  );
                }}
              />
              {request.client && request.store && <PrintDropOffButton request={request} />}
              <Button
                variant="secondary"
                size="medium"
                onPress={handleSaveForLater}
                isLoading={showSaveForLaterErrors && isPendingSendDraftRequest}
                style={isMobile ? { flex: 1 } : undefined}
                ariaLabel={isMobile ? saveForLaterLabel : undefined}
                dataTrackingId="requests.new.actions.save-for-later"
              >
                <IconClock />
                {!isMobile && saveForLaterLabel}
              </Button>
            </Stack>
          </>
        )}
        <Button
          variant="primary"
          size="medium"
          onPress={handleSaveRequest}
          isLoading={showSaveErrors && isPendingSendDraftRequest}
          dataTrackingId="requests.new.actions.create"
        >
          {request.draft ? (
            <Trans id="requests.new.actions.create">Create request</Trans>
          ) : (
            <Trans id="requests.edit.actions.save-and-create">Save and create</Trans>
          )}
        </Button>
      </FullPageLayoutFooter>
      {showPrintDropOffModal && (
        <PrintDropOffModal
          request={request}
          onClose={() => goToRequests({ skipRouterBlocker: true })}
        />
      )}
    </FullPageLayout>
  );
};

export function useRequestContext() {
  return useOutletContext<RequestWithRelations>();
}

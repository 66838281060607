import { useLingui } from '@lingui/react';

import { Stepper, StepProps } from '@/design_system/Stepper/Stepper';
import { SHIPMENT_STEPS, ShipmentWithRelations } from '@/models/shipment';
import useViewPort from '@/utils/useViewport';

const ShipmentStepper = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const currentStepIndex = SHIPMENT_STEPS.findIndex((step) => step.id === shipment.step);

  return (
    <Stepper
      size={isMobile ? 'small' : 'large'}
      showStepCount={isMobile}
      steps={
        SHIPMENT_STEPS.map((step, index) => {
          if ('hidden' in step) {
            return undefined;
          }

          if (
            'hiddenInDestinationClient' in step &&
            step.hiddenInDestinationClient &&
            shipment.destinationClientId
          ) {
            return undefined;
          }

          return {
            name: _(step.name),
            status:
              index < currentStepIndex
                ? 'done'
                : index === currentStepIndex
                  ? 'in-progress'
                  : 'pending',
          };
        }).filter((x) => x !== undefined) as StepProps[]
      }
    />
  );
};

export default ShipmentStepper;

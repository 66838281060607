import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconFilters from '@/icons/Filters.svg';

export const RequestsFiltersButton = ({
  hasActiveFilters,
  isFiltersDrawerOpen,
  setIsFiltersDrawerOpen,
}: {
  hasActiveFilters: boolean;
  isFiltersDrawerOpen: boolean;
  setIsFiltersDrawerOpen: (isOpen: boolean) => void;
}) => {
  const { _ } = useLingui();

  return (
    <Stack style={{ position: 'relative' }}>
      <Button
        iconOnly={true}
        variant="secondary"
        size="large"
        ariaLabel={_(
          msg({
            id: 'requests.filters',
            message: 'Filters',
          })
        )}
        onPress={() => setIsFiltersDrawerOpen(!isFiltersDrawerOpen)}
      >
        <IconFilters />
      </Button>

      {/* Red dot indicating that some filters are currently applied */}
      {hasActiveFilters && (
        <div
          style={{
            display: 'flex',
            backgroundColor: 'var(--color-danger-700)',
            borderRadius: '50%',
            width: '0.75rem',
            height: '0.75rem',
            position: 'absolute',
            top: '-0.125rem',
            left: '1.875rem',
          }}
        />
      )}
    </Stack>
  );
};

import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import Table from '@/design_system/Table';
import { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import { useActionTypesOrganization } from '@/models/actionType';
import { formatCurrency } from '@/utils/number';

const ActionTypes = () => {
  const { _ } = useLingui();

  const { data: { actionTypes, packActionTypes } = {} } = useActionTypesOrganization();

  return (
    <Stack gap="40px">
      <Box padding="24px" gap="24px">
        <h2 className="headline-200-bold">
          <Trans id="settings.action-types.title">Action types</Trans>
        </h2>
        <Table
          aria-label={_(msg({ id: 'settings.action-types.table.label', message: 'Action types' }))}
        >
          <Header>
            <Column width={50}>
              <Trans id="settings.action-types.column.price">Price</Trans>
            </Column>
            <Column width={400}>
              <Trans id="settings.action-types.column.name">Name (en/fr)</Trans>
            </Column>
            <Column width={400}>
              <Trans id="settings.action-types.column.need-name">Need name (en/fr)</Trans>
            </Column>
            <Column width={120}>
              <Trans id="settings.action-types.column.level1">Level 1</Trans>
            </Column>
            <Column width={120}>
              <Trans id="settings.action-types.column.level2">Level 2</Trans>
            </Column>
            <Column width={120}>
              <Trans id="settings.action-types.column.level3">Level 3</Trans>
            </Column>
            <Column width={120}>
              <Trans id="settings.action-types.column.level4">Level 4</Trans>
            </Column>
            <Column width={150}>
              <Trans id="settings.action-types.column.productLevel1">Product family</Trans>
            </Column>
            <Column width={300}>
              <Trans id="settings.action-types.column.productLevel2">Product type</Trans>
            </Column>
            <Column width={150}>
              <Trans id="settings.action-types.column.productLevel3">Product Material</Trans>
            </Column>
            <Column isRowHeader width={320}>
              <Trans id="settings.action-types.column.id">ID</Trans>
            </Column>
          </Header>
          <Body>
            {actionTypes?.map((actionType) => (
              <Row key={actionType.id}>
                <Cell>
                  {formatCurrency(actionType.prices[0].amount, actionType.prices[0].currency)}
                </Cell>
                <Cell>
                  {actionType.actionType.name.en}
                  <br />
                  {actionType.actionType.name.fr}
                </Cell>
                <Cell>
                  {actionType.actionType.needName.en}
                  <br />
                  {actionType.actionType.needName.fr}
                </Cell>
                <Cell>{actionType.actionType.needL1}</Cell>
                <Cell>{actionType.actionType.needL2}</Cell>
                <Cell>{actionType.actionType.needL3}</Cell>
                <Cell>{actionType.actionType.needL4}</Cell>
                <Cell>{actionType.productL1.join(', ') || '-'}</Cell>
                <Cell>{actionType.productL2.join(', ') || '-'}</Cell>
                <Cell>{actionType.productL3.join(', ') || '-'}</Cell>
                <Cell>{actionType.id}</Cell>
              </Row>
            ))}
          </Body>
        </Table>
      </Box>
      <Box padding="24px" gap="24px">
        <h2 className="headline-200-bold">
          <Trans id="settings.pack-action-types.title">Pack action types</Trans>
        </h2>
        <Table
          aria-label={_(
            msg({ id: 'settings.pack-action-types.table.label', message: 'Pack action types' })
          )}
        >
          <Header>
            <Column width={50}>
              <Trans id="settings.action-types.column.price">Price</Trans>
            </Column>
            <Column width={400}>
              <Trans id="settings.action-types.column.name">Name (en/fr)</Trans>
            </Column>
            <Column width={150}>
              <Trans id="settings.action-types.column.productLevel1">Product family</Trans>
            </Column>
            <Column width={300}>
              <Trans id="settings.action-types.column.productLevel2">Product type</Trans>
            </Column>
            <Column width={150}>
              <Trans id="settings.action-types.column.productLevel3">Product Material</Trans>
            </Column>
            <Column isRowHeader width={320}>
              <Trans id="settings.action-types.column.id">ID</Trans>
            </Column>
          </Header>
          <Body>
            {packActionTypes?.map((packActionType) => (
              <Row key={packActionType.id}>
                <Cell>
                  {formatCurrency(
                    packActionType.prices[0].amount,
                    packActionType.prices[0].currency
                  )}
                </Cell>
                <Cell>
                  {packActionType.name.en}
                  <br />
                  <ul>
                    {packActionType.actions.map((action) => (
                      <li key={action.id}>{action.actionType.name.en}</li>
                    ))}
                  </ul>
                  <br />
                  {packActionType.name.fr}
                  <br />
                  <ul>
                    {packActionType.actions.map((action) => (
                      <li key={action.id}>{action.actionType.name.fr}</li>
                    ))}
                  </ul>
                </Cell>
                <Cell>{packActionType.productL1.join(', ') || '-'}</Cell>
                <Cell>{packActionType.productL2.join(', ') || '-'}</Cell>
                <Cell>{packActionType.productL3.join(', ') || '-'}</Cell>
                <Cell>{packActionType.id}</Cell>
              </Row>
            ))}
          </Body>
        </Table>
      </Box>
    </Stack>
  );
};

export default ActionTypes;

import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Stepper, StepProps } from '@/design_system/Stepper/Stepper';
import { ARTICLE_LEGACY_STATUSES } from '@/models/article';
import { ClientRequestWithRelations } from '@/models/request';
import { ClientArticleStepper } from '@/routes/Brand/Requests/Request/components/shared/ClientArticleStepper';
import useViewPort from '@/utils/useViewport';

const CLIENT_STEPS = [
  {
    id: 'estimate',
    name: msg({ id: 'article.status.estimate', message: 'Estimate' }),
  },
  {
    id: 'validation',
    name: msg({ id: 'article.status.validation', message: 'Validation' }),
    hidden: true,
  },
  {
    id: 'transit',
    name: msg({ id: 'article.status.transit', message: 'Transit' }),
  },
  {
    id: 'analyse',
    name: msg({ id: 'article.status.analysis', message: 'Analysis' }),
  },
  {
    id: 'payment',
    name: msg({ id: 'article.status.payment', message: 'Payment' }),
  },
  {
    id: 'repair',
    name: msg({ id: 'article.status.repair', message: 'Repair' }),
  },
  {
    id: 'delivery',
    name: msg({ id: 'article.status.delivery', message: 'Delivery' }),
  },
] as const;

// TODO: During rework of the Stepper we should remove the "static" step property
const ClientStepper = ({
  step,
  request,
}: {
  step: (typeof CLIENT_STEPS)[number]['id'];
  request: ClientRequestWithRelations;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  if (request.workflowId) {
    if (request.articles.length === 1) {
      return (
        <ClientArticleStepper
          size={isMobile ? 'small' : 'large'}
          article={request.articles[0]}
          showStepCount={isMobile}
        />
      );
    }

    return;
  }

  const clientStatusWhenCancelled = ARTICLE_LEGACY_STATUSES.find(
    (status) =>
      status.id ===
      (request.isCancelled && request.articles[0].cancellationDetail?.statusWhenCancelled)
  );

  const getStatus = (clientStep: (typeof CLIENT_STEPS)[number]['id'], index: number) => {
    const currentStepIndex = CLIENT_STEPS.findIndex((s) => s.id == step);

    if (clientStatusWhenCancelled?.stepGroupId) {
      const cancelledStepIndex = CLIENT_STEPS.findIndex(
        (s) => s.id == clientStatusWhenCancelled?.stepGroupId
      );
      if (cancelledStepIndex < index && index < currentStepIndex) {
        return 'skipped';
      }
      if (clientStep === clientStatusWhenCancelled?.stepGroupId) {
        return 'cancelled';
      }
    }
    if (clientStep == step) {
      return 'in-progress';
    }
    if (index < currentStepIndex) {
      return 'done';
    }
    return 'pending';
  };

  return (
    <Stepper
      size={isMobile ? 'small' : 'large'}
      showStepCount={isMobile}
      steps={
        CLIENT_STEPS.map((clientStep, index) => {
          if ('hidden' in clientStep && clientStep.hidden) {
            return undefined;
          }
          return {
            name: _(clientStep.name),
            status: getStatus(clientStep.id, index),
          };
        }).filter((x) => x !== undefined) as StepProps[]
      }
    />
  );
};

export default ClientStepper;

import { ReactNode } from 'react';

import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import useViewPort from '@/utils/useViewport';

import { AcceptDispatchAction } from './AcceptDispatchAction';
import { AcceptRequalificationAction } from './AcceptRequalificationAction';
import { AnalyzeArticleAction } from './AnalyzeArticleAction';
import { DispatchArticleAction } from './DispatchArticleAction';
import { EstimateArticleAction } from './EstimateArticleAction';
import { HandleShipmentIssueAction } from './HandleShipmentIssueAction';
import { RepairArticleAction } from './RepairArticleAction';

const ArticleActions = (props: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  onActionDone: () => void;
  onCheckErrors: () => boolean;
}) => {
  const { isMobile } = useViewPort();

  const { article } = props;

  if (!article.task || article.archived) {
    return null;
  }

  let action: ReactNode | null = null;

  switch (article.task.type) {
    case 'estimate_article':
      action = <EstimateArticleAction {...props} />;
      break;
    case 'dispatch_article':
      action = <DispatchArticleAction {...props} />;
      break;
    case 'accept_dispatch':
      action = <AcceptDispatchAction {...props} />;
      break;
    case 'handle_shipment_issue':
      action = <HandleShipmentIssueAction {...props} />;
      break;
    case 'accept_requalification':
      action = <AcceptRequalificationAction {...props} />;
      break;
    case 'analyze_article':
      action = <AnalyzeArticleAction {...props} />;
      break;
    case 'repair_article':
      action = <RepairArticleAction {...props} />;
      break;
    default:
      action = null;
  }

  if (!action) {
    return null;
  }

  return <div style={{ padding: isMobile ? '1rem' : '1rem 1.5rem' }}>{action}</div>;
};

export default ArticleActions;

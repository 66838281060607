import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { ShipmentWithRelations, useCompleteInTransit } from '@/models/shipment';
import { useCurrentSession } from '@/services/auth';

const InTransitAction = ({
  shipment,
  onTransitDone,
}: {
  shipment: ShipmentWithRelations;
  onTransitDone: () => void;
}) => {
  const { mutateAsync: completeInTransit, isPending, isSuccess } = useCompleteInTransit();

  const { currentSession } = useCurrentSession();
  const canVerifyReception = shipment.canReceptionBeVerifiedBy(currentSession);

  if (!canVerifyReception) {
    return null;
  }

  return (
    <Stack row gap="16px" justifyContent="flex-end">
      <Button
        variant="primary"
        onPress={async () => {
          await completeInTransit(shipment.id);
          onTransitDone();
        }}
        isLoading={isPending || isSuccess}
      >
        <Trans id="shipment.package-received">Mark as received</Trans>
      </Button>
    </Stack>
  );
};

export default InTransitAction;

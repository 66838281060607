import React, { useState } from 'react';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconChevron from '@/icons/Chevron.svg';

const Accordion = ({
  trigger,
  children,
  openByDefault = false,
  ariaLabel,
}: {
  trigger: React.ReactNode;
  children: React.ReactNode;
  openByDefault?: boolean;
  ariaLabel?: string;
}) => {
  const [isOpen, setIsOpen] = useState(openByDefault);

  return (
    <Stack justifyContent="space-between" alignItems="stretch" gap="0.5rem">
      <Button
        ariaLabel={ariaLabel}
        variant="style-less"
        className="accordion-trigger"
        onPress={() => setIsOpen(!isOpen)}
      >
        <Stack style={{ flex: 1 }} row alignItems="center" justifyContent="space-between">
          {trigger}
          <IconChevron down={!isOpen} up={isOpen} />
        </Stack>
      </Button>
      {isOpen && <>{children}</>}
    </Stack>
  );
};

export default Accordion;

import Stack from '@/design_system/Stack';
import { createBEMClasses } from '@/utils/classname';

import './RequestLayout.css';

const { block, element } = createBEMClasses('request-layout');

export const RequestLayout = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <Stack className={`${block()} ${className ?? ''}`}>{children}</Stack>;
};

export const RequestLayoutHeader = ({ children }: { children: React.ReactNode }) => {
  return <Stack className={element('header')}>{children}</Stack>;
};

export const RequestLayoutContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack row flexWrap="nowrap" className={element('content')}>
      {children}
    </Stack>
  );
};

export const RequestLayoutLeftPart = ({ children }: { children: React.ReactNode }) => {
  return <Stack className={element('left-part')}>{children}</Stack>;
};

export const RequestLayoutLeftPartContent = ({ children }: { children: React.ReactNode }) => {
  return <Stack className={element('left-part-content')}>{children}</Stack>;
};

export const RequestLayoutLeftPartFooter = ({ children }: { children: React.ReactNode }) => {
  return <Stack className={element('left-part-footer')}>{children}</Stack>;
};

export const RequestLayoutRightPart = ({ children }: { children: React.ReactNode }) => {
  return <Stack className={element('right-part')}>{children}</Stack>;
};

export const RequestLayoutTopPart = ({ children }: { children: React.ReactNode }) => {
  return <Stack className={element('top-part')}>{children}</Stack>;
};

export const RequestLayoutCenter = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack
      style={{ height: '100%' }}
      gap="1rem"
      alignItems="center"
      flexWrap="nowrap"
      justifyContent="center"
    >
      {children}
    </Stack>
  );
};

import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Box from '@/design_system/Box/Box';
import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack/Stack';
import IconArrow from '@/icons/Arrow.svg';
import { useShipment } from '@/models/shipment';
import { ShipmentActivities } from '@/routes/Shipments/Shipment/components/Activities/ShipmentActivities';
import { ShipmentComments } from '@/routes/Shipments/Shipment/components/ShipmentComments/ShipmentComments';
import { ErrorBoundary } from '@/services/sentry';
import { useResetSimpleBlocker } from '@/utils/navigation';
import { refreshBrowserTabTitle } from '@/utils/refreshBrowserTabTitle';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import ArticlesTable from './components/ArticlesTable/ArticlesTable';
import FinalisationAction from './components/FinalisationAction';
import FinalisationMessage from './components/FinalisationMessage';
import HandoverAction from './components/HandoverAction';
import HandoverMessage from './components/HandoverMessage';
import InTransitAction from './components/InTransitAction';
import InTransitMessage from './components/InTransitMessage';
import PlaceDetails from './components/PlaceDetails';
import PreparationActions from './components/PreparationActions';
import PreparationMessage from './components/PreparationMessage';
import ShipmentHeader from './components/ShipmentHeader';
import ShipmentInfo from './components/ShipmentInfo';
import ShipmentStepper from './components/ShipmentStepper';
import ValidatedMessage from './components/ValidatedMessage';
import VerificationActions from './components/VerificationActions';
import VerificationMessage from './components/VerificationMessage';

const Shipment = () => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const { id } = useParams();
  const { data: shipment, isLoading } = useShipment(id);
  const [commentsRef, scrollCommentsIntoView] = useScrollIntoView<HTMLDivElement>();
  const [containerRef, scrollContainerIntoView] = useScrollIntoView<HTMLDivElement>();

  // Display the shipment reference in the browser tab title
  useEffect(() => {
    if (!shipment) {
      return;
    }

    const shipmentDetails =
      shipment.destinationClient?.name ??
      shipment.destinationStore?.name ??
      shipment.destinationWorkshop?.name;
    const shipmentReference = shipmentDetails
      ? `${shipment.reference} - ${shipmentDetails}`
      : shipment.reference;

    document.querySelector('meta[name="subtitle"]')?.setAttribute('content', shipmentReference);
    refreshBrowserTabTitle();

    return () => {
      document.querySelector('meta[name="subtitle"]')?.setAttribute('content', '');
      refreshBrowserTabTitle();
    };
  }, [shipment]);

  // Clear router blocker state after coming from shipments/new or shipment/edit
  useResetSimpleBlocker();

  if (isLoading) {
    return (
      <div>
        <Trans id="_general.loading">Loading...</Trans>
      </div>
    );
  }

  if (!shipment) {
    return (
      <div>
        <Trans id="shipment.unknown">Unknown shipment</Trans>
      </div>
    );
  }

  return (
    <>
      <div className="container" ref={containerRef}>
        <Stack gap={isMobile ? '2rem' : '1.5rem'}>
          <div className="is-hidden-mobile">
            <Button variant="secondary" to="/shipments">
              <IconArrow left />
              <Trans id="_general.back">Back</Trans>
            </Button>
          </div>

          <Box padding="24px 32px" gap="24px" removeStyleOnMobile>
            <ShipmentHeader shipment={shipment} onCommentButtonPress={scrollCommentsIntoView} />

            <ErrorBoundary>
              <ShipmentInfo shipment={shipment} />
            </ErrorBoundary>

            <ErrorBoundary>
              <ShipmentStepper shipment={shipment} />
            </ErrorBoundary>

            <ErrorBoundary>
              {shipment.step === 'preparation' && <PreparationMessage shipment={shipment} />}

              {shipment.step === 'finalisation' && <FinalisationMessage shipment={shipment} />}

              {shipment.step === 'handover' && <HandoverMessage shipment={shipment} />}

              {shipment.step === 'in-transit' && <InTransitMessage />}

              {shipment.step === 'verification' && <VerificationMessage shipment={shipment} />}

              {shipment.step === 'validated' && <ValidatedMessage shipment={shipment} />}
            </ErrorBoundary>

            <ErrorBoundary>
              <ArticlesTable shipment={shipment} />
            </ErrorBoundary>

            <ErrorBoundary>
              {shipment.step === 'preparation' && (
                <PreparationActions
                  shipment={shipment}
                  onValidationDone={scrollContainerIntoView}
                />
              )}

              {shipment.step === 'finalisation' && (
                <FinalisationAction
                  shipment={shipment}
                  onFinalisationDone={scrollContainerIntoView}
                />
              )}

              {shipment.step === 'handover' && (
                <HandoverAction shipment={shipment} onHandOverDone={scrollContainerIntoView} />
              )}

              {shipment.step === 'in-transit' && (
                <InTransitAction shipment={shipment} onTransitDone={scrollContainerIntoView} />
              )}

              {shipment.step === 'verification' && (
                <VerificationActions
                  shipment={shipment}
                  onVerificationDone={scrollContainerIntoView}
                />
              )}
            </ErrorBoundary>
          </Box>

          <Stack
            row={!isMobile}
            gap="16px"
            flexWrap="nowrap"
            style={{ maxHeight: isMobile ? undefined : '37rem' }}
            ref={commentsRef}
          >
            <Box
              style={{
                flex: '2',
                padding: '24px 0 0 0' /* eslint-disable-line lingui/no-unlocalized-strings */,
              }}
              removeStyleOnMobile
            >
              <ErrorBoundary>
                <ShipmentComments shipmentId={shipment.id} />
              </ErrorBoundary>
            </Box>

            <Stack gap="1.5rem" style={{ flex: '1' }}>
              <ErrorBoundary>
                <PlaceDetails
                  name={_(msg({ id: 'shipment.origin.title', message: 'Origin' }))}
                  place={shipment.origin}
                  address={shipment.originAddress}
                />
                <PlaceDetails
                  name={_(msg({ id: 'shipment.destination.title', message: 'Destination' }))}
                  place={shipment.destination}
                  address={shipment.destinationAddress}
                />
              </ErrorBoundary>
            </Stack>
          </Stack>

          <ShipmentActivities shipment={shipment} />
        </Stack>
      </div>
      <Outlet />
    </>
  );
};

export default Shipment;

import { Key, ListBox, ListBoxItem, Text } from 'react-aria-components';

import { createBEMClasses } from '@/utils/classname';

import './Dropdown.css';

const { block, element } = createBEMClasses('dropdown');

const Dropdown = ({
  ariaLabel,
  children,
  withSeparators,
  onAction,
  size = 'medium',
  style,
  selectionMode,
}: {
  ariaLabel?: string;
  children: React.ReactNode;
  withSeparators?: boolean;
  onAction?: (id: string) => void;
  size?: 'medium' | 'large';
  style?: React.CSSProperties;
  selectionMode?: 'none' | 'single' | 'multiple';
}) => {
  return (
    <ListBox
      aria-label={ariaLabel}
      className={block({ size, 'with-separators': withSeparators })}
      onAction={onAction ? (key: Key) => onAction(key as string) : undefined}
      style={style}
      selectionMode={selectionMode}
    >
      {children}
    </ListBox>
  );
};

export default Dropdown;

export const NodeDropdownItem = ({
  id,
  textValue,
  children,
}: {
  id: string;
  textValue: string;
  children: React.ReactNode;
}) => {
  return (
    <ListBoxItem id={id} className={element('item', 'node')} textValue={textValue}>
      {children}
    </ListBoxItem>
  );
};

export const BasicDropdownItem = ({
  id,
  text,
  textValue,
  icon,
  variant = 'default',
  size = 'small',
}: {
  id: string;
  text: string;
  variant?: 'default' | 'brand';
  size?: 'small' | 'medium' | 'large';
  /**
   * Can be useful in a Combobox to allow matching more than just the text
   */
  textValue?: string;
  icon?: React.ReactNode;
}) => {
  return (
    <ListBoxItem
      id={id}
      className={element('item', { basic: true, variant, size })}
      textValue={textValue ?? text}
    >
      {icon}
      {text}
    </ListBoxItem>
  );
};

export const MultiLineDropdownItem = ({
  id,
  text,
  subText,
}: {
  id: string;
  text: string;
  subText: string;
}) => {
  return (
    <ListBoxItem id={id} className={element('item', 'multi-line')} textValue={`${text} ${subText}`}>
      <Text slot="label">{text}</Text>
      <Text slot="description">{subText}</Text>
    </ListBoxItem>
  );
};

import { useCallback, useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import WorkshopList, { WorkshopListEmptyState } from '@/components/WorkshopList';
import Message from '@/design_system/Message';
import RadioGroup from '@/design_system/RadioGroup';
import Stack from '@/design_system/Stack';
import { useActivities, useUpdateArticle } from '@/models/article';
import { ArticleWithRelations, Request } from '@/models/request';
import { useWorkshops, WorkshopWithRelations } from '@/models/workshop';

const ArticleDispatch = ({
  request,
  article,
  disabled,
  error,
}: {
  request: Request;
  article: ArticleWithRelations;
  disabled: boolean;
  error?: string;
}) => {
  const { _ } = useLingui();

  const [workshopType, setWorkshopType] = useState<'internal' | 'external' | undefined>(
    article.workshop ? (article.workshop.external ? 'external' : 'internal') : undefined
  );

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const updateArticleWorkshop = useCallback(
    (workshop: WorkshopWithRelations | null) => {
      if ((workshop?.id ?? null) !== article.workshopId) {
        const workshopIdUpdate = workshop?.id ?? null;

        updateArticle({
          data: {
            workshopId: workshopIdUpdate,
          },
          optimisticData: {
            workshop,
          },
        });
      }
    },
    [article.workshopId, updateArticle]
  );

  const { data: { workshops } = { workshops: [] }, isFetching: isFetchingWorkshops } = useWorkshops(
    {
      articleId: article.id,
      limit: 100,
    },
    {
      enabled: article.hasActions,
      keepPreviousData: true,
    }
  );

  const { data: { activities: dispatchRefusedActivities } = { activities: [] } } = useActivities({
    articleId: article.id,
    types: ['job_refused'],
  });

  const externalWorkshops = workshops.filter((workshop) => workshop.external);
  const internalWorkshops = workshops.filter((workshop) => !workshop.external);

  const showWorkshopList =
    (workshopType === 'external' && externalWorkshops.length > 0) ||
    (workshopType === 'internal' && internalWorkshops.length > 1);

  const showEmptyState =
    (workshopType === 'external' && externalWorkshops.length === 0) ||
    (workshopType === 'internal' && internalWorkshops.length === 0) ||
    workshops.length === 0;

  const handleChangeWorkshopType = (value: 'internal' | 'external') => {
    setWorkshopType(value);

    if (value === 'internal' && internalWorkshops.length === 1) {
      updateArticleWorkshop(internalWorkshops[0]);
    } else {
      updateArticleWorkshop(null);
    }
  };

  const resetWorkshopType =
    // If a single-choice internal workshop was unselected, reset the radio button
    (!article.workshop && workshopType === 'internal' && internalWorkshops.length === 1) ||
    // If no actions have been selected, reset the radio button
    !article.hasActions ||
    // If no internal or external workshop is available, reset the radio button
    workshops.length === 0;

  const workshopTypeValue = resetWorkshopType ? undefined : workshopType;

  return (
    <Stack gap="4px">
      <RadioGroup
        value={workshopTypeValue ?? 'none'}
        onChange={(value) => handleChangeWorkshopType(value as 'internal' | 'external')}
        label={_(msg({ id: 'article.estimate.dispatch.label', message: 'Dispatch' }))}
        options={[
          { value: 'internal', children: _(msg({ id: 'workshop.internal', message: 'Internal' })) },
          { value: 'external', children: _(msg({ id: 'workshop.external', message: 'External' })) },
        ]}
        isDisabled={!article.hasActions || workshops.length === 0 || disabled}
      />

      {!article.hasActions && !disabled && (
        <Message type="info">
          <Trans id="article.estimate.dispatch.no.actions">
            Please set the repair actions before selecting the workshop.
          </Trans>
        </Message>
      )}

      {article.hasActions && showWorkshopList && (
        <WorkshopList
          selected={article.workshopId ?? undefined}
          workshops={workshopType === 'external' ? externalWorkshops : internalWorkshops}
          dispatchRefusedActivities={dispatchRefusedActivities}
          onSelect={updateArticleWorkshop}
          isFetchingWorkshops={isFetchingWorkshops}
          isDisabled={disabled}
        />
      )}

      {article.hasActions && showEmptyState && (
        <WorkshopListEmptyState
          emptyStateText={_(
            msg({
              id: 'article.estimate.dispatch.no-workshop-found',
              message: "We couldn't find any workshop for this kind of care & repair job needs.",
            })
          )}
        />
      )}

      {error && <Message type="error">{error}</Message>}
    </Stack>
  );
};

export default ArticleDispatch;

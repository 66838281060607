import { ReactNode } from 'react';
import { GridListItem } from 'react-aria-components';
import { useLocation } from 'react-router-dom';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconArchive from '@/icons/Archive.svg';
import IconCheck from '@/icons/Check.svg';
import IconUnarchive from '@/icons/Unarchive.svg';
import IconUnread from '@/icons/Unread.svg';
import {
  TNotification,
  useArchiveNotification,
  useMarkNotificationAsRead,
  useMarkNotificationAsUnread,
  useUnarchiveNotification,
} from '@/models/notification';
import { createBEMClasses } from '@/utils/classname';
import { formatDate, fromNow } from '@/utils/date';
import useViewPort, { checkIfTouchDevice } from '@/utils/useViewport';

import './BaseNotification.css';

const { block, element } = createBEMClasses('base-notification');

export const BaseNotification = ({
  user,
  notification,
  notificationLabel,
  notificationHeader,
  notificationContent,
  icon,
  type = 'neutral',
}: {
  user?: { id: string; name: string };
  notification: {
    id: string;
    readAt: string | null;
    archivedAt: string | null;
    createdAt: string;
    request?: { id: string; reference: string } | null;
    shipment?: { id: string; reference: string } | null;
    articleId?: string | null;
    type: TNotification['type'];
  };
  notificationLabel: string;
  notificationHeader: ReactNode;
  notificationContent?: ReactNode;
  icon?: ReactNode;
  type?: 'neutral' | 'new' | 'warning' | 'danger' | 'primary';
}) => {
  const location = useLocation();
  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsRead();

  const createdAtDate = new Date(notification.createdAt);

  const href = notification.request
    ? `/requests/${notification.request.id}${notification.articleId ? `/articles/${notification.articleId}` : ''}`
    : notification.shipment
      ? `/shipments/${notification.shipment.id}`
      : undefined;

  const reference = notification.request?.reference ?? notification.shipment?.reference;

  return (
    <GridListItem
      textValue={notificationLabel}
      className={block()}
      href={href}
      // `id` is needed to get the property `key` in the GridList#onAction callback
      id={notification.id}
      data-tracking-id={`notification.item.${notification.type}`}
      onAction={() => {
        // If the notification concerns the page the user is on, we need to refresh the page in order to make the changes appear on the page
        if (location.pathname === href) {
          window.location.reload();
        }
        markNotificationAsRead({ notificationId: notification.id });
      }}
    >
      <div
        style={{
          position: 'relative',
          height: '100%',
          overflow: 'visible',
        }}
      >
        {!notification.readAt && <UnreadNotificationDot />}
        {user && (
          <UserAvatar
            user={user}
            size="x-small"
            variant={!notification.archivedAt && notification.readAt ? 'greyedOut' : undefined}
          />
        )}
        {icon && <div className={element('icon', { type })}>{icon}</div>}
      </div>
      <div
        className={!notification.archivedAt && notification.readAt ? 'text-disabled' : ''}
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <Stack
          style={{
            marginLeft: '8px',
            overflow: 'hidden',
            display: 'flex',
            flex: 1,
          }}
        >
          <Stack
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '0.5rem',
              justifyContent: 'space-between',
            }}
          >
            <span className="paragraph-100-regular">
              {reference && <span className="paragraph-100-medium">[{reference}] </span>}
              {notificationHeader}
              <small
                className="paragraph-100-regular text-disabled"
                title={formatDate(createdAtDate, {
                  dateStyle: 'long',
                  timeStyle: 'short',
                })}
              >
                {' '}
                • {fromNow(createdAtDate)}
              </small>
            </span>
            {notification.archivedAt ? (
              <UnarchiveButton notification={notification} />
            ) : (
              <MarkAsReadAndArchiveButton notification={notification} />
            )}
          </Stack>
          {notificationContent}
        </Stack>
      </div>
    </GridListItem>
  );
};

const UnreadNotificationDot = () => {
  const { isMobile } = useViewPort();

  return (
    <div
      style={{
        position: 'absolute',
        height: '0.5rem',
        width: '0.5rem',
        top: 'calc(50% - 4px)',
        left: isMobile ? '-0.75rem' : '-1rem',
        borderRadius: '100%',
        backgroundColor: 'var(--color-secondary-700)',
      }}
    />
  );
};

const MarkAsReadAndArchiveButton = ({
  notification,
}: {
  notification: { id: string; readAt: string | null };
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const isTouchDevice = checkIfTouchDevice();
  const { mutateAsync: markNotificationAsRead, isPending: isMarkNotificationAsReadPending } =
    useMarkNotificationAsRead();
  const { mutateAsync: markNotificationAsUnread, isPending: isMarkNotificationAsUnreadPending } =
    useMarkNotificationAsUnread();
  const { mutateAsync: archiveNotification, isPending: isArchiveNotificationPending } =
    useArchiveNotification();

  const markAsReadOrUnreadButtonLabel = notification.readAt
    ? _(
        msg({
          id: 'notification.mark-as-unread',
          message: 'Mark as unread',
        })
      )
    : _(
        msg({
          id: 'notification.mark-as-read',
          message: 'Mark as read',
        })
      );

  const archiveButtonLabel = _(
    msg({
      id: 'notification.archive',
      message: 'Archive',
    })
  );

  return (
    <Stack
      style={{
        flexDirection: 'row',
        marginLeft: '8px',
        ...(isTouchDevice || isMobile
          ? { position: 'relative' }
          : {
              position: 'absolute',
              right: '0',
            }),
      }}
    >
      <Tooltip content={markAsReadOrUnreadButtonLabel} hideOnTouchDevice>
        <Button
          className={element('action-button')}
          variant="secondary"
          size="medium"
          iconOnly
          onPress={() =>
            notification.readAt
              ? markNotificationAsUnread({ notificationId: notification.id })
              : markNotificationAsRead({ notificationId: notification.id })
          }
          ariaLabel={markAsReadOrUnreadButtonLabel}
          disabled={isMarkNotificationAsReadPending || isMarkNotificationAsUnreadPending}
          style={{
            borderRadius: '6px 0 0 6px', // eslint-disable-line lingui/no-unlocalized-strings
            borderRight: 'var(--color-neutral-0)',
          }}
        >
          {notification.readAt ? <IconUnread /> : <IconCheck />}
        </Button>
      </Tooltip>

      <Tooltip content={archiveButtonLabel} hideOnTouchDevice>
        <Button
          className={element('action-button')}
          variant="secondary"
          size="medium"
          iconOnly
          onPress={() => archiveNotification({ notificationId: notification.id })}
          ariaLabel={archiveButtonLabel}
          disabled={isArchiveNotificationPending}
          style={{
            borderRadius: '0 6px 6px 0', // eslint-disable-line lingui/no-unlocalized-strings
            borderLeft: 'var(--color-neutral-0)',
          }}
        >
          <IconArchive />
        </Button>
      </Tooltip>
    </Stack>
  );
};

const UnarchiveButton = ({
  notification,
}: {
  notification: { id: string; readAt: string | null };
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const isTouchDevice = checkIfTouchDevice();
  const { mutateAsync: unarchiveNotification, isPending: isUnarchiveNotificationPending } =
    useUnarchiveNotification();

  const unarchiveButtonLabel = _(
    msg({
      id: 'notification.unarchive',
      message: 'Unarchive',
    })
  );

  return (
    <Tooltip content={unarchiveButtonLabel} hideOnTouchDevice>
      <Button
        className={element('action-button')}
        variant="secondary"
        size="medium"
        iconOnly
        onPress={() => unarchiveNotification({ notificationId: notification.id })}
        ariaLabel={unarchiveButtonLabel}
        disabled={isUnarchiveNotificationPending}
        style={{
          marginLeft: '8px',
          ...(isTouchDevice || isMobile
            ? { display: 'flex' }
            : {
                position: 'absolute',
                right: 0,
              }),
        }}
      >
        <IconUnarchive />
      </Button>
    </Tooltip>
  );
};

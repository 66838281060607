import { ReactNode } from 'react';

import Stack from '@/design_system/Stack';
import IconDone from '@/icons/Done.svg';
import IconError from '@/icons/Error.svg';
import IconInfo from '@/icons/Info.svg';
import IconWarning from '@/icons/Warning.svg';
import { createBEMClasses } from '@/utils/classname';

import './AlertBar.css';

type AlertBarProps = {
  type?: 'info' | 'success' | 'warning' | 'error';
  size?: 'small' | 'large';
  title?: ReactNode;
  icon?: ReactNode;
  hideIcon?: boolean;
  children?: ReactNode;
  className?: string;
};

const { block, element } = createBEMClasses('alert-bar');

const iconForType = {
  info: <IconInfo />,
  success: <IconDone />,
  warning: <IconWarning />,
  error: <IconError />,
};

const AlertBar = ({
  type = 'info',
  size = 'small',
  title,
  icon,
  hideIcon = false,
  children,
  className,
}: AlertBarProps) => {
  return (
    <Stack
      row
      gap="0.5rem"
      flexWrap="nowrap"
      alignItems="flex-start"
      className={block({ type, size }, className)}
    >
      {!hideIcon && <span className={element('icon')}>{icon ?? iconForType[type]}</span>}
      <Stack gap="0.5rem">
        {title && <div className={element('title')}>{title}</div>}
        {children && <div className={element('children')}>{children}</div>}
      </Stack>
    </Stack>
  );
};

export default AlertBar;

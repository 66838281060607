import { forwardRef, Ref } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import AlertBar from '@/design_system/AlertBar';
import Stack from '@/design_system/Stack';
import { RequestWithRelations } from '@/models/request';
import useViewPort from '@/utils/useViewport';

const NewRequestGlobalError = forwardRef(function ArticleFormGlobalError(
  { showGlobalError, request }: { showGlobalError: boolean; request: RequestWithRelations },
  ref: Ref<HTMLDivElement>
) {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();

  return (
    <div ref={ref}>
      {showGlobalError && (
        <Stack padding={isMobile ? '16px 16px 0' : '24px 32px 0'}>
          <AlertBar
            type="error"
            title={
              request.draft
                ? _(
                    msg({
                      id: 'requests.new.error',
                      message:
                        'Please fill the missing information in order to be able to create a request',
                    })
                  )
                : _(
                    msg({
                      id: 'requests.edit.error',
                      message:
                        'Please fill the missing information in order to be able to edit the request',
                    })
                  )
            }
          />
        </Stack>
      )}
    </div>
  );
});

export default NewRequestGlobalError;

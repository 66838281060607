import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ActionTypeSearchSelect from '@/components/ActionTypeSearchSelect';
import FileUpload from '@/components/FileUpload';
import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import { useRequalify } from '@/models/article';
import { Medium, useDeleteMedium } from '@/models/medium';
import {
  ArticleAction,
  ArticleCustomAction,
  ArticlePackAction,
  ArticleWithRelations,
} from '@/models/request';
import { useCurrentSession } from '@/services/auth';
import { Currency } from '@/utils/number';

const RequalificationDialog = ({
  article,
  isOpen,
  setIsOpen,
  onRequalified,
}: {
  article: ArticleWithRelations;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onRequalified: () => void;
}) => {
  const { _ } = useLingui();

  const { currentSession } = useCurrentSession();
  const workshop = currentSession?.workshop;

  const allowWarranty =
    (article.step && 'allowWarranty' in article.step.config && article.step.config.allowWarranty) ??
    false;
  const allowCustomActions =
    (article.step &&
      'allowCustomActions' in article.step.config &&
      article.step.config.allowCustomActions) ??
    false;

  const photos = (article.media || []).filter((medium) =>
    ['requalification-defect-photo'].includes(medium.type)
  );

  if (!workshop) {
    return null;
  }

  return (
    <Dialog
      title={_(msg({ id: 'requalification-modal.title', message: 'Requalify actions' }))}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      style={{ width: '47rem' }}
    >
      <RequalificationForm
        article={article}
        photos={photos}
        requestId={article.requestId}
        showResponsibility={allowWarranty && !workshop.external}
        allowCustomActions={allowCustomActions}
        customActionCostCurrency={workshop.currency ?? undefined}
        close={() => setIsOpen(false)}
        onRequalified={onRequalified}
      />
    </Dialog>
  );
};

const useRequalificationState = (
  initialArticle?: ArticleWithRelations,
  initialPhotos?: Medium[]
) => {
  const { _ } = useLingui();

  const [actions, setActions] = useState<ArticleAction[]>(
    initialArticle?.currentActions.map((action) => Object.assign({}, action)) ?? []
  );

  const [customActions, setCustomActions] = useState<ArticleCustomAction[]>(
    initialArticle?.currentCustomActions.map((action) => Object.assign({}, action)) ?? []
  );

  const [packActions, setPackActions] = useState<ArticlePackAction[]>(
    initialArticle?.currentPackActions.map((action) => Object.assign({}, action)) ?? []
  );

  const [photos, setPhotos] = useState<Medium[]>(initialPhotos ?? []);

  const [comment, setComment] = useState(initialArticle?.requalificationComment ?? '');

  const emptyFormError =
    actions.length === 0 && customActions.length === 0 && packActions.length === 0
      ? _(
          msg({
            id: 'requalification-modal.actions.error',
            message: 'Please add at least one action or custom action',
          })
        )
      : undefined;

  return {
    actions,
    setActions,
    customActions,
    setCustomActions,
    packActions,
    setPackActions,
    emptyFormError,
    photos,
    setPhotos,
    comment,
    setComment,
  };
};

export type RequalificationState = ReturnType<typeof useRequalificationState>;

const RequalificationForm = ({
  article,
  photos,
  requestId,
  showResponsibility,
  allowCustomActions,
  customActionCostCurrency,
  close,
  onRequalified,
}: {
  article: ArticleWithRelations;
  photos: Medium[];
  requestId: string;
  showResponsibility: boolean;
  allowCustomActions: boolean;
  customActionCostCurrency?: Currency;
  close: () => void;
  onRequalified: () => void;
}) => {
  const { _ } = useLingui();

  const requalificationState = useRequalificationState(
    article.status === 'requalified' ? article : undefined,
    article.status === 'requalified' ? photos : undefined
  );

  const [showErrors, setShowErrors] = useState(false);

  const [mediaToDelete, setMediaToDelete] = useState<string[]>([]);

  const { mutateAsync: deleteMedium } = useDeleteMedium();

  const {
    mutateAsync: requalifyArticle,
    isPending,
    isSuccess,
  } = useRequalify({
    articleId: article.id,
  });

  const cancel = () => {
    mediaToDelete.map((id) => deleteMedium(id));
    requalificationState.photos.map((photo) => deleteMedium(photo.id));
    close();
  };

  const save = async () => {
    setShowErrors(true);

    if (requalificationState.emptyFormError) {
      return;
    }

    await Promise.all([
      ...mediaToDelete.map((id) => deleteMedium(id)),
      requalifyArticle({
        actions: requalificationState.actions.map((action) => ({
          id: action.id,
          actionTypeOrganizationId: action.actionTypeOrganization.id,
          quantity: action.quantity,
          brandResponsibility: showResponsibility ? action.brandResponsibility : undefined,
        })),
        customActions: allowCustomActions
          ? requalificationState.customActions.map((customAction) => ({
              id: customAction.id,
              description: customAction.description,
              quantity: customAction.quantity,
              costAmountWithoutDiscount: customAction.costAmountWithoutDiscount ?? undefined,
              brandResponsibility: showResponsibility
                ? customAction.brandResponsibility
                : undefined,
            }))
          : undefined,
        packActions: requalificationState.packActions.map((packAction) => ({
          id: packAction.id,
          packActionTypeOrganizationId: packAction.packActionTypeOrganizationId,
          quantity: packAction.quantity,
          brandResponsibility: showResponsibility ? packAction.brandResponsibility : undefined,
        })),
        comment: requalificationState.comment ?? null,
      }),
    ]);

    onRequalified();

    close();
  };

  return (
    <>
      <main>
        <Stack gap="1rem">
          <p className="paragraph-100-regular text-secondary">
            <Trans id="requalification-modal.intro">
              If the initial required actions do not match the needs of the item, please search and
              add the appropriate ones below. Once submitted, the requalification will be reviewed
              by the After Sales Manager.
            </Trans>
          </p>
          <ActionTypeSearchSelect
            mode="action"
            showCost
            showResponsibility={showResponsibility}
            allowCustomActions={allowCustomActions}
            articleActions={requalificationState.actions}
            articleCustomActions={requalificationState.customActions}
            articlePackActions={requalificationState.packActions}
            onChangeActions={requalificationState.setActions}
            onChangeCustomActions={requalificationState.setCustomActions}
            onChangePackActions={requalificationState.setPackActions}
            productL1={article.productL1 ?? undefined}
            productL2={article.productL2 ?? undefined}
            productL3={article.productL3 ?? undefined}
            requestId={requestId}
            customActionCostCurrency={customActionCostCurrency}
          />
          <FileUpload
            uploadData={{ type: 'requalification-defect-photo', articleId: article.id }}
            type="photo"
            label={_(
              msg({
                id: 'requalification-modal.photos',
                message: 'Add photos of the area(s) requiring actions',
              })
            )}
            media={requalificationState.photos}
            onChange={requalificationState.setPhotos}
            onDelete={(id) => setMediaToDelete([...mediaToDelete, id])}
          />
          <TextArea
            label={_(
              msg({
                id: 'requalification-modal.comment.label',
                message: 'Comments for the brand',
              })
            )}
            placeholder={_(
              msg({
                id: 'requalification-modal.comment.placeholder',
                message: 'Add a comment for the brand...',
              })
            )}
            value={requalificationState.comment}
            onChange={(e) => requalificationState.setComment(e.target.value)}
          />
          {showErrors && !!requalificationState.emptyFormError && (
            <Message type="error">{requalificationState.emptyFormError}</Message>
          )}
        </Stack>
      </main>
      <footer>
        <Button variant="secondary" size="medium" onPress={cancel}>
          <Trans id="requalification-modal.actions.cancel">Cancel</Trans>
        </Button>
        <Button variant="danger" size="medium" onPress={save} isLoading={isPending || isSuccess}>
          <Trans id="requalification-modal.actions.requalify">Requalify actions</Trans>
        </Button>
      </footer>
    </>
  );
};

export default RequalificationDialog;

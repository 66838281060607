import AlertBar from '@/design_system/AlertBar';
import Stack from '@/design_system/Stack';
import useViewPort from '@/utils/useViewport';

export const NewShipmentGlobalError = ({
  showGlobalError,
  title,
}: {
  showGlobalError: boolean;
  title: React.ReactNode;
}) => {
  const { isMobile } = useViewPort();

  return (
    <>
      {showGlobalError && (
        <Stack padding={isMobile ? '16px 16px 0' : '24px 32px 0'}>
          <AlertBar type="error" title={title} />
        </Stack>
      )}
    </>
  );
};

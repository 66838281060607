import ContactDetails from '@/components/ContactDetails';
import Box from '@/design_system/Box';
import { Address } from '@/models/address';
import { Client } from '@/models/client';
import { Store } from '@/models/store';
import { Workshop } from '@/models/workshop';
import useViewPort from '@/utils/useViewport';

const PlaceDetails = ({
  name,
  place,
  address,
}: {
  name: string;
  place: Workshop | Store | Client | null;
  address: Address | null;
}) => {
  const { isMobile } = useViewPort();

  return (
    <Box padding={isMobile ? '16px' : '24px 32px'} gap="1rem" ariaLabel={name}>
      <h2 className="headline-300-bold">{name}</h2>
      <ContactDetails
        addressName={place?.name}
        address={address ?? undefined}
        phone={place?.phone}
      />
    </Box>
  );
};

export default PlaceDetails;

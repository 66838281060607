import { useMemo } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ActionName from '@/components/ActionTypeSearchSelect/ActionName';
import PackActionName from '@/components/ActionTypeSearchSelect/PackActionName';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import Table from '@/design_system/Table';
import { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import { ArticleAction, ArticleCustomAction, ArticlePackAction } from '@/models/request';
import { useDatabaseTranslation } from '@/models/translation';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

import {
  BrandResponsibilityCell,
  CostCell,
  EditCustomActionCell,
  PriceCell,
  QuantityCell,
} from './Cells';

const ActionsTable = ({
  actions,
  onDecrementAction,
  onIncrementAction,
  onChangeActionResponsibility,
  customActions,
  onDecrementCustomAction,
  onIncrementCustomAction,
  onChangeCustomActionResponsibility,
  onEditCustomAction,
  packActions,
  onDecrementPackAction,
  onIncrementPackAction,
  onChangePackActionResponsibility,
  isDisabled,
  isEditActionDisabled,
  mode,
  showCost,
  showPrice,
  showResponsibility,
  oldActions = [],
  oldCustomActions = [],
  oldPackActions = [],
}: {
  actions: ArticleAction[];
  onDecrementAction: (id: string) => void;
  onIncrementAction: (id: string) => void;
  onChangeActionResponsibility: (id: string, responsibility: boolean) => void;
  customActions: ArticleCustomAction[];
  onDecrementCustomAction: (id: string) => void;
  onIncrementCustomAction: (id: string) => void;
  onChangeCustomActionResponsibility: (id: string, responsibility: boolean) => void;
  onEditCustomAction: (customAction: ArticleCustomAction) => void;
  packActions: ArticlePackAction[];
  onDecrementPackAction: (id: string) => void;
  onIncrementPackAction: (id: string) => void;
  onChangePackActionResponsibility: (id: string, responsibility: boolean) => void;
  isDisabled: boolean;
  isEditActionDisabled: boolean;
  mode: 'need' | 'action' | 'both';
  showCost?: boolean;
  showPrice?: boolean;
  showResponsibility?: boolean;
  oldActions?: ArticleAction[];
  oldCustomActions?: ArticleCustomAction[];
  oldPackActions?: ArticlePackAction[];
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();

  const isExternalWorkshop = currentSession?.workshop && currentSession.workshop.external;

  const commonProps = useMemo(
    () => ({
      mode,
      showCost,
      showPrice,
      showResponsibility,
    }),
    [mode, showCost, showPrice, showResponsibility]
  );

  if (isMobile) {
    return (
      <Stack gap="0.5rem">
        {oldPackActions.map((oldPackAction) => (
          <PackActionCard
            key={oldPackAction.id}
            packAction={oldPackAction}
            isDisabled={true}
            isEditActionDisabled={true}
            old
            {...commonProps}
          />
        ))}
        {oldActions.map((oldAction) => (
          <ActionCard
            key={oldAction.id}
            action={oldAction}
            isDisabled={true}
            isEditActionDisabled={true}
            old
            {...commonProps}
          />
        ))}
        {oldCustomActions.map((oldCustomAction) => (
          <CustomActionCard
            key={oldCustomAction.id}
            customAction={oldCustomAction}
            isDisabled={true}
            isEditActionDisabled={true}
            old
            {...commonProps}
          />
        ))}
        {packActions.map((packAction) => (
          <PackActionCard
            key={packAction.id}
            packAction={packAction}
            onDecrement={() => onDecrementPackAction(packAction.id)}
            onIncrement={() => onIncrementPackAction(packAction.id)}
            onChangeResponsibility={(responsibility) =>
              onChangePackActionResponsibility(packAction.id, responsibility)
            }
            isDisabled={isDisabled}
            isEditActionDisabled={isEditActionDisabled}
            {...commonProps}
          />
        ))}
        {actions.map((action) => (
          <ActionCard
            key={action.id}
            action={action}
            onDecrement={() => onDecrementAction(action.id)}
            onIncrement={() => onIncrementAction(action.id)}
            onChangeResponsibility={(responsibility) =>
              onChangeActionResponsibility(action.id, responsibility)
            }
            isDisabled={isDisabled}
            isEditActionDisabled={isEditActionDisabled}
            {...commonProps}
          />
        ))}
        {customActions.map((customAction) => (
          <CustomActionCard
            key={customAction.id}
            customAction={customAction}
            onDecrement={() => onDecrementCustomAction(customAction.id)}
            onIncrement={() => onIncrementCustomAction(customAction.id)}
            onChangeResponsibility={(responsibility) =>
              onChangeCustomActionResponsibility(customAction.id, responsibility)
            }
            onEdit={() => onEditCustomAction(customAction)}
            isDisabled={isDisabled}
            isEditActionDisabled={isEditActionDisabled}
            {...commonProps}
          />
        ))}
      </Stack>
    );
  }

  const showEditColumn = customActions.length > 0 && !isEditActionDisabled;

  return (
    <Box padding="16px">
      <Table
        aria-label={_(
          mode === 'need'
            ? msg({
                id: 'article.form.actions.table.column.name.title.defect',
                message: 'Needs',
              })
            : mode === 'action'
              ? msg({
                  id: 'article.form.actions.table.column.name.title.action',
                  message: 'Actions',
                })
              : msg({
                  id: 'article.form.actions.table.column.name.title',
                  message: 'Needs & actions',
                })
        )}
      >
        <Header>
          <Column isRowHeader width="1fr" minWidth={250}>
            {mode === 'need' ? (
              <Trans id="article.form.actions.table.column.name.title.defect">Needs</Trans>
            ) : mode === 'action' ? (
              <Trans id="article.form.actions.table.column.name.title.action">Actions</Trans>
            ) : (
              <Trans id="article.form.actions.table.column.name.title">Needs & actions</Trans>
            )}
          </Column>
          <Column width={110} align="center">
            <Trans id="article.form.actions.table.column.quantity.title">Quantity</Trans>
          </Column>
          {showCost && (
            <Column width={110} align="end">
              {isExternalWorkshop ? (
                <Trans id="article.form.actions.table.column.cost.title-external-workshop">
                  Price
                </Trans>
              ) : (
                <Trans id="article.form.actions.table.column.cost.title">Cost</Trans>
              )}
            </Column>
          )}
          {showPrice && (
            <Column width={110} align="end">
              <Trans id="article.form.actions.table.column.client-price.title">Client price</Trans>
            </Column>
          )}
          {showResponsibility && (
            <Column width={110} align="center">
              <Trans id="article.form.actions.table.column.brand-responsibility.title">
                Brand Resp.
              </Trans>
            </Column>
          )}
          {showEditColumn && <Column width={60} />}
        </Header>

        <Body>
          {oldPackActions.map((oldPackAction) => (
            <PackActionRow
              key={oldPackAction.id}
              packAction={oldPackAction}
              isDisabled={true}
              isEditActionDisabled={true}
              old
              showEditColumn={showEditColumn}
              {...commonProps}
            />
          ))}
          {oldActions.map((oldAction) => (
            <ActionRow
              key={oldAction.id}
              action={oldAction}
              isDisabled={true}
              isEditActionDisabled={true}
              old
              showEditColumn={showEditColumn}
              {...commonProps}
            />
          ))}
          {oldCustomActions.map((oldCustomAction) => (
            <CustomActionRow
              key={oldCustomAction.id}
              customAction={oldCustomAction}
              isDisabled={true}
              isEditActionDisabled={true}
              old
              showEditColumn={showEditColumn}
              {...commonProps}
            />
          ))}
          {packActions.map((packAction) => (
            <PackActionRow
              key={packAction.id}
              packAction={packAction}
              onDecrement={() => onDecrementPackAction(packAction.id)}
              onIncrement={() => onIncrementPackAction(packAction.id)}
              onChangeResponsibility={(responsibility) =>
                onChangePackActionResponsibility(packAction.id, responsibility)
              }
              isDisabled={isDisabled}
              isEditActionDisabled={isEditActionDisabled}
              showEditColumn={showEditColumn}
              {...commonProps}
            />
          ))}
          {actions.map((action) => (
            <ActionRow
              key={action.id}
              action={action}
              onDecrement={() => onDecrementAction(action.id)}
              onIncrement={() => onIncrementAction(action.id)}
              onChangeResponsibility={(responsibility) =>
                onChangeActionResponsibility(action.id, responsibility)
              }
              isDisabled={isDisabled}
              isEditActionDisabled={isEditActionDisabled}
              showEditColumn={showEditColumn}
              {...commonProps}
            />
          ))}
          {customActions.map((customAction) => (
            <CustomActionRow
              key={customAction.id}
              customAction={customAction}
              onDecrement={() => onDecrementCustomAction(customAction.id)}
              onIncrement={() => onIncrementCustomAction(customAction.id)}
              onChangeResponsibility={(responsibility) =>
                onChangeCustomActionResponsibility(customAction.id, responsibility)
              }
              onEdit={() => onEditCustomAction(customAction)}
              isDisabled={isDisabled}
              isEditActionDisabled={isEditActionDisabled}
              showEditColumn={showEditColumn}
              {...commonProps}
            />
          ))}
        </Body>
      </Table>
    </Box>
  );
};

const OLD_ACTION_STYLE = {
  textDecoration: 'line-through',
  color: 'var(--color-text-disabled-black)',
};

const ActionCard = ({
  action,
  onDecrement,
  onIncrement,
  onChangeResponsibility,
  isDisabled,
  isEditActionDisabled,
  mode,
  showCost,
  showPrice,
  showResponsibility,
  old,
}: {
  action: ArticleAction;
  onDecrement?: () => void;
  onIncrement?: () => void;
  onChangeResponsibility?: (responsibility: boolean) => void;
  isDisabled?: boolean;
  isEditActionDisabled?: boolean;
  mode: 'need' | 'action' | 'both';
  showCost?: boolean;
  showPrice?: boolean;
  showResponsibility?: boolean;
  old?: boolean;
}) => {
  const { _db } = useDatabaseTranslation();
  const { currentSession } = useCurrentSession();

  return (
    <Box
      padding="0"
      ariaLabel={_db(
        mode === 'need'
          ? action.actionTypeOrganization.actionType.needName
          : action.actionTypeOrganization.actionType.name
      )}
      style={old ? OLD_ACTION_STYLE : undefined}
    >
      <Stack padding="16px" gap="1rem" className="bg-neutral-0">
        {old && (
          <span className="visually-hidden">
            <Trans id="article.form.actions.old-action">Previous action</Trans>
          </span>
        )}
        <ActionName
          actionType={action.actionTypeOrganization.actionType}
          mode={mode}
          refashionStatus={
            !!action.refashionStatus && !currentSession?.workshop?.external
              ? action.refashionStatus
              : undefined
          }
        />
        <hr />
        <CardBrandResponsibility
          action={action}
          onChangeResponsibility={onChangeResponsibility}
          isEditActionDisabled={isEditActionDisabled}
          showResponsibility={showResponsibility}
        />
        <CardQuantity
          action={action}
          onDecrement={onDecrement}
          onIncrement={onIncrement}
          disableAdd={!action.actionTypeOrganization.actionType.isQuantifiable}
          isDisabled={isDisabled}
        />
      </Stack>
      <CardPriceAndCost action={action} showCost={showCost} showPrice={showPrice} />
    </Box>
  );
};

const PackActionCard = ({
  packAction,
  onDecrement,
  onIncrement,
  onChangeResponsibility,
  isDisabled,
  isEditActionDisabled,
  mode,
  showCost,
  showPrice,
  showResponsibility,
  old,
}: {
  packAction: ArticlePackAction;
  onDecrement?: () => void;
  onIncrement?: () => void;
  onChangeResponsibility?: (responsibility: boolean) => void;
  isDisabled?: boolean;
  isEditActionDisabled?: boolean;
  mode: 'need' | 'action' | 'both';
  showCost?: boolean;
  showPrice?: boolean;
  showResponsibility?: boolean;
  old?: boolean;
}) => {
  const { _db } = useDatabaseTranslation();
  const { currentSession } = useCurrentSession();

  return (
    <Box
      padding="0"
      ariaLabel={_db(packAction.packActionTypeOrganization.name)}
      style={old ? OLD_ACTION_STYLE : undefined}
    >
      <Stack padding="16px" gap="1rem" className="bg-neutral-0">
        {old && (
          <span className="visually-hidden">
            <Trans id="article.form.actions.old-action">Previous action</Trans>
          </span>
        )}
        <PackActionName
          packActionType={
            currentSession?.workshop?.external
              ? packAction.packActionTypeOrganization
              : packAction.packActionTypeOrganizationWithRefashionStatus
          }
          mode={mode}
        />
        <hr />
        <CardBrandResponsibility
          action={packAction}
          onChangeResponsibility={onChangeResponsibility}
          isEditActionDisabled={isEditActionDisabled}
          showResponsibility={showResponsibility}
        />
        <CardQuantity
          action={packAction}
          onDecrement={onDecrement}
          onIncrement={onIncrement}
          disableAdd={!packAction.packActionTypeOrganization.isQuantifiable}
          isDisabled={isDisabled}
        />
      </Stack>
      <CardPriceAndCost action={packAction} showCost={showCost} showPrice={showPrice} />
    </Box>
  );
};

const CustomActionCard = ({
  customAction,
  onDecrement,
  onIncrement,
  onChangeResponsibility,
  onEdit,
  isDisabled,
  isEditActionDisabled,
  showCost,
  showPrice,
  showResponsibility,
  old,
}: {
  customAction: ArticleCustomAction;
  onDecrement?: () => void;
  onIncrement?: () => void;
  onChangeResponsibility?: (responsibility: boolean) => void;
  onEdit?: () => void;
  isDisabled?: boolean;
  isEditActionDisabled?: boolean;
  showCost?: boolean;
  showPrice?: boolean;
  showResponsibility?: boolean;
  old?: boolean;
}) => {
  return (
    <Box
      padding="0"
      ariaLabel={customAction.description}
      style={old ? OLD_ACTION_STYLE : undefined}
    >
      <Stack padding="16px" gap="1rem" className="bg-neutral-0">
        <Stack
          row
          gap="0.5rem"
          justifyContent="space-between"
          alignItems="flex-start"
          flexWrap="nowrap"
        >
          {old && (
            <span className="visually-hidden">
              <Trans id="article.form.actions.old-action">Previous action</Trans>
            </span>
          )}
          <p className="paragraph-100-regular">{customAction.description}</p>
          {!isEditActionDisabled && <EditCustomActionCell onPress={onEdit} />}
        </Stack>
        <hr />
        <CardBrandResponsibility
          action={customAction}
          onChangeResponsibility={onChangeResponsibility}
          isEditActionDisabled={isEditActionDisabled}
          showResponsibility={showResponsibility}
        />
        <CardQuantity
          action={customAction}
          onDecrement={onDecrement}
          onIncrement={onIncrement}
          disableAdd={false}
          isDisabled={isDisabled}
        />
      </Stack>
      <CardPriceAndCost action={customAction} showCost={showCost} showPrice={showPrice} />
    </Box>
  );
};

const CardQuantity = ({
  action,
  onDecrement,
  onIncrement,
  disableAdd,
  isDisabled,
}: {
  action: ArticleAction | ArticleCustomAction | ArticlePackAction;
  onDecrement?: () => void;
  onIncrement?: () => void;
  disableAdd?: boolean;
  isDisabled?: boolean;
}) => {
  return (
    <Stack row gap="0.5rem" alignItems="center" justifyContent="space-between">
      <span className="paragraph-100-regular">
        <Trans id="article.form.actions.table.column.quantity.label">Quantity:</Trans>
      </span>
      <QuantityCell
        quantity={action.quantity}
        onDecrement={onDecrement}
        onIncrement={onIncrement}
        disableAdd={disableAdd}
        isDisabled={isDisabled}
      />
    </Stack>
  );
};

const CardBrandResponsibility = ({
  action,
  onChangeResponsibility,
  isEditActionDisabled,
  showResponsibility,
}: {
  action: ArticleAction | ArticleCustomAction | ArticlePackAction;
  onChangeResponsibility?: (brandResponsibility: boolean) => void;
  isEditActionDisabled?: boolean;
  showResponsibility?: boolean;
}) => {
  if (!showResponsibility) {
    return null;
  }

  return (
    <Stack row gap="0.5rem" alignItems="center" justifyContent="space-between">
      <span className="paragraph-100-regular">
        <Trans id="article.form.actions.table.column.brand-responsibility.label">
          Brand responsibility:
        </Trans>
      </span>
      <BrandResponsibilityCell
        value={action.brandResponsibility}
        onChange={onChangeResponsibility}
        size="small"
        isDisabled={isEditActionDisabled}
      />
    </Stack>
  );
};

const CardPriceAndCost = ({
  action,
  showCost,
  showPrice,
}: {
  action: ArticleAction | ArticleCustomAction | ArticlePackAction;
  showCost?: boolean;
  showPrice?: boolean;
}) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();
  const isExternalWorkshop = currentSession?.workshop && currentSession.workshop.external;

  if (!(showCost || showPrice)) {
    return null;
  }

  return (
    <Stack padding="12px 16px" gap="1rem" className="bg-neutral-100">
      <Stack row gap="1rem" justifyContent="flex-end" flexWrap="nowrap" alignItems="flex-start">
        {showCost && (
          <CostCell
            action={action}
            label={
              isExternalWorkshop
                ? _(
                    msg({
                      id: 'article.form.actions.table.column.cost.title-external-workshop',
                      message: 'Price',
                    })
                  )
                : _(msg({ id: 'article.form.actions.table.column.cost.title', message: 'Cost' }))
            }
          />
        )}
        {showPrice && (
          <PriceCell
            action={action}
            label={_(
              msg({
                id: 'article.form.actions.table.column.client-price.title',
                message: 'Client price',
              })
            )}
          />
        )}
      </Stack>
    </Stack>
  );
};

const ActionRow = ({
  action,
  onDecrement,
  onIncrement,
  onChangeResponsibility,
  isDisabled,
  isEditActionDisabled,
  mode,
  showCost,
  showPrice,
  showResponsibility,
  showEditColumn,
  old,
}: {
  action: ArticleAction;
  onDecrement?: () => void;
  onIncrement?: () => void;
  onChangeResponsibility?: (responsibility: boolean) => void;
  isDisabled?: boolean;
  isEditActionDisabled?: boolean;
  mode: 'need' | 'action' | 'both';
  showCost?: boolean;
  showPrice?: boolean;
  showResponsibility?: boolean;
  showEditColumn?: boolean;
  old?: boolean;
}) => {
  const { currentSession } = useCurrentSession();

  return (
    <Row id={action.id}>
      <Cell style={old ? OLD_ACTION_STYLE : undefined}>
        {old && (
          <span className="visually-hidden">
            <Trans id="article.form.actions.old-action">Previous action</Trans>
          </span>
        )}
        <ActionName
          actionType={action.actionTypeOrganization.actionType}
          mode={mode}
          refashionStatus={
            !!action.refashionStatus && !currentSession?.workshop?.external
              ? action.refashionStatus
              : undefined
          }
        />
      </Cell>
      <Cell align="center" style={old ? OLD_ACTION_STYLE : undefined}>
        <QuantityCell
          quantity={action.quantity}
          onDecrement={onDecrement}
          onIncrement={onIncrement}
          isDisabled={isDisabled}
          disableAdd={!action.actionTypeOrganization.actionType.isQuantifiable}
        />
      </Cell>
      {showCost && (
        <Cell align="end" style={old ? OLD_ACTION_STYLE : undefined}>
          <CostCell action={action} />
        </Cell>
      )}
      {showPrice && (
        <Cell align="end" style={old ? OLD_ACTION_STYLE : undefined}>
          <PriceCell action={action} />
        </Cell>
      )}
      {showResponsibility && (
        <Cell align="center" style={old ? OLD_ACTION_STYLE : undefined}>
          <BrandResponsibilityCell
            value={action.brandResponsibility}
            onChange={onChangeResponsibility}
            isDisabled={isEditActionDisabled}
          />
        </Cell>
      )}
      {showEditColumn && <Cell />}
    </Row>
  );
};

const PackActionRow = ({
  packAction,
  onDecrement,
  onIncrement,
  onChangeResponsibility,
  isDisabled,
  isEditActionDisabled,
  mode,
  showCost,
  showPrice,
  showResponsibility,
  showEditColumn,
  old,
}: {
  packAction: ArticlePackAction;
  onDecrement?: () => void;
  onIncrement?: () => void;
  onChangeResponsibility?: (responsibility: boolean) => void;
  isDisabled?: boolean;
  isEditActionDisabled?: boolean;
  mode: 'need' | 'action' | 'both';
  showCost?: boolean;
  showPrice?: boolean;
  showResponsibility?: boolean;
  showEditColumn?: boolean;
  old?: boolean;
}) => {
  const { currentSession } = useCurrentSession();

  return (
    <Row id={packAction.id}>
      <Cell style={old ? OLD_ACTION_STYLE : undefined}>
        {old && (
          <span className="visually-hidden">
            <Trans id="article.form.actions.old-action">Previous action</Trans>
          </span>
        )}
        <PackActionName
          packActionType={
            currentSession?.workshop?.external
              ? packAction.packActionTypeOrganization
              : packAction.packActionTypeOrganizationWithRefashionStatus
          }
          mode={mode}
        />
      </Cell>
      <Cell align="center" style={old ? OLD_ACTION_STYLE : undefined}>
        <QuantityCell
          quantity={packAction.quantity}
          onDecrement={onDecrement}
          onIncrement={onIncrement}
          isDisabled={isDisabled}
          disableAdd={!packAction.packActionTypeOrganization.isQuantifiable}
        />
      </Cell>
      {showCost && (
        <Cell align="end" style={old ? OLD_ACTION_STYLE : undefined}>
          <CostCell action={packAction} />
        </Cell>
      )}
      {showPrice && (
        <Cell align="end" style={old ? OLD_ACTION_STYLE : undefined}>
          <PriceCell action={packAction} />
        </Cell>
      )}
      {showResponsibility && (
        <Cell align="center" style={old ? OLD_ACTION_STYLE : undefined}>
          <BrandResponsibilityCell
            value={packAction.brandResponsibility}
            onChange={onChangeResponsibility}
            isDisabled={isEditActionDisabled}
          />
        </Cell>
      )}
      {showEditColumn && <Cell />}
    </Row>
  );
};

const CustomActionRow = ({
  customAction,
  onDecrement,
  onIncrement,
  onChangeResponsibility,
  isDisabled,
  isEditActionDisabled,
  showCost,
  showPrice,
  showResponsibility,
  showEditColumn,
  onEdit,
  old,
}: {
  customAction: ArticleCustomAction;
  onDecrement?: () => void;
  onIncrement?: () => void;
  onChangeResponsibility?: (responsibility: boolean) => void;
  onEdit?: () => void;
  isDisabled?: boolean;
  isEditActionDisabled?: boolean;
  showCost?: boolean;
  showPrice?: boolean;
  showResponsibility?: boolean;
  showEditColumn?: boolean;
  old?: boolean;
}) => {
  return (
    <Row id={customAction.id}>
      <Cell style={old ? OLD_ACTION_STYLE : undefined}>
        {old && (
          <span className="visually-hidden">
            <Trans id="article.form.actions.old-action">Previous action</Trans>
          </span>
        )}
        <p className="paragraph-100-medium">{customAction.description}</p>
      </Cell>
      <Cell align="center" style={old ? OLD_ACTION_STYLE : undefined}>
        <QuantityCell
          quantity={customAction.quantity}
          onDecrement={onDecrement}
          onIncrement={onIncrement}
          isDisabled={isDisabled}
          disableAdd={false}
        />
      </Cell>
      {showCost && (
        <Cell align="end" style={old ? OLD_ACTION_STYLE : undefined}>
          <CostCell action={customAction} />
        </Cell>
      )}
      {showPrice && (
        <Cell align="end" style={old ? OLD_ACTION_STYLE : undefined}>
          <PriceCell action={customAction} />
        </Cell>
      )}
      {showResponsibility && (
        <Cell align="center" style={old ? OLD_ACTION_STYLE : undefined}>
          <BrandResponsibilityCell
            value={customAction.brandResponsibility}
            onChange={onChangeResponsibility}
            isDisabled={isEditActionDisabled}
          />
        </Cell>
      )}
      {showEditColumn && (
        <Cell align="end" style={old ? OLD_ACTION_STYLE : undefined}>
          {!isEditActionDisabled && <EditCustomActionCell onPress={onEdit} />}
        </Cell>
      )}
    </Row>
  );
};

export default ActionsTable;

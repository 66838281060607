import { Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const ArticleArchivedMessage = ({ article }: { article: ArticleWithRelations }) => {
  if (article.cancellationDetail) {
    return <BaseMessage article={article} />;
  }

  if (article.hasIssue) {
    return (
      <AlertBar
        type="error"
        size="large"
        hideIcon
        title={
          <Trans id="article.archived-message.issue.title">
            This item has been archived after a shipment issue
          </Trans>
        }
      >
        {!!article.archivalDetail?.reason && (
          <p>
            <Trans id="article.archived-message.issue.text">Archival reason:</Trans> &quot;
            {article.archivalDetail.reason}
            &quot;
          </p>
        )}
      </AlertBar>
    );
  }

  return null;
};

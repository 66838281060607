import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import config from '@/config';
import Button from '@/design_system/Button';
import Checkbox from '@/design_system/Checkbox';
import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import IconQrCode from '@/icons/QrCode.svg';
import { ShipmentWithRelations, useCompletePreparation } from '@/models/shipment';
import { AddArticlesModal } from '@/routes/Shipments/Shipment/components/AddArticlesModal';
import { useCurrentOrganization, useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

const PreparationActions = ({
  shipment,
  onValidationDone,
}: {
  shipment: ShipmentWithRelations;
  onValidationDone: () => void;
}) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();
  const [error, setError] = useState<string>();

  const { mutateAsync: completePreparation, isPending, isSuccess } = useCompletePreparation();
  const [showValidationDialog, setShowValidationDialog] = useState(false);
  const [isOpenEditShipmentModal, setIsOpenEditShipmentModal] = useState(false);

  const { currentSession } = useCurrentSession();

  const canCreateShipment = shipment.canBeCreatedBy(currentSession);

  if (!canCreateShipment) {
    return null;
  }

  return (
    <Stack gap="8px">
      <span className="text-danger paragraph-100-regular" style={{ whiteSpace: 'pre-wrap' }}>
        {error}
      </span>
      <Stack
        row={!isMobile}
        gap="16px"
        justifyContent="flex-end"
        alignItems={isMobile ? '' : 'center'}
      >
        <Button variant="secondary" onPress={() => setIsOpenEditShipmentModal(true)}>
          <Trans id="shipment.add-article">Add items</Trans>
        </Button>
        <Button
          variant="primary"
          onPress={() => setShowValidationDialog(true)}
          isLoading={isPending || isSuccess}
        >
          {shipment.carrier === 'private-carrier' ? (
            <Trans id="shipment.ready-for-pickup">Mark package preparation as done</Trans>
          ) : (
            <Trans id="shipment.generate-label">Validate and generate the shipping label</Trans>
          )}
        </Button>
        <ValidationDialog
          isOpen={showValidationDialog}
          onOpenChange={setShowValidationDialog}
          isPending={isPending || isSuccess}
          shipment={shipment}
          onValidate={() => {
            setError(undefined);
            completePreparation(shipment.id)
              .catch((err) => {
                // eslint-disable-next-line lingui/no-unlocalized-strings
                if (err.message?.includes('cannot be picked up on the selected date')) {
                  setError(
                    _(
                      msg({
                        id: 'shipment.pickup-date-error',
                        message:
                          'The shipment cannot be picked up on the selected date. Please select another date.',
                      })
                    )
                  );
                } else {
                  console.error(err);
                  setError(
                    err.message.startsWith('Teliae Error') // eslint-disable-line lingui/no-unlocalized-strings
                      ? err.message +
                          '\n' +
                          _(
                            msg({
                              id: 'shipment.persist-error',
                              message: 'If this error persists, please contact Support.',
                            })
                          )
                      : _(
                          msg({
                            id: 'shipment.unknown-error',
                            message: 'Unknown error. Please contact Support',
                          })
                        )
                  );
                }

                // Close the dialog to allow the user to see the error
                setShowValidationDialog(false);
              })
              .then(onValidationDone);
          }}
        />
        <AddArticlesModal
          isOpen={isOpenEditShipmentModal}
          setIsOpen={setIsOpenEditShipmentModal}
          shipment={shipment}
          key={shipment.articles.map((article) => article.article.id).join('')}
        />
      </Stack>
    </Stack>
  );
};

const ValidationDialog = ({
  isOpen,
  onOpenChange,
  onValidate,
  shipment,
  isPending,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onValidate: () => void;
  shipment: ShipmentWithRelations;
  isPending: boolean;
}) => {
  const { _ } = useLingui();
  const [organization] = useCurrentOrganization();

  const [validationChecked, setValidationChecked] = useState(false);

  return (
    <Dialog
      title={_(
        msg({ id: 'shipment.validate-preparation-dialog.title', message: 'Validate preparation' })
      )}
      isOpen={isOpen}
      onOpenChange={(value) => {
        setValidationChecked(false);
        onOpenChange(value);
      }}
      style={{ width: '32rem' }}
    >
      <main>
        <Stack gap="1rem">
          <div className="paragraph-100-regular">
            <Trans id="shipment.validate-preparation-dialog.message">
              Please confirm that you have printed all items sheets and added them to the package.
              If not, click on the print button.
            </Trans>
          </div>
          <Checkbox size="large" isSelected={validationChecked} onChange={setValidationChecked}>
            <Trans id="shipment.validate-preparation-dialog.validation-checkbox">
              I&apos;ve already printed all items sheets
            </Trans>
          </Checkbox>
        </Stack>
      </main>
      <footer>
        <Button
          variant="secondary"
          size="medium"
          target="_blank"
          ariaLabel={_(
            msg({
              id: 'shipment.validate-preparation-dialog.print-items-sheets',
              message: 'Print items sheets',
            })
          )}
          href={`${config.apiUrl}/shipments/${shipment.id}/recap?organization=${organization?.slug}`}
        >
          <IconQrCode />
          <Trans id="shipment.validate-preparation-dialog.print-items-sheets">
            Print items sheets
          </Trans>
        </Button>
        <Button
          variant="primary"
          size="medium"
          onPress={onValidate}
          disabled={!validationChecked}
          isLoading={isPending}
        >
          <Trans id="shipment.validate-preparation-dialog.validate">Validate</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};

export default PreparationActions;

import { useLingui } from '@lingui/react';

import Stack from '@/design_system/Stack';
import { Stepper, StepperProps, StepProps } from '@/design_system/Stepper/Stepper';
import IconCheck from '@/icons/Check.svg';
import {
  Article,
  ARTICLE_DISPLAYED_STEPS,
  ARTICLE_TASK_TYPES,
  getDisplayedStep,
} from '@/models/article';
import useViewPort from '@/utils/useViewport';

export const ArticleStepper = ({
  article,
  mode,
  isClient,
  ...props
}: {
  article: Article;
  mode: 'task' | 'step';
  isClient?: boolean;
} & Omit<StepperProps, 'steps'>) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  if (!article.steps) {
    return '-';
  }

  const isTaskDoneAtTheArticleLevel =
    (article.task?.type === 'dispatch_article' && !!article.workshopId) ||
    (article.task?.type === 'analyze_article' &&
      (!!article.analyzedAt || !!article.requalifiedAt)) ||
    (article.task?.type === 'accept_requalification' && !!article.requalificationValidatedAt);

  const steps = article.steps
    .map((step) => {
      const displayedStep = getDisplayedStep(step);

      if (!displayedStep) {
        return null;
      }

      const name = _(ARTICLE_DISPLAYED_STEPS.find(({ id }) => id === displayedStep)!.name);
      let label;

      if (mode === 'task' && article.task && step.status === 'in-progress') {
        label = (
          <Stack row alignItems="center" gap="0.25rem" flexWrap="nowrap">
            <span>{_(ARTICLE_TASK_TYPES.find(({ id }) => id === article.task?.type)!.label)}</span>
            {isTaskDoneAtTheArticleLevel && <IconCheck />}
          </Stack>
        );
      }

      return {
        ...step,
        name,
        label,
      };
    })
    .filter((step) => !!step)
    .reduce(
      (acc, step) => {
        if (acc.length === 0) {
          return [step];
        }

        // Merge consecutive transit steps
        if (acc[acc.length - 1].step === 'transit' && step.step === 'transit') {
          if (acc[acc.length - 1].status === 'done') {
            acc[acc.length - 1].status = step.status;
          }
          return acc;
        }

        return [...acc, step];
      },
      [] as (StepProps & { step: string })[]
    );

  const labelVariant =
    mode === 'task'
      ? isTaskDoneAtTheArticleLevel
        ? 'done'
        : 'pending'
      : isMobile
        ? 'awaiting'
        : undefined;

  return <Stepper steps={steps} labelVariant={isClient ? undefined : labelVariant} {...props} />;
};

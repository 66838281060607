import React from 'react';
import { i18n } from '@lingui/core';
import { msg } from '@lingui/macro';
import { I18nProvider as LinguiProvider, useLingui } from '@lingui/react';
import i18nIsoCountries, { Alpha2Code } from 'i18n-iso-countries';

export const SUPPORTED_LOCALES = [
  {
    id: 'en',
    label: msg({ id: '_general.locale.en.label', message: 'English' }),
  },
  {
    id: 'fr',
    label: msg({ id: '_general.locale.fr.label', message: 'French' }),
  },
] as const;

export type Locale = (typeof SUPPORTED_LOCALES)[number]['id'];

export function getBrowserLocale() {
  // Locale can be forced in development mode
  if (import.meta.env.VITE_LANGUAGE) {
    return import.meta.env.VITE_LANGUAGE as Locale;
  }

  const userLanguages = navigator.languages;

  for (const lang of userLanguages) {
    for (const supportedLocale of SUPPORTED_LOCALES) {
      if (lang.startsWith(supportedLocale.id)) {
        return supportedLocale.id;
      }
    }
  }

  return 'en'; // English by default
}

type Country = {
  code: Alpha2Code;
  name: string;
};

const loadCountries = async (locale: Locale) => {
  let countriesLocaleData;

  switch (locale) {
    case 'en':
      countriesLocaleData = await import('i18n-iso-countries/langs/en.json');
      break;
    case 'fr':
      countriesLocaleData = await import('i18n-iso-countries/langs/fr.json');
      break;
  }

  i18nIsoCountries.registerLocale(countriesLocaleData);

  return Object.entries(i18nIsoCountries.getNames(locale))
    .map(([code, name]) => ({
      code: code as Alpha2Code,
      name,
    }))
    .sort((a, b) => (a.name < b.name ? -1 : 1));
};

const countries: Record<string, Country[]> = {};

export async function switchLanguage(locale: Locale) {
  try {
    const { messages } = await import(`../locales/${locale}.po`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    i18n.load(locale, messages);
    document.documentElement.lang = locale;
    countries[locale] = await loadCountries(locale);

    i18n.activate(locale);
  } catch (err) {
    console.error(err);
  }
}

if (import.meta.env.DEV) {
  // @ts-ignore - Small hack to disable lingui warning in dev mode
  i18n._locale = 'en';
}

// @ts-ignore - Enable to switch language from console
window.switchLanguage = switchLanguage;

export const I18nProvider = ({ children }: { children: React.ReactNode }) => {
  return <LinguiProvider i18n={i18n}>{children}</LinguiProvider>;
};

export const useCountries = () => {
  const { i18n } = useLingui();

  return countries[i18n.locale] ?? [];
};

import { MessageDescriptor } from '@lingui/core';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconEdit from '@/icons/Edit.svg';
import { DEFAULT_COLORS } from '@/models/product';
import { formatDate } from '@/utils/date';

export const ArticlePropertyUpdatedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_property_updated'>;
  displayArticleName: boolean;
}) => {
  const { _ } = useLingui();
  let valueAfterTheChange: string | undefined | null;
  let articlePropertyUpdatedMessage: MessageDescriptor | undefined;

  if (activity.data.property === 'data.color') {
    articlePropertyUpdatedMessage = msg({
      id: 'activities.article_property_updated.data.color',
      message: 'the color',
    });
    const colorLabel = DEFAULT_COLORS.find(({ id }) => id === activity.data.after)?.label;

    valueAfterTheChange = colorLabel ? _(colorLabel) : null;
  } else if (activity.data.property === 'data.size') {
    articlePropertyUpdatedMessage = msg({
      id: 'activities.article_property_updated.data.size',
      message: 'the size',
    });
    valueAfterTheChange = activity.data.after;
  } else if (activity.data.property === 'data.brand') {
    articlePropertyUpdatedMessage = msg({
      id: 'activities.article_property_updated.data.brand',
      message: 'the brand',
    });
    valueAfterTheChange = activity.data.after?.name;
  } else if (activity.data.property === 'actions' || activity.data.property === 'custom_actions') {
    articlePropertyUpdatedMessage = msg({
      id: 'activities.article_property_updated.actions',
      message: 'the actions',
    });
  } else if (activity.data.property === 'priceManualDiscount') {
    articlePropertyUpdatedMessage = msg({
      id: 'activities.article_property_updated.price_manual_discount',
      message: 'the discount applied on client price',
    });
    valueAfterTheChange = activity.data.after ? `-${activity.data.after}%` : '0%';
  } else if (activity.data.property === 'purchaseDate') {
    articlePropertyUpdatedMessage = msg({
      id: 'activities.article_property_updated.purchase_date',
      message: 'the purchase date',
    });
    valueAfterTheChange = activity.data.after
      ? formatDate(new Date(activity.data.after), { dateStyle: 'medium' })
      : '';
  } else if (activity.data.property === 'warranty') {
    articlePropertyUpdatedMessage = msg({
      id: 'activities.article_property_updated.warranty',
      message: 'the warranty',
    });
    valueAfterTheChange = activity.data.after
      ? _(
          msg({ id: 'activities.article_property_updated.warranty.yes', message: 'Under warranty' })
        )
      : _(
          msg({ id: 'activities.article_property_updated.warranty.no', message: 'Out-of-Warranty' })
        );
  }

  if (activity.data.property === 'status' || !articlePropertyUpdatedMessage) return null;

  return (
    <BaseActivity
      key={activity.id}
      createdAt={activity.createdAt}
      icon={<IconEdit />}
      creatorName={activity.creator?.name ?? ''}
      message={
        <>
          <BaseActivityText>
            <Trans id="activities.article_property_updated.message">
              edited{' '}
              <span className="paragraph-100-medium">{_(articlePropertyUpdatedMessage)}</span> of
              the item
            </Trans>
          </BaseActivityText>
          <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
          {valueAfterTheChange && (
            <span className="paragraph-100-medium">
              <Trans id="activities.article_property_updated.separator">:</Trans>{' '}
              {valueAfterTheChange}
            </span>
          )}
        </>
      }
    />
  );
};

import { Trans } from '@lingui/macro';

import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const EstimateArticleTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      title={<Trans id="article.task.estimate-article.title">Estimate the item</Trans>}
      message={
        <Trans id="article.task.estimate-article.message">
          Please analyse the item, review the actions and choose a workshop in order to provide an
          estimated quote to the client.
        </Trans>
      }
      article={article}
    />
  );
};

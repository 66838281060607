import { useState } from 'react';
import { useLingui } from '@lingui/react';

import {
  GoToCommentsButton,
  GoToCommentsMenuItem,
} from '@/components/comments/GoToCommentsButton/GoToCommentsButton';
import Button from '@/design_system/Button';
import Menu from '@/design_system/Menu';
import { Section, Separator } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import IconArrow from '@/icons/Arrow.svg';
import IconMore from '@/icons/More.svg';
import { getRequestorTypeLabel, RequestWithRelations, useComments } from '@/models/request';
import {
  ArchiveRequestButton,
  ArchiveRequestDialog,
  ArchiveRequestMenuItem,
} from '@/routes/Requests/components/ArchiveRequestButton';
import {
  DownloadInvoiceButton,
  DownloadInvoiceMenuItem,
  useDownloadInvoiceActions,
} from '@/routes/Requests/Request/components/RequestHeader/DownloadInvoiceButton';
import {
  PrintDropoffReceiptMenuItem,
  PrintItemSheetMenuItem,
  PrintsButton,
  usePrintActions,
} from '@/routes/Requests/Request/components/RequestHeader/PrintsButton';
import { useCurrentOrganization, useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

import {
  SubscriptionButton,
  SubscriptionMenuItem,
  useToggleSubscription,
} from './SubscriptionButton';

export const RequestHeader = ({
  request,
  showActions = true,
  onCommentButtonPress,
}: {
  request: RequestWithRelations;
  showActions?: boolean;
  onCommentButtonPress: () => void;
}) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();
  const [currentOrganization] = useCurrentOrganization();
  const isOrganizationUser = currentSession?.workshop?.external !== true;

  const { data: { comments } = { comments: [] } } = useComments({
    requestId: request.id,
  });

  // Improvement: Store the invoiceId on the request itself to check its existence
  const canDownloadInvoice =
    currentSession?.hasPermission('view_invoice', {
      organizationId: currentOrganization?.id,
    }) && request.articles.some((article) => article.paidAt);

  const canArchive =
    !request.isArchived &&
    currentSession?.hasPermission('archive_article', { organizationId: request.organizationId });

  const { toggleSubscription, isSubscribePending, isUnsubscribePending } = useToggleSubscription({
    request,
  });
  const { printItemsSheets, printDropOffReceipt } = usePrintActions({ request });
  const [showArchivalConfirmation, setShowArchivalConfirmation] = useState(false);
  const { invoice, downloadInvoice } = useDownloadInvoiceActions({ request });

  return (
    <>
      {isMobile && (
        <Button variant="secondary" size="large" to="/requests" iconOnly noBorder>
          <IconArrow left />
        </Button>
      )}

      <Stack gap={isMobile ? undefined : '0.25rem'} style={{ flex: 1 }}>
        <h2 className="headline-300-bold sentry-mask paragraph-100-medium-mobile">
          [{request.reference}]{' '}
          {isOrganizationUser ? request.name.major : request.organization.name}
        </h2>
        <p className="paragraph-50-regular paragraph-200-regular-mobile">
          {isOrganizationUser
            ? request.name.minor
            : _(getRequestorTypeLabel(request.requestorType))}
        </p>
      </Stack>

      {!isMobile && showActions && (
        <Stack row gap="0.5rem">
          <SubscriptionButton
            request={request}
            disabled={isUnsubscribePending || isSubscribePending}
            onPress={() => {
              toggleSubscription();
            }}
          />
          <GoToCommentsButton
            onCommentButtonPress={onCommentButtonPress}
            nbOfComments={comments.length}
          />
          <PrintsButton request={request}></PrintsButton>
          {canDownloadInvoice && <DownloadInvoiceButton request={request} />}
          {canArchive && <ArchiveRequestButton iconOnly request={request} size="large" />}
        </Stack>
      )}
      {isMobile && showActions && (
        <>
          <Menu
            placement="bottom end"
            trigger={
              <Button variant="secondary" size="large" iconOnly noBorder>
                <IconMore />
              </Button>
            }
            onAction={(id) => {
              switch (id) {
                case 'toggle-subscription':
                  toggleSubscription();
                  break;
                case 'go-to-comments':
                  onCommentButtonPress();
                  break;
                case 'print-items-sheets':
                  printItemsSheets();
                  break;
                case 'print-dropoff-receipt':
                  printDropOffReceipt();
                  break;
                case 'archive':
                  setShowArchivalConfirmation(true);
                  break;
                case 'download-invoice':
                  downloadInvoice();
                  break;

                default:
                  break;
              }
            }}
          >
            <Section>
              <SubscriptionMenuItem request={request} />
              <GoToCommentsMenuItem nbOfComments={comments.length} />
            </Section>
            <Separator />
            <Section>
              <PrintItemSheetMenuItem />
              {request.client && request.store && <PrintDropoffReceiptMenuItem />}
            </Section>
            <Separator />
            {canDownloadInvoice && (
              <Section>
                <DownloadInvoiceMenuItem invoice={invoice} />
              </Section>
            )}
            <Separator />
            <Section>
              <ArchiveRequestMenuItem />
            </Section>
          </Menu>
          <ArchiveRequestDialog
            request={request}
            onArchive={() => setShowArchivalConfirmation(false)}
            onArchiveError={() => setShowArchivalConfirmation(false)}
            showArchivalConfirmation={showArchivalConfirmation}
            setShowArchivalConfirmation={setShowArchivalConfirmation}
          />
        </>
      )}
    </>
  );
};

import { useRef, useState } from 'react';
import { Dialog, Popover } from 'react-aria-components';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import isNil from 'lodash/isNil';

import Loader from '@/components/Loader';
import AlertBar from '@/design_system/AlertBar';
import Button from '@/design_system/Button';
import { InputPercentage } from '@/design_system/InputNumber';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconEdit from '@/icons/Edit.svg';
import IconWarning from '@/icons/Warning.svg';
import { useArticleName, useUpdateArticle } from '@/models/article';
import { ArticleWithRelations, Request, RequestWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import './ArticleHeaderPrice.css';

export const ArticlePriceWithTitle = ({
  request,
  article,
  allowDiscount,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  allowDiscount: boolean;
}) => {
  const { isMobile } = useViewPort();
  const isFetching = useIsFetching({ queryKey: ['requests', request.id] });
  const isMutating = useIsMutating({ mutationKey: ['articles', article.id] });

  const name = useArticleName({ article });

  return (
    <Stack gap={'1rem'}>
      {!isMobile && (
        <Stack row gap="16px" alignItems="center">
          <h1 className="headline-400-bold">{name}</h1>
          {(isFetching > 0 || isMutating > 0) && (
            <Loader style={{ fontSize: '1.25rem' }} delay={500} />
          )}
        </Stack>
      )}
      <ArticlePrice
        article={article}
        request={request}
        allowDiscount={allowDiscount}
        showCost
        showPrice
      />
    </Stack>
  );
};

const ArticlePrice = ({
  article,
  request,
  showCost,
  showPrice: showPriceProp,
  allowDiscount,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  showCost?: boolean;
  showPrice?: boolean;
  allowDiscount: boolean;
}) => {
  const { currentSession } = useCurrentSession();
  const { isMobile } = useViewPort();

  const showPrice =
    showPriceProp && request.requestorType !== 'store' && !currentSession?.workshop?.external;

  if (!showCost && !showPrice) {
    return null;
  }

  return (
    <Stack
      row
      gap={'1.5rem'}
      alignItems="center"
      justifyContent={!isMobile ? 'space-between' : 'flex-start'}
      flexWrap="nowrap"
    >
      {showCost && <Cost article={article} />}
      {showPrice && <Price request={request} article={article} allowDiscount={allowDiscount} />}
    </Stack>
  );
};

const Cost = ({ article }: { article: ArticleWithRelations }) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();

  const label = currentSession?.workshop?.external
    ? _(msg({ id: 'article.header.cost-as-external', message: 'Price' }))
    : _(msg({ id: 'article.header.cost', message: 'Internal cost' }));

  const initialAmount = article.currentCostAmountWithoutDiscount;
  const initialCurrency = article.currentCostCurrency;
  const updatedAmount = article.currentCostAmount;
  const updatedCurrency = article.currentCostCurrency;

  const hasUpdatedPrice =
    !isNil(updatedAmount) &&
    (updatedAmount !== initialAmount || updatedCurrency !== initialCurrency);

  return (
    <Stack ariaLabel={label}>
      <span className="paragraph-200-regular  text-secondary">{label}</span>
      <Stack row gap="0.5rem" alignItems="center">
        <span className="paragraph-50-medium">
          {hasUpdatedPrice
            ? formatCurrency(updatedAmount, updatedCurrency)
            : formatCurrency(initialAmount, initialCurrency)}
        </span>
        {hasUpdatedPrice && !isNil(initialAmount) && (
          <s role="deletion" className="paragraph-100-regular">
            {formatCurrency(initialAmount, initialCurrency)}
          </s>
        )}
      </Stack>
    </Stack>
  );
};

const Price = ({
  request,
  article,
  allowDiscount,
}: {
  request: Request;
  article: ArticleWithRelations;
  allowDiscount: boolean;
}) => {
  const { _ } = useLingui();

  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const popoverAnchorRef = useRef<HTMLDivElement>(null);

  const label = _(msg({ id: 'article.header.price', message: 'Client price' }));

  const initialAmount = article.currentPriceAmountWithoutDiscount;
  const initialCurrency = article.currentPriceCurrency;
  const updatedAmount = article.currentPriceAmount;
  const updatedCurrency = article.currentPriceCurrency;

  const hasUpdatedPrice =
    !isNil(updatedAmount) &&
    (updatedAmount !== initialAmount || updatedCurrency !== initialCurrency);

  const showWarning =
    !request.vip &&
    (article.currentActions.some((action) => action.refashionStatus === 'not-applied-discount') ||
      article.currentPackActions.some((packAction) =>
        packAction.packActionTypeOrganizationWithRefashionStatus.actions.some(
          (action) => action.refashionStatus === 'not-applied-discount'
        )
      ));

  return (
    <Stack ariaLabel={label}>
      <span className="paragraph-200-regular text-secondary">{label}</span>
      <Stack row gap="0.5rem" alignItems="center" ref={popoverAnchorRef}>
        {showWarning && (
          <Tooltip
            content={_(
              msg({
                id: 'article.header.price.edit.warning',
                message:
                  'This discount conflicts with the Refashion bonus, preventing it from applying to some actions.',
              })
            )}
            placement="bottom"
          >
            <Button variant="style-less">
              <IconWarning className="headline-200-medium text-warning" />
            </Button>
          </Tooltip>
        )}
        <span className="paragraph-50-medium">
          {hasUpdatedPrice
            ? formatCurrency(updatedAmount, updatedCurrency)
            : formatCurrency(initialAmount, initialCurrency)}
        </span>
        {hasUpdatedPrice && !isNil(initialAmount) && (
          <s className="paragraph-100-regular">{formatCurrency(initialAmount, initialCurrency)}</s>
        )}
        {allowDiscount && !isPopoverOpened && (
          <Button
            size="small"
            variant="secondary"
            iconOnly
            ariaLabel={_(msg({ id: 'article.header.price.edit.trigger', message: 'Edit price' }))}
            onPress={() => setIsPopoverOpened(true)}
          >
            <IconEdit />
          </Button>
        )}
        <Popover
          placement="bottom left"
          triggerRef={popoverAnchorRef}
          isOpen={isPopoverOpened}
          onOpenChange={setIsPopoverOpened}
          offset={4}
        >
          <EditPricePopover article={article} showWarning={showWarning} />
        </Popover>
      </Stack>
    </Stack>
  );
};

const { block } = createBEMClasses('article-header-price');

const EditPricePopover = ({
  article,
  showWarning,
}: {
  article: ArticleWithRelations;
  showWarning: boolean;
}) => {
  const { _ } = useLingui();
  const [priceManualDiscount, setPriceManualDiscount] = useState(
    article.currentPriceManualDiscount ? -article.currentPriceManualDiscount : null
  );

  const {
    mutateAsync: updateArticle,
    isPending: isUpdatingArticle,
    isError,
  } = useUpdateArticle({
    articleId: article.id,
    requestId: article.requestId,
  });

  return (
    <Dialog
      className={block()}
      aria-label={_(msg({ id: 'article.header.price.edit', message: 'Edit price' }))}
    >
      <Stack gap="1rem">
        <p className="paragraph-100-regular text-primary">
          <Trans id="article.header.price.edit.content">
            Apply a discount percentage. This will apply if you add new actions.
          </Trans>
        </p>
        <InputPercentage
          ariaLabel={_(msg({ id: 'article.header.price.edit.label', message: 'Price discount' }))}
          size="small"
          placeholder={_(
            msg({ id: 'article.header.price.edit.placeholder', message: 'i.e: - 10%' })
          )}
          isLoading={isUpdatingArticle}
          value={priceManualDiscount ?? undefined}
          minValue={-1}
          maxValue={1}
          onChange={(value) => {
            const negativeValue = -Math.abs(value);
            setPriceManualDiscount(negativeValue);

            if (value !== article.currentPriceManualDiscount) {
              updateArticle({
                data: { priceManualDiscount: isNaN(value) ? null : Math.abs(value) },
              });
            }
          }}
          error={
            isError ? _(msg({ id: '_general.error.unknown', message: 'Unknown error' })) : undefined
          }
        />
        {showWarning && (
          <AlertBar type="warning" size="large">
            <Trans id="article.header.price.edit.warning">
              This discount conflicts with the Refashion bonus, preventing it from applying to some
              actions.
            </Trans>
          </AlertBar>
        )}
      </Stack>
    </Dialog>
  );
};

export default ArticlePrice;

import { Key } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { BasicDropdownItem } from '@/design_system/Dropdown/Dropdown';
import LegacyInputSelect from '@/design_system/InputSelect/LegacyInputSelect';
import Stack from '@/design_system/Stack';
import { useUpdateArticle } from '@/models/article';
import { useColorOptions, useSizeOptions } from '@/models/product';
import { ArticleWithRelations, Request } from '@/models/request';

const ColorSize = ({
  request,
  article,
  row = true,
}: {
  request: Request;
  article: ArticleWithRelations;
  row?: boolean;
}) => {
  const { _ } = useLingui();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const handleSelectionChange = (property: 'size' | 'color', key: Key) => {
    updateArticle({
      data: {
        data: {
          ...article.data,
          [property]: key,
        },
      },
    });
  };

  const colorOptions = useColorOptions(article.product);
  const sizeOptions = useSizeOptions(article.product, article.productL1);

  return (
    <Stack row={row} mobileColumn gap="1rem">
      <LegacyInputSelect
        label={_(msg({ id: 'article.form.color.label', message: 'Color' }))}
        placeholder={
          colorOptions.length > 0
            ? _(msg({ id: 'article.form.color.placeholder', message: 'Select the item color...' }))
            : 'N/A'
        }
        isDisabled={(!article.product && !article.productL1) || colorOptions.length === 0}
        selectedKey={article.data.color}
        onSelectionChange={(key) => {
          if (!key) {
            return;
          }

          handleSelectionChange('color', key);
        }}
        style={{ flex: 1 }}
      >
        {colorOptions.map((colorOption) => (
          <BasicDropdownItem id={colorOption.id} key={colorOption.id} text={colorOption.text} />
        ))}
      </LegacyInputSelect>
      <LegacyInputSelect
        label={_(msg({ id: 'article.form.size.label', message: 'Size' }))}
        placeholder={
          (!article.product && !article.productL1) || sizeOptions.length > 0
            ? _(msg({ id: 'article.form.size.placeholder', message: 'Select the item size...' }))
            : 'N/A'
        }
        isDisabled={sizeOptions.length === 0}
        selectedKey={article.data.size}
        onSelectionChange={(key) => {
          if (!key) {
            return;
          }

          handleSelectionChange('size', key);
        }}
        style={{ flex: 1 }}
      >
        {sizeOptions.map((sizeOption) => (
          <BasicDropdownItem id={sizeOption.id} key={sizeOption.id} text={sizeOption.text} />
        ))}
      </LegacyInputSelect>
    </Stack>
  );
};

export default ColorSize;

import React, { CSSProperties, ReactNode, useState } from 'react';
import { Button, Key, Label, Popover, Select, SelectValue } from 'react-aria-components';

import Dropdown from '@/design_system/Dropdown/Dropdown';
import Hint from '@/design_system/Hint/Hint';
import Message from '@/design_system/Message/Message';
import IconChevron from '@/icons/Chevron.svg';
import { createBEMClasses } from '@/utils/classname';

import './LegacyInputSelect.css';

const { block, element } = createBEMClasses('legacy-input-select');

export interface LegacyInputSelectProps {
  label?: React.ReactNode;
  ariaLabel?: string;
  placeholder?: string;
  selectedKey?: string;
  onSelectionChange?: (key?: string) => void;
  size?: 'small' | 'medium' | 'large';
  error?: string;
  hintText?: React.ReactNode;
  messageType?: 'error' | 'success' | 'info';
  messageText?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
}

const LegacyInputSelect = ({
  label,
  ariaLabel,
  placeholder,
  selectedKey,
  onSelectionChange,
  size = 'medium',
  error,
  hintText,
  messageType,
  messageText,
  isDisabled = false,
  isInvalid = false,
  className,
  style,
  children,
}: LegacyInputSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Select
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      className={block({ size }, className)}
      aria-label={ariaLabel}
      selectedKey={selectedKey}
      onSelectionChange={(key: Key) => {
        if (key === selectedKey) {
          onSelectionChange?.(undefined);
        } else {
          onSelectionChange?.(key as string);
        }
      }}
      style={style}
      isDisabled={isDisabled}
      isInvalid={isInvalid || !!error}
    >
      {label && (
        <Label
          className={size === 'large' ? 'paragraph-50-medium' : 'label-100'}
          onClick={() => {
            if (!isDisabled) {
              setIsOpen(true);
            }
          }}
        >
          {label}
        </Label>
      )}
      <Button className={element('button')}>
        <SelectValue className={element('value')}>
          {({ selectedItem, selectedText, isPlaceholder }) => {
            if (isPlaceholder) {
              return placeholder;
            }

            if (!!selectedItem && 'selectedNode' in selectedItem) {
              return selectedItem.selectedNode as ReactNode;
            }

            return selectedText;
          }}
        </SelectValue>
        <span aria-hidden="true">
          <IconChevron down />
        </span>
      </Button>
      <Popover>
        <Dropdown>{children}</Dropdown>
      </Popover>

      {hintText && <Hint>{hintText}</Hint>}
      {error && <Message type="error">{error}</Message>}
      {messageText && <Message type={messageType}>{messageText}</Message>}
    </Select>
  );
};

export default React.memo(LegacyInputSelect);

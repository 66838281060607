import IconWrapper, { IconProps } from './IconWrapper';

const IconPlaceStart = IconWrapper({
  name: 'place',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 9C5.75 5.54822 8.54822 2.75 12 2.75C15.4518 2.75 18.25 5.54822 18.25 9C18.25 9.77753 17.8854 10.845 17.2411 12.0812C16.6093 13.2938 15.7584 14.5765 14.8947 15.7569C14.0331 16.9345 13.1698 17.9953 12.521 18.7625C12.3256 18.9936 12.1499 19.1978 12 19.3701C11.8501 19.1978 11.6744 18.9936 11.479 18.7625C10.8302 17.9953 9.96691 16.9345 9.10528 15.7569C8.24165 14.5765 7.39074 13.2938 6.75886 12.0812C6.11464 10.845 5.75 9.77753 5.75 9ZM11.447 21.0067C11.4471 21.0068 11.4472 21.0069 12 20.5L11.4472 21.0069C11.5893 21.1618 11.7898 21.25 12 21.25H21V19.75H13.6504C13.6557 19.7437 13.6611 19.7374 13.6665 19.731C14.3302 18.9461 15.2169 17.8568 16.1053 16.6426C16.9916 15.4312 17.8907 14.0805 18.5714 12.7744C19.2396 11.492 19.75 10.1555 19.75 9C19.75 4.71979 16.2802 1.25 12 1.25C7.71979 1.25 4.25 4.71979 4.25 9C4.25 10.1555 4.76036 11.492 5.42864 12.7744C6.10926 14.0805 7.00835 15.4312 7.89472 16.6426C8.78309 17.8568 9.6698 18.9461 10.3335 19.731C10.6658 20.1239 10.9429 20.4415 11.1376 20.6615C11.2349 20.7715 11.3117 20.8571 11.3644 20.9156L11.4251 20.9826L11.4411 21.0002L11.4454 21.0049L11.4466 21.0063L11.447 21.0067ZM12 6.75C10.7574 6.75 9.75 7.75736 9.75 9C9.75 10.2426 10.7574 11.25 12 11.25C13.2426 11.25 14.25 10.2426 14.25 9C14.25 7.75736 13.2426 6.75 12 6.75ZM8.25 9C8.25 6.92893 9.92893 5.25 12 5.25C14.0711 5.25 15.75 6.92893 15.75 9C15.75 11.0711 14.0711 12.75 12 12.75C9.92893 12.75 8.25 11.0711 8.25 9Z"
      />
    </svg>
  ),
});

const IconPlaceEnd = IconWrapper({
  name: 'place',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 9C5.75 5.54822 8.54822 2.75 12 2.75C15.4518 2.75 18.25 5.54822 18.25 9C18.25 9.77753 17.8854 10.845 17.2411 12.0812C16.6093 13.2938 15.7584 14.5765 14.8947 15.7569C14.0331 16.9345 13.1698 17.9953 12.521 18.7625C12.3256 18.9936 12.1499 19.1978 12 19.3701C11.8501 19.1978 11.6744 18.9936 11.479 18.7625C10.8302 17.9953 9.96691 16.9345 9.10528 15.7569C8.24165 14.5765 7.39074 13.2938 6.75886 12.0812C6.11464 10.845 5.75 9.77753 5.75 9ZM12 20.5C12.5528 21.0069 12.5529 21.0068 12.553 21.0067L12.5534 21.0063L12.5546 21.0049L12.5589 21.0002L12.5749 20.9826L12.6356 20.9156C12.6883 20.8571 12.7651 20.7715 12.8624 20.6615C13.0571 20.4415 13.3342 20.1239 13.6665 19.731C14.3302 18.9461 15.2169 17.8568 16.1053 16.6426C16.9916 15.4312 17.8907 14.0805 18.5714 12.7744C19.2396 11.492 19.75 10.1555 19.75 9C19.75 4.71979 16.2802 1.25 12 1.25C7.71979 1.25 4.25 4.71979 4.25 9C4.25 10.1555 4.76036 11.492 5.42864 12.7744C6.10926 14.0805 7.00835 15.4312 7.89472 16.6426C8.78309 17.8568 9.6698 18.9461 10.3335 19.731C10.3389 19.7374 10.3443 19.7437 10.3496 19.75H3V21.25H12C12.2102 21.25 12.4107 21.1618 12.5528 21.0069L12 20.5ZM12 6.75C10.7574 6.75 9.75 7.75736 9.75 9C9.75 10.2426 10.7574 11.25 12 11.25C13.2426 11.25 14.25 10.2426 14.25 9C14.25 7.75736 13.2426 6.75 12 6.75ZM8.25 9C8.25 6.92893 9.92893 5.25 12 5.25C14.0711 5.25 15.75 6.92893 15.75 9C15.75 11.0711 14.0711 12.75 12 12.75C9.92893 12.75 8.25 11.0711 8.25 9Z"
      />
    </svg>
  ),
});

const IconPlace = ({ start, end, ...props }: { start?: boolean; end?: boolean } & IconProps) => {
  if (start) return <IconPlaceStart {...props} />;
  if (end) return <IconPlaceEnd {...props} />;
  return null;
};

export default IconPlace;

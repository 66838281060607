import { useState } from 'react';
import { Separator } from 'react-aria-components';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ClientFormModal } from '@/components/ClientForm';
import ContactDetails from '@/components/ContactDetails';
import { RequestDueDate } from '@/components/DueDate';
import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import { UserAvatarGroup } from '@/components/UserAvatarGroup/UserAvatarGroup';
import Accordion from '@/design_system/Accordion';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconCalendar from '@/icons/Calendar.svg';
import IconEdit from '@/icons/Edit.svg';
import IconFile from '@/icons/File.svg';
import IconStore from '@/icons/Store.svg';
import IconPerson from '@/icons/User.svg';
import { RequestWithRelations, useActivities } from '@/models/request';
import { useUser } from '@/models/user';
import { useCurrentSession } from '@/services/auth';
import { formatDate } from '@/utils/date';

export const RequestInfoSidePanel = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();
  const [isOpenClientFormModal, setIsOpenClientFormModal] = useState(false);
  const canEditClient =
    !request.isArchived &&
    currentSession?.hasPermission('edit_client', {
      organizationId: request.organizationId,
    });

  const isExternalWorkshopUser = currentSession?.workshop?.external ?? false;
  const { data } = useActivities(
    {
      requestId: request.id,
      types: ['article_estimate_completed'],
      limit: 1,
    },
    { enabled: isExternalWorkshopUser }
  );

  const { data: afterSalesManager } = useUser(data?.activities?.[0]?.creatorId);

  return (
    <Stack gap="1rem">
      <Stack gap="0.5rem" ariaLabel={_(msg({ id: 'request.information', message: 'Information' }))}>
        <Accordion
          openByDefault
          trigger={
            <p className="paragraph-100-medium">
              <Trans id="request.information">Information</Trans>
            </p>
          }
        >
          <div style={{ marginTop: '0.5rem' }}>
            <RequestDetails request={request} />
          </div>
        </Accordion>
      </Stack>
      {request.client && !isExternalWorkshopUser && (
        <>
          <Separator />
          <Stack ariaLabel={_(msg({ id: 'request.contact-details.client', message: 'Client' }))}>
            <Accordion
              openByDefault
              trigger={
                <Stack row alignItems="center" gap="0.5rem">
                  <p className="paragraph-100-medium">
                    <Trans id="request.contact-details.client">Client</Trans>
                  </p>
                </Stack>
              }
            >
              <Stack style={{ marginTop: '0.5rem' }} gap="1rem">
                <ContactDetails
                  type="client"
                  name={request.client.name}
                  email={request.client.email}
                  address={request.client.address}
                  phone={request.client.phone}
                  deliveryChoice={request.client.deliveryOption}
                  isVIP={request.vip}
                  size="small"
                />
                {canEditClient && (
                  <Button
                    variant="secondary"
                    onPress={() => setIsOpenClientFormModal(true)}
                    size="small"
                    ariaLabel={_(msg({ id: 'request.edit.actions.edit-client', message: 'Edit' }))}
                  >
                    <IconEdit />
                    <Trans id="request.edit.actions.edit-client">Edit</Trans>
                  </Button>
                )}
              </Stack>
            </Accordion>
          </Stack>
          <ClientFormModal
            isOpen={isOpenClientFormModal}
            setIsOpen={setIsOpenClientFormModal}
            request={request}
            hideDeliveryOption={!request.storeId}
            variant="default"
            size="medium"
          />
        </>
      )}
      {request.store && !isExternalWorkshopUser && (
        <>
          <Separator />
          <Accordion
            openByDefault
            trigger={
              <p className="paragraph-100-medium">
                <Trans id="request.contact-details.store">Store</Trans>
              </p>
            }
          >
            <Stack
              style={{ marginTop: '0.5rem' }}
              ariaLabel={_(msg({ id: 'request.contact-details.store', message: 'Store' }))}
            >
              <ContactDetails
                type="store"
                name={request.store.name}
                email={request.store.address.contactEmail}
                address={request.store.address}
                phone={request.store.phone}
                size="small"
              />
            </Stack>
          </Accordion>
        </>
      )}
      {afterSalesManager && (
        <>
          <Separator />
          <Accordion
            openByDefault
            trigger={
              <p className="paragraph-100-medium">
                <Trans id="request.contact-details.store.asm">Contact</Trans>
              </p>
            }
          >
            <Stack
              style={{ marginTop: '0.5rem' }}
              ariaLabel={_(msg({ id: 'request.contact-details.store.asm', message: 'Contact' }))}
            >
              <ContactDetails
                type="client"
                name={afterSalesManager.name}
                email={afterSalesManager.email}
                phone={afterSalesManager.phone}
                size="small"
              />
            </Stack>
          </Accordion>
        </>
      )}
    </Stack>
  );
};

export const RequestInfoTopPanel = ({ request }: { request: RequestWithRelations }) => {
  const { currentSession } = useCurrentSession();
  const { _ } = useLingui();
  const [isOpenClientFormModal, setIsOpenClientFormModal] = useState(false);
  const canEditClient =
    !request.isArchived &&
    currentSession?.hasPermission('edit_client', {
      organizationId: request.organizationId,
    });
  const isExternalWorkshopUser = currentSession?.workshop?.external ?? false;
  const { data } = useActivities(
    {
      requestId: request.id,
      types: ['article_estimate_completed'],
      limit: 1,
    },
    { enabled: isExternalWorkshopUser }
  );

  const { data: afterSalesManager } = useUser(data?.activities?.[0]?.creatorId);

  return (
    <Stack row gap="0.25rem" className="paragraph-100-regular">
      <Box padding="0">
        <Stack padding="0.75rem">
          <Accordion
            trigger={
              <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
                <IconFile />
                <Trans id="request.information">Information</Trans>
              </Stack>
            }
          >
            <div style={{ marginTop: '0.5rem' }}>
              <RequestDetails request={request} />
            </div>
          </Accordion>
        </Stack>

        {request.client && !isExternalWorkshopUser && (
          <>
            <Separator />
            <Stack
              padding="0.75rem"
              ariaLabel={_(msg({ id: 'request.contact-details.client', message: 'Client' }))}
            >
              <Accordion
                trigger={
                  <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
                    <IconPerson />
                    {request.client.name}
                  </Stack>
                }
              >
                <Stack style={{ marginTop: '0.5rem' }} gap="1rem">
                  <ContactDetails
                    type="client"
                    email={request.client.email}
                    address={request.client.address}
                    phone={request.client.phone}
                    deliveryChoice={request.client.deliveryOption}
                    size="small"
                  />
                  {canEditClient && (
                    <Button
                      variant="secondary"
                      onPress={() => setIsOpenClientFormModal(true)}
                      size="small"
                      ariaLabel={_(
                        msg({ id: 'request.edit.actions.edit-client', message: 'Edit' })
                      )}
                    >
                      <IconEdit />
                      <Trans id="request.edit.actions.edit-client">Edit</Trans>
                    </Button>
                  )}
                </Stack>
              </Accordion>
            </Stack>
            <ClientFormModal
              isOpen={isOpenClientFormModal}
              setIsOpen={setIsOpenClientFormModal}
              request={request}
              hideDeliveryOption={!request.storeId}
              variant="default"
              size="medium"
            />
          </>
        )}
        {request.store && !isExternalWorkshopUser && (
          <>
            <Separator />
            <Stack
              padding="0.75rem"
              ariaLabel={_(msg({ id: 'request.contact-details.store', message: 'Store' }))}
            >
              <Accordion
                trigger={
                  <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
                    <IconStore />
                    {request.store.name}
                  </Stack>
                }
              >
                <div style={{ marginTop: '0.5rem' }}>
                  <ContactDetails
                    type="store"
                    email={request.store.address.contactEmail}
                    address={request.store.address}
                    phone={request.store.phone}
                    size="small"
                  />
                </div>
              </Accordion>
            </Stack>
          </>
        )}
        {afterSalesManager && (
          <>
            <Separator />
            <Stack
              padding="0.75rem"
              ariaLabel={_(msg({ id: 'request.contact-details.store.asm', message: 'Contact' }))}
            >
              <Accordion
                trigger={
                  <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
                    <IconPerson />
                    <Trans id="request.contact-details.store.asm">Contact</Trans>
                  </Stack>
                }
              >
                <div style={{ marginTop: '0.5rem' }}>
                  <ContactDetails
                    type="client"
                    name={afterSalesManager.name}
                    email={afterSalesManager.email}
                    phone={afterSalesManager.phone}
                    size="small"
                  />
                </div>
              </Accordion>
            </Stack>
          </>
        )}
      </Box>
    </Stack>
  );
};

const RequestDetails = ({ request }: { request: RequestWithRelations }) => {
  const { currentSession } = useCurrentSession();

  return (
    <Stack gap="0.75rem">
      <RequestRef request={request} />
      <CreationDate request={request} />
      {!currentSession?.workshop?.external && request.dueAtDate && (
        <Stack>
          <RequestDueDate
            date={request.dueAtDate}
            variant="info"
            estimatedDate={request.isArchived ? null : request.estimatedDueAtDate}
          />
        </Stack>
      )}
      {request.externalPaymentReference && <PaymentRef request={request} />}
      {request.isArchived && request.archivedAtDate && <ArchiveDate request={request} />}
      <Supervisor request={request} />
      <Collaborators request={request} />
    </Stack>
  );
};

export const RequestRef = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">
        <Trans id="request.reference.long">Reference</Trans>
      </span>
      <span
        className="paragraph-100-medium"
        aria-label={_(msg({ id: 'request.reference.long', message: 'Reference' }))}
      >
        {request.reference}
      </span>
    </Stack>
  );
};

export const CreationDate = ({ request }: { request: RequestWithRelations }) => {
  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">
        <Trans id="request.side-menu.request-details.creation-date">Creation date</Trans>
      </span>
      <Stack row className="paragraph-100-medium" alignItems="center" gap="0.25rem">
        <IconCalendar style={{ fontSize: '1rem' }} />
        {formatDate(request.createdAtDate, { dateStyle: 'medium' })}
      </Stack>
    </Stack>
  );
};

export const PaymentRef = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">
        <Trans id="request.payment-ref">Receipt / invoice no.</Trans>
      </span>
      <span
        className="paragraph-100-medium"
        aria-label={_(msg({ id: 'request.payment-ref', message: 'Receipt / invoice no.' }))}
      >
        {request.externalPaymentReference}
      </span>
    </Stack>
  );
};

export const ArchiveDate = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();

  if (!request.archivedAtDate) {
    return null;
  }

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">
        <Trans id="request.archived-date">Archived date</Trans>
      </span>
      <span
        title={formatDate(request.archivedAtDate, { dateStyle: 'long', timeStyle: 'medium' })}
        className="paragraph-100-medium"
        aria-label={_(msg({ id: 'request.archived-date', message: 'Archived date' }))}
      >
        {formatDate(request.archivedAtDate, { dateStyle: 'short' })}
      </span>
    </Stack>
  );
};

export const Supervisor = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();
  const label = _(msg({ id: 'request.supervisor', message: 'Supervisor' }));
  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">{label}</span>
      <Stack row gap="0.25rem" alignItems="center">
        {!!request.supervisor && <UserAvatar user={request.supervisor} size="x-small" />}
        <span className="paragraph-100-medium sentry-mask" aria-label={label}>
          {request.supervisor?.name ?? '-'}
        </span>
      </Stack>
    </Stack>
  );
};

export const Collaborators = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();
  const label = _(msg({ id: 'request.collaborators', message: 'Collaborators' }));
  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">{label}</span>
      <Stack row gap="0.25rem" alignItems="center">
        {request.collaborators.length > 0 ? (
          <UserAvatarGroup users={request.collaborators} size="x-small" />
        ) : (
          <span className="paragraph-100-medium">- </span>
        )}
      </Stack>
    </Stack>
  );
};

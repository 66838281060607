/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
import { CSSProperties, RefObject, useCallback, useId, useState } from 'react';
import {
  Button as AriaButton,
  Dialog,
  DialogTrigger,
  Key,
  Label,
  Menu,
  MenuContext,
  MenuItem,
  Popover,
  Selection,
} from 'react-aria-components';
import { useLingui } from '@lingui/react';

import DueAtType from '@/components/DueAtType';
import Priority, { PRIORITY_LABELS } from '@/components/Priority';
import ShipmentStepBadge from '@/components/ShipmentStepBadge/ShipmentStepBadge';
import Hint from '@/design_system/Hint/Hint';
import Message from '@/design_system/Message/Message';
import IconChevron from '@/icons/Chevron.svg';
import { SHIPMENT_STEPS, ShipmentStep } from '@/models/shipment';
import { createBEMClasses } from '@/utils/classname';

import './InputMultiSelect.css';

const { block, element } = createBEMClasses('input-multi-select');

export interface InputMultiSelectProps {
  label?: React.ReactNode;
  ariaLabel?: string;
  placeholder?: string;
  size?: 'small' | 'large';
  hintText?: string;
  messageType?: 'error' | 'success' | 'info';
  messageText?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  selectedKeys?: Key[];
  onSelectionChange?: (keys: Key[]) => void;
  style?: CSSProperties;
  chips?: React.ReactNode;
  children: React.ReactNode;
}

/**
 * The correct way to build InputMultiSelect would be to use a ListBox inside a Select component.
 * However, the select component doesn't allow for multiple selection.
 *
 * The second best way to build InputMultiSelect would be to use a Listbox inside a Dialog,
 * and manually handle the opening/closing of the dropdown.
 * However, the ListBox currently has a bug where all the selection gets removed when the user press Escape.
 * Source: https://github.com/adobe/react-spectrum/issues/4793
 *
 * Therefore, we use the third best way to build InputMultiSelect: We use a Menu with MenuItems.
 * This makes it impossible to re-use our custom Dropdown component as it uses ListBox and ListBoxItems,
 * and are incompatible. I hope this is a temporary solution, and in the meantime, since we share the same
 * css classes, it should not be too bothersome.
 */

const InputMultiSelect = ({
  label,
  ariaLabel,
  placeholder,
  size = 'large',
  hintText,
  messageType,
  messageText,
  isDisabled = false,
  isInvalid = false,
  selectedKeys = [],
  onSelectionChange,
  style,
  chips,
  children,
}: InputMultiSelectProps) => {
  const [open, setOpen] = useState(false);
  const id = useId();

  const [triggerRef, setTriggerRef] = useState<RefObject<HTMLLabelElement>>({ current: null });

  const ref = useCallback((node: HTMLLabelElement) => {
    if (node !== null) {
      setTriggerRef({ current: node });
    }
  }, []);

  return (
    <MenuContext.Provider
      value={{
        selectedKeys,
        onSelectionChange: (keys: Selection) => {
          if (keys === 'all') {
            // We don't handle this case yet
            return;
          }

          onSelectionChange?.([...keys.values()] as string[]);
        },
      }}
    >
      <DialogTrigger>
        <div className={block(size)} style={style}>
          {label && <Label className="label-100 text-primary">{label}</Label>}

          <label
            className={element('button', {
              invalid: isInvalid,
              disabled: isDisabled,
            })}
            htmlFor={id}
            ref={ref}
            onClick={() => {
              /* Fix until https://github.com/adobe/react-spectrum/issues/3613 and https://bugzilla.mozilla.org/show_bug.cgi?id=1796300 are resolved */
              if (navigator.userAgent.toLowerCase().includes('firefox')) {
                document.getElementById(id)?.click();
              }
            }}
          >
            {selectedKeys.length === 0 && (
              <div className={element('placeholder')}>{placeholder}</div>
            )}

            <div className={element('chips')}>{chips}</div>

            <AriaButton
              id={id}
              isDisabled={isDisabled}
              onPress={() => setOpen((open) => !open)}
              aria-label={ariaLabel}
            >
              <span aria-hidden="true">
                <IconChevron down />
              </span>
            </AriaButton>
          </label>

          <Popover
            placement="bottom"
            triggerRef={triggerRef}
            isOpen={open}
            onOpenChange={setOpen}
            style={{ minWidth: triggerRef.current?.getBoundingClientRect().width }}
          >
            <Dialog>
              <MenuDropdown selectionMode="multiple" ariaLabel="dropdown">
                {children}
              </MenuDropdown>
            </Dialog>
          </Popover>

          {hintText && <Hint>{hintText}</Hint>}
          {messageText && <Message type={messageType}>{messageText}</Message>}
        </div>
      </DialogTrigger>
    </MenuContext.Provider>
  );
};

export default InputMultiSelect;

const { block: blockMenu, element: elementMenu } = createBEMClasses('dropdown');

const MenuDropdown = ({
  ariaLabel,
  children,
  withSeparators,
  onAction,
  size = 'medium',
  style,
  selectionMode,
}: {
  ariaLabel?: string;
  children: React.ReactNode;
  withSeparators?: boolean;
  onAction?: (id: string) => void;
  size?: 'medium' | 'large';
  style?: React.CSSProperties;
  selectionMode?: 'none' | 'single' | 'multiple';
}) => {
  return (
    <Menu
      aria-label={ariaLabel}
      className={blockMenu({ size, 'with-separators': withSeparators })}
      onAction={onAction ? (key: Key) => onAction(key as string) : undefined}
      style={style}
      selectionMode={selectionMode}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
    >
      {children}
    </Menu>
  );
};

export const RawMultiSelectItem = ({
  id,
  textValue,
  value,
  children,
}: {
  id: string;
  textValue: string;
  value: object;
  children: React.ReactNode;
}) => {
  return (
    <MenuItem id={id} className={elementMenu('item', 'raw')} textValue={textValue} value={value}>
      {children}
    </MenuItem>
  );
};

export const BasicMultiSelectItem = ({
  id,
  text,
  textValue,
  icon,
}: {
  id: string;
  text: string;
  textValue?: string;
  icon?: React.ReactNode;
}) => {
  return (
    <MenuItem id={id} className={elementMenu('item', 'basic')} textValue={textValue ?? text}>
      {icon}
      {text}
    </MenuItem>
  );
};

export const ShipmentStepMultiSelectItem = ({ step }: { step: ShipmentStep }) => {
  const { _ } = useLingui();

  return (
    <MenuItem
      id={step}
      className={elementMenu('item', 'shipment-step')}
      textValue={_(SHIPMENT_STEPS.find((s) => s.id === step)!.name)}
    >
      <ShipmentStepBadge step={step} />
    </MenuItem>
  );
};

export const PriorityMultiSelectItem = ({ priority }: { priority: boolean }) => {
  const { _ } = useLingui();

  return (
    <MenuItem
      id={priority ? 'high' : 'low'}
      className={elementMenu('item')}
      textValue={_(PRIORITY_LABELS[priority ? 'high' : 'low'])}
      value={{ selectedNode: <Priority priority={priority} /> }}
    >
      <Priority priority={priority} />
    </MenuItem>
  );
};

export const DueAtTypeMultiSelectItem = ({ type, days }: { type: string; days: number }) => {
  return (
    <MenuItem
      id={type}
      className={elementMenu('item')}
      textValue={type}
      value={{ selectedNode: <DueAtType type={type} days={days} /> }}
    >
      <DueAtType type={type} days={days} />
    </MenuItem>
  );
};

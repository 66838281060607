import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import { AddressWithRelations } from '@/models/address';
import { ArticleWithRelations, ShipmentCarrierService, ShipmentHandover } from '@/models/shipment';
import useViewPort from '@/utils/useViewport';

import { ArticleSelect } from './components/ArticleSelect';
import { CarrierSelect } from './components/CarrierSelect';
import { PlaceSelect } from './components/PlaceSelect';

const ShipmentForm = ({
  origin,
  setOrigin,
  destination,
  setDestination,
  articles,
  setArticles,
  carrierService,
  setCarrierService,
  handover,
  setHandover,
  pickupDate,
  setPickupDate,
  trackingId,
  setTrackingId,
  error,
}: {
  origin?: AddressWithRelations;
  setOrigin: (address?: AddressWithRelations) => void;
  destination?: AddressWithRelations;
  setDestination: (address?: AddressWithRelations) => void;
  articles: ArticleWithRelations[];
  setArticles: (articles: ArticleWithRelations[]) => void;
  carrierService?: ShipmentCarrierService | 'private-carrier';
  setCarrierService: (carrier: ShipmentCarrierService | 'private-carrier') => void;
  handover?: ShipmentHandover;
  setHandover: (handover?: ShipmentHandover) => void;
  pickupDate?: string;
  setPickupDate: (pickupDate?: string) => void;
  trackingId?: string;
  setTrackingId: (trackingId?: string) => void;
  error?: string;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  return (
    <Stack
      gap={isMobile ? '1rem' : '1.5rem'}
      padding={isMobile ? '1rem' : '2rem'}
      style={{ flex: 1 }}
    >
      <Stack row mobileColumn gap={isMobile ? '1rem' : '1.5rem'}>
        <PlaceSelect
          type="origin"
          prefillDefault
          label={_(msg({ id: 'shipments.new.origin.label', message: 'Origin' }))}
          placeholder={_(
            msg({ id: 'shipments.new.origin.placeholder', message: 'Select an origin' })
          )}
          selected={origin}
          setSelected={setOrigin}
          isDisabled={articles.length > 0}
        />
        <PlaceSelect
          type="destination"
          label={_(msg({ id: 'shipments.new.destination.label', message: 'Destination' }))}
          placeholder={_(
            msg({ id: 'shipments.new.destination.placeholder', message: 'Select a destination' })
          )}
          carrierService={carrierService}
          selected={destination}
          setSelected={setDestination}
          isDisabled={articles.length > 0}
        />
      </Stack>
      <ArticleSelect
        label={
          <div className="label-100">
            <Trans id="shipments.new.article.label">Items</Trans>
          </div>
        }
        ariaLabel={_(msg({ id: 'shipments.new.article.label', message: 'Items' }))}
        addedArticles={articles}
        setAddedArticles={setArticles}
        origin={origin}
        setOrigin={setOrigin}
        destination={destination}
        setDestination={setDestination}
      />

      <CarrierSelect
        articles={articles}
        origin={origin}
        destination={destination}
        carrierService={carrierService}
        setCarrierService={setCarrierService}
        handover={handover}
        setHandover={setHandover}
        pickupDate={pickupDate}
        setPickupDate={setPickupDate}
        trackingId={trackingId}
        setTrackingId={setTrackingId}
      />
      {error && (
        <div>
          <Message type="error">{error}</Message>
        </div>
      )}
    </Stack>
  );
};

export default ShipmentForm;

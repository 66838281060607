import IconWrapper from './IconWrapper';

/**
 * @deprecated This icon has not been reviewed in the Design System
 */
const IconSort = IconWrapper({
  name: 'sort',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 17H4C3.71667 17 3.47933 16.904 3.288 16.712C3.096 16.5207 3 16.2833 3 16C3 15.7167 3.096 15.4793 3.288 15.288C3.47933 15.096 3.71667 15 4 15H8C8.28333 15 8.521 15.096 8.713 15.288C8.90433 15.4793 9 15.7167 9 16C9 16.2833 8.90433 16.5207 8.713 16.712C8.521 16.904 8.28333 17 8 17ZM20 7H4C3.71667 7 3.47933 6.90433 3.288 6.713C3.096 6.521 3 6.28333 3 6C3 5.71667 3.096 5.479 3.288 5.287C3.47933 5.09567 3.71667 5 4 5H20C20.2833 5 20.5207 5.09567 20.712 5.287C20.904 5.479 21 5.71667 21 6C21 6.28333 20.904 6.521 20.712 6.713C20.5207 6.90433 20.2833 7 20 7ZM14 12H4C3.71667 12 3.47933 11.904 3.288 11.712C3.096 11.5207 3 11.2833 3 11C3 10.7167 3.096 10.479 3.288 10.287C3.47933 10.0957 3.71667 10 4 10H14C14.2833 10 14.521 10.0957 14.713 10.287C14.9043 10.479 15 10.7167 15 11C15 11.2833 14.9043 11.5207 14.713 11.712C14.521 11.904 14.2833 12 14 12Z" />
    </svg>
  ),
});

export default IconSort;

import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Stack from '@/design_system/Stack';
import IconFlag from '@/icons/Flag.svg';
import { createBEMClasses } from '@/utils/classname';

import './Priority.css';

const { block, element } = createBEMClasses('priority');

export const PRIORITY_LABELS = {
  high: msg({ id: 'request.priority.high', message: 'High' }),
  low: msg({ id: 'request.priority.normal', message: 'Normal' }),
};

const Priority = ({
  priority,
  textStyle = 'light',
}: {
  priority: boolean;
  textStyle?: 'light' | 'heavy';
}) => {
  const { _ } = useLingui();

  return (
    <Stack row gap="0.25rem" alignItems="center" className={block()}>
      <IconFlag className={element('flag', { priority })} />
      <span className={textStyle === 'light' ? 'paragraph-100-regular' : 'paragraph-100-medium'}>
        {_(PRIORITY_LABELS[priority ? 'high' : 'low'])}
      </span>
    </Stack>
  );
};

export default Priority;

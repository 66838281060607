import { Comments, LegacyComments } from '@/components/comments/Comments/Comments';
import {
  useComments,
  useCreateComment,
  useDeleteComment,
  useUpdateComment,
} from '@/models/request';

export const RequestComments = ({
  requestId,
  articleId,
}: {
  requestId: string;
  articleId?: string;
}) => {
  const { data: { comments } = { comments: [] } } = useComments({
    requestId,
    articleId,
  });
  const { mutateAsync: sendUpdateCommentRequest } = useUpdateComment({ requestId, articleId });
  const { mutateAsync: sendDeleteCommentRequest } = useDeleteComment({ requestId, articleId });
  const { mutateAsync: sendCreateCommentRequest } = useCreateComment({ requestId, articleId });

  return (
    <Comments
      comments={comments}
      displayArticleName={!articleId}
      isReadOnly={(comment) => comment.article?.id !== articleId}
      onCommentCreated={async (data) => {
        await sendCreateCommentRequest(data);
      }}
      onCommentUpdated={async (data) => {
        await sendUpdateCommentRequest(data);
      }}
      onCommentDeleted={async (data) => {
        await sendDeleteCommentRequest(data);
      }}
    />
  );
};

export const LegacyRequestComments = ({
  requestId,
  articleId,
}: {
  requestId: string;
  articleId?: string;
}) => {
  const { data: { comments } = { comments: [] } } = useComments({
    requestId,
    articleId,
  });
  const { mutateAsync: sendUpdateCommentRequest } = useUpdateComment({ requestId, articleId });
  const { mutateAsync: sendDeleteCommentRequest } = useDeleteComment({ requestId, articleId });
  const { mutateAsync: sendCreateCommentRequest } = useCreateComment({ requestId, articleId });

  return (
    <LegacyComments
      comments={comments}
      displayArticleName={!articleId}
      isReadOnly={(comment) => comment.article?.id !== articleId}
      onCommentCreated={async (data) => {
        await sendCreateCommentRequest(data);
      }}
      onCommentUpdated={async (data) => {
        await sendUpdateCommentRequest(data);
      }}
      onCommentDeleted={async (data) => {
        await sendDeleteCommentRequest(data);
      }}
    />
  );
};

import { GroupBase } from 'react-select';

import { InputSelect, type InputSelectProps } from '@/design_system/InputSelect/InputSelect';

import './InputSearchSelect.css';

export const InputSearchSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: InputSelectProps<Option, IsMulti, Group>
) => {
  return <InputSelect {...props} isClearable />;
};

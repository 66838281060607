import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { DiscardChangesDialog } from '@/components/DiscardChangesDialog/DiscardChangesDialog';
import {
  FullPageLayout,
  FullPageLayoutContent,
  FullPageLayoutFooter,
  FullPageLayoutSimpleHeader,
} from '@/components/FullPageLayout/FullPageLayout';
import Button from '@/design_system/Button';
import { AddressWithRelations } from '@/models/address';
import {
  ArticleWithRelations,
  Shipment,
  ShipmentCarrierService,
  ShipmentHandover,
  ShipmentWithRelations,
  useShipment,
  useUpdateDraftShipment,
} from '@/models/shipment';
import { NewShipmentGlobalError } from '@/routes/Shipments/components/NewShipmentGlobalError';
import ShipmentForm from '@/routes/Shipments/Shipment/components/ShipmentForm';
import { newAddressWithRelations } from '@/routes/Shipments/Shipment/components/ShipmentForm/components/PlaceSelect';
import { useCurrentSession } from '@/services/auth';
import { useShowWarningBeforeLeavingApp, useSimpleBlocker } from '@/utils/navigation';
import { useScrollIntoView } from '@/utils/useScrollIntoView';

const Edit = () => {
  const blocker = useSimpleBlocker();
  useShowWarningBeforeLeavingApp();

  return (
    <>
      <EditPageWrapper />
      {blocker.state === 'blocked' && (
        <DiscardChangesDialog
          onConfirm={() => blocker.proceed()}
          onCancel={() => blocker.reset()}
        />
      )}
    </>
  );
};

const EditPageWrapper = () => {
  const { id: shipmentId } = useParams();
  const { data: shipment, isLoading } = useShipment(shipmentId);
  const navigate = useNavigate();
  const { currentSession } = useCurrentSession();

  if (isLoading) {
    return null;
  }

  if (!shipment) {
    navigate('/shipments', { state: { skipRouterBlocker: true } });
    return null;
  }

  if (shipment.status !== 'draft') {
    navigate(`/shipments/${shipment.id}`, { state: { skipRouterBlocker: true } });
    return null;
  }

  if (!shipment.canBeCreatedBy(currentSession)) {
    navigate('/shipments', { state: { skipRouterBlocker: true } });
    return null;
  }

  return <EditPage shipment={shipment} />;
};

const EditPage = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();
  const navigate = useNavigate();

  const [origin, setOrigin] = useState<AddressWithRelations | undefined>(
    newAddressWithRelations(
      shipment.originAddress,
      shipment.originClient,
      shipment.originStore,
      shipment.originWorkshop
    )
  );
  const [destination, setDestination] = useState<AddressWithRelations | undefined>(
    newAddressWithRelations(
      shipment.destinationAddress,
      shipment.destinationClient,
      shipment.destinationStore,
      shipment.destinationWorkshop
    )
  );
  const [articles, setArticles] = useState<ArticleWithRelations[]>(
    shipment.articles.map(({ article }) => article)
  );
  const [carrierService, setCarrierService] = useState<
    ShipmentCarrierService | 'private-carrier' | undefined
  >(shipment.carrierService ?? undefined);
  const [handover, setHandover] = useState<ShipmentHandover | undefined>(
    shipment.handover ?? undefined
  );
  const [pickupDate, setPickupDate] = useState<string | undefined>(
    shipment.pickupDate ?? undefined
  );
  const [trackingId, setTrackingId] = useState<string | undefined>(
    shipment.trackingId ?? undefined
  );
  const [error, setError] = useState<string>();
  const [alertRef, scrollAlertIntoView] = useScrollIntoView<HTMLDivElement>();

  const { mutateAsync: updateDraftShipment, isPending } = useUpdateDraftShipment(shipment.id);

  const handleError = (error: string) => {
    setError(error);
    scrollAlertIntoView();
  };

  const onSubmit = () => {
    if (!origin) {
      handleError(_(msg({ id: 'shipments.new.error.origin', message: 'Please select an origin' })));
      return;
    }

    if (!destination) {
      handleError(
        _(msg({ id: 'shipments.new.error.destination', message: 'Please select a destination' }))
      );
      return;
    }

    if (articles.length === 0) {
      handleError(
        _(msg({ id: 'shipments.new.error.articles', message: 'Please add at least one item' }))
      );
      return;
    }

    if (!carrierService) {
      handleError(
        _(msg({ id: 'shipments.new.error.carrier', message: 'Please select a carrier' }))
      );
      return;
    }

    if (!!carrierService && carrierService !== 'private-carrier' && !handover) {
      handleError(
        _(
          msg({
            id: 'shipments.new.error.handover',
            message: 'Please select a handover option',
          })
        )
      );
      return;
    }

    if (handover === 'pickup' && carrierService !== 'private-carrier' && !pickupDate) {
      handleError(
        _(
          msg({
            id: 'shipments.new.error.pickup-date',
            message: 'Please select a pickup date',
          })
        )
      );
      return;
    }

    updateDraftShipment({
      origin: origin.client?.id ?? origin.store?.id ?? origin.workshop?.id ?? '',
      destination:
        destination.client?.id ?? destination.store?.id ?? destination.workshop?.id ?? '',
      articles: articles.map((article) => article.id),
      carrierService,
      handover: carrierService === 'private-carrier' ? 'pickup' : handover,
      pickupDate:
        handover === 'pickup' && carrierService !== 'private-carrier' ? pickupDate : undefined,
      trackingId: carrierService === 'private-carrier' ? trackingId : undefined,
    })
      .then((shipment: Shipment) => {
        navigate(`/shipments/${shipment.id}`, { state: { skipRouterBlocker: true } });
      })
      .catch((err) => {
        console.error(err);
        setError(
          (err.message as string) ??
            _(
              msg({
                id: 'shipment.unknown-error',
                message: 'Unknown error. Please contact Support',
              })
            )
        );
      });
  };

  return (
    <FullPageLayout>
      <FullPageLayoutSimpleHeader
        title={_(msg({ id: 'shipments.edit.title', message: 'Edit shipment' }))}
        onClose={() =>
          navigate(`/shipments/${shipment.id}`, { state: { skipRouterBlocker: false } })
        }
      />
      <FullPageLayoutContent>
        <div ref={alertRef}>
          <NewShipmentGlobalError
            showGlobalError={!!error}
            title={_(
              msg({
                id: 'shipments.edit.error',
                message:
                  'Please fill the missing information in order to be able to edit the shipment',
              })
            )}
          />
        </div>
        <ShipmentForm
          {...{
            origin,
            setOrigin,
            destination,
            setDestination,
            articles,
            setArticles,
            carrierService,
            setCarrierService,
            handover,
            setHandover,
            pickupDate,
            setPickupDate,
            trackingId,
            setTrackingId,
            error,
          }}
        />
      </FullPageLayoutContent>
      <FullPageLayoutFooter>
        <Button variant="primary" onPress={onSubmit} isLoading={isPending}>
          <Trans id="shipments.edit.save">Save</Trans>
        </Button>
      </FullPageLayoutFooter>
    </FullPageLayout>
  );
};

export default Edit;

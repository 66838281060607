import { ReactNode } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import CloseIcon from '@/icons/Cross.svg';
import { createBEMClasses } from '@/utils/classname';

import './FullPageLayout.css';

const { block, element } = createBEMClasses('full-page-layout');

export const FullPageLayout = ({ children }: { children: ReactNode }) => {
  return <Stack className={block()}>{children}</Stack>;
};

const FullPageLayoutHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Stack className={element('header')} row justifyContent="space-between">
      {children}
    </Stack>
  );
};

export const FullPageLayoutSimpleHeader = ({
  title,
  onClose,
}: {
  title: string;
  onClose: () => void;
}) => {
  const { _ } = useLingui();
  return (
    <FullPageLayoutHeader>
      <h1 className="headline-200-bold">{title}</h1>
      <Button
        onPress={onClose}
        ariaLabel={_(msg({ id: 'full-page-layout.close', message: 'Close' }))}
        iconOnly
        size="medium"
        variant="neutral"
      >
        <CloseIcon />
      </Button>
    </FullPageLayoutHeader>
  );
};

export const FullPageLayoutContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack className={element('container')}>
      <Stack className={element('container__content')}>{children}</Stack>{' '}
    </Stack>
  );
};

export const FullPageLayoutFooter = ({ children }: { children: ReactNode }) => {
  return <Stack className={element('footer')}>{children}</Stack>;
};

import { DropEvent } from 'react-aria';
import { Button as AriaButton, DropZone, FileTrigger } from 'react-aria-components';
import { Plural, Trans } from '@lingui/macro';

import IconCamera from '@/icons/Camera.svg';
import IconUpload from '@/icons/Upload.svg';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './InputFile.css';

const { block } = createBEMClasses('input-file');

export type InputFileProps = {
  inline?: boolean;
  ariaLabel: string;
  type?: 'photo' | 'file';
  onUpload?: (files: File[]) => void;
  allowsMultiple?: boolean;
  variant?: 'default' | 'brand';
  isInvalid?: boolean;
};

const InputFile = ({
  inline,
  ariaLabel,
  type = 'file',
  onUpload,
  allowsMultiple = true,
  variant = 'default',
  isInvalid,
}: InputFileProps) => {
  const { isMobile, isTablet } = useViewPort();

  const handleDropEvent = async (e: DropEvent) => {
    const files = await Promise.all(
      e.items.filter((item) => item.kind === 'file').map(async (item) => await item.getFile())
    );

    if (files.length) {
      onUpload?.(files);
    }
  };

  const handleSelectEvent = (fileList: FileList | null) => {
    const files = Array.from(fileList ?? []);

    if (files.length) {
      onUpload?.(files);
    }
  };

  return (
    <DropZone
      className={block({ variant, inline, invalid: isInvalid })}
      onDrop={(e) => {
        handleDropEvent(e);
      }}
      getDropOperation={(types) =>
        ACCEPTED_FILE_TYPES[type].some((type) => types.has(type)) ? 'copy' : 'cancel'
      }
    >
      <FileTrigger
        onSelect={handleSelectEvent}
        acceptedFileTypes={ACCEPTED_FILE_TYPES[type]}
        allowsMultiple={allowsMultiple}
      >
        <AriaButton aria-label={ariaLabel}>
          {isMobile || isTablet ? <IconCamera /> : <IconUpload />}
          {isMobile || isTablet ? (
            <p className="paragraph-100-regular text-primary">
              <Trans id="design-system.input-file.mobile.label">Take a photo or browse files</Trans>
            </p>
          ) : type === 'file' ? (
            <p className="paragraph-100-regular text-primary">
              <Trans id="design-system.input-file.file.label">
                Drop <Plural value={allowsMultiple ? 5 : 1} one="file" other="files" /> or{' '}
                <span className="paragraph-100-medium">browse computer</span>
              </Trans>
            </p>
          ) : (
            <p className="paragraph-100-regular text-primary">
              <Trans id="design-system.input-file.photo.label">
                Drop <Plural value={allowsMultiple ? 100 : 1} one="photo" other="photos" /> or{' '}
                <span className="paragraph-100-medium">browse computer</span>
              </Trans>
            </p>
          )}
        </AriaButton>
      </FileTrigger>
    </DropZone>
  );
};

const ACCEPTED_FILE_TYPES = {
  photo: ['image/jpeg', 'image/png', 'image/webp', 'image/jfif'],
  file: ['application/pdf', 'image/jpeg', 'image/png', 'image/webp'],
};

export default InputFile;

import { Plural, Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconFile from '@/icons/File.svg';
import { useRequest } from '@/models/request';
import { ShipmentWithRelations } from '@/models/shipment';
import { useCurrentSession } from '@/services/auth';

const ValidatedMessage = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { currentSession } = useCurrentSession();

  const requestId = shipment.articles[0]?.article.requestId;
  const { data: request } = useRequest(requestId);

  const isDestinationWorkshop = currentSession?.workshop?.id === shipment.destinationWorkshopId;
  const canStartAnalysis = isDestinationWorkshop && request?.isInAnalysisStep;

  const totalArticles = shipment.articles.length;
  const articlesWithIssue = shipment.articles.filter((article) => !!article.issue).length;

  return (
    <AlertBar
      type="success"
      size="large"
      title={<Trans id="shipment.validated-message.title">Shipment completed</Trans>}
    >
      <Stack gap="0.5rem" alignItems="flex-start">
        <p>
          <Trans id="shipment.validated-message.text.delivered">
            The package was delivered on time.
          </Trans>{' '}
          {articlesWithIssue ? (
            <>
              {totalArticles === 1 && articlesWithIssue === 1 ? (
                <Trans id="shipment.validated-message.text.issue">
                  A problem has been reported during the verification.
                </Trans>
              ) : (
                <Trans id="shipment.validated-message.text.issue.multiple">
                  A problem has been reported on{' '}
                  <Plural value={articlesWithIssue} one="# item" other="# items" /> during the
                  verification.
                </Trans>
              )}
              <br />
              <Trans id="shipment.validated-message.text.archived">
                The shipment has been automatically archived.
              </Trans>
            </>
          ) : (
            <Trans id="shipment.validated-message.text.no-issues">
              No issues were reported during verification.
            </Trans>
          )}
          {canStartAnalysis && (
            <>
              <br />
              <Trans id="shipment.validated-message.text.start-analysis">
                You can now begin analysing the items.
              </Trans>
            </>
          )}
        </p>
        {canStartAnalysis && (
          <Button variant="secondary" size="medium" to={`/requests/${requestId}`}>
            <IconFile />
            <Trans id="shipment.validated-message.action">Start analysis</Trans>
          </Button>
        )}
      </Stack>
    </AlertBar>
  );
};

export default ValidatedMessage;

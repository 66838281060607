import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNil from 'lodash/isNil';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import { InputMoney } from '@/design_system/InputNumber';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import { CustomActionTypeArticle } from '@/models/actionType';
import { Currency } from '@/utils/number';

const CustomActionDialog = ({
  isOpen,
  onClose,
  onAddAction,
  onEditAction,
  mode,
  showCost,
  showPrice,
  costCurrency,
  priceCurrency,
  initialCustomAction,
  isCostDisabled,
}: {
  isOpen: boolean;
  onClose: () => void;
  onAddAction: (action: CustomActionTypeArticle) => void;
  onEditAction: (action: CustomActionTypeArticle) => void;
  mode: 'need' | 'action' | 'both';
  showCost?: boolean;
  showPrice?: boolean;
  costCurrency?: Currency;
  priceCurrency?: Currency;
  initialCustomAction?: CustomActionTypeArticle;
  isCostDisabled?: boolean;
}) => {
  const { _ } = useLingui();

  return (
    <Dialog
      title={
        initialCustomAction
          ? _(msg({ id: 'article.form.custom-action.dialog.title.edit', message: 'Edit action' }))
          : _(msg({ id: 'article.form.custom-action.dialog.title', message: 'Add a new action' }))
      }
      isOpen={isOpen}
      onOpenChange={onClose}
    >
      <CustomActionForm
        onCancel={onClose}
        onAddAction={onAddAction}
        onEditAction={onEditAction}
        mode={mode}
        showCost={showCost}
        showPrice={showPrice}
        costCurrency={costCurrency}
        priceCurrency={priceCurrency}
        initialCustomAction={initialCustomAction}
        isCostDisabled={isCostDisabled}
      />
    </Dialog>
  );
};

const CustomActionForm = ({
  onCancel,
  onAddAction,
  onEditAction,
  mode,
  showCost,
  showPrice,
  costCurrency = 'EUR',
  priceCurrency = 'EUR',
  initialCustomAction,
  isCostDisabled,
}: {
  onCancel: () => void;
  onAddAction: (action: CustomActionTypeArticle) => void;
  onEditAction: (action: CustomActionTypeArticle) => void;
  mode: 'need' | 'action' | 'both';
  showCost?: boolean;
  showPrice?: boolean;
  costCurrency?: Currency;
  priceCurrency?: Currency;
  initialCustomAction?: CustomActionTypeArticle;
  isCostDisabled?: boolean;
}) => {
  const { _ } = useLingui();

  const [description, setDescription] = useState(initialCustomAction?.description ?? '');
  const [cost, setCost] = useState<number | undefined>(
    initialCustomAction?.costAmountWithoutDiscount ?? undefined
  );
  const [price, setPrice] = useState<number | undefined>(
    initialCustomAction?.priceAmountWithoutDiscount ?? undefined
  );

  const [showErrors, setShowErrors] = useState(false);

  const isDescriptionInvalid = !description;
  const isCostInvalid = isNil(cost) || Number.isNaN(cost);
  const isPriceInvalid = isNil(price) || Number.isNaN(price);

  const onSave = () => {
    if (
      isDescriptionInvalid ||
      (mode !== 'need' && showCost && isCostInvalid) ||
      (mode !== 'need' && showPrice && isPriceInvalid)
    ) {
      setShowErrors(true);
      return;
    }

    const fields = {
      description,
      costAmountWithoutDiscount: mode !== 'need' && showCost ? cost : undefined,
      costCurrency: mode !== 'need' && showCost ? costCurrency : undefined,
      priceAmountWithoutDiscount: mode !== 'need' && showPrice ? price : undefined,
      priceCurrency: mode !== 'need' && showPrice ? priceCurrency : undefined,
    };

    if (initialCustomAction) {
      onEditAction(Object.assign(initialCustomAction, fields));
    } else {
      onAddAction(
        new CustomActionTypeArticle({
          ...fields,
          quantity: 1,
          id: crypto.randomUUID(),
        })
      );
    }
  };

  return (
    <>
      <main>
        <Stack gap="1rem">
          <TextArea
            label={_(
              msg({
                id: 'article.form.custom-action.dialog.description.label',
                message: 'Description',
              })
            )}
            placeholder={_(
              msg({
                id: 'article.form.custom-action.dialog.description.placeholder',
                message: 'Describe the action needed for the item...',
              })
            )}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={
              showErrors && isDescriptionInvalid
                ? _(
                    msg({
                      id: 'article.form.custom-action.dialog.description.error',
                      message: 'Please add an action description',
                    })
                  )
                : undefined
            }
          />
          {mode !== 'need' && showCost && (
            <InputMoney
              label={_(
                msg({
                  id: 'article.form.custom-action.dialog.internal-cost.label',
                  message: 'Internal cost',
                })
              )}
              placeholder={_(
                msg({
                  id: 'article.form.custom-action.dialog.internal-cost.placeholder',
                  message: 'Add a cost for the action...',
                })
              )}
              value={cost}
              onChange={setCost}
              currency={costCurrency}
              error={
                showErrors && isCostInvalid
                  ? _(
                      msg({
                        id: 'article.form.custom-action.dialog.internal-cost.error',
                        message: 'Please add an internal cost',
                      })
                    )
                  : undefined
              }
              isDisabled={isCostDisabled}
            />
          )}
          {mode !== 'need' && showPrice && (
            <InputMoney
              label={_(
                msg({
                  id: 'article.form.custom-action.dialog.client-price.label',
                  message: 'Client price',
                })
              )}
              placeholder={_(
                msg({
                  id: 'article.form.custom-action.dialog.client-price.placeholder',
                  message: 'Add a price for the action...',
                })
              )}
              value={price}
              onChange={setPrice}
              currency={priceCurrency}
              error={
                showErrors && isPriceInvalid
                  ? _(
                      msg({
                        id: 'article.form.custom-action.dialog.client-price.error',
                        message: 'Please add a client price',
                      })
                    )
                  : undefined
              }
            />
          )}
        </Stack>
      </main>
      <footer>
        <Button variant="secondary" onPress={onCancel}>
          <Trans id="article.form.custom-action.dialog.cancel">Cancel</Trans>
        </Button>
        <Button variant="primary" onPress={onSave}>
          {initialCustomAction ? (
            <Trans id="article.form.custom-action.dialog.save">Save</Trans>
          ) : (
            <Trans id="article.form.custom-action.dialog.add">Add a new action</Trans>
          )}
        </Button>
      </footer>
    </>
  );
};

export default CustomActionDialog;

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import Loader from '@/components/Loader';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import { useRequest } from '@/models/request';
import { RequestActivities } from '@/routes/Requests/Request/components/Activities/RequestActivities';
import { RequestArticles } from '@/routes/Requests/Request/components/RequestArticles/RequestArticles';
import { RequestBreadcrumb } from '@/routes/Requests/Request/components/RequestBreadcrumb/RequestBreadcrumb';
import { RequestHeader } from '@/routes/Requests/Request/components/RequestHeader';
import {
  RequestInfoSidePanel,
  RequestInfoTopPanel,
} from '@/routes/Requests/Request/components/RequestInfo';
import ShipmentsTable from '@/routes/Requests/Request/components/ShipmentsTable';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import { useResetSimpleBlocker } from '@/utils/navigation';
import { refreshBrowserTabTitle } from '@/utils/refreshBrowserTabTitle';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import { RequestActionsBar } from './components/RequestActionsBar/RequestActionsBar';
import { RequestComments } from './components/RequestComments';
import {
  RequestLayout,
  RequestLayoutCenter,
  RequestLayoutContent,
  RequestLayoutLeftPart,
  RequestLayoutLeftPartContent,
  RequestLayoutLeftPartFooter,
  RequestLayoutRightPart,
  RequestLayoutTopPart,
} from './components/RequestLayout';
import { RequestLayoutHeader } from './components/RequestLayout';
import { RequestStepActions } from './components/RequestStepActions/RequestStepActions';
import { RequestStepMessage } from './components/RequestStepMessage/RequestStepMessage';

import './Request.css';

const { block, element } = createBEMClasses('request');

export const Request = () => {
  const [commentsRef, scrollCommentsIntoView] = useScrollIntoView<HTMLDivElement>();
  const [containerRef, scrollContainerIntoView] = useScrollIntoView<HTMLDivElement>();

  const { isMobile } = useViewPort();
  const { id } = useParams();
  const { data: request, isLoading } = useRequest(id);

  // Display the request reference in the browser tab title
  useEffect(() => {
    if (!request) {
      return;
    }

    const requestDetails = request.client?.name ?? request.store?.name;
    const requestReference = requestDetails
      ? `${request.reference} - ${requestDetails}`
      : request.reference;

    document.querySelector('meta[name="subtitle"]')?.setAttribute('content', requestReference);
    refreshBrowserTabTitle();

    return () => {
      document.querySelector('meta[name="subtitle"]')?.setAttribute('content', '');
      refreshBrowserTabTitle();
    };
  }, [request]);

  // Clear router blocker state after coming from requests/new
  useResetSimpleBlocker();

  if (isLoading) {
    return (
      <RequestLayoutCenter>
        <Loader style={{ height: '40px', width: '40px' }} />
        <p className="paragraph-100-regular">
          <Trans id="_general.loading">Loading...</Trans>
        </p>
      </RequestLayoutCenter>
    );
  }

  if (!request) {
    return (
      <RequestLayoutCenter>
        <p className="paragraph-100-regular">
          <Trans id="request.unknown">Unknown request</Trans>
        </p>
      </RequestLayoutCenter>
    );
  }

  return (
    <RequestLayout className={block()}>
      <RequestLayoutHeader>
        <RequestHeader request={request} onCommentButtonPress={scrollCommentsIntoView} />
      </RequestLayoutHeader>

      <RequestLayoutContent>
        <RequestLayoutLeftPart>
          <RequestLayoutLeftPartContent>
            {isMobile && (
              <RequestLayoutTopPart>
                <RequestInfoTopPanel request={request} />
              </RequestLayoutTopPart>
            )}
            <Stack className={element('main')} ref={containerRef}>
              <Stack gap="1rem">
                {!isMobile && <RequestBreadcrumb request={request} />}
                <ErrorBoundary>
                  <RequestStepMessage request={request} />
                </ErrorBoundary>
                <RequestStepActions request={request} />

                <ErrorBoundary>
                  <RequestArticles request={request} />
                </ErrorBoundary>
              </Stack>
              {!(
                request.isInDraftStep ||
                request.isInEstimateStep ||
                request.isInValidationStep
              ) && (
                <Stack gap={isMobile ? '0.5rem' : '1rem'}>
                  <h2 className="headline-300-bold">
                    <Trans id="request.related-shipments">Deliveries</Trans>
                  </h2>

                  <ErrorBoundary>
                    <Box
                      removeStyleOnMobile
                      className={element('shipments', { 'no-background': isMobile })}
                      gap="1rem"
                    >
                      <ShipmentsTable request={request} />
                    </Box>
                  </ErrorBoundary>
                </Stack>
              )}
              <Stack gap={isMobile ? undefined : '0.5rem'} ref={commentsRef}>
                <h2 className="headline-300-bold">
                  <Trans id="request.comment.title">Comments</Trans>
                </h2>

                <ErrorBoundary>
                  <Box
                    className={element('comment', { 'no-background': isMobile })}
                    padding="0"
                    removeStyleOnMobile
                  >
                    <RequestComments requestId={request.id} />
                  </Box>
                </ErrorBoundary>
              </Stack>
              <Stack gap={isMobile ? undefined : '0.5rem'}>
                <h2 className="headline-300-bold">
                  <Trans id="request.activity.title">Activity</Trans>
                </h2>

                <ErrorBoundary>
                  <Box
                    className={element('activity', { 'no-background': isMobile })}
                    padding="0"
                    removeStyleOnMobile
                  >
                    <RequestActivities request={request} />
                  </Box>
                </ErrorBoundary>
              </Stack>
            </Stack>
          </RequestLayoutLeftPartContent>

          <RequestLayoutLeftPartFooter>
            <ErrorBoundary>
              <RequestActionsBar request={request} onActionDone={scrollContainerIntoView} />
            </ErrorBoundary>
          </RequestLayoutLeftPartFooter>
        </RequestLayoutLeftPart>

        {!isMobile && (
          <RequestLayoutRightPart>
            <RequestInfoSidePanel request={request} />
          </RequestLayoutRightPart>
        )}
      </RequestLayoutContent>
    </RequestLayout>
  );
};

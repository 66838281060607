import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { type ProductL3 } from '@/api';
import { BasicDropdownItem } from '@/design_system/Dropdown/Dropdown';
import LegacyInputSelect from '@/design_system/InputSelect/LegacyInputSelect';
import { useUpdateArticle } from '@/models/article';
import { useProductOptions } from '@/models/product';
import { type ArticleWithRelations, type RequestWithRelations } from '@/models/request';

export const ProductCategoryL3 = ({
  request,
  article,
  l3Error,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  l3Error?: string;
}) => {
  const { _ } = useLingui();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const { productL3Options } = useProductOptions(request.organization, article.productL1);

  return (
    <LegacyInputSelect
      label={_(msg({ id: 'article.form.material.label', message: 'Material' }))}
      placeholder={_(
        msg({
          id: 'article.form.material.placeholder',
          message: 'Select a material',
        })
      )}
      selectedKey={article.productL3 ?? '-'}
      onSelectionChange={(key) => {
        if (key) {
          updateArticle({
            data: {
              productL3: key as ProductL3,
            },
          });
        }
      }}
      style={{ flex: 1 }}
      isDisabled={!article.productL1 || productL3Options.length === 0}
      error={l3Error}
    >
      {productL3Options.map((productL3Option) => (
        <BasicDropdownItem
          id={productL3Option.id}
          key={productL3Option.id}
          text={productL3Option.text}
        />
      ))}
    </LegacyInputSelect>
  );
};

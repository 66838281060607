import { Trans } from '@lingui/macro';

import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const DispatchArticleTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      title={<Trans id="article.task.dispatch-article.title">Select a workshop</Trans>}
      message={
        <Trans id="article.task.dispatch-article.message">
          Please view the item details and select a workshop to handle the Care & Repair job.
        </Trans>
      }
      article={article}
    />
  );
};

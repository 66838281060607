import Stack from '@/design_system/Stack';
import IconWeight from '@/icons/Weight.svg';
import { ArticleWithRelations } from '@/models/shipment';
import { formatWeight } from '@/utils/number';

const ArticleWeightCell = ({
  article,
  showIcon,
}: {
  article: ArticleWithRelations;
  showIcon?: boolean;
}) => {
  return (
    <Stack row gap="0.5rem" alignItems="center">
      {showIcon && <IconWeight style={{ fontSize: '1.25rem' }} />}
      <p className="paragraph-100-medium">{formatWeight(article.product?.data.weight)}</p>
    </Stack>
  );
};

export default ArticleWeightCell;

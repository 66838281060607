import { useEffect, useState } from 'react';
import { Button as AriaButton } from 'react-aria-components';
import { useSearchParams } from 'react-router-dom';
import { msg, Plural, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ArticlePhoto from '@/components/ArticlePhoto';
import Button from '@/design_system/Button';
import Checkbox from '@/design_system/Checkbox';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import IconStar from '@/icons/Star.svg';
import { useArticleName } from '@/models/article';
import {
  ClientArticleWithRelations,
  ClientRequestWithRelations,
  Feedback,
  FeedbackRating,
  useSubmitFeedback,
} from '@/models/request';
import IconSuccess from '@/routes/Brand/Requests/Request/components/shared/IconSuccess';
import { useCurrentOrganization, useFlags } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import './Completed.css';

const { block, element } = createBEMClasses('client-request-completed');

const Completed = ({ request }: { request: ClientRequestWithRelations }) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();
  const [organization] = useCurrentOrganization();
  const { flags } = useFlags();
  const firstArticleName = useArticleName({ article: request.articles[0] });

  const {
    mutateAsync: submitFeedback,
    isPending: isPendingSubmitFeedback,
    isSuccess: isSuccessSubmitFeedback,
    reset: resetSubmitFeedback,
  } = useSubmitFeedback(request.id);

  const [searchParams] = useSearchParams();
  const globalFeedbackFromEmail = (parseInt(searchParams.get('feedback') ?? '0') || undefined) as
    | FeedbackRating
    | undefined;

  const hasGivenFeedback =
    !!request.feedback?.global &&
    (flags['enable-article-quality-feedback']
      ? !!request.feedback?.articlesFeedback?.length
      : !!request.feedback?.quality) &&
    !!request.feedback?.speed &&
    !!request.feedback?.communication;

  // Immediately save the global feedback from the star clicked on the email, except if feedback has already been submitted
  useEffect(() => {
    if (
      !hasGivenFeedback &&
      globalFeedbackFromEmail &&
      globalFeedbackFromEmail !== request.feedback?.global
    ) {
      submitFeedback({
        global: globalFeedbackFromEmail,
      }).then(() => {
        resetSubmitFeedback();
      });
    }
  }, [
    hasGivenFeedback,
    globalFeedbackFromEmail,
    request.feedback?.global,
    resetSubmitFeedback,
    submitFeedback,
  ]);

  const [feedback, setFeedback] = useState<Feedback>({
    global: globalFeedbackFromEmail,
    acceptContact: false,
  });
  const [showErrors, setShowErrors] = useState(false);

  const [globalRef, globalScrollIntoView] = useScrollIntoView<HTMLDivElement>();
  const [qualityRef, qualityScrollIntoView] = useScrollIntoView<HTMLDivElement>();
  const [speedRef, speedScrollIntoView] = useScrollIntoView<HTMLDivElement>();
  const [communicationRef, communicationScrollIntoView] = useScrollIntoView<HTMLDivElement>();

  const sendResponse = () => {
    setShowErrors(true);

    if (!feedback.global) {
      globalScrollIntoView();
      return;
    }

    if (flags['enable-article-quality-feedback']) {
      if (!feedback.articlesFeedback?.length) {
        qualityScrollIntoView();
        return;
      }
    } else {
      if (!feedback.quality) {
        qualityScrollIntoView();
        return;
      }
    }

    if (!feedback.speed) {
      speedScrollIntoView();
      return;
    }

    if (!feedback.communication) {
      communicationScrollIntoView();
      return;
    }

    submitFeedback(feedback);
  };

  const handleArticleFeedback = (articleId: string, newQualityRating: FeedbackRating) => {
    const newArticleFeedback = { articleId, quality: newQualityRating };
    const currentArticleFeedback = feedback.articlesFeedback?.find(
      (articleFeedback) => articleId === articleFeedback.articleId
    );

    setFeedback({
      ...feedback,
      quality: newQualityRating, // TODO: to remove once we removed enable-article-quality-feedback feature flag
      articlesFeedback:
        // If a feedback already exists for this article, find it and update it
        currentArticleFeedback
          ? feedback.articlesFeedback?.map((articleFeedback) =>
              articleFeedback.articleId === articleId ? newArticleFeedback : articleFeedback
            )
          : // Otherwise, simply add it to articlesFeedback
            [...(feedback.articlesFeedback || []), newArticleFeedback],
    });
  };

  const qualityFeedbackLabel = _(
    msg({
      id: 'client.request.completed.feedback.quality',
      message: 'Quality of repair',
    })
  );

  const qualityFeedbackError =
    showErrors &&
    (flags['enable-article-quality-feedback']
      ? !feedback.articlesFeedback?.length
      : !feedback.quality)
      ? _(
          msg({
            id: 'client.request.completed.feedback.quality.error',
            message: 'Please rate the quality',
          })
        )
      : undefined;

  if (hasGivenFeedback) {
    return (
      <div className={block()}>
        <div className={element('content')}>
          <Stack gap={isMobile ? '0.5rem' : '1rem'} alignItems="center">
            <Stack row={!isMobile} gap="0.5rem" alignItems="center">
              <IconSuccess />
              <h1 className="headline-200-bold headline-300-bold-mobile text-center">
                <Trans id="client.request.completed.success.title">
                  Your response has been sent
                </Trans>
              </h1>
            </Stack>
            <p className="paragraph-50-regular paragraph-100-regular-mobile text-center">
              <Trans id="client.request.completed.success.description">
                Thank you for taking the time to rate our service.
              </Trans>
            </p>
          </Stack>
        </div>
      </div>
    );
  }

  return (
    <div className={block()}>
      <div className={element('content')}>
        <Stack gap={isMobile ? '1.5rem' : '2rem'}>
          <div ref={globalRef}>
            <Stack gap={isMobile ? '0.5rem' : '1rem'}>
              <Stack gap="0.5rem">
                <h1 className="headline-200-bold headline-300-bold-mobile">
                  <Trans id="client.request.completed.title">Your opinion matters to us!</Trans>
                </h1>
                <p className="paragraph-50-regular paragraph-100-regular-mobile">
                  <Trans id="client.request.completed.description">
                    You have recently used our maintenance and repair service for{' '}
                    <Plural
                      value={request.articles.length}
                      one={
                        <span>
                          your item <b className="paragraph-50-medium">{firstArticleName}</b>
                        </span>
                      }
                      other="your # items"
                    />{' '}
                    and we thank you for that. Could you tell us what you thought of this service?
                  </Trans>
                </p>
              </Stack>
              <FeedbackInput
                label={_(
                  msg({
                    id: 'client.request.completed.feedback.global',
                    message: 'Global feedback',
                  })
                )}
                showLabel={false}
                value={feedback.global}
                onChange={(global) => setFeedback({ ...feedback, global })}
                size="large"
                error={
                  showErrors && !feedback.global
                    ? _(
                        msg({
                          id: 'client.request.completed.feedback.global.error',
                          message: 'Please rate the global experience',
                        })
                      )
                    : undefined
                }
              />
            </Stack>
          </div>
          <Stack gap={isMobile ? '1rem' : '1.5rem'}>
            <div ref={qualityRef}>
              {!flags['enable-article-quality-feedback'] ? (
                <FeedbackInput
                  label={qualityFeedbackLabel}
                  value={feedback.quality}
                  onChange={(quality) => setFeedback({ ...feedback, quality })}
                  error={qualityFeedbackError}
                />
              ) : (
                <>
                  {request.articles.length === 1 ? (
                    <FeedbackInput
                      label={qualityFeedbackLabel}
                      value={feedback.articlesFeedback?.[0]?.quality}
                      onChange={(quality) =>
                        setFeedback({
                          ...feedback,
                          quality, // TODO: to remove once we removed enable-article-quality-feedback feature flag
                          articlesFeedback: [
                            {
                              articleId: request.articles?.[0]?.id,
                              quality,
                            },
                          ],
                        })
                      }
                      error={qualityFeedbackError}
                    />
                  ) : (
                    <Stack gap="0.5rem" ariaLabel={qualityFeedbackLabel}>
                      <p className="paragraph-50-medium paragraph-100-medium-mobile">
                        {qualityFeedbackLabel}
                      </p>

                      <Stack gap="1rem">
                        {request.articles.map((article) => (
                          <ArticleQualityFeedbackInput
                            key={article.id}
                            label={qualityFeedbackLabel}
                            article={article}
                            articleQualityRating={
                              feedback.articlesFeedback?.find(
                                (articleFeedback) => article.id === articleFeedback.articleId
                              )?.quality
                            }
                            setArticleFeedback={handleArticleFeedback}
                            error={qualityFeedbackError}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  )}
                </>
              )}
            </div>
            <div ref={speedRef}>
              <FeedbackInput
                label={_(
                  msg({ id: 'client.request.completed.feedback.speed', message: 'Speed of repair' })
                )}
                value={feedback.speed}
                onChange={(speed) => setFeedback({ ...feedback, speed })}
                error={
                  showErrors && !feedback.speed
                    ? _(
                        msg({
                          id: 'client.request.completed.feedback.speed.error',
                          message: 'Please rate the speed',
                        })
                      )
                    : undefined
                }
              />
            </div>
            <div ref={communicationRef}>
              <FeedbackInput
                label={_(
                  msg({
                    id: 'client.request.completed.feedback.communication',
                    message: `Communication with ${organization?.name}`,
                  })
                )}
                value={feedback.communication}
                onChange={(communication) => setFeedback({ ...feedback, communication })}
                error={
                  showErrors && !feedback.communication
                    ? _(
                        msg({
                          id: 'client.request.completed.feedback.communication.error',
                          message: 'Please rate the communication',
                        })
                      )
                    : undefined
                }
              />
            </div>
          </Stack>
          <Stack gap="1rem">
            <Stack gap="0.25rem">
              <p className="paragraph-50-medium paragraph-100-medium-mobile">
                <Trans id="client.request.completed.feedback.comment">Add a comment</Trans>
              </p>
              <TextArea
                ariaLabel={_(
                  msg({ id: 'client.request.completed.feedback.comment', message: 'Add a comment' })
                )}
                placeholder={_(
                  msg({
                    id: 'client.request.completed.feedback.comment.placeholder',
                    message: 'Tell us what you did or did not like...',
                  })
                )}
                value={feedback.comment}
                onChange={(e) => setFeedback({ ...feedback, comment: e.target.value })}
                rows={5}
              />
            </Stack>
            <Checkbox
              isSelected={feedback.acceptContact}
              onChange={(acceptContact) => setFeedback({ ...feedback, acceptContact })}
              size="large"
              variant="brand"
            >
              <p className="paragraph-50-regular">
                <Trans id="client.request.completed.feedback.accept-contact">
                  I agree to being contacted to ask for more details
                </Trans>
              </p>
            </Checkbox>
          </Stack>
        </Stack>
      </div>
      <div className={element('actions')}>
        <Button
          variant="brand"
          size="large"
          onPress={sendResponse}
          isLoading={isPendingSubmitFeedback || isSuccessSubmitFeedback}
        >
          <Trans id="client.request.completed.send">Send my response</Trans>
        </Button>
      </div>
    </div>
  );
};

const FeedbackInput = ({
  label,
  showLabel = true,
  error,
  value,
  onChange,
  size = 'small',
}: {
  label?: string;
  showLabel?: boolean;
  error?: string;
  value?: FeedbackRating;
  onChange: (value: FeedbackRating) => void;
  size?: 'small' | 'large';
}) => {
  return (
    <Stack gap="0.25rem" ariaLabel={label}>
      {!!label && showLabel && (
        <p className="paragraph-50-medium paragraph-100-medium-mobile">{label}</p>
      )}
      <RatingButtons error={error} value={value} onChange={onChange} size={size} />
    </Stack>
  );
};

const ArticleQualityFeedbackInput = ({
  label,
  error,
  article,
  articleQualityRating,
  setArticleFeedback,
}: {
  label: string;
  error?: string;
  article: ClientArticleWithRelations;
  articleQualityRating?: FeedbackRating;
  setArticleFeedback: (articleId: string, newQualityRating: FeedbackRating) => void;
}) => {
  const articleName = useArticleName({ article });

  return (
    <Stack gap="0.25rem">
      <div className="paragraph-100-regular text-secondary">{articleName}</div>

      <Stack row gap="0.75rem">
        <ArticlePhoto photo={article.articlePhoto} showPlaceholder size="small" />

        <Stack gap="0.25rem" ariaLabel={label}>
          <RatingButtons
            error={error}
            value={articleQualityRating}
            onChange={(rating) => setArticleFeedback(article.id, rating)}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const RatingButtons = ({
  error,
  value,
  onChange,
  size = 'small',
}: {
  error?: string;
  value?: FeedbackRating;
  onChange: (value: FeedbackRating) => void;
  size?: 'small' | 'large';
}) => {
  const { _ } = useLingui();
  const [hoveredRating, setHoveredRating] = useState<FeedbackRating>();

  return (
    <>
      <div className={element('feedback', { size })}>
        {([1, 2, 3, 4, 5] as const).map((rating) => (
          <AriaButton
            key={rating}
            onPress={() => onChange(rating)}
            onHoverChange={(isHovering) => {
              if (isHovering) {
                setHoveredRating(rating);
              } else if (!isHovering && hoveredRating === rating) {
                setHoveredRating(undefined);
              }
            }}
          >
            <IconStar filled={rating <= (value ?? 0) || rating <= (hoveredRating ?? 0)} />
            <span className="paragraph-100-medium paragraph-200-regular-mobile">
              {_(RATING_LABELS[rating])}
            </span>
          </AriaButton>
        ))}
      </div>
      {error && <Message type="error">{error}</Message>}
    </>
  );
};

const RATING_LABELS = {
  1: msg({ id: 'request.feedback.1', message: 'Very bad' }),
  2: msg({ id: 'request.feedback.2', message: 'Bad' }),
  3: msg({ id: 'request.feedback.3', message: 'Average' }),
  4: msg({ id: 'request.feedback.4', message: 'Good' }),
  5: msg({ id: 'request.feedback.5', message: 'Very good' }),
};

export default Completed;

import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import {
  ARTICLE_ARCHIVAL_TYPES,
  ARTICLE_CANCELLATION_TYPES,
  ARTICLE_DISPLAYED_STEPS,
} from '@/models/article';
import { RequestWithRelations } from '@/models/request';
import useViewPort from '@/utils/useViewport';

const useRequestDisplayedSteps = (request: RequestWithRelations): string[] => {
  const { _ } = useLingui();

  const articles = request.isManuallyArchived
    ? request.allArticles
    : request.allArticles.filter((article) => article.archivalDetail?.type !== 'manual');

  const isArchived = request.isArchived;
  const archivalDetail = articles[0].archivalDetail;
  const cancellationDetail = articles[0].cancellationDetail;
  const archiveType = ARTICLE_ARCHIVAL_TYPES.find(({ id }) => id === archivalDetail?.type);
  const cancellationType = ARTICLE_CANCELLATION_TYPES.find(
    ({ id }) => id === cancellationDetail?.type
  );
  const displayedSteps = ARTICLE_DISPLAYED_STEPS.filter(({ id }) =>
    articles.find(({ displayedStep }) => displayedStep === id)
  );

  if (isArchived && archivalDetail?.reason === 'export-to-zendesk') {
    return [_(msg({ id: 'article.archival-type.zendesk', message: 'Transferred to Zendesk' }))];
  }

  if (isArchived && cancellationType) {
    return [_(cancellationType.name)];
  }

  if (isArchived && archiveType) {
    if (archiveType.id === 'completed' || !displayedSteps.length) {
      return [_(archiveType.name)];
    } else {
      return displayedSteps.map(
        (displayedStep) => `${_(archiveType.name)}: ${_(displayedStep.name)}`
      );
    }
  }

  return displayedSteps.map((displayedStep) => {
    const hasIssue = articles.some(
      (article) => article.displayedStep === displayedStep.id && article.hasIssue
    );

    if (hasIssue) {
      return `${_(msg({ id: 'article.issue.troubleshooting', message: 'Troubleshooting' }))}: ${_(displayedStep.name)}`;
    } else {
      return _(displayedStep.name);
    }
  });
};

export const RequestDisplayedSteps = ({ request }: { request: RequestWithRelations }) => {
  const displayedSteps = useRequestDisplayedSteps(request);
  const { isMobile } = useViewPort();

  if (isMobile) {
    return (
      <>
        {displayedSteps.map((step, index) => {
          return (
            <Box padding="0.25rem 0.5rem" style={{ flex: 'none' }} key={index}>
              <Stack row gap="0.25rem" alignItems="center" className="paragraph-200-regular">
                {step}
              </Stack>
            </Box>
          );
        })}
      </>
    );
  }

  return (
    <ul style={displayedSteps.length <= 1 ? { listStyle: 'none', padding: 0 } : undefined}>
      {!displayedSteps.length && <li>-</li>}
      {!!displayedSteps.length && displayedSteps.map((step, index) => <li key={index}>{step}</li>)}
    </ul>
  );
};

import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CreationStepConfig } from '@/api';
import { PriceCell } from '@/components/ActionTypeSearchSelect/ActionsTable/Cells';
import { RequestTotalNameCell, RequestTotalPriceCell } from '@/components/ArticlesTableCells';
import RefashionLogo from '@/components/RefashionLogo';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconAdd from '@/icons/Add.svg';
import IconEdit from '@/icons/Edit.svg';
import IconTrash from '@/icons/Trash.svg';
import { useUpdateArticle } from '@/models/article';
import { ClientArticleWithRelations, ClientRequestWithRelations } from '@/models/request';
import { useDatabaseTranslation } from '@/models/translation';
import EstimateDisclaimer from '@/routes/Brand/components/EstimateDisclaimer';
import { ArticleSummary } from '@/routes/Brand/Requests/New/components/Article/components/ArticleSummary/ArticleSummary';
import DeleteArticleModal from '@/routes/Brand/Requests/New/components/DeleteArticleModal';
import EmptyCartModal from '@/routes/Brand/Requests/New/components/EmptyCartModal';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './CartContent.css';

const CartContent = ({
  request,
  onEditArticle,
  onAddActionToArticle,
  onEditActionOnArticle,
  onDeleteArticle,
  onDeleteAllArticles,
  onEditCart,
  collapseHeader = false,
  recap = false,
}: {
  request: ClientRequestWithRelations;
  onAddActionToArticle?: (index: number) => void;
  onEditActionOnArticle?: (
    data:
      | { actionId: string; articleIndex: number }
      | { customActionId: string; articleIndex: number }
  ) => void;
  onEditArticle?: (index: number) => void;
  onDeleteArticle?: (index: number) => void;
  onDeleteAllArticles?: () => void;
  onEditCart?: () => void;
  collapseHeader?: boolean;
  recap?: boolean;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const [showEmptyCartModal, setShowEmptyCartModal] = useState(false);

  const creationConfig = request.articles[0].step?.config as
    | CreationStepConfig['config']
    | undefined;
  const showPrice = creationConfig?.requirePrice ?? false;
  const isStoreOnlyRequest = request.store && !request.client;

  return (
    <Stack gap="1rem">
      <Stack
        row
        gap="0.5rem"
        justifyContent={collapseHeader ? 'flex-start' : 'space-between'}
        alignItems="center"
      >
        <Stack row gap="0.25rem" alignItems="baseline">
          <h3 className="headline-300-bold paragraph-50-medium-mobile">
            {recap ? (
              <Trans id="client.new.cart.title.recap">Cart recap</Trans>
            ) : (
              <Trans id="client.new.cart.title">Cart</Trans>
            )}
          </h3>
          {!recap && <span className="paragraph-100-medium">({request.actionQuantity})</span>}
        </Stack>
        {!recap && (
          <Stack row gap="0.5rem">
            <Button
              iconOnly
              variant="secondary-brand"
              size="small"
              tooltip={_(msg({ id: 'client.new.cart.delete-cart', message: 'Delete cart' }))}
              ariaLabel={_(msg({ id: 'client.new.cart.delete-cart', message: 'Delete cart' }))}
              onPress={() => setShowEmptyCartModal(true)}
            >
              <IconTrash />
            </Button>
            <EmptyCartModal
              isOpen={showEmptyCartModal}
              onOpenChange={setShowEmptyCartModal}
              onDelete={onDeleteAllArticles}
              request={request}
            />
          </Stack>
        )}
        {recap && (
          <Button
            iconOnly
            variant="secondary-brand"
            size="medium"
            tooltip={_(msg({ id: 'client.new.cart.edit-cart', message: 'Edit cart' }))}
            ariaLabel={_(msg({ id: 'client.new.cart.edit-cart', message: 'Edit cart' }))}
            onPress={onEditCart}
          >
            <IconEdit />
          </Button>
        )}
      </Stack>
      {request.articles.map((article, index) => (
        <Article
          key={article.id}
          article={article}
          onAddAction={() => onAddActionToArticle?.(index)}
          onEdit={() => onEditArticle?.(index)}
          onEditAction={(ids) => {
            onEditActionOnArticle?.({
              articleIndex: index,
              ...ids,
            });
          }}
          onDelete={() => {
            if (request.articles.length === 1) {
              onDeleteAllArticles?.();
            } else {
              onDeleteArticle?.(index);
            }
          }}
          request={request}
          recap={recap}
          showPrice={showPrice}
        />
      ))}
      {showPrice && (
        <>
          <Stack
            row
            gap="0.5rem"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="nowrap"
          >
            <div style={{ flex: 1, marginTop: 'auto' }}>
              <RequestTotalNameCell request={request} />
            </div>{' '}
            <RequestTotalPriceCell request={request} />
          </Stack>
        </>
      )}
      {!isStoreOnlyRequest && !(recap && isMobile) && <EstimateDisclaimer />}
    </Stack>
  );
};

const { block } = createBEMClasses('cart-content-article');

const Article = ({
  article,
  onAddAction,
  onEdit,
  onEditAction,
  onDelete,
  request,
  recap,
  showPrice,
}: {
  article: ClientArticleWithRelations;
  onAddAction: () => void;
  onEdit: () => void;
  onEditAction: (data: { actionId: string } | { customActionId: string }) => void;
  onDelete: () => void;
  request: ClientRequestWithRelations;
  recap?: boolean;
  showPrice?: boolean;
}) => {
  const { _ } = useLingui();
  const { _db } = useDatabaseTranslation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { mutateAsync: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const removeAction = (id: string) => {
    const newActions = article.currentActions.filter((action) => action.id !== id);

    if (newActions.length === 0 && article.currentCustomActions.length === 0) {
      setShowDeleteModal(true);
      return;
    }

    updateArticle({
      data: {
        actions: newActions.map(({ id, actionTypeOrganization, quantity }) => ({
          id,
          actionTypeOrganizationId: actionTypeOrganization.id,
          quantity,
        })),
      },
      optimisticData: {
        currentActions: newActions,
      },
    });
  };

  const removeCustomAction = (id: string) => {
    const newCustomActions = article.currentCustomActions.filter((action) => action.id !== id);

    if (newCustomActions.length === 0 && article.currentActions.length === 0) {
      setShowDeleteModal(true);
      return;
    }

    updateArticle({
      data: {
        customActions: newCustomActions.map((customAction) => ({
          id: customAction.id,
          description: customAction.description,
          quantity: customAction.quantity,
        })),
      },
      optimisticData: {
        currentCustomActions: newCustomActions,
      },
    });
  };

  const articleActions = article.currentActions.filter((action) => action.quantity > 0);
  const articleCustomActions = article.currentCustomActions.filter((action) => action.quantity > 0);
  const totalActions = articleActions.length + articleCustomActions.length;

  return (
    <Stack className={block()} gap="0.75rem">
      <Stack row gap="0.5rem" alignItems="center" flexWrap="nowrap" justifyContent="space-between">
        <ArticleSummary article={article} size="small" />
        {!recap && (
          <Stack row gap="0.5rem">
            <Button
              iconOnly
              size="small"
              variant="secondary-brand"
              onPress={onAddAction}
              tooltip={_(
                msg({ id: 'client.new.cart.article.add-action', message: 'Add other services' })
              )}
              ariaLabel={_(
                msg({ id: 'client.new.cart.article.add-action', message: 'Add other services' })
              )}
            >
              <IconAdd />
            </Button>
            <Button
              iconOnly
              size="small"
              variant="secondary-brand"
              onPress={onEdit}
              tooltip={_(msg({ id: 'client.new.cart.article.edit', message: 'Edit item' }))}
              ariaLabel={_(msg({ id: 'client.new.cart.article.edit', message: 'Edit item' }))}
            >
              <IconEdit />
            </Button>
            <Button
              iconOnly
              size="small"
              variant="secondary-brand"
              onPress={() => setShowDeleteModal(true)}
              tooltip={_(msg({ id: 'client.new.cart.article.delete', message: 'Delete item' }))}
              ariaLabel={_(msg({ id: 'client.new.cart.article.delete', message: 'Delete item' }))}
            >
              <IconTrash />
            </Button>
          </Stack>
        )}
      </Stack>
      <hr />
      {totalActions > 0 ? (
        <>
          {articleActions.map((action) => (
            <Stack key={action.id} gap="0.25rem">
              <Stack gap="0.5rem" row>
                <p className="paragraph-100-regular" style={{ flex: 1 }}>
                  {_db(action.actionTypeOrganization.actionType.needName)}{' '}
                  {/* eslint-disable-line lingui/no-unlocalized-strings */}
                  (x{action.quantity})
                  {showPrice && !!action.refashionStatus && (
                    <>
                      {' '}
                      <RefashionLogo status={action.refashionStatus} />
                    </>
                  )}
                </p>
                {showPrice && <PriceCell action={action} />}
              </Stack>
              {!recap && (
                <ActionButtons
                  onEdit={() => onEditAction({ actionId: action.id })}
                  onRemove={() => removeAction(action.id)}
                />
              )}
            </Stack>
          ))}
          {articleCustomActions.map((customAction) => (
            <Stack key={customAction.id} gap="0.25rem">
              <Stack row alignItems="center" flexWrap="nowrap">
                <p className="paragraph-100-regular" style={{ flex: 1 }}>
                  {customAction.description}
                  <span>
                    {' '}
                    {/* eslint-disable-line lingui/no-unlocalized-strings */}
                    (x{customAction.quantity})
                  </span>
                </p>
                {showPrice && (
                  <div style={{ minWidth: 60, textAlign: 'right' }}>
                    {/* The custom action will never have a price during the diag form */}
                    <p className="paragraph-100-medium">
                      <Trans id="client.new.cart.need-assessment">Need assessment</Trans>
                    </p>
                  </div>
                )}
              </Stack>
              {!recap && (
                <ActionButtons
                  onEdit={() => onEditAction({ customActionId: customAction.id })}
                  onRemove={() => removeCustomAction(customAction.id)}
                />
              )}
            </Stack>
          ))}
        </>
      ) : (
        <p className="paragraph-100-regular" style={{ paddingLeft: 8 }}>
          <Trans id="client.new.cart.no-actions">Please add a service or delete this item.</Trans>
        </p>
      )}
      <DeleteArticleModal
        lastArticle={request.articles.length === 1}
        articleId={article.id}
        isOpen={showDeleteModal}
        onOpenChange={setShowDeleteModal}
        onDelete={onDelete}
      />
    </Stack>
  );
};

const ActionButtons = ({ onEdit, onRemove }: { onEdit: () => void; onRemove: () => void }) => {
  const { _ } = useLingui();

  return (
    <Stack row gap="0.5rem">
      <Button
        iconOnly
        size="small"
        variant="secondary-brand"
        onPress={onEdit}
        tooltip={_(msg({ id: 'client.new.cart.action.edit', message: 'Edit service' }))}
        ariaLabel={_(msg({ id: 'client.new.cart.action.edit', message: 'Edit service' }))}
      >
        <IconEdit />
      </Button>
      <Button
        iconOnly
        size="small"
        variant="secondary-brand"
        onPress={onRemove}
        tooltip={_(msg({ id: 'client.new.cart.action.delete', message: 'Delete service' }))}
        ariaLabel={_(msg({ id: 'client.new.cart.action.delete', message: 'Delete service' }))}
      >
        <IconTrash />
      </Button>
    </Stack>
  );
};

export default CartContent;

import config from '@/config';
import { Organization } from '@/models/organization';
import { useClientToken, useCurrentOrganization, useStoreToken } from '@/services/auth';

type ContentType = 'application/json' | 'multipart/form-data';

export const useFetch = <
  T extends {
    response: any;
    body: any;
    query: any;
  } = any,
>() => {
  const [currentOrganization] = useCurrentOrganization();
  const clientToken = useClientToken();
  const storeToken = useStoreToken();

  return fetchApi<T['response'], T['body'], T['query']>(
    currentOrganization,
    clientToken,
    storeToken
  );
};

const fetchApi =
  <
    Response = any,
    Body = any,
    Params =
      | Record<string, string | string[] | ReadonlyArray<string> | number | boolean | undefined>
      | undefined,
  >(
    currentOrganization: Organization | null,
    clientToken?: string,
    storeToken?: string
  ) =>
  <ResponseOverride = Response>(
    path: string,
    params?: Params,
    options: Omit<RequestInit, 'body'> & { body?: Body } = {},
    contentType: ContentType = 'application/json'
  ) => {
    const searchParams = [];

    for (const [key, value] of Object.entries(params || {})) {
      if (Array.isArray(value)) {
        for (const item of value) {
          searchParams.push(`${key}[]=${item}`);
        }
      } else if (typeof value === 'boolean') {
        if (value === false) {
          searchParams.push(`${key}=0`);
        } else {
          searchParams.push(`${key}=1`);
        }
      } else if (value !== undefined) {
        searchParams.push(`${key}=${value?.toString()}`);
      }
    }

    if (options?.body && contentType === 'application/json') {
      options.body = JSON.stringify(options.body) as any;
      options.headers = {
        'Content-Type': contentType,
        ...options.headers,
      };
    } else if (options?.body && contentType === 'multipart/form-data') {
      const formData = new FormData();

      for (const key in options.body) {
        formData.append(key, options.body[key] as string | Blob);
      }

      options.body = formData as any;
    }

    if (currentOrganization) {
      options.headers = {
        'prolong-organization': currentOrganization.slug,
        ...options.headers,
      };
    }

    if (clientToken) {
      options.headers = {
        'client-token': clientToken,
        ...options.headers,
      };
    }

    if (storeToken) {
      options.headers = {
        'store-token': storeToken,
        ...options.headers,
      };
    }

    const url =
      `${config.apiUrl}${!path.startsWith('/') ? '/' : ''}${path}` +
      (searchParams.length ? `?${searchParams.join('&')}` : '');

    return window
      .fetch(url, {
        credentials: 'include',
        ...options,
      } as RequestInit)
      .then(async (res) => {
        const textBody = await res.text();
        let body;

        try {
          body = JSON.parse(textBody);
        } catch {
          body = textBody;
        }

        if (!res.ok) {
          throw new Error(`${body?.message ?? body}`);
        }

        return body as ResponseOverride;
      });
  };

export type PaginatedResults<T extends string> = {
  meta: {
    limit: number;
    offset: number;
    count: number;
  };
} & Record<T, any[]>;

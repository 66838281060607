import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import { useDispatch } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';

export const DispatchArticleAction = ({
  article,
  onActionDone,
  onCheckErrors,
}: {
  article: ArticleWithRelations;
  onActionDone: () => void;
  onCheckErrors: () => boolean;
}) => {
  const {
    mutateAsync: dispatchArticle,
    isPending: isPendingDispatch,
    isSuccess: isSuccessDispatch,
  } = useDispatch({ articleId: article.id });

  const handleDispatch = async () => {
    const hasErrors = onCheckErrors();

    if (!hasErrors) {
      await dispatchArticle();
      onActionDone();
    }
  };

  return (
    <Button
      variant="primary"
      size="medium"
      onPress={handleDispatch}
      isLoading={isPendingDispatch || isSuccessDispatch}
    >
      <Trans id="article.actions.dispatch_article.send">Send to the workshop for validation</Trans>
    </Button>
  );
};

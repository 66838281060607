import Select, { type ClearIndicatorProps, GroupBase, Props as SelectProps } from 'react-select';
import { Trans } from '@lingui/macro';

import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import IconChevron from '@/icons/Chevron.svg';
import IconCross from '@/icons/Cross.svg';
import { createBEMClasses } from '@/utils/classname';

import './InputSelect.css';

const { block, element } = createBEMClasses('input-select');

export type InputSelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = { error?: string } & SelectProps<Option, IsMulti, Group>;

export const InputSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  placeholder,
  error,
  ...props
}: InputSelectProps<Option, IsMulti, Group>) => {
  return (
    <>
      <Select
        {...props}
        menuPosition="fixed"
        classNamePrefix={block()}
        aria-invalid={!!error}
        placeholder={<span className="paragraph-100-regular text-disabled">{placeholder}</span>}
        components={{
          DropdownIndicator: () => (
            <div className={element('chevron')}>
              <IconChevron down />
            </div>
          ),
          ClearIndicator: ({
            innerProps: { ref, ...restInnerProps },
          }: ClearIndicatorProps<Option, IsMulti, Group>) => (
            <div {...restInnerProps} ref={ref} className={element('clear')}>
              <IconCross />
            </div>
          ),
        }}
        unstyled
        noOptionsMessage={() => (
          <Stack
            className="paragraph-100-regular"
            padding="0.5rem"
            style={{ background: 'var(--color-neutral-0)' }}
          >
            <Trans id="input-select.no-results">No results</Trans>
          </Stack>
        )}
        loadingMessage={() => (
          <span
            className="paragraph-100-regular"
            style={{ padding: '0.5rem', background: 'var(--color-neutral-0)' }}
          >
            <Trans id="input-select.loading">Loading...</Trans>
          </span>
        )}
      />

      {error && <Message type="error">{error}</Message>}
    </>
  );
};

import { useEffect, useState } from 'react';
import { msg, Plural, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';

import Loader from '@/components/Loader';
import Button from '@/design_system/Button';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import { BrandWrapper, BrandWrapperCenter, BrandWrapperFooter } from '@/layouts/Brand';
import { useArticleName } from '@/models/article';
import {
  ClientRequestWithRelations,
  useAcceptFreeFinalQuote,
  usePayment,
  useRefuseRequest,
  useRequest,
  useStartPayment,
} from '@/models/request';
import Refusal from '@/routes/Brand/Requests/Request/components/ClientRequestView/components/Refusal';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';
import { PickupPointInfo } from '@/routes/Brand/Requests/Request/components/shared/PickupPointInfo/PickupPointInfo';
import { useStripe } from '@/services/stripe';

import './Payment.css';

const Payment = ({ request }: { request: ClientRequestWithRelations }) => {
  const { _ } = useLingui();

  const { data: payment, isLoading: isLoadingPayment } = usePayment({ requestId: request.id });
  const { mutateAsync: startPayment, isPending: isPendingStartPayment } = useStartPayment();
  const { mutateAsync: acceptFreeFinalQuote, isPending: isPendingAcceptFreeFinalQuote } =
    useAcceptFreeFinalQuote();
  const [paymentError, setPaymentError] = useState<string>();
  const [clientSecret, setClientSecret] = useState<string>();
  const isReturnFromCheckout = new URLSearchParams(window.location.search).get('return');

  if (isLoadingPayment) {
    return (
      <BrandWrapper>
        <Trans id="_general.loading">Loading...</Trans>
      </BrandWrapper>
    );
  }

  if (
    isReturnFromCheckout !== null ||
    payment?.status !== 'unstarted' ||
    clientSecret === 'cs_mock_secret'
  ) {
    return (
      <BrandWrapper>
        <PaymentStatus request={request} />
      </BrandWrapper>
    );
  }

  if (clientSecret) {
    return (
      <BrandWrapper>
        <PaymentCheckout clientSecret={clientSecret} />
      </BrandWrapper>
    );
  }

  return (
    <BrandWrapper>
      <PaymentWelcome
        request={request}
        onAccept={() => {
          let promise;

          if (request.finalPriceAmount === 0) {
            promise = acceptFreeFinalQuote({ requestId: request.id });
          } else {
            promise = startPayment({ requestId: request.id }).then(({ clientSecret }) =>
              setClientSecret(clientSecret)
            );
          }

          promise.catch((err) => {
            console.error(err);
            setPaymentError(
              (err.message as string) ??
                _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
            );
          });
        }}
        isLoading={isPendingStartPayment || isPendingAcceptFreeFinalQuote}
        error={paymentError}
      />
    </BrandWrapper>
  );
};

const PaymentWelcome = ({
  request,
  onAccept,
  isLoading,
  error,
}: {
  request: ClientRequestWithRelations;
  onAccept: () => void;
  isLoading?: boolean;
  error?: string;
}) => {
  const [showRefuseView, setShowRefuseView] = useState(false);

  const firstArticleName = useArticleName({ article: request.articles[0], type: 'short' });

  const isFree = request.finalPriceAmount === 0;

  const { mutate: refuseRequest, isPending, isSuccess } = useRefuseRequest();

  const isInternalPayment = request.articles.find(
    (article) => article.step?.step === 'payment' && article.step?.config.externalPayment === false
  );

  if (showRefuseView) {
    return (
      <Refusal
        onCancelRefuse={() => setShowRefuseView(false)}
        isPending={isPending}
        isSuccess={isSuccess}
        onRefuseRequest={(cancellationDetail) => {
          refuseRequest({
            id: request.id,
            cancellationDetail: {
              reason: cancellationDetail.reason!,
              otherReason: cancellationDetail.otherReason,
            },
          });
        }}
      />
    );
  }

  return (
    <>
      <BrandWrapperCenter>
        <Stack gap="1.5rem">
          <ClientStepper step="payment" request={request} />
          <Stack gap="0.5rem" style={{ marginBottom: '1rem' }}>
            <h1 className="headline-200-bold headline-300-bold-mobile">
              <Trans id="client.request.payment.welcome.title">Care & Repair quote</Trans>
            </h1>
            <p className="paragraph-50-regular paragraph-100-regular-mobile">
              <Trans id="client.request.payment.welcome.description">
                Our experts have confirmed the quote for the repair of{' '}
                <Plural
                  value={request.articles.length}
                  one={
                    <span>
                      your{' '}
                      <span className="paragraph-50-medium paragraph-100-medium-mobile">
                        {firstArticleName}
                      </span>
                    </span>
                  }
                  other={
                    <span>
                      your{' '}
                      <span className="paragraph-50-medium paragraph-100-medium-mobile">
                        # items
                      </span>
                    </span>
                  }
                />
                .
              </Trans>{' '}
              {!isFree && (
                <Trans id="client.request.payment.welcome.please">
                  Please now proceed to payment in order to begin the care & repair services.
                </Trans>
              )}
              {isFree && (
                <b>
                  <Trans id="client.request.payment.welcome.free">
                    And good news, no payment will be required on your side!
                  </Trans>
                </b>
              )}
            </p>
          </Stack>
          <Stack gap="1rem">
            <PickupPointInfo request={request} />
            <ClientArticlesTable request={request} showPrice showArticleComment />
            <ClientInfo request={request} />
          </Stack>
          {error && (
            <Message type="error" style={{ marginTop: '2rem' }}>
              <b>{error}</b>
            </Message>
          )}
        </Stack>
      </BrandWrapperCenter>
      {isInternalPayment && (
        <BrandWrapperFooter>
          <Button
            variant="secondary-brand"
            size="large"
            onPress={() => setShowRefuseView(true)}
            disabled={isLoading}
          >
            <Trans id="client.request.payment.welcome.refuse">Refuse and cancel</Trans>
          </Button>
          <Button
            variant="brand"
            size="large"
            onPress={onAccept}
            disabled={isLoading}
            isLoading={isLoading}
          >
            {!isFree && (
              <Trans id="client.request.payment.welcome.accept">Proceed to payment</Trans>
            )}
            {isFree && (
              <Trans id="client.request.payment.welcome.accept.free">Validate service</Trans>
            )}
          </Button>
        </BrandWrapperFooter>
      )}
    </>
  );
};

export const PaymentCheckout = ({ clientSecret }: { clientSecret: string }) => {
  const stripe = useStripe();

  if (!stripe) {
    return (
      <Loading>
        <Trans id="_general.loading">Loading...</Trans>
      </Loading>
    );
  }

  return (
    <Stack padding="16px" style={{ flex: 1, backgroundColor: '#f7f8f7' }}>
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripe} options={{ clientSecret }}>
          <EmbeddedCheckout className="stripe-checkout" />
        </EmbeddedCheckoutProvider>
      )}
    </Stack>
  );
};

const PaymentStatus = ({ request }: { request: ClientRequestWithRelations }) => {
  const { data: payment } = usePayment(
    {
      requestId: request.id,
    },
    {
      refetchInterval: ({ state: { data } }) => (data?.status !== 'succeeded' ? 1000 : false),
    }
  );
  const { refetch } = useRequest(request.id);

  useEffect(() => {
    if (payment?.status) {
      refetch();
    }
  }, [payment?.status, refetch]);

  return (
    <Loading>
      <Trans id="client.request.payment.in-progress">
        Your payment is being processed, please wait...
      </Trans>
    </Loading>
  );
};

const Loading = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack
      style={{ height: '100%' }}
      gap="1rem"
      alignItems="center"
      flexWrap="nowrap"
      justifyContent="center"
    >
      <Loader style={{ height: '40px', width: '40px' }} />
      <p className="paragraph-100-regular">{children}</p>
    </Stack>
  );
};

export default Payment;

import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Stack from '@/design_system/Stack';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import { type TNotificationOfType } from '@/models/notification';

export const CommentAddedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'comment_added'>;
}) => {
  const { _ } = useLingui();

  const { comment, request, shipment } = notification;
  const entityReference = request?.reference ?? shipment?.reference;

  const notificationLabel = request
    ? _(
        msg({
          id: 'notification.comment-added.label.request',
          message: `New comment on request ${entityReference}`,
        })
      )
    : shipment
      ? _(
          msg({
            id: 'notification.comment-added.label.shipment',
            message: `New comment on shipment ${entityReference}`,
          })
        )
      : _(
          msg({
            id: 'notification.comment-added.label.generic',
            message: `New comment`,
          })
        );

  return (
    <BaseNotification
      user={comment.creator}
      notification={notification}
      notificationLabel={notificationLabel}
      notificationHeader={
        <Trans id="notification.comment-added">
          <span className="paragraph-100-medium">{comment.creator.name}</span> added a new comment
        </Trans>
      }
      notificationContent={
        <Stack
          className="bg-neutral-100"
          style={{
            padding: '8px 12px', // eslint-disable-line lingui/no-unlocalized-strings
            borderRadius: '0.5rem',
          }}
        >
          <p
            className="paragraph-100-regular"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            {comment.content}
          </p>
        </Stack>
      }
    />
  );
};

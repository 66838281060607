import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import FileUpload from '@/components/FileUpload';
import { useMedia } from '@/models/medium';
import { ArticleWithRelations } from '@/models/request';

const ProofOfPurchase = ({ article, error }: { article: ArticleWithRelations; error?: string }) => {
  const { _ } = useLingui();

  const { data: { media } = { media: [] } } = useMedia({
    articleId: article?.id,
    types: ['proof-of-purchase'],
  });

  return (
    <FileUpload
      uploadData={{
        type: 'proof-of-purchase',
        articleId: article.id,
      }}
      ariaLabel={_(
        msg({
          id: 'article.form.proof-of-purchase.aria-label',
          message: 'Upload proof of purchase',
        })
      )}
      type="file"
      label={_(msg({ id: 'article.form.proof-of-purchase.label', message: 'Proof of purchase' }))}
      media={media}
      error={error}
      deleteWithApi
    />
  );
};

export default ProofOfPurchase;

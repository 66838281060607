import { Trans } from '@lingui/macro';

import Stack from '@/design_system/Stack';
import { ArticleWithRelations } from '@/models/request';
import { formatCurrency } from '@/utils/number';

const ArticleWorkshopCell = ({
  article,
  showCost,
}: {
  article: ArticleWithRelations;
  showCost?: boolean;
}) => {
  if (!article.workshop) {
    return <p className="paragraph-100-medium">-</p>;
  }

  return (
    <Stack style={{ minWidth: 0 }}>
      <p className="paragraph-100-medium text-ellipsis" title={article.workshop.name}>
        {article.workshop.name}
      </p>
      <p className="paragraph-200-regular text-secondary text-ellipsis">
        {article.workshop.external && (
          <>
            <Trans id="workshop.external">External</Trans>
            {article.workshop.address?.city && ' • '}
          </>
        )}
        {article.workshop.address?.city}
        {showCost && article.currentCostAmount != null && (
          <> • {formatCurrency(article.currentCostAmount, article.currentCostCurrency)}</>
        )}
      </p>
    </Stack>
  );
};

export default ArticleWorkshopCell;

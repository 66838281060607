import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNil from 'lodash/isNil';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconInfo from '@/icons/Info.svg';
import {
  ActionTypeOrganizationArticle,
  CustomActionTypeArticle,
  PackActionTypeOrganizationArticle,
} from '@/models/actionType';
import {
  ArticleWithRelations,
  ClientArticleWithRelations,
  ClientRequestWithRelations,
  RequestWithRelations,
} from '@/models/request';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

const BaseMoneyCell = ({
  originalAmount,
  originalCurrency,
  finalAmount,
  finalCurrency,
  label,
  discounts,
}: {
  originalAmount?: number | null;
  originalCurrency?: string | null;
  finalAmount: number | null;
  finalCurrency: string | null;
  label?: string;
  discounts?: {
    type: 'refashion' | 'discount';
    amount: number;
  }[];
}) => {
  const { isMobile } = useViewPort();

  const showOriginalAmount =
    !isNil(originalAmount) &&
    !isNil(finalAmount) &&
    (finalAmount !== originalAmount || finalCurrency !== originalCurrency);

  if (isMobile) {
    return (
      <Stack alignItems="flex-end" row gap="0.25rem">
        {label && <p className="paragraph-200-regular text-secondary">{label}</p>}
        {!isNil(finalAmount) ? (
          <p className="paragraph-100-regular text-primary">
            {formatCurrency(finalAmount, finalCurrency)}
            {showOriginalAmount && (
              <s role="deletion" className="paragraph-400-regular text-disabled">
                {formatCurrency(originalAmount, originalCurrency)}
              </s>
            )}
            {!!discounts?.length && (
              <>
                <DiscountDetail discounts={discounts} currency={finalCurrency} />{' '}
              </>
            )}
          </p>
        ) : (
          <p className="paragraph-200-regular">-</p>
        )}
      </Stack>
    );
  }

  return (
    <Stack alignItems="flex-end">
      {label && <p className="paragraph-200-regular text-secondary">{label}</p>}
      {showOriginalAmount && (
        <s role="deletion" className="paragraph-200-regular text-disabled">
          {formatCurrency(originalAmount, originalCurrency)}
        </s>
      )}
      {!isNil(finalAmount) ? (
        <p
          className="paragraph-100-medium"
          style={{ order: isMobile ? 0 : 1, whiteSpace: 'nowrap' }}
        >
          {!!discounts?.length && (
            <>
              <DiscountDetail discounts={discounts} currency={finalCurrency} />{' '}
            </>
          )}
          {formatCurrency(finalAmount, finalCurrency)}
        </p>
      ) : (
        <p className="paragraph-100-medium">-</p>
      )}
    </Stack>
  );
};

const getDiscounts = (
  actions: ActionTypeOrganizationArticle[],
  customActions: CustomActionTypeArticle[],
  packActions: PackActionTypeOrganizationArticle[]
) => {
  const discounts = [];

  const discount =
    actions.reduce(
      (acc, action) =>
        acc +
        ((action.priceAmountWithoutDiscount ?? 0) -
          (action.priceAmountWithoutRefashionBonus ?? 0)) *
          action.quantity,
      0
    ) +
    customActions.reduce(
      (acc, action) =>
        acc +
        ((action.priceAmountWithoutDiscount ?? 0) - (action.priceAmount ?? 0)) * action.quantity,
      0
    ) +
    packActions.reduce(
      (acc, action) =>
        acc +
        ((action.priceAmountWithoutDiscount ?? 0) -
          (action.priceAmountWithoutRefashionBonus ?? 0)) *
          action.quantity,
      0
    );

  const refashion =
    actions.reduce(
      (acc, action) =>
        acc +
        ((action.priceAmountWithoutRefashionBonus ?? 0) - (action.priceAmount ?? 0)) *
          action.quantity,
      0
    ) +
    packActions.reduce(
      (acc, action) =>
        acc +
        ((action.priceAmountWithoutRefashionBonus ?? 0) - (action.priceAmount ?? 0)) *
          action.quantity,
      0
    );

  if (discount) {
    discounts.push({
      type: 'discount' as const,
      amount: discount,
    });
  }

  if (refashion) {
    discounts.push({
      type: 'refashion' as const,
      amount: refashion,
    });
  }

  return {
    discounts,
    discount,
    refashion,
  };
};

const DiscountDetail = ({
  discounts,
  currency,
}: {
  discounts?: {
    type: 'refashion' | 'discount';
    amount: number;
  }[];
  currency?: string | null;
}) => {
  const { _ } = useLingui();

  const labelForType = {
    refashion: _(msg({ id: 'article-money-cell.refashion', message: 'Bonus amount' })),
    discount: _(msg({ id: 'article-money-cell.discount', message: 'Discount amount' })),
  };

  return (
    <Tooltip
      content={
        <Stack>
          {discounts?.map((discount) => (
            <p key={discount.type}>
              {labelForType[discount.type]}: {formatCurrency(-discount.amount, currency)}
            </p>
          ))}
        </Stack>
      }
    >
      <Button variant="style-less" style={{ verticalAlign: 'text-top' }}>
        <IconInfo style={{ fontSize: '1rem' }} />
      </Button>
    </Tooltip>
  );
};

export const ArticleCostCell = ({
  article,
  label,
}: {
  article: ArticleWithRelations;
  label?: string;
}) => {
  return (
    <BaseMoneyCell
      originalAmount={article.currentCostAmountWithoutDiscount}
      originalCurrency={article.currentCostCurrency}
      finalAmount={article.currentCostAmount}
      finalCurrency={article.currentCostCurrency}
      label={label}
    />
  );
};

export const ArticlePriceCell = ({
  article,
  label,
  clientMode,
}: {
  article: ArticleWithRelations | ClientArticleWithRelations;
  label?: string;
  clientMode?: boolean;
}) => {
  const { discounts } = clientMode
    ? getDiscounts(article.clientActions, article.clientCustomActions, article.clientPackActions)
    : getDiscounts(
        article.currentActions,
        article.currentCustomActions,
        article.currentPackActions
      );

  return (
    <BaseMoneyCell
      originalAmount={
        clientMode
          ? article.clientPriceAmountWithoutDiscount
          : article.currentPriceAmountWithoutDiscount
      }
      originalCurrency={clientMode ? article.clientPriceCurrency : article.currentPriceCurrency}
      finalAmount={clientMode ? article.clientPriceAmount : article.currentPriceAmount}
      finalCurrency={clientMode ? article.clientPriceCurrency : article.currentPriceCurrency}
      discounts={discounts}
      label={label}
    />
  );
};

export const RequestTotalNameCell = ({
  request,
  clientMode,
}: {
  request: RequestWithRelations | ClientRequestWithRelations;
  clientMode?: boolean;
}) => {
  const { isMobile } = useViewPort();

  const { discount, refashion } = clientMode
    ? getDiscounts(
        request.articles.flatMap((article) => article.clientActions),
        request.articles.flatMap((article) => article.clientCustomActions),
        request.articles.flatMap((article) => article.clientPackActions)
      )
    : getDiscounts(
        request.articles.flatMap((article) => article.currentActions),
        request.articles.flatMap((article) => article.currentCustomActions),
        request.articles.flatMap((article) => article.currentPackActions)
      );

  return (
    <div>
      <p className="paragraph-100-regular text-secondary">
        {isMobile ? (
          <Trans id="client.articles.table.footer.subtotal">Subtotal</Trans>
        ) : discount > 0 && refashion > 0 ? (
          <Trans id="client.articles.table.footer.subtotal-bonus-and-discount">
            Subtotal with discount and Refashion bonus
          </Trans>
        ) : discount > 0 ? (
          <Trans id="client.articles.table.footer.subtotal-discount">Subtotal with discount</Trans>
        ) : refashion > 0 ? (
          <Trans id="client.articles.table.footer.subtotal-bonus">
            Subtotal with Refashion bonus
          </Trans>
        ) : (
          <Trans id="client.articles.table.footer.subtotal">Subtotal</Trans>
        )}
      </p>
      <p className="paragraph-100-regular text-secondary">
        <Trans id="client.articles.table.total.shipment.label">Shipping fees</Trans>
      </p>
      <p className="paragraph-50-medium">
        <Trans id="client.articles.table.footer.total-simple">Total</Trans>
      </p>
    </div>
  );
};

export const RequestTotalCostCell = ({
  request,
  label,
}: {
  request: RequestWithRelations;
  label?: string;
}) => {
  return (
    <Stack alignItems="flex-end">
      {label && <p className="paragraph-200-regular text-secondary">{label}</p>}
      <p className="paragraph-100-regular" style={{ visibility: 'hidden' }}>
        -
      </p>
      <p className="paragraph-100-regular" style={{ visibility: 'hidden' }}>
        -
      </p>
      <p className="paragraph-50-medium">
        {formatCurrency(request.currentCostAmount, request.currentCostCurrency)}
      </p>
    </Stack>
  );
};

export const RequestTotalPriceCell = ({
  request,
  clientMode,
  label,
}: {
  request: RequestWithRelations | ClientRequestWithRelations;
  clientMode?: boolean;
  label?: string;
}) => {
  const { isMobile } = useViewPort();

  const { discounts } = clientMode
    ? getDiscounts(
        request.articles.flatMap((article) => article.clientActions),
        request.articles.flatMap((article) => article.clientCustomActions),
        request.articles.flatMap((article) => article.clientPackActions)
      )
    : getDiscounts(
        request.articles.flatMap((article) => article.currentActions),
        request.articles.flatMap((article) => article.currentCustomActions),
        request.articles.flatMap((article) => article.currentPackActions)
      );

  const amount = clientMode ? request.clientPriceAmount : request.currentPriceAmount;
  const currency = clientMode ? request.clientPriceCurrency : request.currentPriceCurrency;

  return (
    <Stack alignItems="flex-end">
      {label && <p className="paragraph-200-regular text-secondary">{label}</p>}
      <Stack row={!isMobile} gap={isMobile ? 0 : '0.25rem'} alignItems="center">
        <p className="paragraph-100-regular text-secondary" style={{ whiteSpace: 'nowrap' }}>
          {amount !== null && !!discounts?.length && (
            <>
              <DiscountDetail discounts={discounts} currency={currency} />{' '}
            </>
          )}
          {formatCurrency(amount, currency)}
        </p>
      </Stack>
      <p className="paragraph-100-regular text-secondary">
        <Trans id="client.articles.table.total.shipment.offered">Offered</Trans>
      </p>
      <p className="paragraph-50-medium">{formatCurrency(amount, currency)}</p>
    </Stack>
  );
};

export const RequestPrice = ({
  request,
  clientMode,
  label,
}: {
  request: RequestWithRelations | ClientRequestWithRelations;
  label?: string;
  clientMode?: boolean;
}) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();

  const { discount, refashion, discounts } = clientMode
    ? getDiscounts(
        request.articles.flatMap((article) => article.clientActions),
        request.articles.flatMap((article) => article.clientCustomActions),
        request.articles.flatMap((article) => article.clientPackActions)
      )
    : getDiscounts(
        request.articles.flatMap((article) => article.currentActions),
        request.articles.flatMap((article) => article.currentCustomActions),
        request.articles.flatMap((article) => article.currentPackActions)
      );

  const amount = clientMode ? request.clientPriceAmount : request.currentPriceAmount;
  const currency = clientMode ? request.clientPriceCurrency : request.currentPriceCurrency;

  return (
    <Stack
      className="bg-neutral-300"
      gap="0.5rem"
      padding="1rem"
      style={{ borderRadius: '0.5rem' }}
    >
      {label && <span className="paragraph-100-medium text-primary">{label}</span>}

      <Stack>
        <Stack gap="2px">
          <Stack
            row
            flexWrap="nowrap"
            justifyContent="space-between"
            className="paragraph-200-regular text-secondary"
          >
            <p>
              {isMobile ? (
                <Trans id="client.articles.table.footer.subtotal">Subtotal</Trans>
              ) : discount > 0 && refashion > 0 ? (
                <Trans id="client.articles.table.footer.subtotal-bonus-and-discount">
                  Subtotal with discount and Refashion bonus
                </Trans>
              ) : discount > 0 ? (
                <Trans id="client.articles.table.footer.subtotal-discount">
                  Subtotal with discount
                </Trans>
              ) : refashion > 0 ? (
                <Trans id="client.articles.table.footer.subtotal-bonus">
                  Subtotal with Refashion bonus
                </Trans>
              ) : (
                <Trans id="client.articles.table.footer.subtotal">Subtotal</Trans>
              )}
            </p>
            <p style={{ whiteSpace: 'nowrap' }}>
              {amount !== null && !!discounts?.length && (
                <>
                  <DiscountDetail discounts={discounts} currency={currency} />{' '}
                </>
              )}
              {formatCurrency(amount, currency)}
            </p>
          </Stack>
          <Stack
            row
            flexWrap="nowrap"
            justifyContent="space-between"
            className="paragraph-200-regular text-secondary"
          >
            <p>
              <Trans id="client.articles.table.total.shipment.label">Shipping fees</Trans>
            </p>
            <p>
              <Trans id="client.articles.table.total.shipment.offered">Offered</Trans>
            </p>
          </Stack>
        </Stack>
        <Stack
          row
          flexWrap="nowrap"
          justifyContent="space-between"
          className="paragraph-100-medium text-primary"
          ariaLabel={_(
            msg({ id: 'client.articles.table.footer.total-price', message: 'Total price' })
          )}
        >
          <p>
            <Trans id="client.articles.table.footer.total-simple">Total</Trans>
          </p>
          <p>{formatCurrency(amount, currency)}</p>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const RequestCost = ({
  request,
  label,
}: {
  request: RequestWithRelations | ClientRequestWithRelations;
  label?: string;
}) => {
  const { _ } = useLingui();

  return (
    <Stack
      className="bg-neutral-200"
      gap="0.5rem"
      padding="1rem"
      style={{ borderRadius: '0.5rem' }}
    >
      {label && <span className="paragraph-100-medium text-primary">{label}</span>}

      <Stack
        row
        flexWrap="nowrap"
        justifyContent="space-between"
        className="paragraph-100-medium text-primary"
        ariaLabel={_(msg({ id: 'client.articles.table.footer.total-cost', message: 'Total cost' }))}
      >
        <p>
          <Trans id="client.articles.table.footer.total-simple">Total</Trans>
        </p>
        <p> {formatCurrency(request.currentCostAmount, request.currentCostCurrency)}</p>
      </Stack>
    </Stack>
  );
};

export default BaseMoneyCell;

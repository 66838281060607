import { Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import IconTruck from '@/icons/Truck.svg';
import { RequestWithRelations } from '@/models/request';
import { ClientRefusalMessage } from '@/routes/Requests/Request/components/RequestStepMessage/components/ClientRefusalMessage/ClientRefusalMessage';
import { useCurrentSession } from '@/services/auth';

const TransitMessage = ({ request }: { request: RequestWithRelations }) => {
  if (request.isInPendingClientDeliveryStep) {
    return <StoreToClientMessage request={request} />;
  }

  return null;
};

export default TransitMessage;

const StoreToClientMessage = ({ request }: { request: RequestWithRelations }) => {
  const { currentSession } = useCurrentSession();
  const isUserRelatedToStore = currentSession?.stores?.some(
    (store) => store.id === request.storeId
  );

  // For now all the article are archived at the same time with the same details so we can just take the first one
  const article = request.articles[0];
  if (request.isCancelled && article.cancellationDetail) {
    return (
      <ClientRefusalMessage cancellationDetail={article.cancellationDetail}>
        {isUserRelatedToStore && (
          <p>
            <Trans id="requests.pending-client-delivery-message.archive-text">
              Once the client picks up their items, you can archive the request by clicking on the
              button below.
            </Trans>
          </p>
        )}
      </ClientRefusalMessage>
    );
  }

  return (
    <AlertBar
      type="info"
      size="large"
      icon={<IconTruck />}
      title={
        <Trans id="requests.pending-client-delivery-message.title">Waiting for client pickup</Trans>
      }
    >
      <p>
        <Trans id="requests.pending-client-delivery-message.text">
          The package has arrived at the store and the client has been notified that they can pick
          up the items.
        </Trans>
      </p>
    </AlertBar>
  );
};

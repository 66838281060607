import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import RadioGroup from '@/design_system/RadioGroup';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import IconArrow from '@/icons/Arrow.svg';
import { BrandWrapperCenter } from '@/layouts/Brand';
import { ARTICLE_CANCELLATION_REASONS, ArticleCancellationDetail } from '@/models/article';
import useViewPort from '@/utils/useViewport';

const Refusal = ({
  onCancelRefuse,
  onRefuseRequest,
  isPending,
  isSuccess,
}: {
  onCancelRefuse: () => void;
  onRefuseRequest: (cancellationDetail: {
    reason?: ArticleCancellationDetail['reason'];
    otherReason?: string;
  }) => void;
  isPending: boolean;
  isSuccess: boolean;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const [reason, setReason] = useState<ArticleCancellationDetail['reason']>();
  const [other, setOther] = useState('');

  const refuseDisabled = !reason || (reason === 'other' && !other);

  return (
    <BrandWrapperCenter width="medium">
      <Stack gap={isMobile ? '1rem' : '1.5rem'}>
        <p className="headline-300-bold">
          <Trans id="client.request.refusal.title">Why do you want to cancel?</Trans>
        </p>
        <Stack gap="0.5rem">
          <RadioGroup
            options={ARTICLE_CANCELLATION_REASONS.map(({ id, label }) => ({
              value: id,
              children: _(label),
            }))}
            value={reason}
            onChange={(value) => setReason(value as ArticleCancellationDetail['reason'])}
            boxed
            row={false}
          />
          {reason === 'other' && (
            <TextArea
              ariaLabel={_(
                msg({
                  id: 'client.request.refusal.other.placeholder',
                  message: 'Specify the reason...',
                })
              )}
              placeholder={_(
                msg({
                  id: 'client.request.refusal.other.placeholder',
                  message: 'Specify the reason...',
                })
              )}
              rows={2}
              value={other}
              onChange={(evt) => setOther(evt.target.value)}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          )}
        </Stack>
        <Stack gap="1rem" alignItems="stretch">
          <Button
            variant="danger"
            size="large"
            disabled={refuseDisabled}
            onPress={() => {
              if (!refuseDisabled) {
                onRefuseRequest({
                  reason,
                  otherReason: other || undefined,
                });
              }
            }}
            isLoading={isPending || isSuccess}
          >
            <Trans id="client.request.refusal.refuse">Refuse and cancel</Trans>
          </Button>
          <Button variant="secondary-brand" size="large" onPress={onCancelRefuse}>
            <IconArrow left />
            <Trans id="client.request.refusal.accept">I want to accept</Trans>
          </Button>
        </Stack>
      </Stack>
    </BrandWrapperCenter>
  );
};

export default Refusal;

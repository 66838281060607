import { Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import IconTruck from '@/icons/Truck.svg';

const InTransitMessage = () => {
  return (
    <AlertBar
      type="info"
      size="large"
      icon={<IconTruck />}
      title={
        <Trans id="requests.transit.in-transit-message.title">The delivery is in progress</Trans>
      }
    >
      <p>
        <Trans id="requests.transit.in-transit-message.text">
          The carrier has taken charge of the package.
        </Trans>
      </p>
    </AlertBar>
  );
};

export default InTransitMessage;

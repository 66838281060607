import { ReactNode } from 'react';

import Stack from '@/design_system/Stack';
import { RequestWithRelations } from '@/models/request';
import CompleteDeliveryPendingClientAction from '@/routes/Requests/Request/components/RequestActionsBar/components/CompleteDeliveryPendingClientAction';
import { useCurrentSession } from '@/services/auth';
import useViewport from '@/utils/useViewport';

import { ValidatePaymentAction } from './components/ValidatePaymentAction/ValidatePaymentAction';

export const RequestActionsBar = ({
  request,
  onActionDone,
}: {
  request: RequestWithRelations;
  onActionDone: () => void;
}) => {
  const { currentSession } = useCurrentSession();
  const { isMobile } = useViewport();

  const isResponsibleStore =
    request.storeId && currentSession?.stores.map((store) => store.id).includes(request.storeId);

  if (request.isArchived) {
    return null;
  }

  let actions: ReactNode = null;

  if (request.isInPendingClientDeliveryStep && isResponsibleStore) {
    actions = (
      <CompleteDeliveryPendingClientAction request={request} onPickupCompleted={onActionDone} />
    );
  }

  if (request.isInPaymentStep) {
    actions = <ValidatePaymentAction request={request} onPaymentValidate={onActionDone} />;
  }

  if (!actions) {
    return null;
  }

  return (
    <Stack
      padding={isMobile ? '1rem' : '1rem 2rem'}
      row={!isMobile}
      gap="1rem"
      alignItems={isMobile ? 'stretch' : 'center'}
      justifyContent={isMobile ? 'center' : 'flex-end'}
      style={{ flex: '1 1 auto' }} // eslint-disable-line lingui/no-unlocalized-strings
    >
      {actions}
    </Stack>
  );
};

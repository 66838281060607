import { useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import IconAdd from '@/icons/Add.svg';
import { RequestWithRelations, useCreateDraftRequestArticle } from '@/models/request';
import useViewPort from '@/utils/useViewport';

import { getArticleDetailsError, getArticleServicesError } from './ArticleForm';
import ArticlesTable, { EstimateWarning } from './ArticlesTable';

const Articles = ({ request, error }: { request: RequestWithRelations; error?: string }) => {
  const { isMobile } = useViewPort();
  const navigate = useNavigate();

  const hasArticles = request.articles.length > 0;

  const openArticle = (id: string, newArticle?: boolean) => {
    navigate(`/requests/new/${request.id}/articles/${id}`, {
      state: { from: 'request', isNew: newArticle, skipRouterBlocker: true },
      replace: true,
    });
  };

  const {
    mutateAsync: createArticle,
    isPending: isPendingCreateArticle,
    isSuccess: isSuccessCreateArticle,
    reset: resetCreateArticle,
  } = useCreateDraftRequestArticle(request.id);

  const handleCreateArticle = async () => {
    const { id } = await createArticle();

    openArticle(id, true);
    resetCreateArticle();
  };

  return (
    <Box
      padding={isMobile ? '16px' : '16px 24px'}
      gap="1rem"
      alignItems={hasArticles ? 'stretch' : 'flex-start'}
      flexWrap="nowrap"
    >
      <Stack
        gap="1rem"
        row={hasArticles}
        justifyContent={hasArticles ? 'space-between' : 'flex-start'}
        alignItems={hasArticles ? 'center' : 'flex-start'}
      >
        <h2 className="headline-300-bold">
          <Trans id="requests.new.articles.title">Items</Trans>
        </h2>
        {request.draft && (
          <Stack gap="0.5rem">
            {!hasArticles && (
              <p className="paragraph-100-regular text-secondary">
                <Trans id="requests.new.articles.no-article">No items added yet</Trans>
              </p>
            )}
            <Button
              variant="secondary"
              size="medium"
              onPress={handleCreateArticle}
              isLoading={isPendingCreateArticle || isSuccessCreateArticle}
              dataTrackingId="requests.new.articles.add"
            >
              <IconAdd />
              <Trans id="requests.new.articles.add">Add an item</Trans>
            </Button>
          </Stack>
        )}
      </Stack>
      {!hasArticles && !!error && <Message type="error">{error}</Message>}
      {hasArticles && (
        <>
          <ArticlesTable request={request} onOpenArticle={openArticle} error={error} />
          {/* For now it is not clear in which cases the estimate warning should be shown, so we hide it until new info */}
          {false && <EstimateWarning />}
        </>
      )}
    </Box>
  );
};

export default Articles;

export const useArticlesError = (request: RequestWithRelations) => {
  const { _ } = useLingui();

  const hasNoArticles = request.articles.length === 0;

  const hasIncompleteDraftArticles = request.articles.some(
    (article) => getArticleDetailsError({ article }).hasError
  );

  const hasIncompleteSavedArticles = request.articles.some(
    (article) =>
      getArticleDetailsError({ article }).hasError ||
      getArticleServicesError({ article, photo: article.articlePhoto }).hasError
  );

  const hasArticlesAssignedToDifferentWorkshops =
    [...new Set(request.articles.map((article) => article.workshopId))].length > 1;

  return {
    noArticlesError: hasNoArticles
      ? _(
          msg({
            id: 'requests.new.articles.error.no-article',
            message: 'Please add at least one item',
          })
        )
      : undefined,
    incompleteDraftArticlesError: hasIncompleteDraftArticles
      ? _(
          msg({
            id: 'requests.new.articles.error.incomplete-article',
            message: 'Please complete all the items',
          })
        )
      : undefined,
    incompleteSavedArticlesError: hasIncompleteSavedArticles
      ? _(
          msg({
            id: 'requests.new.articles.error.incomplete-article',
            message: 'Please complete all the items',
          })
        )
      : undefined,
    multipleWorkshopsError: hasArticlesAssignedToDifferentWorkshops
      ? _(
          msg({
            id: 'request.new.articles.error.multiple-workshops',
            message: 'Please assign all the items to the same workshop',
          })
        )
      : undefined,
  };
};

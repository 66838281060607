import { Trans } from '@lingui/macro';

import config from '@/config';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconQrCode from '@/icons/QrCode.svg';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';

import { BaseMessage } from './BaseMessage';

export const EstimationStepMessage = ({
  article,
  request,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
}) => {
  const { currentSession } = useCurrentSession();

  const isResponsibleStore =
    request.storeId && currentSession?.stores.map((store) => store.id).includes(request.storeId);

  return (
    <BaseMessage
      title={<Trans id="article.step.estimate">Waiting for estimate completion</Trans>}
      message={
        isResponsibleStore ? (
          <Stack gap="0.5rem" alignItems="flex-start">
            <p>
              <Trans id="article.estimation.waiting-message.text">
                In the meantime, please make sure to print out and attach the QR code to the item.
              </Trans>
            </p>
            <Stack row gap="8px">
              <Button
                variant="secondary"
                size="medium"
                href={
                  config.apiUrl +
                  `/articles/${article.id}/recap?organization=${request.organization.slug}`
                }
                target="_blank"
                className="text-no-wrap"
              >
                <IconQrCode />
                <Trans id="article.estimation.waiting-message.action.print-item-sheet">
                  Print item sheet
                </Trans>
              </Button>
            </Stack>
          </Stack>
        ) : null
      }
      article={article}
    />
  );
};

import { Trans } from '@lingui/macro';

import IllustrationError from '@/components/IllustrationError';
import Loader from '@/components/Loader';
import Stack from '@/design_system/Stack';
import { ErrorBoundary } from '@/services/sentry';

interface Props {
  isLoading: boolean;
  isError: boolean;
  children: React.ReactNode;
}

/**
 *
 * @returns Display a loader or an error message instead of a table of content
 */
export const TableQueryWrapper = ({ isLoading, isError, children }: Props) => {
  if (!isLoading && !isError) {
    return <>{children}</>;
  }

  return (
    <Stack alignItems="center" justifyContent="center" style={{ flex: 1 }}>
      <ErrorBoundary>
        {isLoading && (
          <Loader
            style={{
              fontSize: '40px',
            }}
          />
        )}

        {isError && (
          <Stack gap="16px" alignItems="center">
            <IllustrationError />
            <p className="paragraph-100-regular text-center">
              <Trans id="generic-error">An error occurred, please try again</Trans>
            </p>
          </Stack>
        )}
      </ErrorBoundary>
    </Stack>
  );
};

import { ClientRequestWithRelations } from '@/models/request';
import UnhandledStep from '@/routes/Brand/Requests/Request/components/shared/UnhandledStep';

import Analysis from './components/Analysis';
import { Archived } from './components/Archived';
import { Completed } from './components/Completed';
import { Delivery } from './components/Delivery';
import Estimation from './components/Estimation';
import Payment from './components/Payment';
import Repair from './components/Repair';
import Transit from './components/Transit';

const StoreRequestView = ({ request }: { request: ClientRequestWithRelations }) => {
  const firstArticle = request.articles[0];

  if (
    (request.isArchived &&
      (request.isManuallyArchived || request.isAutomaticallyArchived) &&
      !request.isArchivedAndExportedToZendesk) ||
    // We want to display the Completed component (the screen with ratings) if the request is
    // archived and completed, (but not if it is archived, completed and cancelled 🤯)
    (request.isArchived && request.isCancelled && request.isInCompletedStep)
  ) {
    return <Archived request={request} />;
  }

  if (
    request.isInDraftStep ||
    request.isInEstimateStep ||
    request.isInDispatchStep ||
    request.isInValidationStep ||
    request.isArchivedAndExportedToZendesk
  ) {
    return <Estimation request={request} />;
  }

  if (
    firstArticle.step?.step === 'transit' &&
    (firstArticle.step.config.originType === 'client' ||
      firstArticle.step.config.originType === 'store') &&
    firstArticle.step.config.destinationType === 'workshop'
  ) {
    return <Transit request={request} />;
  }

  const isRequestInTransitAfterRequalificationRefused = request.articles.some(
    (article) => !!article.requalificationRefusedAt
  );

  if (request.isInAnalysisStep || isRequestInTransitAfterRequalificationRefused) {
    return <Analysis request={request} />;
  }

  if (request.isInPaymentStep) {
    return <Payment request={request} />;
  }

  if (request.isInRepairStep) {
    return <Repair request={request} />;
  }

  if (request.isInTransitStep) {
    return <Delivery request={request} />;
  }

  if (request.isInCompletedStep) {
    return <Completed request={request} />;
  }

  // If we reach this point, it means that the request is in an unknown step
  // We are still showing something as not to scare the user
  // But we are also sending an error to Sentry
  return <UnhandledStep request={request} />;
};

export default StoreRequestView;

import { ReactNode } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { StatusDueDate } from '@/components/DueDate';
import AlertBar from '@/design_system/AlertBar';
import Stack from '@/design_system/Stack';
import { ARTICLE_CANCELLATION_REASONS } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';
import useViewPort from '@/utils/useViewport';

export const BaseMessage = ({
  title,
  message,
  article,
}: {
  title?: ReactNode;
  message?: ReactNode;
  article: ArticleWithRelations;
}) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();

  if (article.cancellationDetail) {
    const cancelledTitle =
      article.cancellationDetail.statusWhenCancelled === 'pending-quote-acceptation'
        ? _(
            msg({
              id: 'article.step-task-message.cancelled.title.estimate',
              message: 'The estimate has been refused by the client',
            })
          )
        : _(
            msg({
              id: 'article.step-task-message.cancelled.title.payment',
              message: 'The final quote has been refused by the client',
            })
          );

    return (
      <AlertBar type="error" size="large" hideIcon title={cancelledTitle}>
        <div>
          <Trans id="article.step-task-message.cancelled.text">Client refusal reason:</Trans> &quot;
          {article.cancellationDetail.reason === 'other' ? (
            <Trans id="article.step-task-message.cancelled.text.other">
              Other - {article.cancellationDetail.otherReason}
            </Trans>
          ) : (
            _(
              ARTICLE_CANCELLATION_REASONS.find(
                ({ id }) => article.cancellationDetail!.reason === id
              )!.label
            )
          )}
          &quot;
        </div>
        {!!message && <div>{message}</div>}
      </AlertBar>
    );
  }

  return (
    <AlertBar
      type={article.task ? 'warning' : 'info'}
      size="large"
      hideIcon
      title={
        <Stack
          row
          gap="1rem"
          justifyContent={isMobile ? 'space-between' : 'flex-start'}
          alignItems="stretch"
          flexWrap="nowrap"
        >
          <p className="paragraph-100-medium">{title}</p>
          {!isMobile && (
            <div
              style={{ width: '1px', height: '1rem', marginTop: '0.25rem' }}
              className="bg-neutral-400"
            />
          )}
          {!!article.statusDueAtDate && (
            <StatusDueDate date={article.statusDueAtDate} displayDayMonthOnly variant="row" />
          )}
        </Stack>
      }
    >
      {message}
    </AlertBar>
  );
};

import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ActionTypeSearchSelect from '@/components/ActionTypeSearchSelect';
import { useUpdateArticle } from '@/models/article';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { useWorkshops } from '@/models/workshop';
import { useCurrentSession } from '@/services/auth';

const ActionTypes = ({
  request,
  article,
  mode,
  isDisabled,
  isEditActionDisabled,
  isEditActionCostDisabled,
  showPrice: showPriceProp,
  showCost,
  showResponsibility: showResponsibilityProp,
  error,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  mode: 'need' | 'action' | 'both';
  isDisabled?: boolean;
  isEditActionDisabled?: boolean;
  isEditActionCostDisabled?: boolean;
  showPrice?: boolean;
  showCost?: boolean;
  showResponsibility?: boolean;
  error?: string;
}) => {
  const { currentSession } = useCurrentSession();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const { data: { workshops: [internalWorkshop] } = { workshops: [] } } = useWorkshops(
    {
      internal: true,
      limit: 1,
    },
    {
      enabled: !article.workshopId,
    }
  );

  const showPrice =
    showPriceProp && request.requestorType !== 'store' && !currentSession?.workshop?.external;
  const allowWarranty =
    article.steps?.find(
      (step) =>
        ['done', 'in-progress'].includes(step.status) &&
        !!step.config &&
        'allowWarranty' in step.config &&
        step.config.allowWarranty
    ) ?? false;
  const allowCustomActions =
    (article.step &&
      'allowCustomActions' in article.step.config &&
      article.step.config.allowCustomActions) ??
    false;

  const showResponsibility =
    showResponsibilityProp && allowWarranty && !currentSession?.workshop?.external;

  const showOldActions = article.step?.step === 'analysis' && article.requalifiedAt;

  const isEstimated =
    article.estimatedActions.length > 0 ||
    article.estimatedCustomActions.length > 0 ||
    article.estimatedPackActions.length > 0;

  return (
    <div>
      <ActionTypeSearchSelect
        mode={mode}
        showCost={showCost}
        showPrice={showPrice}
        showResponsibility={showResponsibility}
        allowCustomActions={allowCustomActions}
        articleActions={article.currentActions}
        articleCustomActions={article.currentCustomActions}
        articlePackActions={article.currentPackActions}
        oldArticleActions={
          showOldActions
            ? isEstimated
              ? article.estimatedActions
              : article.initialActions
            : undefined
        }
        oldArticleCustomActions={
          showOldActions
            ? isEstimated
              ? article.estimatedCustomActions
              : article.initialCustomActions
            : undefined
        }
        oldArticlePackActions={
          showOldActions
            ? isEstimated
              ? article.estimatedPackActions
              : article.initialPackActions
            : undefined
        }
        onChangeActions={(actions) => {
          updateArticle({
            data: {
              actions: actions.map((action) => ({
                id: action.id,
                actionTypeOrganizationId: action.actionTypeOrganization.id,
                quantity: action.quantity,
                brandResponsibility: showResponsibility ? action.brandResponsibility : undefined,
              })),
            },
            optimisticData: {
              currentActions: actions,
            },
          });
        }}
        onChangeCustomActions={(customActions) => {
          updateArticle({
            data: {
              customActions: customActions.map((customAction) => ({
                id: customAction.id,
                description: customAction.description,
                quantity: customAction.quantity,
                brandResponsibility: showResponsibility
                  ? customAction.brandResponsibility
                  : undefined,
                costAmountWithoutDiscount: customAction.costAmountWithoutDiscount ?? undefined,
                priceAmountWithoutDiscount: customAction.priceAmountWithoutDiscount ?? undefined,
              })),
            },
            optimisticData: {
              currentCustomActions: customActions,
            },
          });
        }}
        onChangePackActions={(packActions) => {
          updateArticle({
            data: {
              packActions: packActions.map((packAction) => ({
                id: packAction.id,
                packActionTypeOrganizationId: packAction.packActionTypeOrganization.id,
                quantity: packAction.quantity,
                brandResponsibility: showResponsibility
                  ? packAction.brandResponsibility
                  : undefined,
              })),
            },
          });
        }}
        isDisabled={isDisabled}
        isEditActionDisabled={isEditActionDisabled}
        isEditActionCostDisabled={isEditActionCostDisabled}
        requestId={request.id}
        customActionPriceCurrency={request.defaultPriceCurrency}
        customActionCostCurrency={
          article.workshop?.currency ?? internalWorkshop?.currency ?? undefined
        }
        productL1={article.productL1 ?? undefined}
        productL2={article.productL2 ?? undefined}
        productL3={article.productL3 ?? undefined}
        error={error}
      />
    </div>
  );
};

export const useActionsError = (article?: ArticleWithRelations, request?: RequestWithRelations) => {
  const { _ } = useLingui();

  if (!article || !request) {
    return undefined;
  }

  if (!article.hasActions) {
    return _(
      msg({
        id: 'article.estimate.actions.error',
        message: 'Please add at least one care & repair action',
      })
    );
  }

  if (
    request.requestorType === 'store' &&
    article.currentCustomActions.some(
      (customAction) => customAction.costAmountWithoutDiscount === null
    )
  ) {
    return _(
      msg({
        id: 'article.estimate.actions.error.custom-actions-no-cost',
        message: 'Please provide cost details for the custom actions',
      })
    );
  }

  if (
    request.requestorType !== 'store' &&
    article.currentCustomActions.some(
      (customAction) =>
        customAction.costAmountWithoutDiscount === null ||
        customAction.priceAmountWithoutDiscount === null
    )
  ) {
    return _(
      msg({
        id: 'article.estimate.actions.error.custom-actions',
        message: 'Please provide cost and price details for the custom actions',
      })
    );
  }

  return undefined;
};

export default ActionTypes;

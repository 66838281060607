import {
  DialogTrigger,
  OverlayArrow,
  Popover,
  Tooltip as AriaTooltip,
  TooltipTrigger,
} from 'react-aria-components';

import { createBEMClasses } from '@/utils/classname';
import { checkIfTouchDevice } from '@/utils/useViewport';

import './Tooltip.css';

const { block, element } = createBEMClasses('tooltip');

const Tooltip = ({
  content,
  children,
  placement = 'top',
  className,
  hideOnTouchDevice,
}: {
  content: React.ReactNode;
  children: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  hideOnTouchDevice?: boolean;
}) => {
  const isTouchDevice = checkIfTouchDevice();

  if (!hideOnTouchDevice && isTouchDevice) {
    return (
      <DialogTrigger>
        {children}
        <Popover className={block(undefined, className)} placement={placement} offset={8}>
          <OverlayArrow className={element('overlay')}>
            <svg width={8} height={8}>
              <path d="M0 0,L4 4,L8 0" />
            </svg>
          </OverlayArrow>
          {content}
        </Popover>
      </DialogTrigger>
    );
  }

  return (
    <TooltipTrigger delay={0} closeDelay={0}>
      {children}
      <AriaTooltip className={block(undefined, className)} placement={placement} offset={8}>
        <OverlayArrow className={element('overlay')}>
          <svg width={8} height={8}>
            <path d="M0 0,L4 4,L8 0" />
          </svg>
        </OverlayArrow>
        {content}
      </AriaTooltip>
    </TooltipTrigger>
  );
};

export default Tooltip;

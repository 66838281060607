import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ArticleTask } from '@/api';
import { TableQueryWrapper } from '@/components/TableQueryWrapper';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import InputSearch from '@/design_system/InputSearch';
import Pagination from '@/design_system/Pagination';
import Stack from '@/design_system/Stack';
import Table from '@/design_system/Table';
import { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import { RequestorType, RequestWithRelations, useRequests } from '@/models/request';
import { UserWithRelations, useUsers } from '@/models/user';
import { RequestsEmptyState } from '@/routes/Requests/components/RequestsEmptyState';
import { RequestsNoResults } from '@/routes/Requests/components/RequestsNoResults';
import { RequestsFiltersButton } from '@/routes/Requests/Request/components/RequestsFiltersButton';
import { RequestsMobileFiltersDrawer } from '@/routes/Requests/Request/components/RequestsMobileFiltersDrawer';
import { hasFilterBeenUpdated } from '@/routes/Requests/Request/helpers/hasFilterBeenUpdated';
import { SupervisorPicker } from '@/routes/Requests/Tabs/components/SupervisorPicker/SupervisorPicker';
import { useCurrentOrganization, useCurrentSession } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import { formatDate } from '@/utils/date';
import useViewPort from '@/utils/useViewport';

import { ArticlePhotoGroup } from './components/ArticlePhotoGroup';
import { RequestDueAtCell } from './components/RequestDueAtCell';
import { RequestStatusDueAtCell } from './components/RequestStatusDueAtCell';
import { SupervisorSelect } from './components/SupervisorSelect/SupervisorSelect';
import {
  ArticlesCardItem,
  RequestNameCardItem,
  RequestNameCell,
  RequestorTypeSelect,
  RequestReference,
  REQUESTS_PER_PAGE,
  RequestTasks,
  TaskSelect,
} from './common';

const PriorityTab = ({
  search,
  debouncedSearch,
  debouncedSetSearch,
  page,
  setPage,
  tasks,
  setTasks,
  supervisor,
  setSupervisor,
  requestorTypes,
  setRequestorTypes,
}: {
  search: string;
  debouncedSearch: string;
  debouncedSetSearch: (ref: string) => void;
  page: number;
  setPage: (page: number) => void;
  tasks: ArticleTask['type'][];
  setTasks: (tasks: ArticleTask['type'][]) => void;
  supervisor: string;
  setSupervisor: (supervisor: string) => void;
  requestorTypes: RequestorType[];
  setRequestorTypes: (requestorTypes: RequestorType[]) => void;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const navigate = useNavigate();
  const { currentSession } = useCurrentSession();
  const [organization] = useCurrentOrganization();

  const {
    data: { requests, meta } = {},
    isLoading,
    isError,
  } = useRequests({
    limit: REQUESTS_PER_PAGE,
    offset: (page - 1) * REQUESTS_PER_PAGE,
    search: debouncedSearch || undefined,
    tasks: tasks || undefined,
    supervisor: supervisor || undefined,
    requestorTypes,
    tab: 'to-do',
  });

  const { data: { users: usersWithSupervisePermission } = {} } = useUsers({
    permissions: ['supervise_request'],
  });

  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);

  const hasSupervisePermission = !!currentSession?.hasPermission(
    'supervise_request',
    currentSession?.workshop?.external
      ? { workshopId: currentSession.workshop.id }
      : {
          organizationId: organization?.id,
        }
  );

  const hasActiveFilters = !!tasks.length || !!supervisor || !!requestorTypes.length;

  return (
    <Stack gap="1rem" style={{ height: '100%' }}>
      <Stack row gap="1rem">
        <ErrorBoundary>
          <InputSearch
            placeholder={_(
              msg({
                id: 'requests.search.placeholder',
                message: 'Search for a reference, a client, an item, a store…',
              })
            )}
            ariaLabel={_(
              msg({
                id: 'requests.search.placeholder',
                message: 'Search for a reference, a client, an item, a store…',
              })
            )}
            style={{ flex: 1, minWidth: 175 }}
            value={search}
            onChange={debouncedSetSearch}
            size="medium"
          />
          {isMobile ? (
            <>
              <RequestsFiltersButton
                hasActiveFilters={hasActiveFilters}
                isFiltersDrawerOpen={isFiltersDrawerOpen}
                setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
              />

              <RequestMobileFilters
                isOpen={isFiltersDrawerOpen}
                onOpenChange={setIsFiltersDrawerOpen}
                setPage={setPage}
                tasks={tasks}
                setTasks={setTasks}
                supervisor={supervisor}
                usersWithSupervisePermission={usersWithSupervisePermission}
                setSupervisor={setSupervisor}
                requestorTypes={requestorTypes}
                setRequestorTypes={setRequestorTypes}
              />
            </>
          ) : (
            <>
              <RequestorTypeSelect
                selectedKeys={requestorTypes}
                onSelectionChange={(keys) => {
                  setRequestorTypes(keys);
                  setPage(1);
                }}
              />

              <TaskSelect
                selectedKeys={tasks}
                onSelectionChange={(keys) => {
                  setTasks(keys);
                  setPage(1);
                }}
              />

              <SupervisorSelect
                selectedKey={supervisor}
                availableUsers={usersWithSupervisePermission}
                onSelectionChange={(key) => {
                  setSupervisor(key ?? '');
                  setPage(1);
                }}
              />
            </>
          )}
        </ErrorBoundary>
      </Stack>

      <TableQueryWrapper isLoading={isLoading} isError={isError}>
        {!requests?.length ? (
          search || hasActiveFilters ? (
            <RequestsNoResults />
          ) : (
            <RequestsEmptyState
              title={
                <Trans id="requests.empty-state.all-caught-up-title">
                  You&apos;re all caught up!
                </Trans>
              }
              subtitle={
                <Trans id="requests.empty-state.all-caught-up-subtitle">
                  You don&apos;t have any requests to deal with at the moment
                </Trans>
              }
            />
          )
        ) : (
          <>
            {!isMobile && (
              <Table
                aria-label={_(msg({ id: 'requests.table.label', message: 'Requests' }))}
                onRowAction={(id) => {
                  const request = requests.find((request) => request.id === id)!;
                  if (request.isInDraftStep) {
                    navigate(`/requests/new/${id}`);
                    return;
                  }

                  navigate(`/requests/${id}`);
                }}
              >
                <Header>
                  <Column isRowHeader width={104}>
                    <Trans id="requests.table.column.reference.title">Reference</Trans>
                  </Column>

                  <Column width="1.5fr">
                    <Trans id="requests.table.column.requestor.title">Applicant</Trans>
                  </Column>

                  {!currentSession?.workshop?.external && (
                    <Column width="1fr">
                      <Trans id="requests.table.column.request-creation-date.title">
                        Creation date
                      </Trans>
                    </Column>
                  )}

                  <Column width="1fr">
                    <Trans id="requests.table.column.articles.title">Items</Trans>
                  </Column>

                  <Column width="1.5fr">
                    <Trans id="requests.table.column.tasks.title">Tasks to do</Trans>
                  </Column>

                  <Column width="1.5fr">
                    <Trans id="requests.table.column.request-tasks-due-date.title">
                      Tasks due date
                    </Trans>
                  </Column>

                  {!currentSession?.workshop?.external && (
                    <Column width="1.5fr">
                      <Trans id="requests.table.column.request-due-date.title">
                        Request due date
                      </Trans>
                    </Column>
                  )}

                  <Column width={104} align="center">
                    <Trans id="requests.table.column.supervisor.title">Supervisor</Trans>
                  </Column>
                </Header>

                <Body>
                  {requests?.map((request) => (
                    <RequestRow
                      key={request.id}
                      request={request}
                      users={usersWithSupervisePermission}
                      hasSupervisePermission={hasSupervisePermission}
                    />
                  ))}
                </Body>
              </Table>
            )}

            {isMobile && (
              <Stack gap="16px">
                {requests?.map((request) => (
                  <RequestCard
                    key={request.id}
                    request={request}
                    users={usersWithSupervisePermission}
                    hasSupervisePermission={hasSupervisePermission}
                  />
                ))}
              </Stack>
            )}

            <Pagination
              page={page}
              itemsPerPage={REQUESTS_PER_PAGE}
              count={meta?.count}
              onPageChange={setPage}
            />
          </>
        )}
      </TableQueryWrapper>
    </Stack>
  );
};

const RequestRow = ({
  request,
  users,
  hasSupervisePermission,
}: {
  request: RequestWithRelations;
  users?: UserWithRelations[];
  hasSupervisePermission: boolean;
}) => {
  const { currentSession } = useCurrentSession();

  return (
    <Row id={request.id}>
      <Cell>
        <RequestReference request={request} />
      </Cell>

      <RequestNameCell request={request} />

      {!currentSession?.workshop?.external && (
        <Cell>{formatDate(request.createdAtDate, { dateStyle: 'medium', year: undefined })}</Cell>
      )}

      <Cell>
        <ArticlePhotoGroup request={request} mode="task" />
      </Cell>

      <Cell>
        <RequestTasks request={request} />
      </Cell>

      <Cell>
        <RequestStatusDueAtCell request={request} variant="row" />
      </Cell>

      {!currentSession?.workshop?.external && (
        <Cell>
          <RequestDueAtCell request={request} variant="row" />
        </Cell>
      )}

      <Cell align="center">
        <SupervisorPicker
          request={request}
          users={users}
          variant="row"
          disabled={!hasSupervisePermission || request.isInDraftStep}
        />
      </Cell>
    </Row>
  );
};

const RequestCard = ({
  request,
  users,
  hasSupervisePermission,
}: {
  request: RequestWithRelations;
  users?: UserWithRelations[];
  hasSupervisePermission: boolean;
}) => {
  const navigate = useNavigate();

  const requestLink = request.isInDraftStep
    ? `/requests/new/${request.id}`
    : `/requests/${request.id}`;

  // TODO: fix <button> cannot appear as a descendant of <button> error
  return (
    <Button variant="style-less" onPress={() => navigate(requestLink)}>
      <Box padding="16px" role="row">
        <Stack row justifyContent="space-between">
          <Stack row gap="0.5rem" alignItems="center" style={{ marginBottom: '0.125rem' }}>
            <RequestReference request={request} />
          </Stack>

          <RequestDueAtCell request={request} variant="card" />
        </Stack>

        <RequestNameCardItem request={request} />

        <Stack row gap="0.5rem" alignItems="center" style={{ marginTop: '0.75rem' }}>
          <ArticlesCardItem request={request} />
          <RequestTasks request={request} />
          <RequestStatusDueAtCell request={request} variant="card" />
          <SupervisorPicker
            request={request}
            users={users}
            variant="card"
            disabled={!hasSupervisePermission || request.isInDraftStep}
          />
        </Stack>
      </Box>
    </Button>
  );
};

const RequestMobileFilters = ({
  isOpen,
  onOpenChange,
  setPage,
  tasks,
  setTasks,
  supervisor,
  usersWithSupervisePermission,
  setSupervisor,
  requestorTypes,
  setRequestorTypes,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  setPage: (page: number) => void;
  tasks: ArticleTask['type'][];
  setTasks: (tasks: ArticleTask['type'][]) => void;
  supervisor: string;
  usersWithSupervisePermission?: UserWithRelations[];
  setSupervisor: (supervisor: string) => void;
  requestorTypes: RequestorType[];
  setRequestorTypes: (requestorTypes: RequestorType[]) => void;
}) => {
  const [newRequestorTypes, setNewRequestorTypes] = useState<RequestorType[]>([]);
  const [newTasks, setNewTasks] = useState<ArticleTask['type'][]>([]);
  const [newSupervisor, setNewSupervisor] = useState<string>('');

  const handleClearFilters = () => {
    setNewRequestorTypes([]);
    setNewTasks([]);
    setNewSupervisor('');
  };

  const handleApplyFilters = () => {
    const hasTasksFilterBeenUpdated = hasFilterBeenUpdated(tasks, newTasks);
    const hasRequestorTypesFilterBeenUpdated = hasFilterBeenUpdated(
      requestorTypes,
      newRequestorTypes
    );
    const hasSupervisorFilterBeenUpdated = newSupervisor !== supervisor;

    if (hasTasksFilterBeenUpdated) {
      setTasks(newTasks);
    }

    if (hasRequestorTypesFilterBeenUpdated) {
      setRequestorTypes(newRequestorTypes);
    }

    if (hasSupervisorFilterBeenUpdated) {
      setSupervisor(newSupervisor);
    }

    if (
      hasTasksFilterBeenUpdated ||
      hasRequestorTypesFilterBeenUpdated ||
      hasSupervisorFilterBeenUpdated
    ) {
      setPage(1);
    }

    onOpenChange(false);
  };

  // Those useEffect aim to fill the local state of the drawer with the already applied filters
  useEffect(() => {
    setNewRequestorTypes(requestorTypes);
  }, [requestorTypes, isOpen]);

  useEffect(() => {
    setNewTasks(tasks);
  }, [tasks, isOpen]);

  useEffect(() => {
    setNewSupervisor(supervisor);
  }, [supervisor, isOpen]);

  return (
    <RequestsMobileFiltersDrawer
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <>
        <RequestorTypeSelect
          selectedKeys={newRequestorTypes}
          onSelectionChange={(keys) => {
            setNewRequestorTypes(keys);
          }}
        />

        <TaskSelect
          selectedKeys={newTasks}
          onSelectionChange={(keys) => {
            setNewTasks(keys);
          }}
        />

        <SupervisorSelect
          selectedKey={newSupervisor}
          availableUsers={usersWithSupervisePermission}
          onSelectionChange={(key) => {
            setNewSupervisor(key ?? '');
          }}
        />
      </>
    </RequestsMobileFiltersDrawer>
  );
};

export default PriorityTab;

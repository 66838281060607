import { Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconPackageVerification from '@/icons/PackageVerification.svg';
import IconScan from '@/icons/Scan.svg';
import { ShipmentWithRelations } from '@/models/shipment';
import { useCurrentSession } from '@/services/auth';

const VerificationMessage = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { currentSession } = useCurrentSession();

  const canVerifyReception = shipment.canReceptionBeVerifiedBy(currentSession);

  if (!canVerifyReception) {
    return (
      <AlertBar
        type="info"
        size="large"
        icon={<IconPackageVerification />}
        title={
          <Trans id="shipment.verification-message.waiting.title">
            The delivery is undergoing verification
          </Trans>
        }
      >
        <p>
          <Trans id="shipment.verification-message.waiting.text">
            The package has arrived at its destination. Its contents are currently being checked.
          </Trans>
        </p>
      </AlertBar>
    );
  }

  return (
    <AlertBar
      type="warning"
      size="large"
      icon={<IconPackageVerification />}
      title={<Trans id="shipment.verification-message.title">Verify the package</Trans>}
    >
      <Stack gap="0.5rem" alignItems="flex-start">
        <p>
          {false && (
            <Trans id="shipment.verification-message.text">
              You can scan the QR Code on each item in the package or click on the button with the
              ✔ icon to confirm receipt.
            </Trans>
          )}
          <Trans id="shipment.verification-message.text.alt">
            Click on the button with the ✔ icon to confirm receipt.
          </Trans>
        </p>
        {false && (
          <Button variant="secondary" size="medium">
            <IconScan />
            <Trans id="shipment.verification-message.action">Scan the items</Trans>
          </Button>
        )}
      </Stack>
    </AlertBar>
  );
};

export default VerificationMessage;

import { Dispatch, ReactNode, SetStateAction, useEffect, useRef } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { differenceInYears } from 'date-fns/differenceInYears';

import { ProductL1 } from '@/api';
import FileUpload from '@/components/FileUpload';
import AlertBar from '@/design_system/AlertBar';
import { BasicDropdownItem } from '@/design_system/Dropdown/Dropdown';
import InputDate from '@/design_system/InputDate';
import LegacyInputSelect from '@/design_system/InputSelect/LegacyInputSelect';
import Stack from '@/design_system/Stack';
import IconValidation from '@/icons/Validation.svg';
import { Medium } from '@/models/medium';
import { Product, useColorOptions, useSizeOptions } from '@/models/product';
import { ClientRequestWithRelations } from '@/models/request';
import FusalpWarrantyModal from '@/routes/Brand/Requests/New/components/Article/components/ArticleForm/components/FusalpWarrantyModal';
import { useCurrentOrganization } from '@/services/auth';

const OptionalInfo = ({
  product,
  productL1,
  color,
  setColor,
  size,
  setSize,
  purchaseDate,
  setPurchaseDate,
  warranty,
  setWarranty,
  proofOfPurchase,
  setProofOfPurchase,
  onDeleteMedium,
  request,
}: {
  product?: Product;
  productL1?: ProductL1;
  color?: string;
  setColor: (color?: string) => void;
  size?: string;
  setSize: (size?: string) => void;
  purchaseDate?: string;
  setPurchaseDate: (purchaseDate?: string) => void;
  warranty: boolean;
  setWarranty: (warranty: boolean) => void;
  proofOfPurchase: Medium[];
  setProofOfPurchase: Dispatch<SetStateAction<Medium[]>>;
  onDeleteMedium: (mediumId: string) => void;
  request: ClientRequestWithRelations;
}) => {
  const { _ } = useLingui();
  const [organization] = useCurrentOrganization();

  const colorOptions = useColorOptions(product);
  const sizeOptions = useSizeOptions(product, productL1);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Stack gap="1.5rem" ref={ref}>
      {colorOptions.length > 0 && (
        <LegacyInputSelect
          label={
            <>
              {_(msg({ id: 'client.new.article.form.color.label', message: 'Color' }))}{' '}
              <span className="text-disabled paragraph-100-regular">
                {_(msg({ id: 'client.new.article.form.optional', message: '(Optional)' }))}
              </span>
            </>
          }
          placeholder={_(
            msg({
              id: 'client.new.article.form.color.placeholder',
              message: 'Select a color...',
            })
          )}
          selectedKey={color}
          onSelectionChange={(key) => setColor(key as string)}
          style={{ flex: 1 }}
          size="large"
        >
          {colorOptions.map((colorOption) => (
            <BasicDropdownItem
              id={colorOption.id}
              key={colorOption.id}
              text={colorOption.text}
              variant="brand"
              size="large"
            />
          ))}
        </LegacyInputSelect>
      )}
      {sizeOptions.length > 0 && (
        <LegacyInputSelect
          label={
            <>
              {_(msg({ id: 'client.new.article.form.size.label', message: 'Size' }))}{' '}
              <span className="text-disabled paragraph-100-regular">
                {_(msg({ id: 'client.new.article.form.optional', message: '(Optional)' }))}
              </span>
            </>
          }
          placeholder={_(
            msg({
              id: 'client.new.article.form.size.placeholder',
              message: 'Select a size...',
            })
          )}
          selectedKey={size}
          onSelectionChange={(key) => setSize(key as string)}
          style={{ flex: 1 }}
          size="large"
        >
          {sizeOptions.map((sizeOption) => (
            <BasicDropdownItem
              id={sizeOption.id}
              key={sizeOption.id}
              text={sizeOption.text}
              variant="brand"
              size="large"
            />
          ))}
        </LegacyInputSelect>
      )}
      {!!request.client && (
        <>
          <InputDate
            label={
              <>
                {_(
                  msg({
                    id: 'client.new.article.form.purchase-date.label',
                    message: 'Purchase date',
                  })
                )}{' '}
                <span className="text-disabled paragraph-100-regular">
                  {_(msg({ id: 'client.new.article.form.optional', message: '(Optional)' }))}
                </span>
              </>
            }
            value={purchaseDate}
            onChange={(date) => {
              setPurchaseDate(date);

              if (date) {
                setWarranty(differenceInYears(new Date(), new Date(date)) < 2);
              } else {
                setWarranty(false);
              }
            }}
            maxValue={new Date().toISOString()}
            style={{ flex: 1 }}
            size="large"
            variant="brand"
          />
          {warranty && (
            <AlertBar icon={<IconValidation />} type="warning" size="large">
              <Stack gap="0.5rem" alignItems="flex-start">
                <p>
                  <Trans id="client.new.article.form.warranty.label">
                    Your item may be qualified for warranty. Please submit the necessary proof below
                    to benefit from it.
                  </Trans>
                </p>
                {organization && warrantyInfoForOrganization[organization.slug] && (
                  <> {warrantyInfoForOrganization[organization.slug]}</>
                )}
              </Stack>
            </AlertBar>
          )}
          <FileUpload
            uploadData={{ type: 'proof-of-purchase' }}
            type="file"
            ariaLabel={_(
              msg({
                id: 'client.new.article.form.proof-of-purchase.label',
                message: 'Please add a proof of purchase',
              })
            )}
            label={
              <>
                {_(
                  msg({
                    id: 'client.new.article.form.proof-of-purchase.label',
                    message: 'Please add a proof of purchase',
                  })
                )}{' '}
                <span className="text-disabled paragraph-100-regular">
                  {_(msg({ id: 'client.new.article.form.optional', message: '(Optional)' }))}
                </span>
              </>
            }
            media={proofOfPurchase}
            onChange={setProofOfPurchase}
            onDelete={onDeleteMedium}
            size="large"
            variant="brand"
          />
        </>
      )}
    </Stack>
  );
};

const warrantyInfoForOrganization: Record<string, ReactNode> = {
  fusalp: <FusalpWarrantyModal />,
};

export default OptionalInfo;

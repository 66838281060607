import { Trans } from '@lingui/macro';

import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const DispatchStepMessage = ({ article }: { article: ArticleWithRelations }) => {
  if (article.workshopAcceptedAt) {
    return null;
  }

  if (article.dispatchedAt) {
    return (
      <BaseMessage
        title={
          <Trans id="article.step.dispatch.validation.title">Waiting for workshop validation</Trans>
        }
        message={
          <Trans id="article.step.dispatch.validation.text">
            The workshop is analysing the item to determine if it can handle it.
          </Trans>
        }
        article={article}
      />
    );
  }

  return (
    <BaseMessage
      title={<Trans id="article.step.dispatch.title">Waiting for a workshop dispatch</Trans>}
      message={
        <Trans id="article.step.dispatch.text">
          The item is being reviewed to find the best workshop to handle the job.
        </Trans>
      }
      article={article}
    />
  );
};

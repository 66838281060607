import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Drawer from '@/design_system/Drawer';
import { DrawerBody, DrawerFooter } from '@/design_system/Drawer/Drawer';
import Stack from '@/design_system/Stack';
import IconClock from '@/icons/Clock.svg';
import IconTrash from '@/icons/Trash.svg';
import { useMedia } from '@/models/medium';
import {
  ArticleWithRelations,
  RequestWithRelations,
  useDeleteDraftRequestArticle,
} from '@/models/request';
import {
  ArticleDetails,
  ArticleFormGlobalError,
  ArticleServices,
  useArticleDetailsError,
  useArticleServicesError,
  useHasNoAvailableActions,
} from '@/routes/Requests/New/components/ArticleForm';
import LegacyRequestComments from '@/routes/Requests/Request/components/RequestComments';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import NewArticleHeader from './components/NewArticleHeader';
import { useRequestContext } from './New';

const NewArticle = () => {
  const { id, articleId } = useParams();
  const request = useRequestContext();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const article = request.articles.find(({ id }) => id === articleId);

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (state?.from) {
      // Wait for the Drawer CSS enter animation to finish before resetting state
      setTimeout(
        () =>
          navigate(pathname, {
            state: { ...state, from: undefined, skipRouterBlocker: undefined },
            replace: true,
          }),
        150
      );
    }
  }, [navigate, pathname, state]);

  const { data: { media } = { media: [] } } = useMedia({ articleId: article?.id, limit: 1 });

  const articleIsEmpty =
    !!article &&
    !article.productId &&
    !article.productL1 &&
    !article.productL2 &&
    !article.productL3 &&
    !article.data?.color &&
    !article.data?.size &&
    !article.purchaseDate &&
    !article.warranty &&
    !article.currentActions.length &&
    !article.currentCustomActions.length &&
    !article.currentPackActions.length &&
    !media.length;

  const { mutateAsync: deleteArticle } = useDeleteDraftRequestArticle(request.id);

  const deleteAndClose = async () => {
    if (article) {
      await deleteArticle(article.id);
    }

    close();
  };

  const close = () => {
    setIsOpen(false);

    // Wait for the Drawer CSS exit animation to finish before navigating away
    setTimeout(
      () => navigate(`/requests/new/${id}`, { state: { skipRouterBlocker: true }, replace: true }),
      150
    );
  };

  const isEditing = !state?.isNew;

  return (
    <Drawer
      isOpen={isOpen}
      animateEntry={state?.from === 'request'}
      onOpenChange={isEditing ? close : deleteAndClose}
      closeConfirmation={
        articleIsEmpty || isEditing
          ? undefined
          : {
              title: <Trans id="dialog.discard-changes.title">Discard changes</Trans>,
              content: (
                <Trans id="dialog.discard-changes.content">
                  You&apos;re about to leave this page without saving your changes. All unsaved
                  changes will be lost.
                  <br />
                  Are you sure?
                </Trans>
              ),
              confirm: <Trans id="dialog.discard-changes.confirm">Discard changes</Trans>,
            }
      }
    >
      {!request || !article ? null : (
        <DrawerContent request={request} article={article} isEditing={isEditing} close={close} />
      )}
    </Drawer>
  );
};

const DrawerContent = ({
  request,
  article,
  isEditing,
  close,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  isEditing: boolean;
  close: () => void;
}) => {
  const { isMobile } = useViewPort();

  // Errors

  const [showSaveForLaterErrors, setShowSaveForLaterErrors] = useState(false);
  const [showSaveErrors, setShowSaveErrors] = useState(false);

  const articleDetailsError = useArticleDetailsError(article);
  const articleServicesError = useArticleServicesError(article);

  const [alertRef, scrollAlertIntoView] = useScrollIntoView<HTMLDivElement>();
  const hasNoAvailableActions = useHasNoAvailableActions(article);

  // Actions

  const {
    mutateAsync: deleteArticle,
    isPending: isPendingDeleteArticle,
    isSuccess: isSuccessDeleteArticle,
  } = useDeleteDraftRequestArticle(article.requestId);

  const handleDelete = async () => {
    await deleteArticle(article.id);
    close();
  };

  const handleSaveForLater = () => {
    setShowSaveForLaterErrors(true);
    setShowSaveErrors(false);

    if (articleDetailsError.hasError) {
      scrollAlertIntoView();
      return;
    }

    close();
  };

  const handleSave = () => {
    setShowSaveErrors(true);
    setShowSaveForLaterErrors(false);

    if (articleDetailsError.hasError || articleServicesError.hasError) {
      scrollAlertIntoView();
      return;
    }

    close();
  };

  return (
    <>
      <NewArticleHeader request={request} article={article} isEditing={isEditing} />
      <DrawerBody>
        <Stack padding={isMobile ? '16px' : '16px 24px'}>
          <ArticleFormGlobalError
            ref={alertRef}
            showGlobalError={
              (showSaveForLaterErrors && articleDetailsError.hasError) ||
              (showSaveErrors && (articleDetailsError.hasError || articleServicesError.hasError))
            }
            showNoActionsWarning={
              hasNoAvailableActions && article.currentCustomActions.length === 0
            }
          />
          <Stack gap="1rem">
            <ArticleDetails
              request={request}
              article={article}
              showErrors={showSaveForLaterErrors || showSaveErrors}
            />
            <ArticleServices request={request} article={article} showErrors={showSaveErrors} />
            {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
            <Box style={{ padding: '16px 0 8px' }} removeStyleOnMobile>
              <LegacyRequestComments requestId={request.id} articleId={article.id} />
            </Box>
          </Stack>
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        {request.draft && isEditing && (
          <Button
            variant="secondary-danger"
            size="medium"
            onPress={handleDelete}
            isLoading={isPendingDeleteArticle || isSuccessDeleteArticle}
            dataTrackingId="requests.new.articles.drawer.actions.remove"
          >
            <IconTrash />
            <Trans id="requests.new.articles.drawer.actions.remove">Remove</Trans>
          </Button>
        )}
        <Button
          variant="secondary"
          size="medium"
          onPress={handleSaveForLater}
          dataTrackingId="requests.new.articles.drawer.actions.save-for-later"
        >
          <IconClock />
          <Trans id="requests.new.articles.drawer.actions.save-for-later">Save for later</Trans>
        </Button>
        <Button
          variant="primary"
          size="medium"
          onPress={handleSave}
          dataTrackingId="requests.new.articles.drawer.actions.save"
        >
          {isEditing ? (
            <Trans id="requests.new.articles.drawer.actions.save">Save</Trans>
          ) : (
            <Trans id="requests.new.articles.drawer.actions.add">Add item</Trans>
          )}
        </Button>
      </DrawerFooter>
    </>
  );
};

export default NewArticle;

import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import type { DefaultError, QueryKey } from '@tanstack/query-core';
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import type { BaseStepConfig, Endpoints } from '@/api';
import { Comment, CommentVisibility } from '@/models/comment/comment';
import { useFetch } from '@/utils/fetch';

import { Address } from './address';
import { Article } from './article';
import { Client } from './client';
import { Model } from './model';
import { Organization } from './organization';
import { Product } from './product';
import { Request } from './request';
import { ShipmentArticle } from './shipmentArticle';
import { Store } from './store';
import { UserWithRelations } from './user';
import { Workshop } from './workshop';

export class Shipment extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  reference!: string;
  status!: ShipmentStatus;
  step!: ShipmentStep;
  trackingId!: string | null;
  trackingUrl!: string | null;
  carrier!: ShipmentCarrier | 'private-carrier' | null;
  carrierService!: ShipmentCarrierService | 'private-carrier' | null;
  handover!: ShipmentHandover | null;
  pickupDate!: string | null;
  originalEta!: string | null;
  updatedEta!: string | null;
  shippingLabel!: string | null;

  trackingEvents?: TrackingEvent[];
  data!: object;

  originClientId!: string | null;
  originStoreId!: string | null;
  originWorkshopId!: string | null;
  destinationClientId!: string | null;
  destinationStoreId!: string | null;
  destinationWorkshopId!: string | null;
  createdAt!: string;

  statusDueAt!: string | null;

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  get origin() {
    const thisShipment = this as unknown as ShipmentWithRelations;

    return thisShipment.originClient ?? thisShipment.originStore ?? thisShipment.originWorkshop;
  }

  get destination() {
    const thisShipment = this as unknown as ShipmentWithRelations;

    return (
      thisShipment.destinationClient ??
      thisShipment.destinationStore ??
      thisShipment.destinationWorkshop
    );
  }

  get originalEtaDate() {
    return this.originalEta ? new Date(this.originalEta) : null;
  }

  get updatedEtaDate() {
    return this.updatedEta ? new Date(this.updatedEta) : null;
  }

  get departureDate() {
    const firstTransit = this.trackingEvents?.find((h) => h.status === 'in_transit');

    return firstTransit ? new Date(firstTransit.date) : null;
  }

  get arrivalDate() {
    const firstDelivered = this.trackingEvents?.find((h) => h.status === 'delivered');

    return firstDelivered ? new Date(firstDelivered.date) : null;
  }

  get pickupDateDate() {
    return this.pickupDate ? new Date(this.pickupDate) : null;
  }

  get statusDueAtDate() {
    return this.statusDueAt ? new Date(this.statusDueAt) : null;
  }

  canReceptionBeVerifiedBy(user?: UserWithRelations | null) {
    if (!user) {
      return false;
    }

    if (this.destinationStoreId) {
      return user.hasPermission('verify_shipment_reception', {
        storeId: this.destinationStoreId,
      });
    }

    if (this.destinationWorkshopId) {
      return user.hasPermission('verify_shipment_reception', {
        workshopId: this.destinationWorkshopId,
      });
    }

    return false;
  }

  canBeCreatedBy(user?: UserWithRelations | null) {
    if (!user) {
      return false;
    }

    if (this.originStoreId) {
      return user.hasPermission('create_shipment_from_origin', {
        storeId: this.originStoreId,
      });
    }

    if (this.originWorkshopId) {
      return user.hasPermission('create_shipment_from_origin', {
        workshopId: this.originWorkshopId,
      });
    }

    return false;
  }
}

export const useCarrierName = (shipment?: Shipment) => {
  const { _ } = useLingui();

  const carrierName = SHIPMENT_CARRIERS.find((c) => c.id === shipment?.carrier)?.name;

  return carrierName ? (typeof carrierName === 'string' ? carrierName : _(carrierName)) : '-';
};

// Inspired from: https://docs.ship24.com/status/#statusmilestone
export const TRACKING_STATUS = [
  'pending',
  'info_received',
  'in_transit',
  'out_for_delivery',
  'failed_attempt',
  'available_for_pickup',
  'delivered',
  'exception',
] as const;

interface TrackingEvent {
  date: string;
  status: (typeof TRACKING_STATUS)[number] | null;
  error: boolean;
  location: string | null;
  details: string | null;
}

export const instanciateArticleWithRelations = (
  article:
    | Endpoints['GET /shipments/:id']['response']['articles'][number]['article']
    | Endpoints['GET /shipments/articles']['response']['articles'][number]
) => {
  const request =
    article.request ?? ('archivedRequest' in article ? article.archivedRequest : null);

  return new Article(article)
    .with('product', article.product ? new Product(article.product) : null)
    .with(
      'request',
      new Request(request)
        .with('client', request!.client ? new Client(request!.client) : null)
        .with('store', request!.store ? new Store(request!.store) : null)
    )
    .with('organization', 'organization' in article ? new Organization(article.organization) : null)
    .with('workshop', article.workshop ? new Workshop(article.workshop) : null);
};

export type ArticleWithRelations = ReturnType<typeof instanciateArticleWithRelations>;

const instanciateShipmentWithRelations = (shipment: Endpoints['GET /shipments/:id']['response']) =>
  new Shipment(shipment)
    .with(
      'articles',
      shipment.articles.map((shipmentArticle) =>
        new ShipmentArticle(shipmentArticle).with(
          'article',
          instanciateArticleWithRelations(shipmentArticle.article)
        )
      )
    )
    .with('originClient', shipment.originClient ? new Client(shipment.originClient) : null)
    .with('originStore', shipment.originStore ? new Store(shipment.originStore) : null)
    .with('originWorkshop', shipment.originWorkshop ? new Workshop(shipment.originWorkshop) : null)
    .with('originAddress', shipment.originAddress ? new Address(shipment.originAddress) : null)
    .with(
      'destinationClient',
      shipment.destinationClient ? new Client(shipment.destinationClient) : null
    )
    .with(
      'destinationStore',
      shipment.destinationStore ? new Store(shipment.destinationStore) : null
    )
    .with(
      'destinationWorkshop',
      shipment.destinationWorkshop ? new Workshop(shipment.destinationWorkshop) : null
    )
    .with(
      'destinationAddress',
      shipment.destinationAddress ? new Address(shipment.destinationAddress) : null
    )
    .with('organization', new Organization(shipment.organization));

export type ShipmentWithRelations = ReturnType<typeof instanciateShipmentWithRelations>;

export const useShipments = (
  params?: Endpoints['GET /shipments']['query'],
  options: {
    enabled?: boolean;
  } = {}
) => {
  const fetch = useFetch<Endpoints['GET /shipments']>();

  return useQuery({
    queryKey: ['shipments', params],
    queryFn: () =>
      fetch('/shipments', params).then(({ shipments, meta }) => ({
        shipments: shipments.map(instanciateShipmentWithRelations),
        meta,
      })),
    enabled: options.enabled,
  });
};

export const useShipment = (id?: string) => {
  const fetch = useFetch<Endpoints['GET /shipments/:id']>();

  return useQuery({
    queryKey: ['shipments', id],
    queryFn: () => fetch(`/shipments/${id!}`).then(instanciateShipmentWithRelations),
    refetchIntervalInBackground: true,
    enabled: !!id,
    refetchInterval: (data) => {
      const shipmentStatus = data.state.data?.status;

      if (shipmentStatus === 'pending-pickup' || shipmentStatus === 'pending-dropoff') {
        return 5 * 60 * 1000; // Every five minutes
      }

      if (shipmentStatus === 'in-transit') {
        return 60 * 60 * 1000; // Every hour
      }

      return false;
    },
  });
};

export const useComments = ({
  shipmentId,
  ...params
}: {
  shipmentId: string;
  limit?: number;
  offset?: number;
}) => {
  const fetch = useFetch<Endpoints['GET /shipments/:id/comments']>();

  return useQuery({
    queryKey: ['comments', shipmentId, params],
    queryFn: async () => {
      // FIXME: Is this limit ok? Should we handle pagination?
      const defaultParams = { limit: 250, offset: 0 };

      return await fetch(`/shipments/${shipmentId}/comments`, {
        ...defaultParams,
        ...params,
      }).then(({ comments, meta }) => ({
        comments: comments.map((comment) => new Comment(comment)),
        meta,
      }));
    },
  });
};

export const useCreateComment = ({ shipmentId }: { shipmentId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /shipments/:id/comments']>();

  return useMutation({
    mutationFn: ({ content, visibility }: { content: string; visibility: CommentVisibility }) => {
      return fetch(`/shipments/${shipmentId}/comments`, undefined, {
        method: 'POST',
        body: { content, visibility },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['comments', shipmentId] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useUpdateComment = ({ shipmentId }: { shipmentId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /shipments/:id/comments/:commentId']>();

  return useMutation({
    mutationFn: ({ commentId, content }: { commentId: string; content: string }) => {
      return fetch(`/shipments/${shipmentId}/comments/${commentId}`, undefined, {
        method: 'PATCH',
        body: { content },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['comments', shipmentId] });
    },
  });
};

export const useDeleteComment = ({ shipmentId }: { shipmentId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['DELETE /shipments/:id/comments/:commentId']>();

  return useMutation({
    mutationFn: ({ commentId }: { commentId: string }) => {
      return fetch(`/shipments/${shipmentId}/comments/${commentId}`, undefined, {
        method: 'DELETE',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['comments', shipmentId] });
    },
  });
};

export const useRemoveArticleFromShipment = ({ shipmentId }: { shipmentId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['DELETE /shipments/:id/articles/:articleId']>();

  return useMutation({
    mutationFn: ({ articleId }: { articleId: string }) => {
      return fetch(`/shipments/${shipmentId}/articles/${articleId}`, undefined, {
        method: 'DELETE',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['shipments', shipmentId] });
      queryClient.invalidateQueries({ queryKey: ['shipments/articles'] });
    },
  });
};

export const SHIPMENT_STEPS = [
  {
    name: msg({ id: 'shipment.status.preparation', message: 'Preparation' }),
    id: 'preparation',
    statuses: ['draft'],
    color: 'black',
  },
  {
    name: msg({ id: 'shipment.status.finalisation', message: 'Finalisation' }),
    id: 'finalisation',
    statuses: ['created'],
    color: 'black',
  },
  {
    name: msg({ id: 'shipment.status.handover', message: 'Handover' }),
    id: 'handover',
    statuses: ['pending-dropoff', 'pending-pickup'],
    color: 'blue',
  },
  {
    name: msg({ id: 'shipment.status.in-transit', message: 'In transit' }),
    id: 'in-transit',
    statuses: ['in-transit'],
    color: 'purple',
  },
  {
    hiddenInDestinationClient: true,
    name: msg({ id: 'shipment.status.verification', message: 'Verification' }),
    id: 'verification',
    statuses: ['received'],
    color: 'orange',
  },
  {
    hidden: true,
    name: msg({ id: 'shipment.status.validated', message: 'Validated' }),
    id: 'validated',
    statuses: ['validated'],
    color: 'green',
  },
] as const;

export type ShipmentStep = (typeof SHIPMENT_STEPS)[number]['id'];
export type ShipmentStatus = (typeof SHIPMENT_STEPS)[number]['statuses'][number];

/* eslint-disable lingui/no-unlocalized-strings */
export const SHIPMENT_CARRIERS = [
  {
    id: 'dhl',
    name: 'DHL',
  },
  {
    id: 'chronopost',
    name: 'Chronopost',
  },
  {
    id: 'mondial_relay',
    name: 'Mondial Relay',
  },
] as const;

export const SHIPMENT_CARRIER_SERVICES = [
  {
    id: 'chrono_reverse_13',
    name: 'Chrono Reverse 13',
  },
  {
    id: 'economy_select',
    name: 'Economy Select',
  },
  {
    id: 'domestic_express',
    name: 'Domestic Express',
  },
  {
    id: 'express_worldwide',
    name: 'Express Worldwide',
  },
  {
    id: 'express_worldwide_ue',
    name: 'Express Worldwide UE',
  },
  {
    id: 'economy_select_ue',
    name: 'Economy Select UE',
  },
  {
    id: 'point_relais',
    name: 'Point Relais',
  },
] as const;
/* eslint-enable lingui/no-unlocalized-strings */

export type ShipmentCarrier = (typeof SHIPMENT_CARRIERS)[number]['id'];
export type ShipmentCarrierService = (typeof SHIPMENT_CARRIER_SERVICES)[number]['id'];

export const SHIPMENT_HANDOVER = [
  {
    id: 'pickup',
    name: msg({ id: 'shipment.handover.pickup', message: 'Pickup' }),
  },
  {
    id: 'dropoff',
    name: msg({ id: 'shipment.handover.dropoff', message: 'Drop-off' }),
  },
] as const;

export type ShipmentHandover = (typeof SHIPMENT_HANDOVER)[number]['id'];

export const useCreateShipment = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /shipments']>();

  return useMutation({
    mutationFn: (data: {
      origin: string;
      destination: string;
      articles: string[];
      carrierService?: ShipmentCarrierService | 'private-carrier';
      handover?: ShipmentHandover;
      pickupDate?: string;
      trackingId?: string;
    }) =>
      fetch('/shipments', undefined, { method: 'POST', body: data }).then(
        (shipment: any) => new Shipment(shipment)
      ),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['shipments'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useUpdateDraftShipment = (id: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /shipments/:id']>();

  return useMutation({
    mutationFn: (data: {
      origin?: string;
      destination?: string;
      articles?: string[];
      carrierService?: ShipmentCarrierService | 'private-carrier';
      handover?: ShipmentHandover;
      pickupDate?: string;
      trackingId?: string;
    }) =>
      fetch(`/shipments/${id}`, undefined, { method: 'PATCH', body: data }).then(
        (shipment: any) => new Shipment(shipment)
      ),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['shipments'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useUpdateShipmentTrackingId = (id: string) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /shipments/:id/tracking-id']>();

  return useMutation({
    mutationFn: (trackingId: string | null) =>
      fetch(`/shipments/${id}/tracking-id`, undefined, { method: 'PATCH', body: { trackingId } }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['shipments'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useCompletePreparation = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /shipments/:id/complete-preparation']>();

  return useMutation({
    mutationFn: (id: string) =>
      fetch(`/shipments/${id}/complete-preparation`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['shipments'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useCompleteFinalisation = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /shipments/:id/complete-finalisation']>();

  return useMutation({
    mutationFn: (id: string) =>
      fetch(`/shipments/${id}/complete-finalisation`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['shipments'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useCompleteHandover = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /shipments/:id/complete-handover']>();

  return useMutation({
    mutationFn: (id: string) =>
      fetch(`/shipments/${id}/complete-handover`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['shipments'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useCompleteInTransit = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /shipments/:id/complete-in-transit']>();

  return useMutation({
    mutationFn: (id: string) =>
      fetch(`/shipments/${id}/complete-in-transit`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['shipments'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useVerifyArticle = ({
  shipmentId,
  articleId,
}: {
  shipmentId: string;
  articleId: string;
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /shipments/:id/verify-article/:articleId']>();

  return useMutation({
    mutationFn: (body: { verified: boolean; issue?: string | null }) =>
      fetch(`/shipments/${shipmentId}/verify-article/${articleId}`, undefined, {
        method: 'POST',
        body,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['shipments', shipmentId] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useResolveShipmentIssue = ({
  shipmentId,
  articleId,
}: {
  shipmentId: string;
  articleId: string;
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /shipments/:id/resolve-issue/:articleId']>();

  return useMutation({
    mutationFn: (body: { issueResolution: string }) =>
      fetch(`/shipments/${shipmentId}/resolve-issue/${articleId}`, undefined, {
        method: 'POST',
        body,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['shipments', shipmentId] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useArchiveShipmentIssue = ({
  shipmentId,
  articleId,
}: {
  shipmentId: string;
  articleId: string;
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /shipments/:id/archive-issue/:articleId']>();

  return useMutation({
    mutationFn: (body: { archivalReason: string }) =>
      fetch(`/shipments/${shipmentId}/archive-issue/${articleId}`, undefined, {
        method: 'POST',
        body,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['shipments', shipmentId] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useCompleteVerification = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /shipments/:id/complete-verification']>();

  return useMutation({
    mutationFn: (id: string) =>
      fetch(`/shipments/${id}/complete-verification`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['shipments'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useAvailableCarriers = ({
  organizationId,
  originId,
  destinationId,
}: {
  organizationId?: string;
  originId?: string;
  destinationId?: string;
}) => {
  const fetch = useFetch<Endpoints['GET /shipments/available-carriers']>();

  return useQuery({
    queryKey: ['shipments', 'available-carriers', { organizationId, originId, destinationId }],
    queryFn: () =>
      fetch('/shipments/available-carriers', {
        organizationId: organizationId!,
        originId: originId!,
        destinationId: destinationId!,
      }),
    enabled: !!organizationId && !!originId && !!destinationId,
  });
};

export const useInfiniteActivities = ({ shipmentId }: { shipmentId: string }) => {
  const fetch = useFetch<Endpoints['GET /shipments/:id/activities']>();

  return useInfiniteQuery<
    Endpoints['GET /shipments/:id/activities']['response'],
    DefaultError,
    InfiniteData<Endpoints['GET /shipments/:id/activities']['response']>,
    QueryKey,
    string | null
  >({
    queryKey: ['activities', shipmentId],
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage.meta.next,

    queryFn: ({ pageParam }) => {
      const requestParams = pageParam ? { before: pageParam } : undefined;

      return fetch(`/shipments/${shipmentId}/activities`, requestParams);
    },
  });
};

export const useShipExternalStoreToWorkshopShipment = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /shipments/:id/ship']>();

  return useMutation({
    mutationFn: ({
      id,
      ...body
    }: Endpoints['POST /shipments/:id/ship']['body'] & {
      id: string;
    }) => fetch(`/shipments/${id}/ship`, undefined, { method: 'POST', body }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['shipments'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useServicePoint = (id?: number | string, initialData?: ServicePoint) => {
  const fetch = useFetch<Endpoints['GET /shipments/service-points/:id']>();

  return useQuery({
    queryKey: ['shipments', 'service-points', { id }],
    queryFn: () => fetch(`/shipments/service-points/${id}`),
    enabled: !!id,
    initialData,
  });
};

export type ServicePoint = Endpoints['GET /shipments/service-points/:id']['response'];

export const useArticleOngoingShipment = (article: { id: string; step: BaseStepConfig | null }) => {
  const { data: { shipments } = { shipments: [] }, isLoading } = useShipments({
    articleId: article.id,
  });

  if (article.step?.step !== 'transit') {
    return { shipment: undefined, isLoading: false };
  }

  const stepConfig = article.step.config;

  if (stepConfig.originType === 'store' && stepConfig.destinationType === 'client') {
    return { shipment: undefined, isLoading: false };
  }

  const shipment = shipments.find((shipment) => {
    const hasArticle = !!shipment.articles.find(({ articleId }) => articleId === article.id);

    const matchesOrigin =
      (stepConfig.originType === 'store' && !!shipment.originStoreId) ||
      (stepConfig.originType === 'workshop' && !!shipment.originWorkshopId) ||
      (stepConfig.originType === 'client' && !!shipment.originClientId);

    const matchesDestination =
      (stepConfig.destinationType === 'store' && !!shipment.destinationStoreId) ||
      (stepConfig.destinationType === 'workshop' && !!shipment.destinationWorkshopId) ||
      (stepConfig.destinationType === 'client' && !!shipment.destinationClientId);

    return hasArticle && matchesOrigin && matchesDestination;
  });

  return { shipment, isLoading };
};

import { NodeDropdownItem } from '@/design_system/Dropdown/Dropdown';
import Stack from '@/design_system/Stack';
import { PackActionTypeOrganizationWithRelations } from '@/models/actionType';
import { useDatabaseTranslation } from '@/models/translation';
import { useCurrentSession } from '@/services/auth';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import PackActionName from './PackActionName';

const PackActionDropdownItem = ({
  packAction,
  mode,
  showCost,
  showPrice,
}: {
  packAction: PackActionTypeOrganizationWithRelations;
  mode: 'need' | 'action' | 'both';
  showCost?: boolean;
  showPrice?: boolean;
}) => {
  const { _db } = useDatabaseTranslation();
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();

  return (
    <NodeDropdownItem id={packAction.id} key={packAction.id} textValue={_db(packAction.name)}>
      <Stack
        row={!isMobile}
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent="space-between"
        gap="0.5rem"
        flexWrap="nowrap"
        style={{ flex: 1 }}
      >
        <PackActionName
          packActionType={
            !currentSession?.workshop?.external && !!packAction.refashionBonus
              ? Object.assign(packAction, {
                  actions: packAction.actions.map((action) =>
                    Object.assign(action, {
                      refashionStatus: action.refashionId ? 'applied' : undefined,
                    })
                  ),
                })
              : packAction
          }
          mode={mode}
          alwaysShowActions
        />
        <Stack
          row
          alignItems="center"
          gap="0.5rem"
          flexWrap="nowrap"
          style={{ alignSelf: isMobile ? 'flex-end' : 'center' }}
        >
          {showCost && (
            <p className="paragraph-100-regular">
              {formatCurrency(packAction.dynamicCost?.amount, packAction.dynamicCost?.currency)}
            </p>
          )}
          {showPrice && (
            <p className="paragraph-100-medium">
              {formatCurrency(packAction.dynamicPrice?.amount, packAction.dynamicPrice?.currency)}
            </p>
          )}
        </Stack>
      </Stack>
    </NodeDropdownItem>
  );
};

export default PackActionDropdownItem;

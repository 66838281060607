import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import FileUpload from '@/components/FileUpload';
import { ArticleWithRelations } from '@/models/request';

const ArticlePhotos = ({
  article,
  disabled,
  error,
}: {
  article: ArticleWithRelations;
  disabled?: boolean;
  error?: string;
}) => {
  const { _ } = useLingui();

  return (
    <FileUpload
      uploadData={{
        type: 'article-photo',
        articleId: article.id,
      }}
      ariaLabel={_(
        msg({
          id: 'article.estimate.article-pictures.aria-label',
          message: 'Upload item pictures',
        })
      )}
      type="photo"
      label={_(msg({ id: 'article.estimate.article-pictures.label', message: 'Item pictures' }))}
      disabled={disabled}
      media={article.media}
      error={error}
      deleteWithApi
    />
  );
};

export default ArticlePhotos;

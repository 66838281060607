import { forwardRef, ReactNode, Ref, useEffect, useRef } from 'react';
import {
  Label,
  Radio as AriaRadio,
  RadioGroup as AriaRadioGroup,
  RadioGroupProps as AriaRadioGroupProps,
  RadioProps as AriaRadioProps,
} from 'react-aria-components';
import { msg } from '@lingui/macro';

import { createBEMClasses } from '@/utils/classname';

import './RadioGroup.css';

const { block, element } = createBEMClasses('client-radio');

interface RadioGroupProps extends AriaRadioGroupProps {
  label?: ReactNode;
  options: RadioProps[];
  variant?: 'text' | 'image-row' | 'image-column' | 'icon';
  grid?: 'small' | 'medium' | 'large';
  gap?: string;
  className?: string;
}

interface RadioProps extends AriaRadioProps {
  imageUrl?: string;
  icon?: ReactNode;
  text?: ReactNode;
  subText?: string;
  className?: string;
}

const ClientRadioGroup = forwardRef(function ClientRadioGroup(
  { label, options, variant = 'text', grid: gridProp, gap, className, ...props }: RadioGroupProps,
  refProp: Ref<HTMLDivElement>
) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refProp && 'current' in refProp) {
      refProp.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [refProp]);

  const grid =
    gridProp ??
    (variant === 'text'
      ? 'small'
      : variant === 'image-column' || variant === 'icon'
        ? 'medium'
        : 'large');

  return (
    <AriaRadioGroup {...props} className={block({}, className)} ref={refProp ?? ref}>
      {!!label && <Label className="paragraph-50-medium">{label}</Label>}
      <div className={element('options', { variant, grid })} style={{ gap }}>
        {options.map(
          ({ imageUrl, icon, text, subText, className: optionClassName, ...option }, index) => (
            <AriaRadio
              key={index}
              className={element('option', { variant }, optionClassName)}
              {...option}
            >
              {(variant === 'image-row' || variant === 'image-column') && (
                <div className={element('option__image')}>
                  {imageUrl && <img src={imageUrl} alt="" />}
                </div>
              )}
              {variant === 'icon' && !!icon && (
                <div className={element('option__icon')}>{icon}</div>
              )}
              <div
                className={element(
                  'option__text',
                  undefined,
                  'paragraph-50-regular paragraph-100-regular-mobile'
                )}
              >
                {text}
              </div>
              {(variant === 'text' || variant === 'icon') && subText && (
                <p
                  className={element(
                    'option__subtext',
                    undefined,
                    'paragraph-200-regular paragraph-300-regular-mobile text-secondary'
                  )}
                >
                  {subText}
                </p>
              )}
            </AriaRadio>
          )
        )}
      </div>
    </AriaRadioGroup>
  );
});

export const yesNoRadioOptions = [
  { value: 'yes', text: msg({ id: 'design-system.radio-group.yes-no.yes', message: 'Yes' }) },
  { value: 'no', text: msg({ id: 'design-system.radio-group.yes-no.no', message: 'No' }) },
];

export default ClientRadioGroup;

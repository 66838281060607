import { createContext, useContext } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CreationStepConfig } from '@/api';
import {
  ArticleActionsCell,
  ArticleCostCell,
  ArticleNameCell,
  ArticlePriceCell,
  ArticleWorkshopCell,
  RequestTotalCostCell,
  RequestTotalNameCell,
  RequestTotalPriceCell,
} from '@/components/ArticlesTableCells';
import AlertBar from '@/design_system/AlertBar';
import Badge from '@/design_system/Badge';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import Table, { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import IconCross from '@/icons/Cross.svg';
import IconEdit from '@/icons/Edit.svg';
import {
  ArticleWithRelations,
  RequestWithRelations,
  useDeleteDraftRequestArticle,
} from '@/models/request';
import useViewPort from '@/utils/useViewport';

import { useArticleDetailsError, useArticleServicesError } from './ArticleForm';

interface ArticlesTableContextData {
  request: RequestWithRelations;
  showWorkshopColumn?: boolean;
  showCostColumn?: boolean;
  showPriceColumn?: boolean;
  costLabel: string;
  priceLabel: string;
  onOpenArticle: (id: string) => void;
}

const ArticlesTableContext = createContext({} as ArticlesTableContextData);

const ArticlesTable = ({
  request,
  onOpenArticle,
  error,
}: {
  request: RequestWithRelations;
  onOpenArticle: (id: string) => void;
  error?: string;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const creationConfig = request.articles[0]?.step?.config as
    | CreationStepConfig['config']
    | undefined;

  const showWorkshopColumn = creationConfig?.requireDispatch;
  const showCostColumn = creationConfig?.requireCost;
  const showPriceColumn =
    creationConfig?.requirePrice === true && request.requestorType !== 'store';

  const costLabel = _(
    msg({
      id: 'request.articles.table.column.internal-cost.label',
      message: 'Internal cost',
    })
  );

  const priceLabel = _(
    msg({ id: 'request.articles.table.column.client-price.label', message: 'Client price' })
  );

  const contextValue = {
    request,
    showWorkshopColumn,
    showCostColumn,
    showPriceColumn,
    costLabel,
    priceLabel,
    onOpenArticle,
  };

  if (isMobile) {
    return (
      <ArticlesTableContext.Provider value={contextValue}>
        <Box padding="16px" className="bg-neutral-200">
          <Stack gap="0.5rem">
            {(showCostColumn || showPriceColumn) && <TotalCard />}
            {request.articles.map((article) => (
              <ArticleCard key={article.id} article={article} />
            ))}
            {error && <Message type="error">{error}</Message>}
          </Stack>
        </Box>
      </ArticlesTableContext.Provider>
    );
  }

  return (
    <>
      <ArticlesTableContext.Provider value={contextValue}>
        <Table
          aria-label={_(msg({ id: 'request.articles.table.label', message: 'Items' }))}
          onRowAction={(ref) => {
            if (ref !== 'total') {
              onOpenArticle(ref as string);
            }
          }}
        >
          <Header>
            <Column isRowHeader width="5fr" minWidth={150}>
              <Trans id="request.articles.table.label">Items</Trans>
            </Column>
            <Column width={100}>
              <Trans id="request.articles.table.status.label">Status</Trans>
            </Column>
            <Column width="5fr" minWidth={180}>
              {showCostColumn || showPriceColumn ? (
                <Trans id="request.articles.table.column.action.label">Required actions</Trans>
              ) : (
                <Trans id="request.articles.table.column.defect.label">Defect</Trans>
              )}
            </Column>
            {showWorkshopColumn && (
              <Column minWidth={180}>
                <Trans id="request.articles.table.column.workshop.label">Workshop</Trans>
              </Column>
            )}
            {showCostColumn && (
              <Column width={120} align="end">
                {costLabel}
              </Column>
            )}
            {showPriceColumn && (
              <Column width={120} align="end">
                {priceLabel}
              </Column>
            )}
            <Column width={100} />
          </Header>
          <Body>
            {request.articles.map((article) => (
              <ArticleRow key={article.id} article={article} />
            ))}
            {(showCostColumn || showPriceColumn) && <TotalRow />}
          </Body>
        </Table>
      </ArticlesTableContext.Provider>
      {error && <Message type="error">{error}</Message>}
    </>
  );
};

const TotalCard = () => {
  const { request, showCostColumn, showPriceColumn, costLabel, priceLabel } =
    useContext(ArticlesTableContext);

  return (
    <Stack
      row
      gap="0.5rem"
      flexWrap="nowrap"
      justifyContent="space-between"
      alignItems="center"
      style={{ paddingRight: 12 }}
    >
      <div style={{ flex: 1, marginTop: 'auto' }}>
        <RequestTotalNameCell request={request} />
      </div>
      {showCostColumn && <RequestTotalCostCell request={request} label={costLabel} />}
      {showPriceColumn && <RequestTotalPriceCell request={request} label={priceLabel} />}
    </Stack>
  );
};

const TotalRow = () => {
  const { request, showWorkshopColumn, showCostColumn, showPriceColumn } =
    useContext(ArticlesTableContext);

  return (
    <Row id="total" className="bg-neutral-100" unclickable>
      <Cell colSpan={2}>
        <RequestTotalNameCell request={request} />
      </Cell>
      <Cell />
      {showWorkshopColumn && <Cell />}
      {showCostColumn && (
        <Cell align="end">
          <RequestTotalCostCell request={request} />
        </Cell>
      )}
      {showPriceColumn && (
        <Cell align="end">
          <RequestTotalPriceCell request={request} />
        </Cell>
      )}
      <Cell />
    </Row>
  );
};

const ArticleCard = ({ article }: { article: ArticleWithRelations }) => {
  const {
    request,
    showWorkshopColumn,
    showCostColumn,
    showPriceColumn,
    costLabel,
    priceLabel,
    onOpenArticle,
  } = useContext(ArticlesTableContext);

  return (
    <Box padding="0">
      <Stack padding="16px" gap="1rem" className="bg-neutral-0">
        <Stack
          row
          gap="0.5rem"
          flexWrap="nowrap"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <ArticleNameCell article={article} />
          <ReadyBadge article={article} />
        </Stack>
        {article.hasActions && (
          <Stack gap="0.75rem">
            <hr />
            <ArticleActionsCell
              actions={article.currentActions}
              customActions={article.currentCustomActions}
              packActions={article.currentPackActions}
              mode={showCostColumn || showPriceColumn ? 'action' : 'need'}
              showBonus
            />
          </Stack>
        )}
        {showWorkshopColumn && (
          <Stack gap="0.75rem">
            <hr />
            <ArticleWorkshopCell article={article} />
          </Stack>
        )}
      </Stack>
      <Stack padding="12px 16px" gap="1rem" className="bg-neutral-100">
        <Stack row gap="1rem" justifyContent="flex-end" flexWrap="nowrap" alignItems="flex-start">
          {showCostColumn && <ArticleCostCell article={article} label={costLabel} />}
          {showPriceColumn && <ArticlePriceCell article={article} label={priceLabel} />}
        </Stack>
        <hr />
        <Stack gap="0.5rem">
          <EditButton onEdit={() => onOpenArticle(article.id)} />
          {request.draft && <DeleteButton article={article} />}
        </Stack>
      </Stack>
    </Box>
  );
};

const ArticleRow = ({ article }: { article: ArticleWithRelations }) => {
  const { request, showWorkshopColumn, showCostColumn, showPriceColumn, onOpenArticle } =
    useContext(ArticlesTableContext);

  return (
    <Row id={article.id}>
      <Cell>
        <ArticleNameCell article={article} />
      </Cell>
      <Cell>
        <ReadyBadge article={article} />
      </Cell>
      <Cell>
        <ArticleActionsCell
          actions={article.currentActions}
          customActions={article.currentCustomActions}
          packActions={article.currentPackActions}
          mode={showCostColumn || showPriceColumn ? 'action' : 'need'}
          showBonus
        />
      </Cell>
      {showWorkshopColumn && (
        <Cell>
          <ArticleWorkshopCell article={article} />
        </Cell>
      )}
      {showCostColumn && (
        <Cell align="end">
          <ArticleCostCell article={article} />
        </Cell>
      )}
      {showPriceColumn && (
        <Cell align="end">
          <ArticlePriceCell article={article} />
        </Cell>
      )}
      <Cell align="end">
        <Stack row gap="0.5rem" flexWrap="nowrap">
          <EditButton onEdit={() => onOpenArticle(article.id)} />
          {request.draft && <DeleteButton article={article} />}
        </Stack>
      </Cell>
    </Row>
  );
};

const EditButton = ({ onEdit }: { onEdit: () => void }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  if (isMobile) {
    return (
      <Button variant="secondary" size="medium" onPress={onEdit}>
        <IconEdit />
        <Trans id="request.articles.table.column.actions.edit.label">Edit item</Trans>
      </Button>
    );
  }

  return (
    <Button
      iconOnly
      variant="secondary"
      size="medium"
      tooltip={_(msg({ id: 'request.articles.table.column.actions.edit', message: 'Edit' }))}
      ariaLabel={_(msg({ id: 'request.articles.table.column.actions.edit', message: 'Edit' }))}
      onPress={onEdit}
    >
      <IconEdit />
    </Button>
  );
};

const DeleteButton = ({ article }: { article: ArticleWithRelations }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const {
    mutateAsync: deleteArticle,
    isPending: isPendingDeleteArticle,
    isSuccess: isSuccessDeleteArticle,
  } = useDeleteDraftRequestArticle(article.requestId);

  if (isMobile) {
    return (
      <Button
        variant="secondary"
        size="medium"
        onPress={() => deleteArticle(article.id)}
        isLoading={isPendingDeleteArticle || isSuccessDeleteArticle}
      >
        <IconCross />
        <Trans id="request.articles.table.column.actions.remove.label">Remove item</Trans>
      </Button>
    );
  }

  return (
    <Button
      iconOnly
      variant="secondary"
      size="medium"
      tooltip={_(msg({ id: 'request.articles.table.column.actions.remove', message: 'Remove' }))}
      ariaLabel={_(msg({ id: 'request.articles.table.column.actions.remove', message: 'Remove' }))}
      onPress={() => deleteArticle(article.id)}
      isLoading={isPendingDeleteArticle || isSuccessDeleteArticle}
    >
      <IconCross />
    </Button>
  );
};

const ReadyBadge = ({ article }: { article: ArticleWithRelations }) => {
  const articleDetailsError = useArticleDetailsError(article);
  const articleServicesError = useArticleServicesError(article);

  const isReady = !articleDetailsError.hasError && !articleServicesError.hasError;

  return (
    <Badge color={isReady ? 'green' : 'black'} variant="low" ellipse>
      {isReady ? (
        <Trans id="request.articles.table.status.ready">Ready</Trans>
      ) : (
        <Trans id="request.articles.table.status.draft">Draft</Trans>
      )}
    </Badge>
  );
};

export const EstimateWarning = () => {
  return (
    <AlertBar
      type="info"
      size="large"
      title={
        <p className="paragraph-100-regular">
          <Trans id="requests.new.articles.price-disclaimer">
            The price is an initial estimate and may change following analysis of the item(s) by the
            after-sales and repair workshop.
          </Trans>
        </p>
      }
    />
  );
};

export default ArticlesTable;

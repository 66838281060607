import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { BasicDropdownItem } from '@/design_system/Dropdown/Dropdown';
import LegacyInputSearchSelect from '@/design_system/InputSearchSelect/LegacyInputSearchSelect';
import { Product, useProducts } from '@/models/product';
import { useCurrentOrganization } from '@/services/auth';
import { Locale } from '@/services/i18n';
import useDebouncedState from '@/utils/useDebouncedState';

const ProductSearchSelect = ({
  showLabel = true,
  ariaLabel,
  placeholder,
  value,
  onChange,
  isDisabled,
  size,
  variant = 'default',
  error,
  focusOnRender,
}: {
  showLabel?: boolean;
  ariaLabel?: string;
  placeholder?: string;
  value: Product | undefined | null;
  onChange: (product: Product | undefined) => void;
  isDisabled?: boolean;
  size?: 'medium' | 'large';
  variant?: 'default' | 'brand';
  error?: string;
  focusOnRender?: boolean;
}) => {
  const { i18n, _ } = useLingui();
  const [organization] = useCurrentOrganization();
  const productExternalId =
    organization?.config.customWording?.productExternalId?.[i18n.locale as Locale];

  const [query, debouncedQuery, setQuery] = useDebouncedState<string>(
    value ? getOptionText(value) : '',
    500
  );

  const {
    data: { products } = { products: [] },
    isPending,
    isFetching,
  } = useProducts(
    {
      query: debouncedQuery || undefined,
      limit: 10,
    },
    {
      keepPreviousData: true,
      enabled: !!debouncedQuery && !isDisabled,
    }
  );

  const label = _(
    msg({
      id: 'article.form.article-reference.label',
      message: `Item reference${productExternalId ? ` (${productExternalId})` : ''} `,
    })
  );

  return (
    <LegacyInputSearchSelect
      variant="search"
      label={showLabel ? label : undefined}
      ariaLabel={ariaLabel}
      placeholder={
        placeholder ??
        _(
          msg({
            id: 'article.form.article-reference.placeholder',
            message: 'Search by item reference or name',
          })
        )
      }
      inputValue={query}
      onInputChange={setQuery}
      selectedKey={value?.externalId ?? ''}
      onSelectionChange={(key: string) => {
        const newProduct = products.find((product) => product.id === key);

        if (newProduct) {
          setQuery(getOptionText(newProduct));
          onChange(newProduct);
        } else {
          setQuery('');
          onChange(undefined);
        }
      }}
      style={{ flex: 1 }}
      isPending={isPending}
      isFetching={isFetching || query !== debouncedQuery}
      isDisabled={isDisabled}
      size={size}
      styleVariant={variant}
      focusOnRender={focusOnRender}
      error={error}
    >
      {products.map((product) => (
        <BasicDropdownItem
          id={product.id}
          key={product.id}
          text={getOptionText(product)}
          textValue={'OF' + getOptionText(product)}
          variant={variant}
        />
      ))}
    </LegacyInputSearchSelect>
  );
};

const getOptionText = (product: Product) =>
  `${product.externalId} - ${product.name}` +
  (product.data.collection ? ` (${product.data.collection})` : '');

export default ProductSearchSelect;

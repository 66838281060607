import Stack from '@/design_system/Stack';

interface EmptyStateProps {
  title?: React.ReactNode;
  subtitle: React.ReactNode;
  illustration: React.ReactNode;
}

/**
 *
 * Display an illustration and some text when there is no data to display
 */
export const EmptyState = ({ title, subtitle, illustration }: EmptyStateProps) => {
  return (
    <Stack gap="16px" alignItems="center" justifyContent="center" style={{ flex: 1 }}>
      {illustration}

      <Stack gap="4px" alignItems="center">
        {title && <p className='paragraph-50-medium text-center"'>{title}</p>}

        <p className="paragraph-100-regular text-center">{subtitle}</p>
      </Stack>
    </Stack>
  );
};

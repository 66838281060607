import { useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { GoToCommentsButton } from '@/components/comments/GoToCommentsButton/GoToCommentsButton';
import config from '@/config';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconEdit from '@/icons/Edit.svg';
import IconPrint from '@/icons/Print.svg';
import IconQrCode from '@/icons/QrCode.svg';
import { ShipmentWithRelations, useComments } from '@/models/shipment';
import { useCurrentOrganization, useCurrentSession } from '@/services/auth';
import { useBlobUrl } from '@/utils/useBlobUrl';
import useViewPort from '@/utils/useViewport';

const ShipmentHeader = ({
  shipment,
  onCommentButtonPress,
}: {
  shipment: ShipmentWithRelations;
  onCommentButtonPress: () => void;
}) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();
  const [organization] = useCurrentOrganization();
  const navigate = useNavigate();
  const { data: { comments } = { comments: [] } } = useComments({
    shipmentId: shipment.id,
  });

  const canEditShipment = shipment.canBeCreatedBy(currentSession);

  const shippingLabelUrl = useBlobUrl(shipment.shippingLabel, 'application/pdf');

  return (
    <Stack
      gap={isMobile ? '4px' : '24px'}
      alignItems="center"
      row
      justifyContent="space-between"
      flexWrap="nowrap"
    >
      <h1 className="headline-200-bold headline-300-bold-mobile">
        <Trans id="shipment.title">Shipment {shipment.reference}</Trans>
      </h1>
      <Stack gap="8px" row>
        {canEditShipment && shipment.status === 'draft' && (
          <Button
            variant="secondary"
            size="large"
            iconOnly
            ariaLabel={_(msg({ id: 'shipment.edit', message: 'Edit shipment' }))}
            tooltip={_(msg({ id: 'shipment.edit', message: 'Edit shipment' }))}
            onPress={() => navigate('/shipments/' + shipment.id + '/edit')}
          >
            <IconEdit />
          </Button>
        )}
        <GoToCommentsButton
          onCommentButtonPress={onCommentButtonPress}
          nbOfComments={comments.length}
        />
        {shipment.status !== 'draft' && (
          <Button
            variant="secondary"
            size="large"
            iconOnly
            href={
              shippingLabelUrl ??
              `${config.apiUrl}/shipments/${shipment.id}/internal-shipping-label?organization=${shipment.organization.slug}`
            }
            target={shippingLabelUrl ? undefined : '_blank'}
            download={`${shipment.reference}.pdf`}
            ariaLabel={_(
              msg({ id: 'shipment.actions.print-shipping-label', message: 'Print shipping label' })
            )}
            tooltip={_(
              msg({ id: 'shipment.actions.print-shipping-label', message: 'Print shipping label' })
            )}
          >
            <IconPrint />
          </Button>
        )}
        {shipment.step === 'preparation' && (
          <Button
            variant="secondary"
            size="large"
            iconOnly
            href={
              config.apiUrl + `/shipments/${shipment.id}/recap?organization=${organization?.slug}`
            }
            target="_blank"
            ariaLabel={_(
              msg({
                id: 'shipment.actions.print-item-sheet',
                message: 'Print all items sheets',
              })
            )}
            tooltip={_(
              msg({
                id: 'shipment.actions.print-item-sheet',
                message: 'Print all items sheets',
              })
            )}
          >
            <IconQrCode />
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default ShipmentHeader;

import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { useEstimate } from '@/models/article';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import ArticlePrice from '@/routes/Requests/Request/Article/ArticlePrice';
import useViewPort from '@/utils/useViewport';

export const EstimateArticleAction = ({
  article,
  request,
  onActionDone,
  onCheckErrors,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  onActionDone: () => void;
  onCheckErrors: () => boolean;
}) => {
  const { isMobile } = useViewPort();

  const {
    mutateAsync: estimateArticle,
    isPending: isPendingEstimate,
    isSuccess: isSuccessEstimate,
  } = useEstimate({
    articleId: article.id,
  });

  const handleEstimate = async () => {
    const hasErrors = onCheckErrors();

    if (!hasErrors) {
      await estimateArticle();
      onActionDone();
    }
  };

  return (
    <Stack
      row={!isMobile}
      gap={isMobile ? '0.5rem' : '1.5rem'}
      alignItems={isMobile ? 'stretch' : 'center'}
      style={{ flex: '1' }}
      justifyContent="flex-end"
    >
      <Stack row justifyContent={isMobile ? 'flex-end' : 'center'}>
        <ArticlePrice request={request} article={article} allowDiscount={true} showCost showPrice />
      </Stack>
      {!isMobile && (
        <div
          style={{ backgroundColor: 'var(--color-neutral-300)', width: '1px', height: '24px' }}
        ></div>
      )}
      <Button
        variant="primary"
        size="medium"
        onPress={handleEstimate}
        isLoading={isPendingEstimate || isSuccessEstimate}
      >
        <Trans id="article.actions.estimate_article.validate">Validate the estimate</Trans>
      </Button>
    </Stack>
  );
};

import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import AlertBar from '@/design_system/AlertBar';
import IconUserRefusal from '@/icons/UserRefusal.svg';
import { ARTICLE_CANCELLATION_REASONS, ArticleCancellationDetail } from '@/models/article';

export const ClientRefusalMessage = ({
  cancellationDetail,
  children,
}: {
  cancellationDetail: ArticleCancellationDetail | null;
  children?: React.ReactNode;
}) => {
  const { _ } = useLingui();

  const refusalReason = ARTICLE_CANCELLATION_REASONS.find(
    ({ id }) => cancellationDetail?.reason === id
  )!;

  const title =
    cancellationDetail?.statusWhenCancelled === 'pending-quote-acceptation'
      ? msg({
          id: 'requests.pending-client-delivery-message.cancelled-during-quote-acceptation.title',
          message: 'The estimate has been refused by the client',
        })
      : msg({
          id: 'requests.pending-client-delivery-message.cancelled-during-bill-payment.title',
          message: 'The final quote has been refused by the client',
        });

  return (
    <AlertBar title={_(title)} type="error" size="large" icon={<IconUserRefusal />}>
      <div>
        <Trans id="request.archived-message.client-refusal.text">Client refusal reason:</Trans>{' '}
        &quot;
        {refusalReason.id === 'other' ? (
          <Trans id="request.archived-message.client-refusal.other">
            Other - {cancellationDetail?.otherReason}
          </Trans>
        ) : (
          _(refusalReason.label)
        )}
        &quot;
      </div>
      <div>{children}</div>
    </AlertBar>
  );
};

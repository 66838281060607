import { msg } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconUserRefusal from '@/icons/UserRefusal.svg';

export const PaymentRefusedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'payment_refused'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="danger"
      icon={<IconUserRefusal />}
      creatorName={msg({
        id: 'activities.payment_refused.client',
        message: 'The client',
      })}
      message={msg({
        id: 'activities.payment_refused.message',
        message: 'refused the payment',
      })}
    />
  );
};

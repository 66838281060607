import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import { useShowToast } from '@/design_system/Toast';
import { useCanClaimRequest, useClaimRequest } from '@/models/request';
import { useCurrentSession } from '@/services/auth';

export const RequestClaim = () => {
  const [params] = useSearchParams();

  const requestId = params.get('id');
  const clientToken = params.get('clientToken');
  const { data: canClaim, isError } = useCanClaimRequest({ requestId, clientToken });

  return (
    <>
      {canClaim?.status === 'has_access' && <Navigate to={`/requests/${requestId}`} />}
      {canClaim?.status === 'can_claim' && requestId && clientToken && (
        <ClaimDialog requestId={requestId} clientToken={clientToken} />
      )}
      {(canClaim?.status === 'cannot_access' || isError) && <AccessDeniedDialog />}
    </>
  );
};

const AccessDeniedDialog = () => {
  const navigate = useNavigate();

  return (
    <Dialog
      style={{ width: '25rem' }}
      isOpen
      title={<Trans id="request.claim.access-denied.title">Access denied</Trans>}
      onOpenChange={() => navigate('/requests')}
      dismissable={false}
    >
      <main>
        <p className="paragraph-100-regular">
          <Trans id="request.claim.access-denied.content">
            You cannot take this request in charge or it doesn&apos;t exist anymore.
          </Trans>
        </p>
      </main>
      <footer>
        <Button variant="secondary" size="medium" onPress={() => navigate('/requests')}>
          <Trans id="request.claim.access-denied.actions.ok">Ok</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};

const ClaimDialog = ({ requestId, clientToken }: { requestId: string; clientToken: string }) => {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const { currentSession } = useCurrentSession();
  const showToast = useShowToast();

  // Maybe we'll need to handle the case where the user has roles on multiple stores
  const store = currentSession?.stores[0];

  const { mutateAsync: claimRequest, isPending: isPendingClaimRequest } = useClaimRequest({
    requestId: requestId,
  });

  return (
    <Dialog
      style={{ width: '25rem' }}
      isOpen
      title={<Trans id="request.claim.title">Take request in charge</Trans>}
      onOpenChange={() => navigate('/requests')}
      dismissable={false}
    >
      <main>
        <p className="paragraph-100-regular">
          <Trans id="request.claim.content">
            This request was created by a client and doesn&apos;t belong to any store. Do you want
            to take it in charge on behalf of <strong>{store?.name}</strong>?
          </Trans>
        </p>
      </main>
      <footer>
        <Button
          variant="secondary"
          size="medium"
          onPress={() => navigate('/requests')}
          disabled={isPendingClaimRequest}
        >
          <Trans id="request.claim.actions.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          size="medium"
          isLoading={isPendingClaimRequest}
          onPress={() => {
            claimRequest({
              clientToken: clientToken,
            }).then(() => {
              navigate(`/requests/${requestId}`);
              showToast({
                text: _(
                  msg({
                    id: 'request.claim.actions.success',
                    message: 'The request has been successfully taken in charge by your store',
                  })
                ),
                type: 'success',
              });
            });
          }}
        >
          <Trans id="request.claim.actions.take-in-charge">Take in charge</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};

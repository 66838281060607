import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import NewComment from '@/components/NewComment';
import RadioGroup, { yesNoRadioOptions } from '@/design_system/RadioGroup/RadioGroup';
import Stack from '@/design_system/Stack';
import IconInfo from '@/icons/Info.svg';
import { useUpdateArticle } from '@/models/article';
import { ArticleWithRelations, Request } from '@/models/request';

const ClientComment = ({
  request,
  article,
  disabled,
}: {
  request: Request;
  article: ArticleWithRelations;
  disabled: boolean;
}) => {
  const { _ } = useLingui();

  const { mutate: updateArticle, isPending: isUpdatingArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const [showComment, setShowComment] = useState(!!article.clientComment);

  const startEditComment = () => {
    setShowComment(true);
  };

  const deleteComment = () => {
    setShowComment(false);
    if (article.clientComment !== null) {
      updateArticle({ data: { clientComment: null } });
    }
  };

  return (
    <Stack gap="4px">
      <RadioGroup
        value={showComment ? 'yes' : 'no'}
        onChange={(value) => {
          if (value === 'yes') {
            startEditComment();
          } else {
            deleteComment();
          }
        }}
        label={_(
          msg({
            id: 'article.form.client-comment.label',
            message: 'Would you like to add a comment for the client?',
          })
        )}
        options={yesNoRadioOptions.map((option) => ({ ...option, children: _(option.children) }))}
        isDisabled={disabled}
      />

      {showComment && (
        <>
          <NewComment
            comment={article.clientComment}
            setComment={(comment) => updateArticle({ data: { clientComment: comment } })}
            ariaLabel={_(
              msg({
                id: 'article.form.client-comment.input-label',
                message: 'Write a comment for the client',
              })
            )}
            isDisabled={disabled}
            isLoading={isUpdatingArticle}
          />

          <Stack row gap="0.25rem" alignItems="center" flexWrap="nowrap">
            <div className="color-primary-700" style={{ fontSize: '1.25rem' }}>
              <IconInfo />
            </div>
            <p className="paragraph-100-regular">
              <Trans id="article.form.client-comment.disclaimer">
                This comment will be visible for the client in the estimate page.
              </Trans>
            </p>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default ClientComment;

import { useState } from 'react';
import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import IconArchive from '@/icons/Archive.svg';
import IconShipmentIssueResolved from '@/icons/ShipmentIssueResolved.svg';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { useArticleOngoingShipment } from '@/models/shipment';
import { useCurrentSession } from '@/services/auth';

import ArchiveShipmentIssueDialog from './ArchiveShipmentIssueDialog';
import ResolveShipmentIssueDialog from './ResolveShipmentIssueDialog';

export const HandleShipmentIssueAction = ({
  article,
  request,
  onActionDone,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  onActionDone: () => void;
}) => {
  const { currentSession } = useCurrentSession();

  const [isOpenResolveIssueDialog, setIsOpenResolveIssueDialog] = useState(false);
  const [isOpenArchiveIssueDialog, setIsOpenArchiveIssueDialog] = useState(false);

  const { shipment } = useArticleOngoingShipment(article);

  const shipmentArticle = shipment?.articles.find(({ articleId }) => articleId === article.id);

  const canVerifyReception = shipment?.canReceptionBeVerifiedBy(currentSession);
  const canArchive = currentSession?.hasPermission('archive_article', {
    organizationId: article.organizationId,
  });

  if (!shipment || !shipmentArticle) {
    return;
  }

  if (canVerifyReception) {
    return (
      <>
        <Button variant="primary" size="medium" onPress={() => setIsOpenResolveIssueDialog(true)}>
          <IconShipmentIssueResolved />
          <Trans id="article.actions.handle_shipment_issue.resolve">Resolve shipment issue</Trans>
        </Button>
        <ResolveShipmentIssueDialog
          shipmentArticle={shipmentArticle}
          isOpen={isOpenResolveIssueDialog}
          setIsOpen={setIsOpenResolveIssueDialog}
          onActionDone={onActionDone}
        />
      </>
    );
  }

  if (canArchive) {
    return (
      <>
        <Button
          variant="secondary-danger"
          size="medium"
          onPress={() => setIsOpenArchiveIssueDialog(true)}
        >
          <IconArchive />
          <Trans id="article.actions.handle_shipment_issue.archive">Archive item</Trans>
        </Button>
        <ArchiveShipmentIssueDialog
          shipmentArticle={shipmentArticle}
          request={request}
          isOpen={isOpenArchiveIssueDialog}
          setIsOpen={setIsOpenArchiveIssueDialog}
          onActionDone={onActionDone}
        />
      </>
    );
  }

  return null;
};

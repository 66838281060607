import { ReactNode } from 'react';
import { Plural, Trans } from '@lingui/macro';

import Stack from '@/design_system/Stack';
import IconPackage from '@/icons/Package.svg';
import IconShipmentDone from '@/icons/ShipmentDone.svg';
import IconTruck from '@/icons/Truck.svg';
import { BrandWrapper, BrandWrapperCenter } from '@/layouts/Brand';
import { ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';

export const Delivery = ({ request }: { request: ClientRequestWithRelations }) => {
  let messageContent: ReactNode | null = null;

  if (request.isInPendingClientDeliveryStep && request.isCancelled) {
    const initialQuoteRefused = !!request.articles[0].quoteRefusedAt;

    return (
      <BrandWrapper>
        <BrandWrapperCenter>
          <Stack gap="1.5rem">
            <Stack row gap="0.5rem">
              <p className="headline-200-bold headline-300-bold-mobile">
                <Trans id="store.request.delivery.quote-refused.title">
                  The request has been archived
                </Trans>
              </p>
              <p className="paragraph-50-regular paragraph-100-regular-mobile">
                {initialQuoteRefused && (
                  <Trans id="store.request.delivery.initial-quote-refused">
                    The client refused the estimate.
                  </Trans>
                )}
                {!initialQuoteRefused && (
                  <Trans id="store.request.delivery.final-quote-refused">
                    The client refused the final quote.
                  </Trans>
                )}{' '}
                <Trans id="store.request.delivery.store-pickup">
                  They have been notified to come pick-up their{' '}
                  <Plural value={request.articles.length} one="item" other="items" /> in your store.
                </Trans>
              </p>
            </Stack>
            <ClientArticlesTable request={request} showArticleComment />
            {!!request.client && <ClientInfo request={request} canEdit={false} />}
          </Stack>
        </BrandWrapperCenter>
      </BrandWrapper>
    );
  }

  if (request.isInPendingDeliveryStep) {
    messageContent = (
      <Stack row gap="0.5rem" flexWrap="nowrap">
        <IconPackage className="color-tertiary-700" style={{ fontSize: '24px' }} />
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          {(!request.client || request.client.deliveryOption === 'store') && (
            <Trans id="store.request.delivery.pending.store">
              The workshop is preparing the{' '}
              <Plural value={request.articles.length} one="item for its" other="items for their" />{' '}
              shipment back to your store.
            </Trans>
          )}
          {request.client?.deliveryOption !== 'store' && (
            <Trans id="store.request.delivery.pending.client">
              The workshop is preparing the{' '}
              <Plural value={request.articles.length} one="item for its" other="items for their" />{' '}
              shipment back to the client.
            </Trans>
          )}
        </p>
      </Stack>
    );
  } else if (request.isInTransitDeliveryStep || request.isInReceivedDeliveryStep) {
    messageContent = (
      <Stack row gap="0.5rem" flexWrap="nowrap">
        <IconTruck className="color-tertiary-700" style={{ fontSize: '24px' }} />
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          {(!request.client || request.client.deliveryOption === 'store') && (
            <Trans id="store.request.delivery.in-transit.store">
              The{' '}
              <Plural
                value={request.articles.length}
                one="item is on its"
                other="items are on their"
              />{' '}
              way back to your store.
            </Trans>
          )}
          {request.client?.deliveryOption !== 'store' && (
            <Trans id="store.request.delivery.in-transit.client">
              The{' '}
              <Plural
                value={request.articles.length}
                one="item is on its"
                other="items are on their"
              />{' '}
              way back to the client.
            </Trans>
          )}
        </p>
      </Stack>
    );
  } else if (request.isInPendingClientDeliveryStep) {
    messageContent = (
      <>
        <Stack row gap="0.5rem" flexWrap="nowrap">
          <IconShipmentDone className="color-primary-700" style={{ fontSize: '24px' }} />
          <p className="paragraph-50-regular paragraph-100-regular-mobile">
            <Trans id="store.request.delivery.pending-client">
              The <Plural value={request.articles.length} one="item has" other="items have" /> been
              successfully delivered to your store. The client has been notified to come pick{' '}
              <Plural value={request.articles.length} one="it" other="them" /> up.
            </Trans>
          </p>
        </Stack>
      </>
    );
  }

  return (
    <BrandWrapper>
      <BrandWrapperCenter>
        <Stack gap="1.5rem">
          <ClientStepper step="delivery" request={request} />
          {messageContent}
          <Stack gap="1rem">
            <ClientArticlesTable
              request={request}
              showPrice={!!request.client}
              showArticleComment
            />
            {!!request.client && <ClientInfo request={request} canEdit={false} />}
          </Stack>
        </Stack>
      </BrandWrapperCenter>
    </BrandWrapper>
  );
};

import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import LegacyInputSelect from '@/design_system/InputSelect/LegacyInputSelect';
import { UserWithRelations } from '@/models/user';
import { UserMultiSelectItem } from '@/routes/Requests/Tabs/common';

export const SupervisorSelect = ({
  selectedKey,
  availableUsers,
  onSelectionChange,
}: {
  selectedKey: string;
  availableUsers?: UserWithRelations[];
  onSelectionChange: (key: string | undefined) => void;
}) => {
  const { _ } = useLingui();

  return (
    <LegacyInputSelect
      placeholder={_(
        msg({ id: 'requests.supervisor-select.placeholder', message: 'Supervisor: All' })
      )}
      ariaLabel={_(
        msg({ id: 'requests.supervisor-select.label', message: 'Search by supervisor' })
      )}
      selectedKey={selectedKey}
      onSelectionChange={(key) => {
        onSelectionChange(key ?? '');
      }}
      size="medium"
      style={{ minWidth: 185 }}
    >
      {availableUsers?.map((user) => <UserMultiSelectItem key={user.id} user={user} />)}
    </LegacyInputSelect>
  );
};

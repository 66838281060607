import { forwardRef, Ref } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CreationStepConfig } from '@/api';
import AlertBar from '@/design_system/AlertBar';
import Box from '@/design_system/Box';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import { useActionTypes } from '@/models/actionType';
import { Medium } from '@/models/medium';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import ArticleDispatch from '@/routes/Requests/components/ArticleDispatch';
import ActionTypes from '@/routes/Requests/Request/Article/ActionTypes';
import ArticlePhotos from '@/routes/Requests/Request/Article/ArticlePhotos';
import ColorSize from '@/routes/Requests/Request/Article/ColorSize';
import { Brand } from '@/routes/Requests/Request/Article/components/Brand';
import {
  ProductCategoryL1,
  ProductCategoryL2,
  ProductCategoryL3,
} from '@/routes/Requests/Request/Article/components/ProductCategories';
import ProductInfo from '@/routes/Requests/Request/Article/ProductInfo';
import { ProductPhoto } from '@/routes/Requests/Request/Article/ProductPhoto';
import Warranty from '@/routes/Requests/Request/Article/Warranty';
import useViewPort from '@/utils/useViewport';

export const ArticleDetails = ({
  request,
  article,
  showErrors,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  showErrors: boolean;
}) => {
  const { isMobile } = useViewPort();

  const errors = useArticleDetailsError(article);

  const allowWarranty =
    (article.step && 'allowWarranty' in article.step.config && article.step.config.allowWarranty) ??
    false;

  return (
    <Box padding={isMobile ? '16px' : '16px 24px'} gap="1rem">
      <h2 className="headline-300-bold">
        <Trans id="requests.articles.form.title">Information</Trans>
      </h2>

      <Box
        padding="16px"
        style={{
          borderColor: showErrors && errors.productError ? 'var(--color-danger-700)' : undefined,
          background: 'var(--color-neutral-200)',
        }}
        gap="0.5rem"
        className={isMobile ? 'bg-neutral-100' : 'bg-neutral-0'}
      >
        <Stack row mobileColumn gap="1rem">
          <ProductInfo request={request} article={article} key={article.productId} />

          <div className="requests__new__articles__new__product-or paragraph-100-regular">
            <hr />
            <span>
              <Trans id="_general.or">or</Trans>
            </span>
            <hr />
          </div>

          <ProductCategoryL1 request={request} article={article} />
        </Stack>

        {showErrors && !!errors.productError && (
          <Message type="error">{errors.productError}</Message>
        )}
      </Box>

      <ProductCategoryL2
        request={request}
        article={article}
        l2Error={showErrors && errors.productL2Error ? errors.productL2Error : undefined}
      />

      {!!request.organization.brands.length && (
        <Brand
          request={request}
          article={article}
          otherBrandError={
            showErrors && errors.otherBrandError ? errors.otherBrandError : undefined
          }
        />
      )}

      <ProductCategoryL3
        request={request}
        article={article}
        l3Error={showErrors && errors.productL3Error ? errors.productL3Error : undefined}
      />

      <ColorSize request={request} article={article} />

      {!!request.client && allowWarranty && (
        <Warranty
          request={request}
          article={article}
          key={`purchase-date ${article.purchaseDate ?? ''}`}
        />
      )}

      {!!article.product?.data.image && (
        <ProductPhoto
          productName={article.product.name}
          productImage={article.product.data.image}
        />
      )}
    </Box>
  );
};

export const ArticleServices = ({
  request,
  article,
  showErrors,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  showErrors: boolean;
}) => {
  const { isMobile } = useViewPort();

  const creationConfig = article.step?.config as CreationStepConfig['config'] | undefined;

  const errors = useArticleServicesError(article);

  return (
    <Box padding={isMobile ? '16px' : '16px 24px'} gap="1rem">
      <h2 className="headline-300-bold">
        <Trans id="requests.articles.form.actions.title">Services</Trans>
      </h2>
      <ActionTypes
        request={request}
        article={article}
        mode={creationConfig?.requireCost || creationConfig?.requirePrice ? 'both' : 'need'}
        showCost={creationConfig?.requireCost}
        showPrice={creationConfig?.requirePrice}
        error={showErrors ? errors.actionsError : undefined}
      />
      <ArticlePhotos article={article} error={showErrors ? errors.photosError : undefined} />
      {creationConfig?.requireDispatch && (
        <ArticleDispatch
          request={request}
          article={article}
          error={showErrors ? errors.workshopError : undefined}
          disabled={false}
        />
      )}
    </Box>
  );
};

export const useHasNoAvailableActions = (article: ArticleWithRelations) => {
  const { data: actionTypesData } = useActionTypes(
    {
      requestId: article.requestId,
      productL1: article.productL1 ?? undefined,
      productL2: article.productL2 ?? undefined,
      productL3: article.productL3 ?? undefined,
    },
    {
      enabled:
        article.currentActions.length === 0 &&
        article.currentCustomActions.length === 0 &&
        article.currentPackActions.length === 0 &&
        !!article.productL1,
      keepPreviousData: true,
    }
  );

  const hasNoAvailableActions =
    actionTypesData?.actionTypes?.length === 0 && actionTypesData?.packActionTypes?.length === 0;

  return hasNoAvailableActions;
};

export const getArticleDetailsError = ({ article }: { article: ArticleWithRelations }) => {
  const productError =
    !article.product && !article.productL1
      ? msg({
          id: 'requests.articles.form.error.product',
          message: 'Please select an item reference or a type',
        })
      : undefined;

  const productL2Error =
    !article.product && article.productL1 && !article.productL2
      ? msg({ id: 'requests.articles.form.error.productL2', message: 'Please select a category' })
      : undefined;

  const productL3Error =
    !article.product && article.productL1 && !article.productL3
      ? msg({ id: 'requests.articles.form.error.productL3', message: 'Please select a material' })
      : undefined;

  const otherBrandError =
    article.data.brand?.isOther && !article.data.brand?.name
      ? msg({
          id: 'article.form.brand.other.specify-name.error',
          message: 'Please select a brand name',
        })
      : undefined;

  return {
    productError,
    productL2Error,
    productL3Error,
    otherBrandError,
    hasError: !!productError || !!productL2Error || !!productL3Error || !!otherBrandError,
  };
};

export const useArticleDetailsError = (article: ArticleWithRelations) => {
  const { _ } = useLingui();
  const { productError, productL2Error, productL3Error, otherBrandError, hasError } =
    getArticleDetailsError({
      article,
    });

  return {
    productError: productError ? _(productError) : undefined,
    productL2Error: productL2Error ? _(productL2Error) : undefined,
    productL3Error: productL3Error ? _(productL3Error) : undefined,
    otherBrandError: otherBrandError ? _(otherBrandError) : undefined,
    hasError,
  };
};

export const getArticleServicesError = ({
  article,
  hasNoAvailableActions,
  photo,
}: {
  article: ArticleWithRelations;
  hasNoAvailableActions?: boolean;
  photo: Medium | undefined;
}) => {
  const creationConfig = article.step?.config as CreationStepConfig['config'] | undefined;
  const requireDispatch = creationConfig?.requireDispatch === true;
  const requireCost = creationConfig?.requireCost === true;
  const requirePrice = creationConfig?.requirePrice === true;

  const noActionsError =
    article.currentActions.length === 0 &&
    article.currentCustomActions.length === 0 &&
    article.currentPackActions.length === 0
      ? hasNoAvailableActions
        ? msg({
            id: 'requests.articles.form.error.actions-custom',
            message: 'No defects are available for this item. Please add a custom defect.',
          })
        : msg({
            id: 'requests.articles.form.error.actions',
            message: 'Please select at least one service',
          })
      : undefined;

  const costError =
    requireCost &&
    article.currentCustomActions.some((customAction) => customAction.costAmount === null)
      ? msg({
          id: 'article.estimate.actions.error.custom-actions-no-cost',
          message: 'Please provide cost details for the custom actions',
        })
      : undefined;

  const priceError =
    requirePrice &&
    article.currentCustomActions.some((customAction) => customAction.priceAmount === null)
      ? msg({
          id: 'article.estimate.actions.error.custom-actions-no-price',
          message: 'Please provide price details for the custom actions',
        })
      : undefined;

  const actionsError = noActionsError ?? costError ?? priceError;

  const photosError = !photo
    ? msg({ id: 'requests.articles.form.error.photo', message: 'Please add at least one photo' })
    : undefined;

  const workshopError =
    requireDispatch && !article.workshopId
      ? msg({ id: 'requests.articles.form.error.workshop', message: 'Please select a workshop' })
      : undefined;

  return {
    actionsError,
    photosError,
    workshopError,
    hasError: !!actionsError || !!photosError || !!workshopError,
  };
};

export const useArticleServicesError = (article: ArticleWithRelations) => {
  const { _ } = useLingui();

  const hasNoAvailableActions = useHasNoAvailableActions(article);

  const { actionsError, photosError, workshopError, hasError } = getArticleServicesError({
    article,
    hasNoAvailableActions,
    photo: article.articlePhoto,
  });

  return {
    actionsError: actionsError ? _(actionsError) : undefined,
    photosError: photosError ? _(photosError) : undefined,
    workshopError: workshopError ? _(workshopError) : undefined,
    hasError,
  };
};

export const ArticleFormGlobalError = forwardRef(function ArticleFormGlobalError(
  {
    showGlobalError,
    showNoActionsWarning,
  }: { showGlobalError: boolean; showNoActionsWarning: boolean },
  ref: Ref<HTMLDivElement>
) {
  return (
    <Stack
      ref={ref}
      style={{ marginBottom: showGlobalError || showNoActionsWarning ? '1rem' : undefined }}
      gap="0.5rem"
    >
      {showGlobalError && (
        <AlertBar
          type="error"
          title={
            <Trans id="requests.edit.articles.alert.missing-info">
              Please fill the missing information.
            </Trans>
          }
        />
      )}
      {showNoActionsWarning && (
        <AlertBar
          type="warning"
          title={
            <Trans id="requests.edit.articles.alert.no-catalog-actions">
              No services available for this item. Please add a custom defect to add item to the
              request.
            </Trans>
          }
        />
      )}
    </Stack>
  );
});

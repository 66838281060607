import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import { BasicDropdownItem } from '@/design_system/Dropdown/Dropdown';
import LegacyInputSelect from '@/design_system/InputSelect/LegacyInputSelect';
import InputText from '@/design_system/InputText';
import Pagination from '@/design_system/Pagination';
import Stack from '@/design_system/Stack';
import Table from '@/design_system/Table';
import { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import IconEdit from '@/icons/Edit.svg';
import IconSend from '@/icons/Send.svg';
import IconTrash from '@/icons/Trash.svg';
import { Store, useStores } from '@/models/store';
import { UserWithRelations, useUsers } from '@/models/user';
import {
  LEGACY_USER_ROLE_TYPE_LABELS,
  LegacyUserRoleType,
  useDeleteUserRole,
  useInvitations,
  useInviteUser,
  useUpdateUserRole,
} from '@/models/userRole';
import { useWorkshops, Workshop } from '@/models/workshop';
import { useCurrentOrganization, useCurrentSession } from '@/services/auth';
import { formatDate } from '@/utils/date';

const USERS_PER_PAGE = 10;

const Users = () => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();
  const [page, setPage] = useState(1);

  const { data: { users, meta } = {} } = useUsers({
    limit: USERS_PER_PAGE,
    offset: (page - 1) * USERS_PER_PAGE,
  });

  const { data: invitations } = useInvitations();

  // TODO: Use a combobox here instead
  const { data: { stores } = {} } = useStores({
    limit: 100,
    internal: true,
  });

  const { data: { workshops } = {} } = useWorkshops({
    internal: true,
  });

  const { mutateAsync: deleteInvitation, isPending: isDeletingInvitation } = useDeleteUserRole();

  return (
    <Stack gap="40px">
      <Box padding="24px" gap="24px">
        <Stack row alignItems="center" justifyContent="space-between">
          <h2 className="headline-200-bold">
            <Trans id="settings.users.title">Organization members</Trans>
          </h2>
          <InviteUser stores={stores} workshops={workshops} />
        </Stack>
        <Table aria-label={_(msg({ id: 'settings.users.table.label', message: 'Membres' }))}>
          <Header>
            <Column width="2fr" minWidth={200} isRowHeader>
              <Trans id="settings.users.column.name">Name</Trans>
            </Column>
            <Column width="2fr" minWidth={200}>
              <Trans id="settings.users.column.email">Email</Trans>
            </Column>
            <Column width="2fr" minWidth={200}>
              <Trans id="settings.users.column.role">Role</Trans>
            </Column>
            <Column width={90} />
          </Header>
          <Body>
            {users?.map((user) => (
              <Row key={user.id}>
                <Cell className="sentry-mask">
                  <b>{user.name}</b>
                </Cell>
                <Cell className="sentry-mask">{user.email}</Cell>
                <Cell>
                  {user.roles.map((userRole) => {
                    if (userRole.store) {
                      return `${userRole.role.name} (${userRole.store.name})`;
                    }

                    if (userRole.workshop) {
                      return `${userRole.role.name} (${userRole.workshop.name})`;
                    }

                    return userRole.role.name;
                  })}
                </Cell>
                <Cell>
                  <Stack row gap="4px">
                    <UpdateUserRole user={user} stores={stores} workshops={workshops} />
                    {currentSession?.id !== user.id && (
                      <Button
                        variant="secondary"
                        iconOnly
                        size="small"
                        ariaLabel={_(
                          msg({
                            id: 'settings.users.remove-user.tooltip',
                            message: `Remove ${user.name} from your organization`,
                          })
                        )}
                        tooltip={
                          <p className="sentry-mask">
                            {_(
                              msg({
                                id: 'settings.users.remove-user.tooltip',
                                message: `Remove ${user.name} from your organization`,
                              })
                            )}
                          </p>
                        }
                        disabled={isDeletingInvitation}
                        onPress={() => {
                          if (
                            confirm(
                              _(
                                msg({
                                  id: 'settings.users.remove-user.confirm-dialog',
                                  message: `Are you sure you want to remove ${user.name} from your organization?`,
                                })
                              )
                            )
                          ) {
                            deleteInvitation({ userId: user.id }).catch(console.error);
                          }
                        }}
                      >
                        <IconTrash />
                      </Button>
                    )}
                  </Stack>
                </Cell>
              </Row>
            ))}
          </Body>
        </Table>
        <Pagination
          page={page}
          itemsPerPage={USERS_PER_PAGE}
          count={meta?.count}
          onPageChange={setPage}
        />
      </Box>
      {(invitations?.length ?? 0) > 0 && (
        <Box padding="24px" gap="24px">
          <Stack row alignItems="center" justifyContent="space-between">
            <h2 className="headline-200-bold">
              <Trans id="settings.users.invited.title">Invited members</Trans>
            </h2>
            <InviteUser stores={stores} />
          </Stack>
          <Table
            aria-label={_(
              msg({ id: 'settings.users.invited.table.label', message: 'Invited members' })
            )}
          >
            <Header>
              <Column width="2fr" minWidth={200} isRowHeader>
                <Trans id="settings.users.invited.columns.email">Email</Trans>
              </Column>
              <Column width="1fr" minWidth={110}>
                <Trans id="settings.users.invited.columns.role">Role</Trans>
              </Column>
              <Column width="1fr" minWidth={110}>
                <Trans id="settings.users.invited.columns.invitation-date">Invitation date</Trans>
              </Column>
              <Column width={90} />
            </Header>
            <Body>
              {invitations?.map((userRole) => (
                <Row key={userRole.id}>
                  <Cell className="sentry-mask">{userRole.email}</Cell>
                  <Cell>
                    {userRole.role.name}
                    {userRole.store && ` (${userRole.store.name})`}
                    {userRole.workshop && ` (${userRole.workshop.name})`}
                  </Cell>
                  <Cell>
                    {formatDate(userRole.invitedAtDate, {
                      dateStyle: 'medium',
                      timeStyle: 'short',
                    })}
                  </Cell>
                  <Cell>
                    <Button
                      variant="secondary"
                      iconOnly
                      size="small"
                      ariaLabel={_(
                        msg({
                          id: 'settings.users.invited.uninvite.tooltip',
                          message: `Uninvite ${userRole.email!}`,
                        })
                      )}
                      tooltip={_(
                        msg({
                          id: 'settings.users.invited.uninvite.tooltip',
                          message: `Uninvite ${userRole.email!}`,
                        })
                      )}
                      disabled={isDeletingInvitation}
                      onPress={() => {
                        if (
                          confirm(
                            _(
                              msg({
                                id: 'settings.users.invited.uninvite.confirm-dialog',
                                message: `Are you sure you want to delete the invitation for ${userRole.email!}?`,
                              })
                            )
                          )
                        ) {
                          deleteInvitation({ email: userRole.email! }).catch(console.error);
                        }
                      }}
                    >
                      <IconTrash />
                    </Button>
                  </Cell>
                </Row>
              ))}
            </Body>
          </Table>
        </Box>
      )}
    </Stack>
  );
};

const InviteUser = ({ stores = [], workshops }: { stores?: Store[]; workshops?: Workshop[] }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button variant="secondary" size="small" onPress={() => setIsOpen(true)}>
        <IconSend />
        <Trans id="settings.users.invite-modal.trigger">Invite new member</Trans>
      </Button>
      <UserRoleDialog stores={stores} workshops={workshops} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

const UpdateUserRole = ({
  user,
  stores,
  workshops,
}: {
  user: UserWithRelations;
  stores?: Store[];
  workshops?: Workshop[];
}) => {
  const { _ } = useLingui();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        variant="secondary"
        iconOnly
        size="small"
        ariaLabel={_(
          msg({ id: 'settings.users.update-modal.trigger', message: `Manage ${user.name}` })
        )}
        tooltip={
          <p className="sentry-mask">
            {_(msg({ id: 'settings.users.update-modal.trigger', message: `Manage ${user.name}` }))}
          </p>
        }
        onPress={() => setIsOpen(true)}
      >
        <IconEdit />
      </Button>
      <UserRoleDialog
        stores={stores}
        workshops={workshops}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        user={user}
      />
    </>
  );
};

const UserRoleDialog = ({
  stores = [],
  workshops = [],
  isOpen,
  setIsOpen,
  user,
}: {
  stores?: Store[];
  workshops?: Workshop[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  user?: UserWithRelations;
}) => {
  const { _ } = useLingui();

  const { mutateAsync: inviteUser, isPending: isPendingInviteUser } = useInviteUser();
  const { mutateAsync: updateUserRole, isPending: isPendingUpdateUserRole } = useUpdateUserRole();

  const isPending = isPendingInviteUser || isPendingUpdateUserRole;

  const submit = async (
    email: string,
    legacyRole: LegacyUserRoleType,
    storeId?: string,
    workshopId?: string
  ) => {
    if (user) {
      await updateUserRole({
        userId: user.id,
        legacyRole,
        storeId: legacyRole === 'store-manager' ? storeId : null,
        workshopId: legacyRole === 'workshop-manager' ? workshopId : null,
      });

      setIsOpen(false);
    } else {
      await inviteUser({
        email,
        legacyRole,
        storeId: legacyRole === 'store-manager' ? storeId : null,
        workshopId: legacyRole === 'workshop-manager' ? workshopId : null,
      });

      setIsOpen(false);
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          if (isPending) return;
          setIsOpen(false);
        }
      }}
      title={
        user ? (
          <p className="sentry-mask">
            {_(msg({ id: 'settings.users.update-modal.title', message: `Manage ${user.name}` }))}
          </p>
        ) : (
          _(msg({ id: 'settings.users.invite-modal.title', message: 'Invite a user' }))
        )
      }
    >
      <UserRoleDialogForm
        stores={stores}
        workshops={workshops}
        user={user}
        onSubmit={submit}
        isPendingSubmit={isPending}
      />
    </Dialog>
  );
};

const UserRoleDialogForm = ({
  stores = [],
  workshops = [],
  user,
  onSubmit,
  isPendingSubmit,
}: {
  stores?: Store[];
  workshops?: Workshop[];
  user?: UserWithRelations;
  onSubmit: (
    email: string,
    legacyRole: LegacyUserRoleType,
    storeId?: string,
    workshopId?: string
  ) => Promise<void>;
  isPendingSubmit: boolean;
}) => {
  const { _ } = useLingui();

  const [currentOrganization] = useCurrentOrganization();

  const initialUserRole = user?.roles.find(
    (userRole) => userRole.organizationId === currentOrganization?.id
  );
  const initialLegacyRole = initialUserRole?.legacyRole;
  const initialStoreId = initialUserRole?.storeId ?? undefined;
  const initialWorkshopId = initialUserRole?.workshopId ?? undefined;

  const [email, setEmail] = useState(user?.email ?? '');
  const [legacyRole, setLegacyRole] = useState<LegacyUserRoleType | undefined>(initialLegacyRole);
  const [storeId, setStoreId] = useState<string | undefined>(initialStoreId);
  const [workshopId, setWorkshopId] = useState<string | undefined>(initialWorkshopId);

  const [error, setError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [storeError, setStoreError] = useState('');
  const [workshopError, setWorkshopError] = useState('');

  const enableUpdate =
    initialLegacyRole !== legacyRole ||
    initialStoreId !== storeId ||
    initialWorkshopId !== workshopId;

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (!legacyRole) {
      setRoleError(
        _(msg({ id: 'settings.user.modal.role-error', message: 'Please select a role' }))
      );
      return;
    }

    if (legacyRole === 'store-manager' && !storeId) {
      setStoreError(
        _(msg({ id: 'settings.user.modal.store-error', message: 'Please select a store' }))
      );
      return;
    }

    if (legacyRole === 'workshop-manager' && !workshopId) {
      setWorkshopError(
        _(msg({ id: 'settings.user.modal.workshop-error', message: 'Please select a workshop' }))
      );
      return;
    }

    onSubmit(email, legacyRole, storeId, workshopId).catch((err) => {
      console.error(err);
      setError(
        (err.message as string) ??
          _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
      );
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <main>
        <Stack gap="24px">
          <InputText
            label={<Trans id="settings.users.invite-modal.field.email.label">Email</Trans>}
            type="email"
            placeholder="user@prolong.io"
            value={email}
            onChange={setEmail}
            isRequired
            isDisabled={isPendingSubmit || !!user}
            isInvalid={!!error}
          />
          <LegacyInputSelect
            label={<Trans id="settings.users.invite-modal.field.role.label">Role</Trans>}
            placeholder={_(
              msg({
                id: 'settings.users.invite-modal.field.role.placeholder',
                message: 'Select a role',
              })
            )}
            selectedKey={legacyRole}
            onSelectionChange={(legacyRole) => {
              setLegacyRole(legacyRole as LegacyUserRoleType);
              setRoleError('');
            }}
            isDisabled={isPendingSubmit}
            isInvalid={!!error || !!roleError}
            messageType={roleError ? 'error' : undefined}
            messageText={roleError}
          >
            <BasicDropdownItem id="admin" text={_(LEGACY_USER_ROLE_TYPE_LABELS['admin'])} />
            <BasicDropdownItem
              id="after-sales-manager"
              text={_(LEGACY_USER_ROLE_TYPE_LABELS['after-sales-manager'])}
            />
            {stores.length > 0 && (
              <BasicDropdownItem
                id="store-manager"
                text={_(LEGACY_USER_ROLE_TYPE_LABELS['store-manager'])}
              />
            )}
            {workshops.length > 0 && (
              <BasicDropdownItem
                id="workshop-manager"
                text={_(LEGACY_USER_ROLE_TYPE_LABELS['workshop-manager'])}
              />
            )}
          </LegacyInputSelect>
          {legacyRole === 'store-manager' && (
            <LegacyInputSelect
              label={<Trans id="settings.users.invite-modal.field.store.label">Store</Trans>}
              placeholder={_(
                msg({
                  id: 'settings.users.invite-modal.field.store.placeholder',
                  message: 'Select a store',
                })
              )}
              selectedKey={storeId}
              onSelectionChange={(storeId) => {
                setStoreId(storeId as string);
                setStoreError('');
              }}
              isDisabled={isPendingSubmit}
              isInvalid={!!error || !!storeError}
              error={storeError}
            >
              {stores.map((store) => (
                <BasicDropdownItem key={store.id} id={store.id} text={store.name} />
              ))}
            </LegacyInputSelect>
          )}
          {legacyRole === 'workshop-manager' && (
            <LegacyInputSelect
              label={<Trans id="settings.users.invite-modal.field.workshop.label">Workshop</Trans>}
              placeholder={_(
                msg({
                  id: 'settings.users.invite-modal.field.workshop.placeholder',
                  message: 'Select a workshop',
                })
              )}
              selectedKey={workshopId}
              onSelectionChange={(workshopId) => {
                setWorkshopId(workshopId as string);
                setWorkshopError('');
              }}
              isDisabled={isPendingSubmit}
              isInvalid={!!error || !!workshopError}
              error={workshopError}
            >
              {workshops.map((workshop) => (
                <BasicDropdownItem key={workshop.id} id={workshop.id} text={workshop.name} />
              ))}
            </LegacyInputSelect>
          )}
          {!user && (
            <p className="paragraph-100-regular">
              <Trans id="settings.users.invite-modal.disclaimer">
                The invited user will receive <b>an email with a link</b> to set up their account.
              </Trans>
            </p>
          )}
          {error && <p className="paragraph-100-medium text-danger text-center">{error}</p>}
        </Stack>
      </main>
      <footer>
        {user ? (
          <Button
            variant="primary"
            size="small"
            type="submit"
            isLoading={isPendingSubmit}
            disabled={!enableUpdate}
          >
            <Trans id="settings.users.update-modal.submit">Save</Trans>
          </Button>
        ) : (
          <Button variant="primary" size="small" type="submit" isLoading={isPendingSubmit}>
            <Trans id="settings.users.invite-modal.submit">Send invitation</Trans>
          </Button>
        )}
      </footer>
    </form>
  );
};

export default Users;

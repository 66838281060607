import { Trans } from '@lingui/macro';

import { RequestWithRelations } from '@/models/request';
import { useShipments } from '@/models/shipment';
import { ShipmentsList } from '@/routes/Shipments/Tabs/common';
import useViewPort from '@/utils/useViewport';

const ShipmentsTable = ({ request }: { request: RequestWithRelations }) => {
  const { isMobile } = useViewPort();
  const { data: { shipments } = { shipments: [] }, isLoading } = useShipments({
    requestId: request.id,
  });

  return (
    <>
      {!isLoading && shipments.length === 0 && (
        <p
          className="paragraph-200-regular text-secondary"
          style={{ paddingTop: isMobile ? '0.5rem' : undefined }}
        >
          <Trans id="request.related-shipments.none">No deliveries associated yet</Trans>
        </p>
      )}
      {!isLoading && shipments.length > 0 && <ShipmentsList shipments={shipments} />}
    </>
  );
};

export default ShipmentsTable;

import { Link } from 'react-aria-components';
import { useLingui } from '@lingui/react';

import Stack from '@/design_system/Stack';
import { Organization } from '@/models/organization';
import { getRequestorTypeLabel } from '@/models/request';
import { ArticleWithRelations } from '@/models/shipment';
import { requestorTypeIcons } from '@/routes/Requests/Tabs/common';
import { useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';

import './ArticleRequestCell.css';

const { block, element } = createBEMClasses('article-request-cell');

const ArticleRequestCell = ({
  article,
  organization,
}: {
  article: ArticleWithRelations;
  organization: Organization;
}) => {
  const { currentSession } = useCurrentSession();
  const { _ } = useLingui();

  const request = article.request;

  return (
    <Link href={`/requests/${article.request.id}`} className={block(undefined, 'reset-link-style')}>
      <Stack row gap="0.5rem" flexWrap="nowrap">
        <div className={element('requestor-type')}>{requestorTypeIcons[request.requestorType]}</div>
        <div>
          <p className="paragraph-100-medium">{request.reference}</p>
          <p className="paragraph-200-regular">
            {currentSession?.workshop?.external ? (
              <>
                {organization.name} ({_(getRequestorTypeLabel(request.requestorType))})
              </>
            ) : (
              <>
                {request.name.major} {!!request.name.minor && `(${request.name.minor})`}
              </>
            )}
          </p>
        </div>
      </Stack>
    </Link>
  );
};

export default ArticleRequestCell;

import IconWrapper from './IconWrapper';

const IconFlag = IconWrapper({
  name: 'flag',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C6.41421 2 6.75 2.33579 6.75 2.75V4H18C18.2943 4 18.5614 4.1721 18.683 4.44009C18.8046 4.70807 18.7582 5.02241 18.5644 5.24388L15.4966 8.75L18.5644 12.2561C18.7582 12.4776 18.8046 12.7919 18.683 13.0599C18.5614 13.3279 18.2943 13.5 18 13.5H6.75V21.25C6.75 21.6642 6.41421 22 6 22C5.58579 22 5.25 21.6642 5.25 21.25V2.75C5.25 2.33579 5.58579 2 6 2Z"
      />
    </svg>
  ),
});

export default IconFlag;

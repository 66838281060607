import { ReactNode, useEffect, useRef, useState } from 'react';
import { ComboBox, Input, Label, Popover } from 'react-aria-components';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Dropdown from '@/design_system/Dropdown';
import { BasicDropdownItem } from '@/design_system/Dropdown/Dropdown';
import Hint from '@/design_system/Hint';
import Message from '@/design_system/Message';
import IconChevron from '@/icons/Chevron.svg';
import IconSearch from '@/icons/Search.svg';
import { createBEMClasses } from '@/utils/classname';

import './LegacyInputSearchSelect.css';

const { block, element } = createBEMClasses('legacy-input-search-select');

export interface LegacyInputSearchSelectProps {
  variant: 'search' | 'select';
  label?: React.ReactNode;
  ariaLabel?: string;
  placeholder?: string;
  inputValue: string;
  onInputChange: (value: string) => void;
  selectedKey: string;
  onSelectionChange: (key: string) => void;
  hintText?: ReactNode;
  messageType?: 'error' | 'success' | 'info';
  messageText?: string;
  error?: string;
  isPending?: boolean;
  isFetching?: boolean;
  isDisabled?: boolean;
  size?: 'medium' | 'large';
  styleVariant?: 'default' | 'brand';
  focusOnRender?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode[];
}

const LegacyInputSearchSelect = ({
  variant,
  label,
  ariaLabel,
  placeholder,
  inputValue,
  onInputChange,
  selectedKey,
  onSelectionChange,
  hintText,
  messageType,
  messageText,
  error,
  isPending,
  isFetching,
  isDisabled,
  size = 'medium',
  styleVariant = 'default',
  focusOnRender,
  style,
  children,
}: LegacyInputSearchSelectProps) => {
  const { _ } = useLingui();
  const hiddenButtonRef = useRef<HTMLButtonElement>(null);
  const [offset, setOffset] = useState(0);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusOnRender) {
      ref.current?.focus();
    }
  }, [focusOnRender]);

  return (
    <ComboBox
      aria-label={ariaLabel}
      inputValue={inputValue}
      onInputChange={(value) => {
        onInputChange(value);

        if (value === '') {
          onSelectionChange('');
        }
      }}
      selectedKey={selectedKey}
      onSelectionChange={(key) => {
        /**
         * For some reasons, when the user tab to another field,
         * this function is called with an empty string.
         * We don't want to unselect the current value in this case.
         */
        if (!key) {
          return;
        }

        if (key === selectedKey) {
          return;
        }

        // For some reason, the input stays focused after the user selects an item,
        // and calling blur() on the input doesn't work.
        // So we focus on a hidden button placed just before instead.
        hiddenButtonRef.current?.focus();

        onSelectionChange(key as string);
      }}
      menuTrigger={variant === 'search' ? 'input' : 'focus'}
      isDisabled={isDisabled}
      isInvalid={!!error}
      style={style}
      className={block({ variant, size, loading: isFetching })}
      allowsCustomValue
      // We do not want Combobox to do local filtering since we do it on the server side
      defaultFilter={() => true}
      // @ts-ignore This is not in the types of Combobox, but it is passed to useComboboxState. This fixes a bug when sometimes Combobox doesn't open the Popover thinking there are no items even though there is.
      allowsEmptyCollection
    >
      {label && <Label>{label}</Label>}
      <div className={element('field')}>
        <button className={element('field__hidden-button')} ref={hiddenButtonRef} />
        <IconSearch />
        <Input
          placeholder={placeholder}
          onFocus={() => {
            /**
             * Force popover re-positioning to handle webkit virtual keyboard
             */
            setOffset(1);
            setTimeout(() => {
              setOffset(0);
            }, 50);
          }}
          ref={ref}
        />
        <IconChevron down />
      </div>
      {hintText && <Hint>{hintText}</Hint>}
      {error && <Message type="error">{error}</Message>}
      {messageText && <Message type={messageType}>{messageText}</Message>}
      <Popover offset={offset}>
        <Dropdown size={size}>
          {isPending && (
            <BasicDropdownItem
              id="loading"
              key="loading"
              text={_(msg({ id: '_general.loading', message: 'Loading...' }))}
              textValue={_(msg({ id: '_general.loading', message: 'Loading...' }))}
              variant={styleVariant}
              size={size}
            />
          )}
          {/* We show search results only if the input is not empty */}
          {variant === 'search' && inputValue.length > 0 && children}
          {variant === 'select' && children}
          {!isFetching && children.length === 0 && (
            <BasicDropdownItem
              id="no-results"
              key="no-results"
              text={_(
                msg({ id: 'design-system.input-search-select.no-results', message: 'No results' })
              )}
              textValue={_(
                msg({ id: 'design-system.input-search-select.no-results', message: 'No results' })
              )}
              variant={styleVariant}
              size={size}
            />
          )}
        </Dropdown>
      </Popover>
    </ComboBox>
  );
};

export default LegacyInputSearchSelect;

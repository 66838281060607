import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { startOfDay } from 'date-fns/startOfDay';

import ShipmentStepBadge from '@/components/ShipmentStepBadge/ShipmentStepBadge';
import Box from '@/design_system/Box';
import Chip from '@/design_system/Chip';
import InputMultiSelect, {
  BasicMultiSelectItem,
  ShipmentStepMultiSelectItem,
} from '@/design_system/InputMultiSelect/InputMultiSelect';
import Stack from '@/design_system/Stack';
import Table from '@/design_system/Table';
import { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import IconClock from '@/icons/Clock.svg';
import IconDone from '@/icons/Done.svg';
import IconPlace from '@/icons/Place.svg';
import IconTruck from '@/icons/Truck.svg';
import {
  Shipment,
  SHIPMENT_CARRIERS,
  SHIPMENT_STEPS,
  ShipmentCarrier,
  ShipmentStep,
  ShipmentWithRelations,
  useCarrierName,
} from '@/models/shipment';
import { createBEMClasses } from '@/utils/classname';
import { formatDate } from '@/utils/date';
import useViewPort from '@/utils/useViewport';

import { ShipmentStatusDueAtCell } from './components/ShipmentStatusDueAtCell';

export const SHIPMENTS_PER_PAGE = 10;

const { block } = createBEMClasses('shipments__arrival-date');

export const ArrivalDate = ({ shipment }: { shipment: Shipment }) => {
  const { arrivalDate, updatedEtaDate, originalEtaDate } = shipment;

  if (arrivalDate || (shipment.carrier === 'private-carrier' && originalEtaDate)) {
    return (
      <span className={block({ arrived: true })}>
        <IconDone /> {formatDate(arrivalDate ?? originalEtaDate!, { dateStyle: 'short' })}
      </span>
    );
  }

  const eta = updatedEtaDate ?? originalEtaDate;

  if (eta) {
    const isLate = startOfDay(eta) < startOfDay(new Date());

    if (isLate) {
      return (
        <span className={block({ late: true })}>
          <IconClock /> {formatDate(eta, { dateStyle: 'short' })}{' '}
          <Trans id="shipments.estimated.short">(est.)</Trans>
        </span>
      );
    }

    return (
      <span className={block({ transit: true })}>
        <IconTruck /> {formatDate(eta, { dateStyle: 'short' })}{' '}
        <Trans id="shipments.estimated.short">(est.)</Trans>
      </span>
    );
  }

  return null;
};

export const ShipmentOrigin = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  if (!shipment.origin) {
    return '-';
  }

  const date = shipment.departureDate ?? shipment.pickupDateDate;

  return (
    <Stack>
      <span className="paragraph-100-medium text-primary">{shipment.origin.name}</span>
      <span className="paragraph-200-regular text-secondary">{shipment.originAddress?.city}</span>
      {date && (
        <span className="paragraph-200-regular text-secondary">
          {formatDate(date, { dateStyle: 'short' })}
        </span>
      )}
    </Stack>
  );
};

export const ShipmentDestination = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  if (!shipment.destination) {
    return '-';
  }

  return (
    <Stack>
      <span className="paragraph-100-medium">{shipment.destination.name}</span>
      <span className="paragraph-200-regular text-secondary">
        {shipment.destinationAddress?.city}
      </span>
      <ArrivalDate shipment={shipment} />
    </Stack>
  );
};

export const ShipmentsList = ({
  shipments,
  showStatusDueAt,
}: {
  shipments: ShipmentWithRelations[];
  showStatusDueAt?: boolean;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const navigate = useNavigate();

  return (
    <>
      {!isMobile && (
        <Table
          aria-label={_(msg({ id: 'shipments.table.label', message: 'Shipments' }))}
          onRowAction={(id) => navigate(`/shipments/${id}`)}
        >
          <Header>
            <Column isRowHeader width={100}>
              <Trans id="shipments.table.column.reference.title">Ref.</Trans>
            </Column>
            <Column width={140} align="center">
              <Trans id="shipments.table.column.articles.title">Items</Trans>
            </Column>
            <Column width={150}>
              <Trans id="shipments.table.column.status.title">Status</Trans>
            </Column>
            {showStatusDueAt && (
              <Column width={150}>
                <Trans id="shipments.table.column.status-due-date.title">Status due date</Trans>
              </Column>
            )}
            <Column width="1fr" minWidth={220}>
              <Trans id="shipments.table.column.origin.title">Origin</Trans>
            </Column>
            <Column width="1fr" minWidth={220}>
              <Trans id="shipments.table.column.destination.title">Destination</Trans>
            </Column>
          </Header>
          <Body>
            {shipments?.map((shipment) => (
              <ShipmentRow
                key={shipment.id}
                shipment={shipment}
                showStatusDueAt={showStatusDueAt}
              />
            ))}
          </Body>
        </Table>
      )}

      {isMobile && (
        <Stack gap="16px">
          {shipments?.map((shipment) => (
            <ShipmentCard key={shipment.id} shipment={shipment} showStatusDueAt={showStatusDueAt} />
          ))}
        </Stack>
      )}
    </>
  );
};

const ShipmentRow = ({
  shipment,
  showStatusDueAt,
}: {
  shipment: ShipmentWithRelations;
  showStatusDueAt?: boolean;
}) => {
  const carrierName = useCarrierName(shipment);

  return (
    <Row id={shipment.id}>
      <Cell subtext={carrierName}>{shipment.reference}</Cell>
      <Cell align="center">{shipment.articles.length}</Cell>
      <Cell>
        <ShipmentStepBadge step={shipment.step} />
      </Cell>
      {showStatusDueAt && (
        <Cell>
          <ShipmentStatusDueAtCell shipment={shipment} variant="row" />
        </Cell>
      )}
      <Cell>
        <ShipmentOrigin shipment={shipment} />
      </Cell>
      <Cell>
        <ShipmentDestination shipment={shipment} />
      </Cell>
    </Row>
  );
};

const ShipmentCard = ({
  shipment,
  showStatusDueAt,
}: {
  shipment: ShipmentWithRelations;
  showStatusDueAt?: boolean;
}) => {
  const carrierName = useCarrierName(shipment);

  return (
    <NavLink to={`/shipments/${shipment.id}`} className="reset-link-style">
      <Box padding="16px" gap="16px" role="row" className="bg-neutral-0">
        <Stack row flexWrap="nowrap" justifyContent="space-between" gap="8px">
          <Stack>
            <span className="paragraph-100-medium text-ellipsis">{shipment.reference}</span>
            {!!shipment.carrier && <span className="paragraph-200-regular">{carrierName}</span>}
            <span className="paragraph-200-regular">
              {shipment.articles.length} {shipment.articles.length !== 1 ? 'articles' : 'article'}
            </span>
          </Stack>
          <div>
            <ShipmentStepBadge step={shipment.step} />
          </div>
        </Stack>
        <hr />
        <Stack gap="8px">
          <Stack row gap="4px">
            <Stack justifyContent="center" style={{ fontSize: '1.25rem', height: '1.5rem' }}>
              <IconPlace start />
            </Stack>
            <ShipmentOrigin shipment={shipment} />
          </Stack>
          <Stack row gap="4px">
            <Stack justifyContent="center" style={{ fontSize: '1.25rem', height: '1.5rem' }}>
              <IconPlace end />
            </Stack>
            <ShipmentDestination shipment={shipment} />
          </Stack>
          {showStatusDueAt && <ShipmentStatusDueAtCell shipment={shipment} variant="card" />}
        </Stack>
      </Box>
    </NavLink>
  );
};

export const CarrierSelect = ({
  selectedKeys,
  onSelectionChange,
}: {
  selectedKeys: ShipmentCarrier[];
  onSelectionChange: (keys: ShipmentCarrier[]) => void;
}) => {
  const { _ } = useLingui();

  return (
    <InputMultiSelect
      placeholder={_(msg({ id: 'shipments.filter.carrier.placeholder', message: 'Carrier: All' }))}
      ariaLabel={_(msg({ id: 'shipments.filter.carrier.label', message: 'Search by carrier' }))}
      size="large"
      style={{ minWidth: 175 }}
      onSelectionChange={(keys) => {
        onSelectionChange([...keys] as ShipmentCarrier[]);
      }}
      selectedKeys={selectedKeys}
      chips={SHIPMENT_CARRIERS.filter((carrier) => selectedKeys.includes(carrier.id)).map(
        (carrier) => (
          <Chip
            size="x-small"
            key={carrier.id}
            onCancel={() => {
              onSelectionChange(selectedKeys.filter((c) => c !== carrier.id));
            }}
          >
            {carrier.name}
          </Chip>
        )
      )}
    >
      {SHIPMENT_CARRIERS.map((carrier) => (
        <BasicMultiSelectItem id={carrier.id} key={carrier.id} text={carrier.name} />
      ))}
    </InputMultiSelect>
  );
};

export const ShipmentStepSelect = ({
  selectedKeys,
  onSelectionChange,
}: {
  selectedKeys: ShipmentStep[];
  onSelectionChange: (keys: ShipmentStep[]) => void;
}) => {
  const { _ } = useLingui();

  return (
    <InputMultiSelect
      placeholder={_(msg({ id: 'shipments.filter.status.placeholder', message: 'Status: All' }))}
      ariaLabel={_(msg({ id: 'shipments.filter.status.label', message: 'Search by status' }))}
      size="large"
      style={{ minWidth: 175 }}
      onSelectionChange={(keys) => {
        onSelectionChange([...keys] as ShipmentStep[]);
      }}
      selectedKeys={selectedKeys}
      chips={SHIPMENT_STEPS.filter((step) => selectedKeys.includes(step.id)).map((step) => (
        <ShipmentStepBadge
          key={step.id}
          step={step.id}
          onClick={() => {
            onSelectionChange(selectedKeys.filter((s) => s !== step.id));
          }}
        />
      ))}
    >
      {SHIPMENT_STEPS.map((step) => (
        <ShipmentStepMultiSelectItem key={step.id} step={step.id} />
      ))}
    </InputMultiSelect>
  );
};

import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNil from 'lodash/isNil';

import { BaseMoneyCell } from '@/components/ArticlesTableCells';
import InputQuantity from '@/components/InputQuantity';
import Button from '@/design_system/Button';
import RadioButtonGroup, {
  yesNoRadioOptions,
} from '@/design_system/RadioButtonGroup/RadioButtonGroup';
import IconEdit from '@/icons/Edit.svg';
import { ArticleAction, ArticleCustomAction, ArticlePackAction } from '@/models/request';
import useViewPort from '@/utils/useViewport';

export const BrandResponsibilityCell = ({
  value,
  onChange,
  size = 'large',
  isDisabled,
}: {
  value: boolean;
  onChange?: (value: boolean) => void;
  size?: 'small' | 'large';
  isDisabled?: boolean;
}) => {
  const { _ } = useLingui();

  const selectedOptionValue = value ? 'yes' : 'no';

  if (isDisabled) {
    return (
      <p className={size === 'small' ? 'paragraph-200-regular' : 'paragraph-100-regular'}>
        {_(yesNoRadioOptions.find((option) => option.value === selectedOptionValue)!.children)}
      </p>
    );
  }

  return (
    <RadioButtonGroup
      ariaLabel={_(
        msg({
          id: 'article.form.actions.table.column.brand-responsibility.label',
          message: 'Brand responsibility:',
        })
      )}
      value={selectedOptionValue}
      onChange={(option) => onChange?.(option === 'yes')}
      options={yesNoRadioOptions.map((option) => ({ ...option, children: _(option.children) }))}
      size={size}
    />
  );
};

export const CostCell = ({
  action,
  label,
}: {
  action: ArticleAction | ArticleCustomAction | ArticlePackAction;
  label?: string;
}) => {
  return (
    <BaseMoneyCell
      finalAmount={
        !isNil(action.costAmountWithoutDiscount)
          ? action.costAmountWithoutDiscount * action.quantity
          : null
      }
      finalCurrency={action.costCurrency}
      label={label}
    />
  );
};

export const PriceCell = ({
  action,
  label,
}: {
  action: ArticleAction | ArticleCustomAction | ArticlePackAction;
  label?: string;
}) => {
  const discounts = [];

  let discount = 0;
  let refashion = 0;

  if ('priceAmountWithoutRefashionBonus' in action) {
    discount =
      ((action.priceAmountWithoutDiscount ?? 0) - (action.priceAmountWithoutRefashionBonus ?? 0)) *
      action.quantity;
    refashion =
      ((action.priceAmountWithoutRefashionBonus ?? 0) - (action.priceAmount ?? 0)) *
      action.quantity;
  } else {
    discount =
      ((action.priceAmountWithoutDiscount ?? 0) - (action.priceAmount ?? 0)) * action.quantity;
    refashion = 0;
  }

  if (discount) {
    discounts.push({
      type: 'discount' as const,
      amount: discount,
    });
  }

  if (refashion) {
    discounts.push({
      type: 'refashion' as const,
      amount: refashion,
    });
  }

  return (
    <BaseMoneyCell
      originalAmount={
        !isNil(action.priceAmountWithoutDiscount)
          ? action.priceAmountWithoutDiscount * action.quantity
          : null
      }
      originalCurrency={action.priceCurrency}
      finalAmount={!isNil(action.priceAmount) ? action.priceAmount * action.quantity : null}
      finalCurrency={action.priceCurrency}
      label={label}
      discounts={discounts}
    />
  );
};

export const QuantityCell = ({
  quantity,
  onDecrement,
  onIncrement,
  isDisabled,
  disableAdd,
}: {
  quantity: number;
  onDecrement?: () => void;
  onIncrement?: () => void;
  isDisabled?: boolean;
  disableAdd?: boolean;
}) => {
  return (
    <InputQuantity
      quantity={quantity}
      onDecrement={onDecrement}
      onIncrement={onIncrement}
      isDisabled={isDisabled}
      disableAdd={disableAdd}
      size="small"
    />
  );
};

export const EditCustomActionCell = ({ onPress }: { onPress?: () => void }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  if (!onPress) {
    return null;
  }

  return (
    <Button
      variant="secondary"
      size={isMobile ? 'small' : 'medium'}
      iconOnly
      tooltip={_(
        msg({ id: 'article.form.actions.table.column.edit.label', message: 'Edit action' })
      )}
      ariaLabel={_(
        msg({ id: 'article.form.actions.table.column.edit.label', message: 'Edit action' })
      )}
      onPress={onPress}
    >
      <IconEdit />
    </Button>
  );
};

import { Button as AriaButton } from 'react-aria-components';
import { Trans } from '@lingui/macro';

import { createBEMClasses } from '@/utils/classname';

import './Pagination.css';

const { block, element } = createBEMClasses('pagination');

interface PaginationProps {
  page: number;
  itemsPerPage: number;
  count?: number;
  onPageChange?: (page: number) => void;
}

const Pagination = ({ page, itemsPerPage, count, onPageChange }: PaginationProps) => {
  const from = (page - 1) * itemsPerPage + 1;
  const to = Math.min(page * itemsPerPage, count ?? Infinity);
  const maxPage = Math.ceil((count ?? 0) / itemsPerPage) || page;

  let startPages: number[];
  let ellipsisLeft: boolean;
  let middlePages: number[];
  let ellipsisRight: boolean;
  let endPages: number[];

  if (maxPage <= 5) {
    startPages = [1, 2, 3, 4, 5].slice(0, maxPage);
    ellipsisLeft = false;
    middlePages = [];
    ellipsisRight = false;
    endPages = [];
  } else if (page <= 4) {
    startPages = [1, 2, 3, 4, 5];
    ellipsisLeft = false;
    middlePages = [];
    ellipsisRight = maxPage !== 6;
    endPages = [maxPage];
  } else if (page >= maxPage - 3) {
    startPages = [1];
    ellipsisLeft = maxPage - 4 > 2;
    middlePages = [];
    ellipsisRight = false;
    endPages = [maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
  } else {
    startPages = [1];
    ellipsisLeft = true;
    middlePages = [page - 2, page - 1, page, page + 1, page + 2];
    ellipsisRight = true;
    endPages = [maxPage];
  }

  return (
    <div className={block()}>
      <p className={element('paragraph')}>
        <Trans id="design-system.pagination.showing-items">
          Showing {from}-{to} of {count ?? '-'} items
        </Trans>
      </p>
      <div className={element('pages')}>
        {startPages.map((pageNumber) => (
          <AriaButton
            key={pageNumber}
            className={element('button-number', {
              'is-active': pageNumber === page,
            })}
            onPress={() => onPageChange?.(pageNumber)}
          >
            {pageNumber}
          </AriaButton>
        ))}
        {ellipsisLeft && <div className={element('ellipsis')}>...</div>}
        {middlePages.map((pageNumber) => (
          <AriaButton
            key={pageNumber}
            className={element('button-number', {
              'is-active': pageNumber === page,
            })}
            onPress={() => onPageChange?.(pageNumber)}
          >
            {pageNumber}
          </AriaButton>
        ))}
        {ellipsisRight && <div className={element('ellipsis')}>...</div>}
        {endPages.map((pageNumber) => (
          <AriaButton
            key={pageNumber}
            className={element('button-number', {
              'is-active': pageNumber === page,
            })}
            onPress={() => onPageChange?.(pageNumber)}
          >
            {pageNumber}
          </AriaButton>
        ))}
      </div>
    </div>
  );
};

export default Pagination;

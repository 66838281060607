import { useState } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ProductL1, ProductL2, ProductL3 } from '@/api';
import Button from '@/design_system/Button';
import LegacyInputSearchSelect from '@/design_system/InputSearchSelect/LegacyInputSearchSelect';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import IconAddToList from '@/icons/AddToList.svg';
import {
  ActionTypeOrganizationArticle,
  PackActionTypeOrganizationArticle,
  useActionTypes,
} from '@/models/actionType';
import { ArticleAction, ArticleCustomAction, ArticlePackAction } from '@/models/request';
import { Currency } from '@/utils/number';
import useDebouncedState from '@/utils/useDebouncedState';

import ActionDropdownItem from './ActionDropdownItem';
import ActionsTable from './ActionsTable';
import CustomActionDialog from './CustomActionDialog';
import PackActionDropdownItem from './PackActionDropdownItem';

const ActionTypeSearchSelect = ({
  mode,
  showCost,
  showPrice,
  showResponsibility,
  allowCustomActions,
  articleActions,
  articleCustomActions,
  articlePackActions,
  onChangeActions,
  onChangeCustomActions,
  onChangePackActions,
  oldArticleActions,
  oldArticleCustomActions,
  oldArticlePackActions,
  isDisabled = false,
  isEditActionDisabled = false,
  isEditActionCostDisabled = false,
  requestId,
  customActionCostCurrency,
  customActionPriceCurrency,
  productL1,
  productL2,
  productL3,
  error,
}: {
  mode: 'need' | 'action' | 'both';
  showCost?: boolean;
  showPrice?: boolean;
  showResponsibility?: boolean;
  allowCustomActions?: boolean;
  articleActions: ArticleAction[];
  articleCustomActions: ArticleCustomAction[];
  articlePackActions: ArticlePackAction[];
  onChangeActions: (actions: ArticleAction[]) => void;
  onChangeCustomActions: (customActions: ArticleCustomAction[]) => void;
  onChangePackActions: (packActions: ArticlePackAction[]) => void;
  oldArticleActions?: ArticleAction[];
  oldArticleCustomActions?: ArticleCustomAction[];
  oldArticlePackActions?: ArticlePackAction[];
  isDisabled?: boolean;
  isEditActionDisabled?: boolean;
  isEditActionCostDisabled?: boolean;
  requestId: string;
  customActionCostCurrency?: Currency;
  customActionPriceCurrency?: Currency;
  productL1?: ProductL1;
  productL2?: ProductL2;
  productL3?: ProductL3;
  error?: string;
}) => {
  const { _ } = useLingui();

  const [query, debouncedQuery, setQuery] = useDebouncedState<string>('', 500);
  const [selectedKey, setSelectedKey] = useState<string>();

  const [isOpenCustomActionDialog, setIsOpenCustomActionDialog] = useState(false);
  const [customActionToEdit, setCustomActionToEdit] = useState<ArticleCustomAction>();

  const actionTypeQueryMode = mode === 'action' ? 'name' : mode === 'need' ? 'needName' : 'both';

  const {
    data: { actionTypes, packActionTypes } = { actionTypes: [], packActionTypes: [] },
    isPending,
    isFetching,
  } = useActionTypes(
    {
      requestId,
      query: debouncedQuery || undefined,
      queryMode: debouncedQuery ? actionTypeQueryMode : undefined,
      productL1,
      productL2,
      productL3,
    },
    {
      enabled: !isDisabled,
      keepPreviousData: true,
    }
  );

  const handleSelectionChange = (id: string) => {
    if (id) {
      const actionAlreadyAdded =
        !!articleActions.find((action) => action.actionTypeOrganizationId === id) ||
        !!articlePackActions.find((packAction) => packAction.packActionTypeOrganizationId === id);

      if (!actionAlreadyAdded) {
        const newAction = actionTypes?.find(
          (actionTypeOrganization) => actionTypeOrganization.id === id
        );
        const newPackAction = packActionTypes?.find(
          (packActionTypeOrganization) => packActionTypeOrganization.id === id
        );

        if (newAction) {
          onChangeActions([
            ...articleActions,
            new ActionTypeOrganizationArticle({
              priceRefashionBonus: newAction.refashionBonus ?? null,
              priceAmount: newAction.dynamicPrice
                ? newAction.dynamicPrice.amount - (newAction.refashionBonus ?? 0)
                : null,
              priceAmountWithoutDiscount: newAction.dynamicPrice?.amount ?? null,
              priceCurrency: newAction.dynamicPrice?.currency ?? null,
              costAmountWithoutDiscount: newAction.dynamicCost?.amount ?? null,
              costCurrency: newAction.dynamicCost?.currency ?? null,
              quantity: 1,
              id: crypto.randomUUID(),
              actionTypeOrganizationId: newAction.id,
            }).with('actionTypeOrganization', newAction),
          ]);
        } else if (newPackAction) {
          onChangePackActions([
            ...articlePackActions,
            new PackActionTypeOrganizationArticle({
              priceRefashionBonus: newPackAction.refashionBonus ?? null,
              priceAmount: newPackAction.dynamicPrice
                ? newPackAction.dynamicPrice.amount - (newPackAction.refashionBonus ?? 0)
                : null,
              priceAmountWithoutDiscount: newPackAction.dynamicPrice?.amount ?? null,
              priceCurrency: newPackAction.dynamicPrice?.currency ?? null,
              costAmountWithoutDiscount: newPackAction.dynamicCost?.amount ?? null,
              costCurrency: newPackAction.dynamicCost?.currency ?? null,
              quantity: 1,
              id: crypto.randomUUID(),
              packActionTypeOrganizationId: newPackAction.id,
            }).with('packActionTypeOrganization', newPackAction),
          ]);
        }
      }

      setQuery('');
      setSelectedKey(id);
    }
  };

  const handleDecrementAction = (actionId: string) => {
    const newActions = articleActions
      .map((action) =>
        action.id === actionId ? Object.assign(action, { quantity: action.quantity - 1 }) : action
      )
      .filter(({ quantity }) => quantity > 0);

    onChangeActions(newActions);

    // This makes sure that the InputSelectSearch will not show the last selected key as active
    // in a scenario where you remove the lastly added action.
    setSelectedKey('');
  };

  const handleIncrementAction = (actionId: string) => {
    const newActions = articleActions.map((action) =>
      action.id === actionId ? Object.assign(action, { quantity: action.quantity + 1 }) : action
    );

    onChangeActions(newActions);
  };

  const handleDecrementPackAction = (packActionId: string) => {
    const newPackActions = articlePackActions
      .map((packAction) =>
        packAction.id === packActionId
          ? Object.assign(packAction, { quantity: packAction.quantity - 1 })
          : packAction
      )
      .filter(({ quantity }) => quantity > 0);

    onChangePackActions(newPackActions);

    // This makes sure that the InputSelectSearch will not show the last selected key as active
    // in a scenario where you remove the lastly added action.
    setSelectedKey('');
  };

  const handleIncrementPackAction = (packActionId: string) => {
    const newPackActions = articlePackActions.map((packAction) =>
      packAction.id === packActionId
        ? Object.assign(packAction, { quantity: packAction.quantity + 1 })
        : packAction
    );

    onChangePackActions(newPackActions);
  };

  const handleCloseCustomActionDialog = () => {
    setIsOpenCustomActionDialog(false);
    setCustomActionToEdit(undefined);
  };

  const handleAddCustomAction = (newCustomAction: ArticleCustomAction) => {
    onChangeCustomActions([...articleCustomActions, newCustomAction]);
    setIsOpenCustomActionDialog(false);
  };

  const handleStartEditCustomAction = (customAction: ArticleCustomAction) => {
    setIsOpenCustomActionDialog(true);
    setCustomActionToEdit(customAction);
  };

  const handleEditCustomAction = (newCustomAction: ArticleCustomAction) => {
    const newCustomActions = articleCustomActions.map((customAction) =>
      customAction.id === customActionToEdit?.id ? newCustomAction : customAction
    );

    onChangeCustomActions(newCustomActions);
    setCustomActionToEdit(undefined);
    setIsOpenCustomActionDialog(false);
  };

  const handleDecrementCustomAction = (customActionId: string) => {
    const newCustomActions = articleCustomActions
      .map((customAction) =>
        customAction.id === customActionId
          ? Object.assign(customAction, { quantity: customAction.quantity - 1 })
          : customAction
      )
      .filter(({ quantity }) => quantity > 0);

    onChangeCustomActions(newCustomActions);
  };

  const handleIncrementCustomAction = (customActionId: string) => {
    const newCustomActions = articleCustomActions.map((customAction) =>
      customAction.id === customActionId
        ? Object.assign(customAction, { quantity: customAction.quantity + 1 })
        : customAction
    );

    onChangeCustomActions(newCustomActions);
  };

  const handleChangeActionResponsibility = (actionId: string, brandResponsibility: boolean) => {
    const newActions = articleActions.map((action) =>
      action.id === actionId ? Object.assign(action, { brandResponsibility }) : action
    );

    onChangeActions(newActions);
  };

  const handleChangePackActionResponsibility = (
    packActionId: string,
    brandResponsibility: boolean
  ) => {
    const newPackActions = articlePackActions.map((packAction) =>
      packAction.id === packActionId
        ? Object.assign(packAction, { brandResponsibility })
        : packAction
    );

    onChangePackActions(newPackActions);
  };

  const handleChangeCustomActionResponsibility = (
    customActionId: string,
    brandResponsibility: boolean
  ) => {
    const newCustomActions = articleCustomActions.map((customAction) =>
      customAction.id === customActionId
        ? Object.assign(customAction, { brandResponsibility })
        : customAction
    );

    onChangeCustomActions(newCustomActions);
  };

  const label = _(
    mode === 'need'
      ? msg({ id: 'article.form.actions.label.defect', message: 'Defects' })
      : msg({ id: 'article.form.actions.label', message: 'Care & repair actions' })
  );

  const customActionDialogTriggerLabel = _(
    msg({ id: 'article.form.custom-action.dialog.trigger.label', message: 'Add a new action' })
  );

  return (
    <Stack gap="0.5rem">
      {isDisabled && <p className="label-100 text-primary">{label}</p>}

      {!isDisabled && (
        <Stack row gap="0.5rem">
          <LegacyInputSearchSelect
            variant="select"
            label={label}
            placeholder={_(
              mode === 'need'
                ? msg({
                    id: 'article.form.actions.placeholder.defect',
                    message: 'Search a need or defect...',
                  })
                : msg({
                    id: 'article.form.actions.placeholder',
                    message: 'Search a care or repair action...',
                  })
            )}
            inputValue={query}
            onInputChange={setQuery}
            selectedKey={selectedKey ?? ''}
            onSelectionChange={(key) => handleSelectionChange(key)}
            style={{ flex: 1 }}
            isPending={isPending}
            isFetching={isFetching || query !== debouncedQuery}
            error={error}
          >
            {packActionTypes
              ?.filter(
                (packAction) =>
                  !articlePackActions.find(
                    (selectedPackAction) =>
                      packAction.id === selectedPackAction.packActionTypeOrganizationId &&
                      selectedPackAction.quantity > 0
                  )
              )
              .map((packAction) => (
                <PackActionDropdownItem
                  key={packAction.id}
                  packAction={packAction}
                  mode={mode}
                  showCost={showCost}
                  showPrice={showPrice}
                />
              ))}
            {actionTypes
              ?.filter(
                (action) =>
                  !articleActions.find(
                    (selectedAction) =>
                      action.id === selectedAction.actionTypeOrganizationId &&
                      selectedAction.quantity > 0
                  )
              )
              .map((action) => (
                <ActionDropdownItem
                  key={action.id}
                  action={action}
                  mode={mode}
                  showCost={showCost}
                  showPrice={showPrice}
                />
              ))}
          </LegacyInputSearchSelect>

          {allowCustomActions && (
            <Button
              size="large"
              variant="secondary"
              iconOnly
              ariaLabel={customActionDialogTriggerLabel}
              tooltip={customActionDialogTriggerLabel}
              onPress={() => setIsOpenCustomActionDialog(true)}
              style={{ marginTop: 24, borderColor: error ? 'var(--color-danger-700)' : undefined }}
            >
              <IconAddToList />
            </Button>
          )}
        </Stack>
      )}

      {!isEditActionDisabled && (
        <CustomActionDialog
          isOpen={isOpenCustomActionDialog}
          onClose={handleCloseCustomActionDialog}
          onAddAction={handleAddCustomAction}
          onEditAction={handleEditCustomAction}
          mode={mode}
          showCost={showCost}
          showPrice={showPrice}
          costCurrency={customActionCostCurrency}
          priceCurrency={customActionPriceCurrency}
          initialCustomAction={customActionToEdit}
          isCostDisabled={isEditActionCostDisabled}
        />
      )}

      {(articleActions.length > 0 ||
        articleCustomActions.length > 0 ||
        articlePackActions.length > 0) && (
        <ActionsTable
          actions={articleActions}
          onDecrementAction={handleDecrementAction}
          onIncrementAction={handleIncrementAction}
          onChangeActionResponsibility={handleChangeActionResponsibility}
          customActions={articleCustomActions}
          onDecrementCustomAction={handleDecrementCustomAction}
          onIncrementCustomAction={handleIncrementCustomAction}
          onChangeCustomActionResponsibility={handleChangeCustomActionResponsibility}
          onEditCustomAction={handleStartEditCustomAction}
          packActions={articlePackActions}
          onDecrementPackAction={handleDecrementPackAction}
          onIncrementPackAction={handleIncrementPackAction}
          onChangePackActionResponsibility={handleChangePackActionResponsibility}
          oldActions={oldArticleActions}
          oldCustomActions={oldArticleCustomActions}
          oldPackActions={oldArticlePackActions}
          isDisabled={isDisabled}
          isEditActionDisabled={isEditActionDisabled}
          mode={mode}
          showCost={showCost}
          showPrice={showPrice}
          showResponsibility={showResponsibility}
        />
      )}

      {isDisabled && error && <Message type="error">{error}</Message>}
    </Stack>
  );
};

export default ActionTypeSearchSelect;

import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { type ProductL1 } from '@/api';
import { BasicDropdownItem } from '@/design_system/Dropdown/Dropdown';
import LegacyInputSelect from '@/design_system/InputSelect/LegacyInputSelect';
import { useUpdateArticle } from '@/models/article';
import { PRODUCT_CATEGORIES, useProductOptions } from '@/models/product';
import { type ArticleWithRelations, type RequestWithRelations } from '@/models/request';

export const ProductCategoryL1 = ({
  request,
  article,
  l1Error,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  l1Error?: string;
}) => {
  const { _ } = useLingui();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const { productL1Options } = useProductOptions(request.organization, article.productL1);

  return (
    <LegacyInputSelect
      label={_(msg({ id: 'article.form.type.label', message: 'Type' }))}
      placeholder={_(
        msg({
          id: 'article.form.type.placeholder',
          message: 'Select an item type',
        })
      )}
      selectedKey={article.productL1 ?? '-'}
      onSelectionChange={(key) => {
        if (key) {
          const { categories: productL2s, subCategories: productL3s } =
            PRODUCT_CATEGORIES.find((category) => category.id === key) ?? {};

          updateArticle({
            data: {
              productL1: key as ProductL1,
              productL2: productL2s?.length === 1 ? productL2s[0].id : null,
              productL3: productL3s?.length === 1 ? productL3s[0].id : null,
            },
          });
        }
      }}
      style={{ flex: 1 }}
      isDisabled={!!article.product}
      error={l1Error}
    >
      {productL1Options.map((productL1Option) => (
        <BasicDropdownItem
          id={productL1Option.id}
          key={productL1Option.id}
          text={productL1Option.text}
        />
      ))}
    </LegacyInputSelect>
  );
};

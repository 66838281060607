import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { RouterProvider } from 'react-aria';
import { Outlet, useMatches, useNavigate, useSearchParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import type { Endpoints, Flags, PublicKeys } from '@/api';
import config from '@/config';
import { Organization } from '@/models/organization';
import { instanciateUserWithRelations, UserWithRelations } from '@/models/user';
import {
  useCurrentOrganizationFromSession,
  useCurrentOrganizationFromUrl,
} from '@/utils/organization';

type AuthContextData = {
  currentSession: UserWithRelations | null | undefined; // null -> unauthenticated, undefined -> loading
  setCurrentSession: (session: UserWithRelations | null) => void;
  flags?: Flags;
  setFlags: (flags: Flags) => void;
  publicKeys?: PublicKeys;
  setPublicKeys: (publicKeys: PublicKeys) => void;
  currentOrganization: Organization | null;
  setCurrentOrganization?: (organization: Organization | null) => void;
  isCurrentOrganizationInvalid: boolean;
  clientToken?: string;
  storeToken?: string;
};

const AuthContext = createContext({} as AuthContextData);

const initialSessionExists = document.cookie.includes('__session_exists');

// Starts the session fetch as soon as possible
const sessionPromise = fetch(`${config.apiUrl}/sessions/current`, { credentials: 'include' })
  .then((res) => res.json())
  .then(({ user, flags, publicKeys }: Endpoints['GET /sessions/current']['response']) => ({
    user: user ? instanciateUserWithRelations(user) : null,
    flags,
    publicKeys,
  }));

type MatchHandle =
  | {
      authenticated?: boolean;
      roles?: string[];
    }
  | undefined;

export const AuthProvider = () => {
  const navigate = useNavigate();
  const matches = useMatches();

  const [currentSession, setCurrentSession] = useState<UserWithRelations | null | undefined>(
    initialSessionExists ? undefined : null
  );

  const [flags, setFlags] = useState<Flags | undefined>();
  const [publicKeys, setPublicKeys] = useState<PublicKeys | undefined>();

  const [sessionOrganization, setSessionOrganization] =
    useCurrentOrganizationFromSession(currentSession);
  const { organization: urlOrganization, isInvalidOrganization: isUrlOrganizationInvalid } =
    useCurrentOrganizationFromUrl();

  const [searchParams] = useSearchParams();
  const clientToken = searchParams.get('clientToken') ?? undefined;
  const storeToken = searchParams.get('storeToken') ?? undefined;

  const isAuthenticatedRoute = matches.some(
    (match) => (match.handle as MatchHandle)?.authenticated
  );

  useEffect(() => {
    sessionPromise.then(({ user, flags, publicKeys }) => {
      // Not logged in and on an authenticated route, redirect to login page
      if (!user && isAuthenticatedRoute) {
        const redirectTo =
          (window.location.pathname ?? '') +
          (window.location.search ?? '') +
          (window.location.hash ?? '');
        // Convert `?` and `&` to encoded `%3F` and `%26` respectively to ensure the redirection works properly
        const safeRedirectTo = redirectTo.replace(/\?/g, '%3F').replace(/&/g, '%26');
        window.location.href = '/login?redirect=' + safeRedirectTo;
        setCurrentSession(undefined);
        return;
      }

      // Logged in and on an unauthenticated page, redirect to home page
      if (user && !isAuthenticatedRoute) {
        // Or redirect to the redirect query param if it's a valid path
        if (window.location.pathname === '/login') {
          const safeRedirectTo = new URLSearchParams(window.location.search).get('redirect') ?? '';
          // Convert back `%3F` and `%26` to `?` and `&` respectively to ensure the redirection works properly
          const redirectTo = safeRedirectTo.replace(/%3F/g, '?').replace(/%26/g, '&');

          // Prevent redirecting to domains
          if (redirectTo.match(/^\/[^/]/)) {
            window.location.href = redirectTo;
            return;
          }
        }

        window.location.href = '/';
        return;
      }

      setCurrentSession(user ?? null);
      setFlags(flags);
      setPublicKeys(publicKeys);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const value = useMemo(
    () => ({
      currentSession,
      setCurrentSession,
      flags,
      setFlags,
      publicKeys,
      setPublicKeys,
      currentOrganization: currentSession ? sessionOrganization : urlOrganization,
      setCurrentOrganization: currentSession ? setSessionOrganization : undefined,
      isCurrentOrganizationInvalid: currentSession ? false : isUrlOrganizationInvalid,
      clientToken,
      storeToken,
    }),
    [
      clientToken,
      storeToken,
      currentSession,
      flags,
      publicKeys,
      isUrlOrganizationInvalid,
      sessionOrganization,
      setSessionOrganization,
      urlOrganization,
    ]
  );

  if (currentSession === undefined || (currentSession === null && isAuthenticatedRoute)) {
    return <AuthLoader />;
  }

  return (
    <RouterProvider navigate={navigate}>
      <AuthContext.Provider value={value}>
        <Outlet />
      </AuthContext.Provider>
    </RouterProvider>
  );
};

export const useCurrentSession = () => {
  const { currentSession, setCurrentSession } = useContext(AuthContext);

  return {
    currentSession,
    setCurrentSession,
  };
};

export const useFlags = () => {
  const { flags, setFlags } = useContext(AuthContext);

  return { flags: flags!, setFlags };
};

export const usePublicKeys = () => {
  const { publicKeys, setPublicKeys } = useContext(AuthContext);

  return { publicKeys: publicKeys!, setPublicKeys };
};

export const useCurrentOrganization = () => {
  const { currentOrganization, setCurrentOrganization, isCurrentOrganizationInvalid } =
    useContext(AuthContext);

  return [currentOrganization, setCurrentOrganization, isCurrentOrganizationInvalid] as const;
};

export const useClientToken = () => {
  const { clientToken } = useContext(AuthContext);

  return clientToken;
};

export const useStoreToken = () => {
  const { storeToken } = useContext(AuthContext);

  return storeToken;
};

const AuthLoader = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), 3000);

    return () => clearTimeout(timeout);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <p className="paragraph-100-regular">
        <Trans id="auth.loading">Loading...</Trans>
      </p>
      <div className="auth-loader" />
    </div>
  );
};

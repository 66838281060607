import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Box from '@/design_system/Box';
import { BasicDropdownItem } from '@/design_system/Dropdown/Dropdown';
import {
  DueAtTypeMultiSelectItem,
  PriorityMultiSelectItem,
} from '@/design_system/InputMultiSelect/InputMultiSelect';
import LegacyInputSelect from '@/design_system/InputSelect';
import Stack from '@/design_system/Stack';
import {
  RequestWithRelations,
  useUpdateDraftRequest,
  useUpdateRequestType,
} from '@/models/request';
import { Workflow } from '@/models/workflow';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

const SettingsForm = ({
  request,
  workflow,
}: {
  request: RequestWithRelations;
  workflow?: Workflow;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const { currentSession } = useCurrentSession();

  const canCreateClientRequest = currentSession?.hasPermission('create_client_request', {
    organizationId: request.organizationId,
  });

  const canCreateStoreRequest = currentSession?.hasPermission('create_store_request', {
    organizationId: request.organizationId,
  });

  const { mutate: updateRequest } = useUpdateDraftRequest(request.id);
  const { mutate: updateRequestType } = useUpdateRequestType(request.id);

  const type = request.client ? 'client' : 'store';

  const dueAtConfig = workflow?.config.requestDueAt;

  return (
    <Box padding={isMobile ? '16px' : '16px 24px'} gap="1rem" style={{ flex: 'initial' }}>
      <h2 className="headline-300-bold headline-400-bold-mobile">
        <Trans id="requests.new.settings.title">Settings</Trans>
      </h2>
      <Stack row mobileColumn gap="1rem">
        <LegacyInputSelect
          label={_(msg({ id: 'requests.new.requestor-type.label', message: 'Type' }))}
          selectedKey={request.client ? 'client' : 'store'}
          onSelectionChange={(key) => {
            if (!!key && key !== type) {
              updateRequestType({ type: key as 'client' | 'store' });
            }
          }}
          style={{ flex: 1 }}
          isDisabled={!request.draft}
        >
          {canCreateClientRequest && (
            <BasicDropdownItem
              id="client"
              key="client"
              text={_(msg({ id: 'requests.new.requestor-type.client', message: 'Client' }))}
            />
          )}
          {canCreateStoreRequest && (
            <BasicDropdownItem
              id="store"
              key="store"
              text={_(msg({ id: 'requests.new.requestor-type.store', message: 'Store-damaged' }))}
            />
          )}
        </LegacyInputSelect>
        {dueAtConfig ? (
          <LegacyInputSelect
            label={_(msg({ id: 'requests.new.due-date.label', message: 'Due Date' }))}
            selectedKey={request.dueAtType ?? undefined}
            onSelectionChange={(key) => updateRequest({ dueAtType: key })}
            style={{ flex: 1 }}
            isDisabled={dueAtConfig.length === 1}
          >
            {dueAtConfig.map((dueAtOption) => (
              <DueAtTypeMultiSelectItem key={dueAtOption.type} {...dueAtOption} />
            ))}
          </LegacyInputSelect>
        ) : (
          <LegacyInputSelect
            label={_(msg({ id: 'requests.new.priority.label', message: 'Priority' }))}
            selectedKey={request.priority ? 'high' : 'low'}
            onSelectionChange={(key) => updateRequest({ priority: key === 'high' })}
            style={{ flex: 1 }}
          >
            <PriorityMultiSelectItem priority={false} />
            <PriorityMultiSelectItem priority={true} />
          </LegacyInputSelect>
        )}
      </Stack>
    </Box>
  );
};

export default SettingsForm;

import { msg } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconUserValidation from '@/icons/UserValidation.svg';

export const FreeFinalQuoteAcceptedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'free_final_quote_accepted'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconUserValidation />}
      creatorName={msg({
        id: 'activities.free_final_quote_accepted.client',
        message: 'The client',
      })}
      message={msg({
        id: 'activities.free_final_quote_accepted.message',
        message: 'accepted the final quote',
      })}
    />
  );
};

import { Button as AriaButton } from 'react-aria-components';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Loader from '@/components/Loader';
import Badge from '@/design_system/Badge';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconCheck from '@/icons/Check.svg';
import IconValidation from '@/icons/Validation.svg';
import { ArticleActivityOfType } from '@/models/article';
import { WorkshopWithRelations } from '@/models/workshop';
import { createBEMClasses } from '@/utils/classname';
import { formatCurrency } from '@/utils/number';

import './WorkshopList.css';

type WorkshopListProps = {
  selected?: string;
  workshops: WorkshopWithRelations[];
  dispatchRefusedActivities: ArticleActivityOfType<'job_refused'>[];
  onSelect: (workshop: WorkshopWithRelations) => void;
  isFetchingWorkshops?: boolean;
  isDisabled?: boolean;
};

const { block, element } = createBEMClasses('workshop-list');

const WorkshopList = ({
  workshops,
  dispatchRefusedActivities,
  selected,
  onSelect,
  isFetchingWorkshops,
  isDisabled,
}: WorkshopListProps) => {
  const { _ } = useLingui();

  const otherRegion = _(msg({ id: 'workshop.region.other', message: 'Other' }));

  const workshopsByRegion = workshops.reduce<Record<string, WorkshopWithRelations[]>>(
    (result, workshop) => {
      result[workshop.region ?? otherRegion] ??= [];
      result[workshop.region ?? otherRegion].push(workshop);

      return result;
    },
    {}
  );

  const selectedWorkshop = workshops.find((workshop) => workshop.id === selected);

  return (
    <Stack gap="8px" className={block({}, 'inner-shadow-on-scroll')}>
      {isDisabled
        ? selectedWorkshop && (
            <WorkshopListItem
              workshop={selectedWorkshop}
              dispatchRefusedActivities={[]}
              isDisabled={isDisabled}
            />
          )
        : Object.entries(workshopsByRegion)
            .sort(([regionA], [regionB]) => (regionA > regionB ? 1 : -1))
            .map(([region, workshops]) => (
              <Stack
                key={region}
                gap="8px"
                ariaLabel={_(
                  msg({ id: 'workshop-list.region.label', message: `Workshops in ${region}` })
                )}
              >
                <p className="paragraph-100-medium">{region}</p>
                {workshops.map((workshop) => (
                  <WorkshopListItem
                    key={workshop.id}
                    workshop={workshop}
                    dispatchRefusedActivities={dispatchRefusedActivities}
                    onSelect={() => onSelect(workshop)}
                    isFetchingWorkshops={isFetchingWorkshops}
                    selected={selected}
                  />
                ))}
              </Stack>
            ))}
    </Stack>
  );
};

const WorkshopListItem = ({
  workshop,
  dispatchRefusedActivities,
  isDisabled,
  onSelect,
  isFetchingWorkshops,
  selected,
}: {
  workshop: WorkshopWithRelations;
  dispatchRefusedActivities: ArticleActivityOfType<'job_refused'>[];
  onSelect?: () => void;
  selected?: string;
  isFetchingWorkshops?: boolean;
  isDisabled?: boolean;
}) => {
  const { _ } = useLingui();

  const hasAlreadyRefusedJob = dispatchRefusedActivities.some(
    (activity) => activity.workshopId === workshop.id
  );

  const base = (
    <>
      <img src={workshop.logoSquare ?? workshop.logo ?? '/workshop.png'} alt="" />
      <div>
        <Stack row gap="0.5rem" alignItems="center" style={{ rowGap: 0 }}>
          <p className="paragraph-100-medium">{workshop.name}</p>
          {workshop.organizationConfig?.autoAcceptDispatch && <AutoAcceptBadge />}{' '}
          {hasAlreadyRefusedJob && <RefusedBadge />}
        </Stack>
        <p className="paragraph-200-regular">{workshop.address?.formatted}</p>
        <p className="paragraph-200-regular">{workshop.formattedPhone}</p>
        <Stack row gap="4px" alignItems="center">
          <p className="paragraph-100-medium">{formatCurrency(workshop.cost, workshop.currency)}</p>
          {isFetchingWorkshops && <Loader style={{ fontSize: '0.75rem' }} />}
        </Stack>
      </div>
      <div className={element('item__hover')}>
        {workshop.id === selected ? (
          <Button variant="primary" size="medium" displayOnly>
            <IconCheck />
            <Trans id="components.workshop-list.partner-chosen">Partner chosen</Trans>
          </Button>
        ) : (
          <Button variant="secondary" size="medium" displayOnly>
            <Trans id="components.workshop-list.choose-partner">Choose this partner</Trans>
          </Button>
        )}
      </div>
    </>
  );

  if (isDisabled) {
    return (
      <div
        className={element('item')}
        aria-label={_(
          msg({
            id: 'components.workshop-list.workshop-selected',
            message: `Workshop ${workshop.name} selected`,
          })
        )}
      >
        {base}
      </div>
    );
  }

  return (
    <AriaButton
      onPress={onSelect}
      className={element('item', { selected: workshop.id === selected })}
      aria-label={
        workshop.id === selected
          ? _(
              msg({
                id: 'components.workshop-list.workshop-selected',
                message: `Workshop ${workshop.name} selected`,
              })
            )
          : _(
              msg({
                id: 'components.workshop-list.select-workshop',
                message: `Select workshop ${workshop.name}`,
              })
            )
      }
    >
      {base}
    </AriaButton>
  );
};

const AutoAcceptBadge = () => (
  <Badge size="large" color="purple" variant="low">
    <Stack row alignItems="center" gap="4px">
      <IconValidation />
      <Trans id="components.workshop-list.auto-accept">Auto-accept</Trans>
    </Stack>
  </Badge>
);

const RefusedBadge = () => (
  <Badge size="large" color="red" variant="low">
    <Trans id="components.workshop-list.refused">Refused the job</Trans>
  </Badge>
);

export default WorkshopList;

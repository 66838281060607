import React, { useState } from 'react';
import { Label } from 'react-aria-components';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';

import { BasicDropdownItem } from '@/design_system/Dropdown/Dropdown';
import LegacyInputSelect from '@/design_system/InputSelect/LegacyInputSelect';
import InputText from '@/design_system/InputText';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import Phone from '@/models/partials/phone';

const InputPhone = ({
  label,
  value,
  size = 'small',
  variant = 'default',
  onChange,
  isInvalid,
  isDisabled,
}: {
  label?: React.ReactNode;
  value: Phone;
  size?: 'small' | 'medium' | 'large';
  variant?: 'default' | 'brand';
  onChange?: (phone: Phone) => void;
  isInvalid?: boolean;
  isDisabled?: boolean;
}) => {
  const { _ } = useLingui();

  const onFieldChange = (field: keyof Phone) => (fieldValue: string) => {
    onChange?.({ ...value, [field]: fieldValue });
  };

  const [country, setCountry] = useState(() => {
    try {
      if (isPhoneValid(value)) {
        const parsedValue = parsePhoneNumber(`+${value.countryCode}${value.number}`);
        return parsedValue?.country ?? parsedValue?.getPossibleCountries()?.[0] ?? 'FR';
      } else {
        return 'FR';
      }
    } catch (e) {
      console.error(e);
      return 'FR';
    }
  });

  const onSelectCountry = (key: CountryCode) => {
    setCountry(key);
    onFieldChange('countryCode')(getCountryCallingCode(key));
  };

  return (
    <Stack gap="4px">
      {label && (
        <Label className={size === 'large' ? 'paragraph-50-medium' : 'paragraph-100-medium'}>
          {label}
        </Label>
      )}
      <Stack row gap="8px">
        <LegacyInputSelect
          size={size}
          ariaLabel={_(
            msg({ id: 'components.input-phone.code.label', message: 'Phone country code' })
          )}
          selectedKey={country}
          onSelectionChange={(key) => onSelectCountry(key as CountryCode)}
          style={{ width: size === 'large' ? '8.5rem' : '7rem' }}
          isDisabled={isDisabled}
        >
          <MemoizedCountryCodeOptions size={size} variant={variant} />
        </LegacyInputSelect>
        <InputText
          size={size}
          aria-label={_(
            msg({ id: 'components.input-phone.number.label', message: 'Phone number' })
          )}
          placeholder={_(
            msg({ id: 'components.input-phone.number.label', message: 'Phone number' })
          )}
          value={value.number}
          onChange={(value) => onFieldChange('number')(value.replace(/[^\d]/g, ''))}
          isRequired
          type="tel"
          inputMode="tel"
          autoComplete="tel-national"
          style={{ flex: 1 }}
          isInvalid={isInvalid}
          isDisabled={isDisabled}
        />
      </Stack>
      {isInvalid && (
        <Message type="error">
          {_(
            msg({
              id: 'components.input-phone.error',
              message: 'Please provide a valid phone number',
            })
          )}
        </Message>
      )}
    </Stack>
  );
};

export default React.memo(InputPhone);

export const usePhoneState = (initialData?: Phone | null) =>
  useState<Phone>(initialData ?? { countryCode: '33', number: '' });

export const isPhoneValid = (phone?: { number?: string; countryCode?: string }): phone is Phone => {
  if (!phone || !phone?.number || !phone?.countryCode) {
    return false;
  }

  return isValidPhoneNumber(`+${phone.countryCode}${phone.number}`);
};

const CountryCodeOptions = ({
  size = 'small',
  variant = 'default',
}: {
  size: 'small' | 'medium' | 'large';
  variant?: 'default' | 'brand';
}) => {
  const countryCodeOptions = getCountries().map((countryKey) => {
    const callingCode = getCountryCallingCode(countryKey);

    return {
      id: countryKey,
      text: `${countryKey} (+${callingCode})`,
    };
  });

  return countryCodeOptions.map((countryCodeOption) => (
    <BasicDropdownItem
      id={countryCodeOption.id}
      key={countryCodeOption.id}
      text={countryCodeOption.text}
      size={size}
      variant={variant}
    />
  ));
};

/**
 * This prevents React-Aria from re-rendering the country code options
 */
const MemoizedCountryCodeOptions = React.memo(CountryCodeOptions);

import { useState } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { differenceInYears } from 'date-fns/differenceInYears';

import InputDate from '@/design_system/InputDate';
import RadioGroup from '@/design_system/RadioGroup';
import { yesNoRadioOptions } from '@/design_system/RadioGroup/RadioGroup';
import Stack from '@/design_system/Stack';
import { useUpdateArticle } from '@/models/article';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import ProofOfPurchase from '@/routes/Requests/Request/Article/ProofOfPurchase';

const Warranty = ({
  request,
  article,
  error,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  error?: { proofOfPurchaseError?: string };
}) => {
  const { _ } = useLingui();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const [purchaseDate, setPurchaseDate] = useState(article.purchaseDate ?? undefined);

  const handleBlurInputDate = () => {
    if (purchaseDate && purchaseDate !== article.purchaseDate) {
      updateArticle({
        data: {
          purchaseDate,
        },
      });
    } else if (!purchaseDate) {
      setPurchaseDate(article.purchaseDate ?? undefined);
    }
  };

  const setWarranty = (warranty: boolean) => {
    updateArticle({
      data: {
        warranty,
      },
    });
  };

  return (
    <Stack gap="1rem">
      <InputDate
        label={_(msg({ id: 'article.form.purchase-date.label', message: 'Purchase date' }))}
        value={purchaseDate}
        onChange={(date) => {
          setPurchaseDate(date);

          if (date) {
            setWarranty(differenceInYears(new Date(), new Date(date)) < 2);
          } else {
            setWarranty(false);
          }
        }}
        maxValue={new Date().toISOString()}
        style={{ flex: 1 }}
        onBlur={handleBlurInputDate}
      />
      <ProofOfPurchase article={article} error={error?.proofOfPurchaseError} />
      <RadioGroup
        label={_(
          msg({ id: 'article.form.warranty.label', message: 'Is the product still on warranty?' })
        )}
        value={article.warranty ? 'yes' : 'no'}
        onChange={(value) => setWarranty(value === 'yes')}
        options={yesNoRadioOptions.map((option) => ({ ...option, children: _(option.children) }))}
        style={{ flex: 1 }}
      />
    </Stack>
  );
};

export default Warranty;

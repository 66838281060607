import { useLingui } from '@lingui/react';

import Badge from '@/design_system/Badge';
import { SHIPMENT_STEPS, ShipmentStep } from '@/models/shipment';

export type ShipmentStepBadgeProps = {
  step: ShipmentStep;
  onClick?: () => void;
};

const ShipmentStepBadge = ({ step: stepId, onClick }: ShipmentStepBadgeProps) => {
  const { _ } = useLingui();

  const step = SHIPMENT_STEPS.find(({ id }) => id === stepId);

  if (!step) {
    return null;
  }

  return (
    <Badge color={step.color} variant="low" size="large" onClick={onClick} ellipse>
      {_(step.name)}
    </Badge>
  );
};

export default ShipmentStepBadge;

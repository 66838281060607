import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import IconUserRefusal from '@/icons/UserRefusal.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const FinalQuoteRefusedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'final_quote_refused'>;
}) => {
  const { _ } = useLingui();

  return (
    <BaseNotification
      icon={<IconUserRefusal />}
      type="danger"
      notification={notification}
      notificationLabel={_(
        msg({
          id: 'notification.final-quote-refused.label',
          message: `Payment refused for request ${notification.request.reference}`,
        })
      )}
      notificationHeader={
        <Trans id="notification.final-quote-refused">
          <span className="paragraph-100-medium">The client</span> refused the payment
        </Trans>
      }
    />
  );
};

import { NodeDropdownItem } from '@/design_system/Dropdown/Dropdown';
import Stack from '@/design_system/Stack';
import { ActionTypeOrganizationWithRelations } from '@/models/actionType';
import { useDatabaseTranslation } from '@/models/translation';
import { useCurrentSession } from '@/services/auth';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import ActionName from './ActionName';

const ActionDropdownItem = ({
  action,
  mode,
  showCost,
  showPrice,
}: {
  action: ActionTypeOrganizationWithRelations;
  mode: 'need' | 'action' | 'both';
  showCost?: boolean;
  showPrice?: boolean;
}) => {
  const { _db } = useDatabaseTranslation();
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();

  return (
    <NodeDropdownItem
      id={action.id}
      key={action.id}
      textValue={_db(mode === 'need' ? action.actionType.needName : action.actionType.name)}
    >
      <Stack
        row={!isMobile}
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent="space-between"
        gap="0.5rem"
        flexWrap="nowrap"
        style={{ flex: 1 }}
      >
        <ActionName
          actionType={action.actionType}
          mode={mode}
          refashionStatus={
            !!action.refashionBonus && !currentSession?.workshop?.external ? 'applied' : undefined
          }
        />
        <Stack
          row
          alignItems="center"
          gap="0.5rem"
          flexWrap="nowrap"
          style={{ alignSelf: isMobile ? 'flex-end' : 'center' }}
        >
          {showCost && (
            <p className="paragraph-100-medium">
              {formatCurrency(action.dynamicCost?.amount, action.dynamicCost?.currency)}
            </p>
          )}
          {showPrice && (
            <p className="paragraph-100-medium">
              {formatCurrency(action.dynamicPrice?.amount, action.dynamicPrice?.currency)}
            </p>
          )}
        </Stack>
      </Stack>
    </NodeDropdownItem>
  );
};

export default ActionDropdownItem;

import { useLingui } from '@lingui/react';

import { GoToCommentsMenuItem } from '@/components/comments/GoToCommentsButton/GoToCommentsButton';
import Button from '@/design_system/Button';
import Menu from '@/design_system/Menu';
import { Section } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import IconArrow from '@/icons/Arrow.svg';
import IconMore from '@/icons/More.svg';
import { useArticleName } from '@/models/article';
import {
  ArticleWithRelations,
  getRequestorTypeLabel,
  RequestWithRelations,
  useComments,
} from '@/models/request';
import {
  PrintItemSheetMenuItem,
  usePrintActions,
} from '@/routes/Requests/Request/components/RequestHeader/PrintsButton';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

export const ArticleHeader = ({
  request,
  article,
  onCommentButtonPress,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  onCommentButtonPress: () => void;
}) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();
  const isOrganizationUser = currentSession?.workshop?.external !== true;

  const articleName = useArticleName({ article });
  const { data: { comments } = { comments: [] } } = useComments({
    requestId: request.id,
  });

  const { printItemSheet } = usePrintActions({ request, article });
  const media = (article.media || [])
    .filter((medium) => ['article-photo', 'defect-photo'].includes(medium.type))
    .slice(0, 1);

  return (
    <Stack row gap={isMobile ? '0.5rem' : undefined} style={{ flex: 1 }}>
      {isMobile && (
        <Stack row>
          <Button variant="secondary" size="large" to={`/requests/${request.id}`} iconOnly noBorder>
            <IconArrow left />
          </Button>

          {media.length > 0 && (
            <img
              src={media[0].url}
              alt="article"
              style={{
                height: '40px',
                width: '40px',
                objectFit: 'contain',
                backgroundColor: 'var(--color-neutral-100)',
                borderRadius: '8px',
              }}
            />
          )}
        </Stack>
      )}

      <Stack gap={isMobile ? undefined : '0.25rem'} style={{ flex: 1 }}>
        <h2 className="headline-300-bold sentry-mask paragraph-200-medium-mobile">
          [{request.reference}]{' '}
          {isOrganizationUser ? request.name.major : request.organization.name}
        </h2>
        {isMobile && <p className="headline-500-bold-mobile">{articleName}</p>}
        {!isMobile && (
          <p className="paragraph-50-regular">
            {isOrganizationUser
              ? request.name.minor
              : _(getRequestorTypeLabel(request.requestorType))}
          </p>
        )}
      </Stack>

      {isMobile && (
        <>
          <Menu
            placement="bottom end"
            trigger={
              <Button variant="secondary" size="large" iconOnly noBorder>
                <IconMore />
              </Button>
            }
            onAction={(id) => {
              switch (id) {
                case 'go-to-comments':
                  onCommentButtonPress();
                  break;
                case 'print-item-sheet':
                  printItemSheet();
                  break;
                default:
                  break;
              }
            }}
          >
            <Section>
              <GoToCommentsMenuItem nbOfComments={comments.length} />
            </Section>
            <Section>
              <PrintItemSheetMenuItem singleItem />
            </Section>
          </Menu>
        </>
      )}
    </Stack>
  );
};

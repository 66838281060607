import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import IconCancel from '@/icons/Cancel.svg';
import { useRefuseRequalification, useValidateRequalification } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';

export const AcceptRequalificationAction = ({
  article,
  onActionDone,
}: {
  article: ArticleWithRelations;
  onActionDone: () => void;
}) => {
  const { _ } = useLingui();

  const {
    mutateAsync: refuseRequalification,
    isPending: isPendingRefuseRequalification,
    isSuccess: isSuccessRefuseRequalification,
  } = useRefuseRequalification({
    articleId: article.id,
  });

  const {
    mutateAsync: validateRequalification,
    isPending: isPendingValidateRequalification,
    isSuccess: isSuccessValidateRequalification,
  } = useValidateRequalification({
    articleId: article.id,
  });

  const [isOpenRefusalModal, setIsOpenRefusalModal] = useState(false);

  const handleRefuseRequalification = async (reason: string) => {
    await refuseRequalification({ comment: reason });
    setIsOpenRefusalModal(false);
    onActionDone();
  };

  const handleValidateRequalification = async () => {
    await validateRequalification();
    onActionDone();
  };

  return (
    <Stack row gap="1.5rem" alignItems="center">
      <Button
        variant="secondary"
        size="medium"
        onPress={() => setIsOpenRefusalModal(true)}
        isLoading={isPendingRefuseRequalification || isSuccessRefuseRequalification}
      >
        <IconCancel />
        <Trans id="article.actions.accept_requalification.refuse">Refuse requalification</Trans>
      </Button>
      <Dialog
        title={_(
          msg({
            id: 'article.actions.accept_requalification.refuse.modal.title',
            message: 'Refuse requalification',
          })
        )}
        isOpen={isOpenRefusalModal}
        onOpenChange={setIsOpenRefusalModal}
      >
        <RefusalDialogForm
          onCancel={() => setIsOpenRefusalModal(false)}
          onRefuse={handleRefuseRequalification}
          isLoadingRefuse={isPendingRefuseRequalification || isSuccessRefuseRequalification}
        />
      </Dialog>
      <Button
        variant="primary"
        size="medium"
        onPress={handleValidateRequalification}
        isLoading={isPendingValidateRequalification || isSuccessValidateRequalification}
      >
        <Trans id="article.actions.accept_requalification.validate">Validate requalification</Trans>
      </Button>
    </Stack>
  );
};

export const RefusalDialogForm = ({
  onCancel,
  onRefuse,
  isLoadingRefuse,
}: {
  onCancel: () => void;
  onRefuse: (reason: string) => Promise<void>;
  isLoadingRefuse: boolean;
}) => {
  const { _ } = useLingui();
  const [reason, setReason] = useState('');

  const [showError, setShowError] = useState(false);

  const error = !reason
    ? _(
        msg({
          id: 'article.actions.accept_requalification.refuse.modal.reason.error',
          message: 'Please provide a reason',
        })
      )
    : undefined;

  const handleRefuse = () => {
    setShowError(true);

    if (error) {
      return;
    }

    onRefuse(reason);
  };

  return (
    <>
      <main>
        <Stack gap="1rem">
          <p className="paragraph-100-regular">
            <Trans id="article.actions.accept_requalification.refuse.modal.text">
              Please specify the refusal reason to the workshop. The workshop will have to send the
              package to another workshop.
            </Trans>
          </p>
          <TextArea
            label={_(
              msg({
                id: 'article.actions.accept_requalification.refuse.modal.reason.label',
                message: 'Specify the reason for refusal',
              })
            )}
            placeholder={_(
              msg({
                id: 'article.actions.accept_requalification.refuse.modal.reason.placeholder',
                message: 'Describe the reason...',
              })
            )}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            error={showError ? error : undefined}
          />
        </Stack>
      </main>
      <footer>
        <Button variant="secondary" onPress={onCancel}>
          <Trans id="article.actions.accept_requalification.refuse.modal.cancel">Cancel</Trans>
        </Button>
        <Button variant="danger" onPress={handleRefuse} isLoading={isLoadingRefuse}>
          <Trans id="article.actions.accept_requalification.refuse.modal.refuse">
            Refuse requalification
          </Trans>
        </Button>
      </footer>
    </>
  );
};

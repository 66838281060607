import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TableQueryWrapper } from '@/components/TableQueryWrapper';
import InputSearch from '@/design_system/InputSearch';
import Pagination from '@/design_system/Pagination';
import Stack from '@/design_system/Stack';
import { ShipmentCarrier, ShipmentStep, useShipments } from '@/models/shipment';
import { ShipmentsEmptyState } from '@/routes/Shipments/components/ShipmentsEmptyState';
import { ErrorBoundary } from '@/services/sentry';

import { CarrierSelect, SHIPMENTS_PER_PAGE, ShipmentsList, ShipmentStepSelect } from './common';

const OngoingTab = ({
  search,
  debouncedSearch,
  debouncedSetSearch,
  page,
  setPage,
  steps,
  setSteps,
  carriers,
  setCarriers,
}: {
  search: string;
  debouncedSearch: string;
  debouncedSetSearch: (ref: string) => void;
  page: number;
  setPage: (page: number) => void;
  steps: ShipmentStep[];
  setSteps: (steps: ShipmentStep[]) => void;
  carriers: ShipmentCarrier[];
  setCarriers: (carriers: ShipmentCarrier[]) => void;
}) => {
  const { _ } = useLingui();
  const {
    data: { shipments, meta } = {},
    isLoading,
    isError,
  } = useShipments({
    limit: SHIPMENTS_PER_PAGE,
    offset: (page - 1) * SHIPMENTS_PER_PAGE,
    search: debouncedSearch || undefined,
    steps: steps?.length ? steps : undefined,
    carriers,
    tab: 'waiting',
  });

  return (
    <Stack gap="24px" style={{ height: '100%' }}>
      <Stack row gap="8px">
        <ErrorBoundary>
          <InputSearch
            placeholder={_(
              msg({
                id: 'shipments.search.placeholder',
                message: 'Search a reference, origin or destination...',
              })
            )}
            ariaLabel={_(
              msg({
                id: 'shipments.search.placeholder',
                message: 'Search a reference, origin or destination...',
              })
            )}
            style={{ flex: 1, minWidth: 175 }}
            value={search}
            onChange={debouncedSetSearch}
            size="medium"
          />
          <CarrierSelect
            selectedKeys={carriers}
            onSelectionChange={(keys) => {
              setCarriers([...keys] as ShipmentCarrier[]);
              setPage(1);
            }}
          />
          <ShipmentStepSelect
            selectedKeys={steps}
            onSelectionChange={(keys) => {
              setSteps([...keys] as ShipmentStep[]);
              setPage(1);
            }}
          />
        </ErrorBoundary>
      </Stack>

      <TableQueryWrapper isLoading={isLoading} isError={isError}>
        {!shipments?.length ? (
          <ShipmentsEmptyState
            subtitle={
              <Trans id="shipments.empty-state.no-shipment-subtitle">No shipments to display</Trans>
            }
          />
        ) : (
          <>
            <ShipmentsList shipments={shipments ?? []} showStatusDueAt />
            <Pagination
              page={page}
              itemsPerPage={SHIPMENTS_PER_PAGE}
              count={meta?.count}
              onPageChange={setPage}
            />
          </>
        )}
      </TableQueryWrapper>
    </Stack>
  );
};

export default OngoingTab;

import { CSSProperties, ReactNode } from 'react';
import { Input, Label, NumberField } from 'react-aria-components';

import Hint from '@/design_system/Hint';
import Message from '@/design_system/Message';
import { createBEMClasses } from '@/utils/classname';
import { Currency } from '@/utils/number';

import './InputNumber.css';

export type InputNumberProps = {
  label?: ReactNode;
  ariaLabel?: string;
  placeholder?: string;
  size?: 'small' | 'large';
  hintText?: string;
  messageType?: 'error' | 'success' | 'info';
  messageText?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isRequired?: boolean;
  isLoading?: boolean;
  error?: string;

  value?: number;
  onChange?: (value: number) => void;
  formatOptions?: Intl.NumberFormatOptions;
  minValue?: number;
  maxValue?: number;

  style?: CSSProperties;
  className?: string;
};

const { block } = createBEMClasses('input-number');

const InputNumber = ({
  label,
  ariaLabel,
  placeholder,
  size = 'large',
  hintText,
  messageType,
  messageText,
  isDisabled,
  isInvalid,
  isRequired,
  isLoading,
  error,
  value,
  onChange,
  formatOptions,
  minValue,
  maxValue,
  style,
  className,
}: InputNumberProps) => {
  return (
    <NumberField
      aria-label={ariaLabel}
      className={block({ size, loading: isLoading }, className)}
      style={style}
      isDisabled={isDisabled}
      isInvalid={isInvalid || !!error}
      isRequired={isRequired}
      value={value}
      onChange={onChange}
      formatOptions={formatOptions}
      minValue={minValue}
      maxValue={maxValue}
    >
      {label && <Label className="label-100 text-primary">{label}</Label>}
      <Input placeholder={placeholder} required={isRequired} />
      {hintText && <Hint>{hintText}</Hint>}
      {error && <Message type="error">{error}</Message>}
      {messageText && <Message type={messageType}>{messageText}</Message>}
    </NumberField>
  );
};

/**
 * Configures InputNumber for currency formatting
 * And handles the value in cents
 */
export const InputMoney = ({
  value,
  currency,
  onChange,
  minValue,
  formatOptions,
  ...props
}: InputNumberProps & {
  currency: Currency;
}) => (
  <InputNumber
    {...props}
    value={value !== undefined ? value / 100 : undefined}
    onChange={(value) => onChange?.(value * 100)}
    minValue={minValue ?? 0}
    formatOptions={{
      style: 'currency',
      currency,
      ...formatOptions,
    }}
  />
);

/**
 * Configures InputNumber for percentage formatting
 * And handles the value in percentage (0 to 100)
 */
export const InputPercentage = ({
  value,
  onChange,
  formatOptions,
  minValue,
  maxValue,
  ...props
}: InputNumberProps) => (
  <InputNumber
    {...props}
    value={value !== undefined ? value / 100 : undefined}
    onChange={(value) => onChange?.(value * 100)}
    minValue={minValue ?? 0}
    maxValue={maxValue ?? 1}
    formatOptions={{
      style: 'percent',
      ...formatOptions,
    }}
  />
);

export default InputNumber;

import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import IconEdit from '@/icons/Edit.svg';
import { AddressRaw } from '@/models/address';
import Phone from '@/models/partials/phone';
import { Store } from '@/models/store';

import { getStoreError, StoreForm, useStoreState } from './StoreForm';

type StoreData = {
  storeId: string;
  name: string;
  address: AddressRaw;
  phone: Phone;
  externalId?: string | null;
  external?: boolean;
};

export const UpdateStore = ({
  store,
  updateStore,
  isPending,
  size,
  variant,
}: {
  store: Store;
  updateStore: (data: StoreData) => Promise<void>;
  isPending: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'default' | 'brand';
}) => {
  const { _ } = useLingui();
  const [open, setOpen] = useState(false);

  const state = useStoreState(store);

  return (
    <Dialog
      isOpen={open}
      onOpenChange={(next) => {
        if (isPending) return;
        setOpen(next);
      }}
      trigger={
        <Button
          variant={variant === 'brand' ? 'secondary-brand' : 'secondary'}
          iconOnly
          size={variant === 'brand' ? 'medium' : 'small'}
          ariaLabel={_(
            msg({ id: 'settings.stores.edit-modal.trigger', message: `Edit ${store.name}` })
          )}
          tooltip={_(
            msg({ id: 'settings.stores.edit-modal.trigger', message: `Edit ${store.name}` })
          )}
        >
          <IconEdit />
        </Button>
      }
      title={<Trans id="settings.stores.edit-modal.title">Edit {store.name}</Trans>}
    >
      <main style={{ overflow: 'auto', maxHeight: '70vh' }}>
        <StoreForm state={state} size={size} variant={variant} />
      </main>
      <footer>
        <Button
          variant={variant === 'brand' ? 'brand' : 'primary'}
          size={size}
          type="submit"
          isLoading={isPending}
          onPress={() => {
            if (getStoreError(state).hasError) {
              state.setShowErrors(true);
              return;
            }

            updateStore({
              storeId: store.id,
              name: state.name,
              address: state.address as AddressRaw,
              phone: state.phone,
            })
              .then(() => {
                state.setShowErrors(false);
                setOpen(false);
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          <Trans id="settings.stores.edit-modal.submit">Save</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};

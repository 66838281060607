import { Label } from 'react-aria-components';
import { Link } from 'react-router-dom';
import { msg, Plural, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { startOfDay } from 'date-fns/startOfDay';

import { StatusDueDate } from '@/components/DueDate';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconAdd from '@/icons/Add.svg';
import IconEdit from '@/icons/Edit.svg';
import { ShipmentWithRelations, useCarrierName } from '@/models/shipment';
import { TrackingIdModal } from '@/routes/Shipments/Shipment/components/TrackingIdModal/TrackingIdModal';
import { useCurrentSession } from '@/services/auth';
import { formatDate } from '@/utils/date';
import { formatWeight } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

const ShipmentInfo = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { isMobile } = useViewPort();

  const carrierName = useCarrierName(shipment);

  return (
    <Stack gap={isMobile ? '24px' : '48px'} row>
      <Stack>
        <span className="paragraph-200-regular text-secondary">
          <Trans id="shipment.creation-date.label">Creation date</Trans>
        </span>
        <span className="paragraph-100-medium">
          {formatDate(shipment.createdAtDate, { dateStyle: 'short' })}
        </span>
      </Stack>
      <Stack>
        <span className="paragraph-200-regular text-secondary">
          <Trans id="shipment.reference.label">Prolong ref.</Trans>
        </span>
        <span className="paragraph-100-medium">{shipment.reference}</span>
      </Stack>
      <TrackingNumber shipment={shipment} />
      {!isMobile && (
        <Stack>
          <span className="paragraph-200-regular text-secondary">
            <Trans id="shipment.articles.label">Items</Trans>
          </span>
          <span className="paragraph-100-medium">
            <Plural
              id="shipment.articles.count"
              value={shipment.articles.length}
              _0="No items"
              one="# item"
              other="# items"
            />
          </span>
        </Stack>
      )}
      {!!shipment.statusDueAtDate && (
        <StatusDueDate date={shipment.statusDueAtDate} variant="info" />
      )}
      <Stack>
        <span className="paragraph-200-regular text-secondary">
          <Trans id="shipment.weight.label">Weight</Trans>
        </span>
        <span className="paragraph-100-medium">
          {formatWeight(
            shipment.articles
              .map(({ article }) => article.product?.data.weight)
              .filter((w) => w)
              .reduce((a, b) => a! + b!, 0)
          )}
        </span>
      </Stack>
      <Stack>
        <span className="paragraph-200-regular text-secondary">
          <Trans id="shipment.courier.label">Courier</Trans>
        </span>
        <span className="paragraph-100-medium">{carrierName}</span>
      </Stack>
      {shipment.pickupDate && (
        <Stack>
          <span className="paragraph-200-regular text-secondary">
            <Trans id="shipment.pickup-date.label">Pickup date</Trans>
          </span>
          <span className="paragraph-100-medium">
            {formatDate(new Date(shipment.pickupDate), { dateStyle: 'short' })}
          </span>
        </Stack>
      )}
      {(shipment.arrivalDate || shipment.updatedEtaDate || shipment.originalEtaDate) && (
        <Stack>
          <span className="paragraph-200-regular text-secondary">
            <Trans id="shipment.delivery-date.label">Delivery date</Trans>
          </span>
          <span className="paragraph-100-medium">
            <ArrivalDate shipment={shipment} />
          </span>
        </Stack>
      )}
    </Stack>
  );
};

const ArrivalDate = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { arrivalDate, updatedEtaDate, originalEtaDate } = shipment;

  if (arrivalDate || (shipment.carrier === 'private-carrier' && originalEtaDate)) {
    return formatDate(arrivalDate ?? originalEtaDate!, { dateStyle: 'short' });
  }

  const eta = updatedEtaDate ?? originalEtaDate;

  if (eta) {
    const isLate = startOfDay(eta) < startOfDay(new Date());

    if (isLate) {
      return (
        <>
          {formatDate(eta, { dateStyle: 'short' })}{' '}
          <Trans id="shipment.estimated.short">(est.)</Trans>
        </>
      );
    }

    return (
      <>
        {formatDate(eta, { dateStyle: 'short' })}{' '}
        <Trans id="shipment.estimated.short">(est.)</Trans>
      </>
    );
  }

  return '-';
};

const TrackingNumber = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();

  const canEditTrackingNumber =
    shipment.carrier === 'private-carrier' &&
    !['received', 'validated'].includes(shipment.status) &&
    shipment.canBeCreatedBy(currentSession);

  const trackingId = shipment.trackingUrl ? (
    <Link
      to={shipment.trackingUrl}
      target="_blank"
      rel="noreferrer"
      className="paragraph-100-medium text-primary"
    >
      {shipment.trackingId ?? <Trans id="shipment.trackingNumber.unknown">Unknown</Trans>}
    </Link>
  ) : (
    <span className="paragraph-100-medium">
      {shipment.trackingId ?? <Trans id="shipment.trackingNumber.unknown">Unknown</Trans>}
    </span>
  );

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">
        <Trans id="shipment.trackingNumber.label">Tracking number</Trans>
      </span>
      {canEditTrackingNumber && (
        <TrackingIdModal shipment={shipment}>
          <Label style={{ cursor: 'pointer' }}>
            <Stack row alignItems="center" gap="4px">
              {!shipment.trackingId && (
                <>
                  <Button
                    variant="secondary"
                    size="small"
                    iconOnly
                    ariaLabel={_(
                      msg({
                        id: 'shipment.trackingNumber.add',
                        message: 'Add tracking number',
                      })
                    )}
                  >
                    <IconAdd />
                  </Button>
                  <span className="paragraph-100-regular text-disabled">
                    <Trans id="shipment.trackingNumber.unknown">Unknown</Trans>
                  </span>
                </>
              )}
              {shipment.trackingId && (
                <>
                  {trackingId}
                  <Button
                    variant="secondary"
                    size="small"
                    iconOnly
                    ariaLabel={_(
                      msg({
                        id: 'shipment.trackingNumber.edit',
                        message: 'Edit tracking number',
                      })
                    )}
                  >
                    <IconEdit />
                  </Button>
                </>
              )}
            </Stack>
          </Label>
        </TrackingIdModal>
      )}
      {!canEditTrackingNumber && trackingId}
    </Stack>
  );
};

export default ShipmentInfo;

import { Key, useCallback } from 'react';
import {
  Cell as AriaCell,
  CellProps as AriaCellProps,
  Collection,
  Column as AriaColumn,
  ColumnProps,
  ResizableTableContainer,
  Row as AriaRow,
  RowProps,
  SortDescriptor,
  Table as TableAria,
  TableBody,
  TableBodyProps,
  TableHeader as AriaTableHeader,
  TableHeaderProps,
  TableProps as AriaTableProps,
  useTableOptions,
} from 'react-aria-components';

import Checkbox from '@/design_system/Checkbox';
import IconSort from '@/icons/Sort.svg';
import { createBEMClasses } from '@/utils/classname';

import './Table.css';

const { block, element } = createBEMClasses('table');

interface TableProps extends AriaTableProps {
  className?: string;
  onRowAction?: (key: Key) => void;
  onSortChange?: (
    descriptor?: SortDescriptor | ((prev?: SortDescriptor) => SortDescriptor | undefined)
  ) => void;
  sortDescriptor?: SortDescriptor;
  selectionMode?: 'single' | 'multiple';
  children: React.ReactNode;
  /**
   * Adds extra padding to the left and right of the table, to follow the redesigned guidelines.
   */
  extraSidePadding?: boolean;
}

const Table = ({
  className,
  onRowAction,
  selectionMode,
  children,
  onSortChange,
  extraSidePadding,
  ...props
}: TableProps) => (
  <ResizableTableContainer style={{ overflow: 'auto' }}>
    <TableAria
      className={block(
        { clickable: !!onRowAction || !!selectionMode, 'extra-side-padding': extraSidePadding },
        className
      )}
      onRowAction={onRowAction}
      selectionMode={selectionMode}
      onSortChange={({ column }) =>
        onSortChange?.((prev) =>
          prev?.column === column ? undefined : { column, direction: 'descending' }
        )
      }
      {...props}
    >
      {children}
    </TableAria>
  </ResizableTableContainer>
);

export const Header = <T extends object>({
  columns,
  children,
  className,
  noRadius,
  ...props
}: TableHeaderProps<T> & { noRadius?: boolean }) => {
  const { selectionBehavior, selectionMode } = useTableOptions();

  return (
    <AriaTableHeader className={element('header', { 'no-radius': noRadius }, className)} {...props}>
      {selectionBehavior === 'toggle' && (
        <Column>{selectionMode === 'multiple' && <Checkbox slot="selection" />}</Column>
      )}
      <Collection items={columns}>{children}</Collection>
    </AriaTableHeader>
  );
};

export const Column = ({
  className,
  align = 'start',
  ...props
}: ColumnProps & {
  align?: 'start' | 'center' | 'end';
}) => (
  <AriaColumn className={element('column', { align }, className as string)} {...props}>
    {({ allowsSorting, sortDirection }) => (
      <span
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: '0.3rem',
        }}
      >
        <>
          {props.children}
          {allowsSorting && (
            <>
              <span
                aria-hidden="true"
                className={element('column__sort', { enabled: sortDirection === 'descending' })}
              >
                <IconSort />
              </span>
            </>
          )}
        </>
      </span>
    )}
  </AriaColumn>
);

export const Body = <T extends object>({ className, ...props }: TableBodyProps<T>) => (
  <TableBody className={element('body', undefined, className as string)} {...props} />
);

export const Row = <T extends object>({
  id,
  columns,
  children,
  className,
  unclickable,
  noBorder,
  ...props
}: RowProps<T> & { unclickable?: boolean; noBorder?: boolean }) => {
  const { selectionBehavior } = useTableOptions();

  return (
    <AriaRow
      id={id}
      className={element('row', { unclickable, 'no-border': noBorder }, className as string)}
      {...props}
    >
      {selectionBehavior === 'toggle' && (
        <Cell>
          <Checkbox slot="selection" />
        </Cell>
      )}
      <Collection items={columns}>{children}</Collection>
    </AriaRow>
  );
};

interface CellProps extends AriaCellProps {
  align?: 'start' | 'center' | 'end' | 'stretch';
  supertext?: React.ReactNode;
  subtext?: React.ReactNode;
  icon?: React.ReactNode;
  state?: 'danger';
  colSpan?: number;
  children?: React.ReactNode;
  clickable?: boolean;
}

export const Cell = ({
  className,
  align = 'start',
  supertext,
  subtext,
  icon,
  state,
  colSpan,
  children,
  clickable,
  ...props
}: CellProps) => {
  const ref = useCallback(
    (node: HTMLTableCellElement) => {
      if (node !== null && colSpan) {
        node.setAttribute('colspan', colSpan.toString());
      }
    },
    [colSpan]
  );

  return (
    <AriaCell
      className={element('cell', { align, state, clickable }, className as string)}
      {...props}
      ref={colSpan ? ref : undefined}
    >
      <div className={element('cell__wrapper')}>
        {icon && <div className={element('cell__icon')}>{icon}</div>}
        <div className={element('cell__content')}>
          {supertext && <small>{supertext}</small>}
          <div>{children}</div>
          {subtext && <small>{subtext}</small>}
        </div>
      </div>
    </AriaCell>
  );
};

export default Table;

import { NavLink } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import ArticlePhoto from '@/components/ArticlePhoto';
import Stack from '@/design_system/Stack';
import { useArticleName } from '@/models/article';
import {
  ArticleWithRelations as ArticleWithRelationsFromRequest,
  ClientArticleWithRelations,
} from '@/models/request';
import { ArticleWithRelations as ArticleWithRelationsFromShipment } from '@/models/shipment';
import useViewPort from '@/utils/useViewport';

const ArticleNameCell = ({
  id,
  article,
}: {
  id?: string;
  article?:
    | ArticleWithRelationsFromRequest
    | ClientArticleWithRelations
    | ArticleWithRelationsFromShipment;
}) => {
  const { isMobile } = useViewPort();
  const name = useArticleName({ article });

  return (
    <Stack
      row
      gap={isMobile ? '0.5rem' : '1rem'}
      alignItems="center"
      flexWrap="nowrap"
      style={{ minWidth: 0 }}
    >
      <ArticlePhoto photo={article?.articlePhoto} size={isMobile ? 'small' : 'medium'} />
      <div style={{ overflow: 'hidden' }}>
        {id ? (
          <NavLink to={`articles/${id}`} className="reset-link">
            <p className="paragraph-100-medium text-ellipsis">{name}</p>
          </NavLink>
        ) : (
          <p className="paragraph-100-medium text-ellipsis">{name}</p>
        )}

        {article?.product?.data.reference && (
          <p className="paragraph-200-regular text-secondary">
            <Trans id="article.reference.short">Ref:</Trans>{' '}
            <span>{article.product.data.reference}</span>
          </p>
        )}

        <Stack row gap="0.25rem">
          {article?.data.color && (
            <p className="paragraph-200-regular text-secondary">
              <span>{article.data.color}</span>
            </p>
          )}
          {article?.data.color && article?.data.size && (
            <p className="paragraph-200-regular text-secondary">
              <span>•</span>
            </p>
          )}
          {article?.data.size && (
            <p className="paragraph-200-regular text-secondary">
              <Trans id="article.size">Size:</Trans> <span>{article.data.size}</span>
            </p>
          )}
        </Stack>
      </div>
    </Stack>
  );
};

export default ArticleNameCell;

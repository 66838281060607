import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import SimpleBar from 'simplebar-react';

import { Comment } from '@/components/comments/Comment/Comment';
import { NewComment } from '@/components/comments/NewComment/NewComment';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconInfo from '@/icons/Info.svg';
import { Comment as CommentModel, CommentVisibility } from '@/models/comment/comment';
import { useCurrentOrganization, useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

interface Props {
  comments: CommentModel[];
  displayArticleName?: boolean;
  isReadOnly?: (comment: CommentModel) => boolean;
  onCommentCreated: (data: { content: string; visibility: CommentVisibility }) => Promise<void>;
  onCommentUpdated: (data: { commentId: string; content: string }) => Promise<void>;
  onCommentDeleted: (data: { commentId: string }) => Promise<void>;
  legacyLayout?: boolean;
}

// TODO: Remove this component
export const LegacyComments = (props: Props) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  return (
    <>
      <h2
        className="headline-300-bold"
        style={{
          padding: isMobile
            ? '0 0 24px 0' /* eslint-disable-line lingui/no-unlocalized-strings */
            : '0 32px 24px 32px' /* eslint-disable-line lingui/no-unlocalized-strings */,
        }}
      >
        <Stack row alignItems="center" gap="0.25rem">
          <Trans id={'comments.title'}>Comments</Trans>
          <div>({props.comments.length})</div>
          <Tooltip
            content={_(
              msg({
                id: 'comments.title.tooltip',
                message: "These comments won't be visible by the client",
              })
            )}
          >
            <Button variant="style-less">
              <IconInfo className="headline-300-bold" />
            </Button>
          </Tooltip>
        </Stack>
      </h2>
      <Comments {...props} legacyLayout />
    </>
  );
};

export const Comments = ({
  comments,
  displayArticleName,
  isReadOnly,
  onCommentCreated,
  onCommentUpdated,
  onCommentDeleted,
  legacyLayout,
}: Props) => {
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();
  const [currentOrganization] = useCurrentOrganization();

  return (
    <>
      <div
        style={{
          padding: !legacyLayout
            ? isMobile
              ? '0.5rem 0'
              : '1rem'
            : isMobile
              ? '1px 2px'
              : '0 32px 8px 32px',
        }}
      >
        <NewComment
          creator={currentSession!}
          onCommentCreated={onCommentCreated}
          currentOrganization={currentOrganization}
        />
      </div>

      <SimpleBar
        style={{
          padding: isMobile
            ? undefined
            : !legacyLayout
              ? '0 1rem 1.5rem 1rem' /* eslint-disable-line lingui/no-unlocalized-strings */
              : '0 32px' /* eslint-disable-line lingui/no-unlocalized-strings */,
          overflow: 'auto',
          maxHeight: isMobile ? undefined : '30rem',
        }}
        autoHide={false}
      >
        {comments.length === 0 && (
          <p
            style={{ margin: !legacyLayout ? (isMobile ? '0 0.25rem' : '0 3.5rem') : '16px 0' }}
            className="paragraph-200-regular text-secondary"
          >
            <Trans id="comments.no-comment">No comments yet</Trans>
          </p>
        )}

        {comments
          .sort((c1, c2) => c2.createdAtDate.getTime() - c1.createdAtDate.getTime())
          .map((comment) => {
            const canModerateCommentOrgComment =
              (comment.visibility === 'organization' || comment.visibility === 'public') &&
              currentSession?.hasPermission('moderate_comment', {
                organizationId: comment.organizationId,
              });

            const canModerateExternalWorkshopComment =
              currentSession?.workshop?.id &&
              comment.visibility === 'external-workshop' &&
              currentSession?.hasPermission('moderate_comment', {
                workshopId: currentSession?.workshop?.id,
              });

            return (
              <Comment
                key={comment.id}
                comment={comment}
                displayArticleName={!!displayArticleName}
                isReadOnly={
                  (comment.creatorId !== currentSession?.id &&
                    !canModerateCommentOrgComment &&
                    !canModerateExternalWorkshopComment) ||
                  (isReadOnly && isReadOnly(comment))
                }
                isOwnComment={comment.creatorId === currentSession?.id}
                onCommentUpdated={async (updatedContent) => {
                  await onCommentUpdated({ commentId: comment.id, content: updatedContent });
                }}
                onCommentDeleted={async () => {
                  await onCommentDeleted({ commentId: comment.id });
                }}
              />
            );
          })}
      </SimpleBar>
    </>
  );
};

import { createContext, useContext } from 'react';
import { GridList, GridListItem } from 'react-aria-components';
import { useNavigate } from 'react-router-dom';
import { msg, Plural, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  ArticleCostCell,
  ArticleNameCell,
  ArticlePriceCell,
  ArticleWorkshopCell,
} from '@/components/ArticlesTableCells';
import { RequestCost, RequestPrice } from '@/components/ArticlesTableCells/ArticleMoneyCell';
import { ArticleStepper } from '@/components/ArticleStepper/ArticleStepper';
import { StatusDueDate } from '@/components/DueDate';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import Table from '@/design_system/Table';
import { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import IconArchive from '@/icons/Archive.svg';
import IconChevron from '@/icons/Chevron.svg';
import IconClock from '@/icons/Clock.svg';
import IconPay from '@/icons/Pay.svg';
import IconStoreRepair from '@/icons/StoreRepair.svg';
import IconTask from '@/icons/Task.svg';
import IconTools from '@/icons/Tools.svg';
import { useArticleName } from '@/models/article';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './RequestArticles.css';

const { block, element } = createBEMClasses('request-articles');

interface ArticlesTableContextData {
  request: RequestWithRelations;
  showWorkshopColumn?: boolean;
  showPriceColumn?: boolean;
  costLabel: string;
  priceLabel: string;
}

const ArticlesTableContext = createContext({} as ArticlesTableContextData);

export const RequestArticles = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();

  const articlesWithTaskToDo = request.articles.filter((article) =>
    article.task?.userRoles.some(({ permission, scope }) =>
      currentSession?.hasPermission(permission, scope)
    )
  );

  const completedArticles = request.articles.filter((article) => article.completed);

  const otherArticles = request.articles.filter(
    (article) => !articlesWithTaskToDo.includes(article) && !completedArticles.includes(article)
  );

  const isResponsibleWorkshop = currentSession?.isResponsibleWorkshop(
    request.articles[0] ?? request.archivedArticles[0]
  );
  const isExternalWorkshop = currentSession?.workshop?.external;

  const showWorkshopColumn = !isResponsibleWorkshop;

  // Show price only if the request is in the user's organization
  const showPriceColumn = request.requestorType !== 'store' && !isExternalWorkshop;

  const costLabel =
    isResponsibleWorkshop && isExternalWorkshop
      ? _(
          msg({
            id: 'request.articles.table.column.workshop-price.label',
            message: 'Price',
          })
        )
      : _(
          msg({
            id: 'request.articles.table.column.internal-cost.label',
            message: 'Internal cost',
          })
        );

  const priceLabel = _(
    msg({ id: 'request.articles.table.column.client-price.label', message: 'Client price' })
  );

  const contextValue = {
    request,
    showWorkshopColumn,
    showPriceColumn,
    costLabel,
    priceLabel,
  };

  return (
    <ArticlesTableContext.Provider value={contextValue}>
      <Stack gap="1rem" className={block()}>
        {articlesWithTaskToDo.length > 0 && (
          <Box padding="0">
            <Stack>
              <Stack className={element('pending-tasks')} row>
                <IconTask />
                <span className="paragraph-100-medium">
                  <Trans id="request.articles.table.pending-tasks.label">Pending item tasks</Trans>
                </span>
              </Stack>
              {isMobile ? (
                <ArticlesCardList
                  articles={articlesWithTaskToDo}
                  mode="task"
                  label={_(
                    msg({
                      id: 'request.articles.table.pending-tasks.label',
                      message: 'Pending item tasks',
                    })
                  )}
                />
              ) : (
                <ArticlesTable articles={articlesWithTaskToDo} mode="task" />
              )}
            </Stack>
          </Box>
        )}
        {otherArticles.length > 0 && (
          <Box padding="0">
            <Stack>
              <Stack className={element('awaiting-tasks')} row>
                <IconClock />
                <span className="paragraph-100-medium">
                  <Trans id="request.articles.table.waiting-tasks.label">Waiting for others</Trans>
                </span>
              </Stack>
              {isMobile ? (
                <ArticlesCardList
                  articles={otherArticles}
                  mode="step"
                  label={_(
                    msg({
                      id: 'request.articles.table.waiting-tasks.label',
                      message: 'Waiting for others',
                    })
                  )}
                />
              ) : (
                <ArticlesTable articles={otherArticles} mode="step" />
              )}
            </Stack>
          </Box>
        )}
        {completedArticles.length > 0 && (
          <Box padding="0">
            <Stack>
              <Stack className={element('completed-articles')} row>
                <IconClock />
                <span className="paragraph-100-medium">
                  <Trans id="request.articles.table.completed.label">Completed items</Trans>
                </span>
              </Stack>
              {isMobile ? (
                <ArticlesCardList
                  articles={completedArticles}
                  mode="step"
                  label={_(
                    msg({
                      id: 'request.articles.table.waiting-tasks.label',
                      message: 'Waiting for others',
                    })
                  )}
                />
              ) : (
                <ArticlesTable articles={completedArticles} mode="step" />
              )}
            </Stack>
          </Box>
        )}
        <RequestPrice request={request} label={priceLabel} />
        <RequestCost request={request} label={costLabel} />
        {request.archivedArticles.length > 0 && (
          <Box padding="0">
            <Stack>
              <Stack className={element('archived-articles')} row>
                <IconArchive />
                <span className="paragraph-100-medium">
                  <Trans id="request.articles.table.archived-articles.label">Archived items</Trans>
                </span>
              </Stack>
              {isMobile ? (
                <ArticlesCardList
                  articles={request.archivedArticles}
                  mode="step"
                  label={_(
                    msg({
                      id: 'request.articles.table.archived-articles.label',
                      message: 'Archived items',
                    })
                  )}
                />
              ) : (
                <ArticlesTable articles={request.archivedArticles} mode="step" />
              )}
            </Stack>
          </Box>
        )}
      </Stack>
    </ArticlesTableContext.Provider>
  );
};

const ArticlesCardList = ({
  articles,
  mode,
  label,
}: {
  articles: ArticleWithRelations[];
  mode: 'task' | 'step';
  label: string;
}) => {
  const navigate = useNavigate();
  const { request } = useContext(ArticlesTableContext);

  return (
    <GridList
      onAction={(key) => {
        navigate(`/requests/${request.id}/articles/${key}`);
      }}
      aria-label={label}
    >
      {articles.map((article) => (
        <ArticleCard key={article.id} article={article} mode={mode} />
      ))}
    </GridList>
  );
};

const ArticleCard = ({
  article,
  mode,
}: {
  article: ArticleWithRelations;
  mode: 'task' | 'step';
}) => {
  const { showWorkshopColumn, showPriceColumn, costLabel, priceLabel } =
    useContext(ArticlesTableContext);

  const numberOfActions = article.numberOfActions;
  const articleName = useArticleName({ article });

  return (
    <GridListItem id={article.id} textValue={articleName} className={element('card')}>
      <Stack padding="16px" gap="0.75rem" style={{ flex: 1, opacity: article.archived ? 0.3 : 1 }}>
        <Stack
          row
          gap="0.5rem"
          flexWrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
        >
          <ArticleNameCell id={article.id} article={article} />
          <IconChevron right style={{ fontSize: '1.5rem' }} />
        </Stack>
        {!article.completed && (
          <ArticleStepper size="medium" article={article} mode={mode}>
            {!article.archived && (
              <>
                {article.statusDueAtDate ? (
                  <StatusDueDate date={article.statusDueAtDate} displayDayMonthOnly variant="row" />
                ) : (
                  '-'
                )}
              </>
            )}
          </ArticleStepper>
        )}
        <Stack
          row
          gap="0.5rem"
          className="paragraph-200-regular text-primary"
          alignItems="flex-start"
        >
          {article.hasActions && (
            <CardItem>
              <IconTools style={{ fontSize: '1rem' }} />
              <Trans id="request.articles.table.column.service-type.actions">
                {numberOfActions} {}
                <Plural value={numberOfActions} one="action" other="actions" />
              </Trans>
            </CardItem>
          )}
          {showWorkshopColumn && (
            <CardItem>
              <IconStoreRepair style={{ fontSize: '1rem' }} />
              <span>{article.workshop?.name}</span>
            </CardItem>
          )}
          <CardItem>
            <IconPay payOut style={{ fontSize: '1rem' }} />
            <span>
              {costLabel}
              <Trans id="_general.colon">:</Trans>
            </span>
            <ArticleCostCell article={article} />
          </CardItem>
          {showPriceColumn && (
            <CardItem>
              <IconPay payIn style={{ fontSize: '1rem' }} />
              <span>
                {priceLabel}
                <Trans id="_general.colon">:</Trans>
              </span>
              <ArticlePriceCell article={article} />
            </CardItem>
          )}
        </Stack>
      </Stack>
    </GridListItem>
  );
};

const CardItem = ({ children }: { children: React.ReactNode }) => (
  <Box padding="0.25rem 0.5rem" style={{ flex: 'none' }}>
    <Stack row alignItems="center" gap="0.25rem">
      {children}
    </Stack>
  </Box>
);

const ArticlesTable = ({
  articles,
  mode,
}: {
  articles: ArticleWithRelations[];
  mode: 'task' | 'step';
}) => {
  const { showWorkshopColumn, costLabel, showPriceColumn, priceLabel } =
    useContext(ArticlesTableContext);
  const { _ } = useLingui();

  const isCompletedTable = articles[0]?.completed;
  const isArchivedTable = articles[0]?.archived;

  return (
    <Table
      aria-label={_(msg({ id: 'request.articles.table.label', message: 'Items' }))}
      extraSidePadding
    >
      <Header noRadius>
        <Column isRowHeader width="2fr" minWidth={200}>
          <Trans id="request.articles.table.label">Items</Trans>
        </Column>
        {!isCompletedTable && (
          <Column width="1fr" minWidth={140}>
            {mode === 'task' && (
              <Trans id="request.articles.table.column.task.label">Task to do</Trans>
            )}
            {mode === 'step' && <Trans id="request.articles.table.column.step.label">Step</Trans>}
          </Column>
        )}
        {!isArchivedTable && (
          <Column width="1fr" minWidth={128}>
            {mode === 'task' && (
              <Trans id="request.articles.table.column.task-due-date.label">Task due date</Trans>
            )}
            {mode === 'step' && (
              <Trans id="request.articles.table.column.step-due-date.label">Step due date</Trans>
            )}
          </Column>
        )}
        <Column width="2fr" minWidth={128}>
          <Trans id="request.articles.table.column.service-type.label">Service Type</Trans>
        </Column>
        {showWorkshopColumn && (
          <Column width="1fr" minWidth={160}>
            <Trans id="request.articles.table.column.workshop.label">Workshop</Trans>
          </Column>
        )}
        {!showWorkshopColumn && (
          <Column minWidth={80} align="end">
            {costLabel}
          </Column>
        )}
        {showPriceColumn && (
          <Column minWidth={80} align="end">
            {priceLabel}
          </Column>
        )}
      </Header>
      <Body>
        {articles.map((article) => (
          <ArticleRow key={article.id} article={article} mode={mode} />
        ))}
      </Body>
    </Table>
  );
};
const ArticleRow = ({
  article,
  mode,
}: {
  article: ArticleWithRelations;
  mode: 'task' | 'step';
}) => {
  const { showWorkshopColumn, showPriceColumn } = useContext(ArticlesTableContext);

  return (
    <Row
      id={article.id}
      style={article.archived ? { opacity: 0.3 } : undefined}
      href={`/requests/${article.requestId}/articles/${article.id}`}
    >
      <Cell>
        <ArticleNameCell article={article} />
      </Cell>
      {!article.completed && (
        <Cell align="stretch">
          <ArticleStepper size="medium" article={article} mode={mode} />
        </Cell>
      )}

      {!article.archived && (
        <Cell>
          {article.statusDueAtDate ? (
            <StatusDueDate date={article.statusDueAtDate} displayDayMonthOnly variant="row" />
          ) : (
            '-'
          )}
        </Cell>
      )}
      <Cell>
        <ArticleServiceTypeCell article={article} />
      </Cell>
      {showWorkshopColumn && (
        <Cell>
          <ArticleWorkshopCell article={article} showCost />
        </Cell>
      )}
      {!showWorkshopColumn && (
        <Cell align="end">
          <ArticleCostCell article={article} />
        </Cell>
      )}
      {showPriceColumn && (
        <Cell align="end">
          <ArticlePriceCell article={article} />
        </Cell>
      )}
    </Row>
  );
};

const ArticleServiceTypeCell = ({ article }: { article: ArticleWithRelations }) => {
  const numberOfActions = article.numberOfActions;
  return (
    <Stack>
      <span className="paragraph-100-medium">
        <Trans id="request.articles.table.column.service-type.care-and-repair">Care & repair</Trans>
      </span>
      <span className="paragraph-200-regular text-secondary">
        <Trans id="request.articles.table.column.service-type.actions">
          {numberOfActions} <Plural value={numberOfActions} one="action" other="actions" />
        </Trans>
      </span>
    </Stack>
  );
};
